import routes, { BatchDefaultsEditMode } from "components/routes";
import locationUtils from "utils/locationUtils";

interface MenuItem {
  route: string;
  title: string;
  isActive: boolean;
}

const getBatchDefaultMenuItems = function (
  batchKey: string,
  mode: BatchDefaultsEditMode
): MenuItem[] {
  const growthRatesRoute = routes.batchdefaults.growthRates(batchKey, mode);
  const gtlAndLtlRoute = routes.batchdefaults.gtlAndLtl(batchKey, mode);
  const scalingRoute = routes.batchdefaults.scaling(batchKey, mode);
  const incentivesRoute = routes.batchdefaults.incentives(batchKey, mode);
  const exceptionsRoute = routes.batchdefaults.exceptions(batchKey, mode);
  const exportTermsRoute = routes.batchdefaults.exportTerms(batchKey, mode);
  const renewalNoticeRoute = routes.batchdefaults.renewalNotice(batchKey, mode);

  return [
    {
      title: "Growth Rates",
      route: growthRatesRoute,
      isActive: locationUtils.isRouteCurrentlyActive(growthRatesRoute)
    },
    {
      title: "LTL/GTL",
      route: gtlAndLtlRoute,
      isActive: locationUtils.isRouteCurrentlyActive(gtlAndLtlRoute)
    },
    {
      title: "Scaling",
      route: scalingRoute,
      isActive: locationUtils.isRouteCurrentlyActive(scalingRoute)
    },
    {
      title: "Incentives",
      route: incentivesRoute,
      isActive: locationUtils.isRouteCurrentlyActive(incentivesRoute)
    },
    {
      title: "Exceptions",
      route: exceptionsRoute,
      isActive: locationUtils.isRouteCurrentlyActive(exceptionsRoute)
    },
    {
      title: "Lease Term Export",
      route: exportTermsRoute,
      isActive: locationUtils.isRouteCurrentlyActive(exportTermsRoute)
    },
    {
      title: "Notice Period",
      route: renewalNoticeRoute,
      isActive: locationUtils.isRouteCurrentlyActive(renewalNoticeRoute)
    }
  ];
};

export default getBatchDefaultMenuItems;
