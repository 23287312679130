const getErrorForField = function(
  fieldValidationErrors: { [key: string]: string },
  field: string
): string | undefined {
  for (const prop in fieldValidationErrors) {
    if (
      fieldValidationErrors.hasOwnProperty(prop) &&
      prop.toLowerCase() === field.toLowerCase()
    ) {
      return fieldValidationErrors[prop];
    }
  }

  return;
};

export default {
  getErrorForField
};
