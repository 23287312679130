import { createThemedUseStyles } from "theme";
import { SecondaryButtonProps } from ".";

export default createThemedUseStyles(theme => ({
  button: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: theme.typeScale.medium,
    padding: "12px 14px",
    minWidth: theme.sizeScale.large,
    width: "auto",
    cursor: "pointer",
    textAlign: "center",
    minHeight: "43px",
    margin: "center",
    fontWeight: "700",
    letterSpacing: "normal",
    textDecoration: "none",
    transition: "transform .2s ease-in-out",
    borderRadius: "3px",
    border: "2px solid",
    color: (props: SecondaryButtonProps) => {
      switch (props.type) {
        case "important":
          return theme.buttons.secondaryImportant.foreground;
        case "notice":
          return theme.buttons.secondaryNotice.foreground;
        case "neutral":
        default:
          return theme.buttons.secondary.foreground;
      }
    },
    borderColor: (props: SecondaryButtonProps) => {
      if (props.disabled) {
        return theme.pallette.inactive;
      }

      switch (props.type) {
        case "important":
          return theme.buttons.secondaryImportant.border;
        case "notice":
          return theme.buttons.secondaryNotice.border;
        case "neutral":
        default:
          return theme.buttons.secondary.border;
      }
    },
    backgroundColor: (props: SecondaryButtonProps) => {
      if (props.disabled) {
        return theme.pallette.inactive;
      }

      switch (props.type) {
        case "important":
          return theme.buttons.secondaryImportant.background;
        case "notice":
          return theme.buttons.secondaryNotice.background;
        case "neutral":
        default:
          return theme.buttons.secondary.background;
      }
    },
    "&:hover": {
      color: (props: SecondaryButtonProps) => {
        if (props.disabled) {
          return theme.pallette.inactive;
        }

        switch (props.type) {
          case "important":
            return theme.buttons.secondaryImportant.foregroundFocus;
          case "notice":
            return theme.buttons.secondaryNotice.foregroundFocus;
          case "neutral":
          default:
            return theme.buttons.secondary.foregroundFocus;
        }
      },
      borderColor: (props: SecondaryButtonProps) => {
        if (props.disabled) {
          return theme.pallette.inactive;
        }

        switch (props.type) {
          case "important":
            return theme.buttons.secondaryImportant.borderFocus;
          case "notice":
            return theme.buttons.secondaryNotice.borderFocus;
          case "neutral":
          default:
            return theme.buttons.secondary.borderFocus;
        }
      },
      backgroundColor: (props: SecondaryButtonProps) => {
        if (props.disabled) {
          return theme.pallette.inactive;
        }

        switch (props.type) {
          case "important":
            return theme.buttons.secondaryImportant.backgroundFocus;
          case "notice":
            return theme.buttons.secondaryNotice.backgroundFocus;
          case "neutral":
          default:
            return theme.buttons.secondary.backgroundFocus;
        }
      },
      transform: "scale(1.01) perspective(1px)"
    }
  },
  title: {}
}));
