import React from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { withLabel } from "../Label";

export interface SearchboxProps {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  mode?: "search" | "filter";
}

const Searchbox: React.FC<SearchboxProps> = function(props) {
  return (
    <div className="p-inputgroup">
      <InputText
        value={props.value}
        onChange={e => props.onChange(e.currentTarget.value)}
      />
      <Button icon={`pi pi-${props.mode || "search"}`} />
    </div>
  );
};

Searchbox.defaultProps = {
  mode: "search"
};

export default withLabel(Searchbox);
