import React from "react";
import Spacer from "../Spacer";
import useStyle from "./useStyle";
import cssUtils from "utils/cssUtils";

export type LabelPosition = "top" | "left" | "bottom" | "right"; // todo: Implement

export interface LabelProps {
  label?: string | null | undefined;
  labelPosition?: LabelPosition;
  className?: string | undefined;
}

const Label: React.FC<LabelProps> = function(props) {
  const classes = useStyle();
  let labelClass;

  switch (props.labelPosition) {
    case "top":
      labelClass = classes.topLabel;
      break;
    case "left":
      labelClass = classes.leftLabel;
      break;
    case "right":
      labelClass = classes.rightLabel;
      break;
    case "bottom":
      labelClass = classes.bottomLabel;
      break;
  }

  const className = props.className
    ? cssUtils.join(labelClass, classes.root, props.className)
    : cssUtils.join(labelClass, classes.root);

  return <span className={className}>{props.label}</span>;
};

export function withLabel<P extends {}>(
  component: React.ComponentClass<P> | React.FC<P>
) {
  const Inner = component;
  const hoc = (props: P & LabelProps) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const classes = useStyle();
    const { label, ...innerProps } = props;
    if (props.label == null) {
      return <Inner {...(innerProps as P)} />;
    }

    if (props.labelPosition === "left") {
      return (
        <div className={classes.withLabel}>
          <Label label={props.label} labelPosition={props.labelPosition} />
          <Spacer orientation="h" />
          <Inner {...(innerProps as P)} />
        </div>
      );
    }
    if (props.labelPosition === "right") {
      return (
        <div className={classes.withLabel}>
          <Inner {...(innerProps as P)} />
          <Spacer orientation="h" />
          <Label label={props.label} labelPosition={props.labelPosition} />
        </div>
      );
    }

    return (
      <div>
        <div>
          <Label label={props.label} labelPosition={props.labelPosition} />
        </div>
        <Spacer size="xs" />
        <div>
          <Inner {...(innerProps as P)} />
        </div>
      </div>
    );
  };
  hoc.displayName = `withLabel(${component.name})`;
  return hoc;
}

Label.defaultProps = {
  labelPosition: "top"
};

export default Label;
