import React from "react";
import Menu, { MenuItemProps } from "components/forms/Menu";
import routes from "components/routes";
import locationUtils from "utils/locationUtils";

export interface MenuProps {
  title?: string | undefined;
  items?: MenuItemProps[];
  actionArea?: JSX.Element;
}

const BaselineDefaultsMenu: React.FC<MenuProps> = function (props: MenuProps) {
  const sampleMenuItems: MenuItemProps[] = [
    {
      title: "GROWTH RATES",
      to: routes.baselinedefaults.growthRates,
      isActive: locationUtils.isRouteCurrentlyActive(
        routes.baselinedefaults.growthRates
      )
    },
    {
      title: "LTL/GTL",
      to: routes.baselinedefaults.gtlAndLtl,
      isActive: locationUtils.isRouteCurrentlyActive(
        routes.baselinedefaults.gtlAndLtl
      )
    },
    {
      title: "SCALING",
      to: routes.baselinedefaults.scaling,
      isActive: locationUtils.isRouteCurrentlyActive(
        routes.baselinedefaults.scaling
      )
    },
    {
      title: "INCENTIVES",
      to: routes.baselinedefaults.incentives,
      isActive: locationUtils.isRouteCurrentlyActive(
        routes.baselinedefaults.incentives
      )
    },
    {
      title: "EXCEPTIONS",
      to: routes.baselinedefaults.exceptions,
      isActive: locationUtils.isRouteCurrentlyActive(
        routes.baselinedefaults.exceptions
      )
    },
    {
      title: "LEASE TERM EXPORT",
      to: routes.baselinedefaults.exportTerms,
      isActive: locationUtils.isRouteCurrentlyActive(
        routes.baselinedefaults.exportTerms
      )
    },
    {
      title: "NOTICE PERIOD",
      to: routes.baselinedefaults.renewalNotice,
      isActive: locationUtils.isRouteCurrentlyActive(
        routes.baselinedefaults.renewalNotice
      )
    }
  ];

  return <Menu {...props} title="DEFAULTS" items={sampleMenuItems} />;
};

export default BaselineDefaultsMenu;
