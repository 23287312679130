import React, { useContext } from "react";
import Menu from "../Menu";
import Page from "components/layouts/Page";
import useStyle from "./useStyle";
import Tile from "components/controls/Tile";
import RequirePermission from "../RequirePermission";
import batchesImageAsset from "assets/Tile-Apartment.jpg";
import createBatchImageAsset from "assets/Tile-Billiard.jpg";
import createOneOffImageAsset from "assets/Tile-OneOff.jpg";
import baseLineDefaultsImageAsset from "assets/Tile-Pool.jpg";
import alternateTileImageAsset from "assets/Home-Tile.jpg";
import regulationsImageAsset from "assets/Tile-Regulations.jpg";
import alternateTileImageAsset2 from "assets/header.jpg";
import { IdentityContext, Identity } from "components/IdentityProvider";
import Spinner from "components/controls/Spinner";
import routes from "components/routes";

const HomePage: React.FC = function () {
  const classes = useStyle();

  const identity: Identity = useContext(IdentityContext);

  if (!identity || !identity.isLoaded) {
    return <Spinner isVisible={true} />;
  }

  return (
    <Page menu={<Menu title="HOME" />}>
      <div className={classes.pageWrapper}>
        <div className={classes.column}>
          <RequirePermission permissions={["canCreateBatch"]}>
            <Tile
              title="Create Batch"
              href={routes.createBatch}
              assetName={createBatchImageAsset}
            />
          </RequirePermission>
          <RequirePermission permissions={["canViewBatchListForm"]}>
            <Tile
              title="Batches"
              href={routes.batchListPage}
              assetName={batchesImageAsset}
            />
          </RequirePermission>
          <RequirePermission permissions={["canCreateBatch"]}>
            <Tile
              title="Create One-Off"
              href={routes.createOneOff}
              assetName={createOneOffImageAsset}
            />
          </RequirePermission>
          <RequirePermission permissions={["canViewBaselineDefaultsForms"]}>
            <Tile
              title="Baseline Defaults"
              href={routes.baselinedefaults.growthRates}
              assetName={baseLineDefaultsImageAsset}
            />
          </RequirePermission>
          <RequirePermission permissions={["canViewBatchListForm"]}>
            <Tile
              title="Regulations"
              href={routes.regulations.bmr}
              assetName={regulationsImageAsset}
            />
          </RequirePermission>
          <RequirePermission permissions={["canAddEditDeleteUser"]}>
            <Tile
              title="Add User"
              href={routes.admin.addUser}
              assetName={alternateTileImageAsset}
            />
          </RequirePermission>
          <RequirePermission permissions={["canViewUserAdminForm"]}>
            <Tile
              title="View / Edit Users"
              href={routes.admin.users}
              assetName={alternateTileImageAsset2}
            />
          </RequirePermission>
        </div>
      </div>
    </Page>
  );
};

export default HomePage;
