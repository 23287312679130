import { OverlayPanel as PrimeOverlayPanel } from "primereact/overlaypanel";
import React from "react";
import useStyle from "./useStyle";
import cssUtils from "utils/cssUtils";

export type Checked = boolean | "mixed";

export interface OverlayPanelProps {
  onOpen?: () => void;
  onHide?: () => void;

  buttonTitle: string;
  buttonIcon: string;

  style?: object | undefined;
  className?: string | undefined;
}

const OverlayPanel: React.FC<OverlayPanelProps> = function (props) {
  const classes = useStyle(props);
  let primeOverlayPanelRef: PrimeOverlayPanel | null = null;

  return (
    <div>
      <i
        className={cssUtils.join(
          classes.button,
          props.buttonIcon,
          props.className
        )}
        onClick={(e) => {
          primeOverlayPanelRef && primeOverlayPanelRef.toggle(e);
          props.onOpen && props.onOpen();
        }}
      />
      <PrimeOverlayPanel
        ref={(el) => (primeOverlayPanelRef = el)}
        onHide={() => props.onHide && props.onHide()}
        style={props.style}
        appendTo={document.body}
      >
        {props.children}
      </PrimeOverlayPanel>
    </div>
  );
};

export default OverlayPanel;
