import React from "react";
import {
  BestRenewalOffersFormComment,
  BestRenewalOfferColumnConfiguration
} from "api/generated";
import ColumnSelectorSideBar from "components/forms/BestRenewalOffersPage/ColumnSelectorSideBar";
import utils from "utils";
import { SideBarState } from "components/store/AtlasStateTypes";
import PropertyCommentsSideBar from "components/forms/BestRenewalOffersPage/Comments/PropertyCommentsSideBar";
import UnitCommentsSideBar from "components/forms/BestRenewalOffersPage/Comments/UnitCommentsSideBar";
import BatchCommentsSideBar from "components/forms/BestRenewalOffersPage/Comments/BatchCommentsSideBar";
import { GenericFormComment } from "./GenericFormCommentTypes";

interface SideBarsProps {
  bestRenewalOffersKey: string;
  sideBarState: SideBarState;
  closeSideBars: () => void;
  updateColumnConfigurations?: (
    columnConfigurations: BestRenewalOfferColumnConfiguration[]
  ) => void;
  addPropertyComment: (
    bestRenewalOffersKey: string,
    propertyId: string,
    comment: string
  ) => void;
  addUnitComment: (
    bestRenewalOffersKey: string,
    unitKey: string,
    comment: string
  ) => void;
  deletePropertyComment: (
    bestRenewalOffersKey: string,
    propertyId: string,
    commentId: number
  ) => void;
  deleteUnitComment: (
    bestRenewalOffersKey: string,
    unitKey: string,
    commentId: number
  ) => void;
}

const SideBars: React.FC<SideBarsProps> = function (props: SideBarsProps) {
  const setPropertyNotes = (note: BestRenewalOffersFormComment) => {
    if (
      props.sideBarState.propertyNotesDisplayState.selectedProperty &&
      props.sideBarState.propertyNotesDisplayState.selectedProperty.key
    ) {
      props.addPropertyComment(
        props.bestRenewalOffersKey,
        props.sideBarState.propertyNotesDisplayState.selectedProperty.key,
        note.text || ""
      );
    }
  };

  const unit = props.sideBarState.unitNotesDisplayState.selectedUnit;

  return (
    <div>
      <PropertyCommentsSideBar
        bestRenewalOffersKey={props.bestRenewalOffersKey}
        isVisible={props.sideBarState.propertyNotesDisplayState.isVisible}
        property={
          props.sideBarState.propertyNotesDisplayState.selectedProperty || {}
        }
        onSave={(note: GenericFormComment) => setPropertyNotes(note)}
        onCancel={() => props.closeSideBars()}
        deletePropertyComment={props.deletePropertyComment}
      />
      <UnitCommentsSideBar
        bestRenewalOffersKey={props.bestRenewalOffersKey}
        isVisible={props.sideBarState.unitNotesDisplayState.isVisible}
        unitWithComments={unit || {}}
        onSave={(comment: GenericFormComment) => {
          if (
            !unit ||
            !unit.key ||
            !comment.text ||
            utils.stringIsNullOrEmpty(comment.text)
          ) {
            return;
          }

          props.addUnitComment(
            props.bestRenewalOffersKey,
            unit.key,
            comment.text
          );
        }}
        onCancel={() => props.closeSideBars()}
        deleteUnitComment={props.deleteUnitComment}
      />
      <BatchCommentsSideBar
        isVisible={props.sideBarState.batchNotesDisplayState.isVisible}
        batchComments={props.sideBarState.batchNotesDisplayState.batchComments}
        onCancel={() => props.closeSideBars()}
        deletePropertyComment={props.deletePropertyComment}
        deleteUnitComment={props.deleteUnitComment}
        bestRenewalOffersKey={props.bestRenewalOffersKey}
      />
      <ColumnSelectorSideBar
        isVisible={props.sideBarState.columnSelectorDisplayState.isVisible}
        columnConfigurations={
          props.sideBarState.columnSelectorDisplayState.columnConfigurations ||
          []
        }
        onClose={(
          columnConfigurations: BestRenewalOfferColumnConfiguration[]
        ) => {
          props.closeSideBars();
          props.updateColumnConfigurations &&
            props.updateColumnConfigurations(columnConfigurations);
        }}
      />
    </div>
  );
};

export default SideBars;
