import React, { useState } from "react";
import {
  Option,
  BaselineDefaultsScalingFormDefaults,
  BaselineDefaultsScalingFormBedroomType,
  BaselineDefaultsScalingFormPropertyType,
  DefaultsPageConfiguration
} from "api";
import createDataTable, {
  IPagedTableState,
  ColumnDefinition,
  DefaultInputEditorData,
  DataTableProps
} from "components/controls/createTable";

import Dropdown from "components/controls/Dropdown";
import listUtils from "utils/listUtils";

export interface DivisionDefaultsTableProps {
  defaults?: BaselineDefaultsScalingFormDefaults[] | undefined;
  bedrooms: BaselineDefaultsScalingFormBedroomType[];
  properties: BaselineDefaultsScalingFormPropertyType[];
  onChange: (defaults: BaselineDefaultsScalingFormDefaults[]) => void;
  hidePropertyColumn?: boolean | undefined;
  hideBedroomTypeColumn?: boolean | undefined;
  hideRemoveRowColumn?: boolean | undefined;
  paging?: boolean | undefined;
  onAddRowAction?: (() => void) | undefined;
  includeRemoveRowColumn?: "show" | "buffer" | undefined;
  pageConfiguration: DefaultsPageConfiguration | undefined;
}

interface TableState extends IPagedTableState {
  divisionFilter?: Option;
  sortField?: any;
  sortOrder?: any;
  sortState?: "unsorted" | "asc" | "desc" | undefined;
}

const DefaultsScalingFormTable: React.FC<DivisionDefaultsTableProps> = props => {
  const [table, setTable] = useState<TableState>({
    offset: 0,
    limit: 10,
    sortOrder: 1
  });

  const onRowChanged = (
    oldRow: BaselineDefaultsScalingFormDefaults,
    newRow: BaselineDefaultsScalingFormDefaults
  ) => {
    if (!props.defaults) {
      return;
    }

    props.onChange(
      listUtils.copyListWithNewItem(props.defaults, oldRow, newRow)
    );
  };

  const onRemoveRow = (row: BaselineDefaultsScalingFormDefaults) => {
    if (!props.defaults) {
      return;
    }

    const newDefaults = listUtils.copyListWithItemRemoved(props.defaults, row);
    props.onChange(newDefaults);
  };

  const onDefaultsPropertyChange = (
    defaultsRow: BaselineDefaultsScalingFormDefaults
  ) => {
    return (
      selectedProperty: BaselineDefaultsScalingFormPropertyType | undefined
    ) => {
      const newDefaultsRow: BaselineDefaultsScalingFormDefaults = {
        ...defaultsRow,
        selectedPropertyType: selectedProperty
      };

      onRowChanged(defaultsRow, newDefaultsRow);
    };
  };

  const onDefaultsBedroomChange = (
    defaultsRow: BaselineDefaultsScalingFormDefaults
  ) => {
    return (
      selectedBedroom: BaselineDefaultsScalingFormBedroomType | undefined
    ) => {
      const newDefaultsRow: BaselineDefaultsScalingFormDefaults = {
        ...defaultsRow,
        selectedBedroomType: selectedBedroom
      };

      onRowChanged(defaultsRow, newDefaultsRow);
    };
  };

  const getPropertyDefaultsBody = function(rowData: any, column: any) {
    const defaults = rowData as BaselineDefaultsScalingFormDefaults;

    let dropDown = (
      <Dropdown
        onChange={onDefaultsPropertyChange(defaults)}
        selectedValue={defaults.selectedPropertyType}
        options={props.properties}
        style={{ minWidth: "100%", maxWidth: "100%" }}
        validationMessage={
          defaults.fieldValidationErrors &&
          defaults.fieldValidationErrors["SelectedPropertyType"]
        }
      />
    );

    let label = (
      <div>
        {defaults.selectedPropertyType &&
          defaults.selectedPropertyType.displayValue}
      </div>
    );

    if (props.pageConfiguration && props.pageConfiguration.isEditEnabled) {
      return dropDown;
    } else {
      return label;
    }
  };

  const getBedroomDefaultsBody = function(rowData: any, column: any) {
    const defaults = rowData as BaselineDefaultsScalingFormDefaults;

    let dropDown = (
      <Dropdown
        onChange={onDefaultsBedroomChange(defaults)}
        selectedValue={defaults.selectedBedroomType}
        options={props.bedrooms}
        style={{ minWidth: "100%", maxWidth: "100%" }}
        validationMessage={
          defaults.fieldValidationErrors &&
          defaults.fieldValidationErrors["SelectedBedroomType"]
        }
      />
    );

    let label = (
      <div>
        {defaults.selectedBedroomType &&
          defaults.selectedBedroomType.displayValue}
      </div>
    );

    if (props.pageConfiguration && props.pageConfiguration.isEditEnabled) {
      return dropDown;
    } else {
      return label;
    }
  };

  const getDefaultInputEditorData = function(): DefaultInputEditorData<BaselineDefaultsScalingFormDefaults> {
    return {
      isEditDisabled: !(
        props.pageConfiguration && props.pageConfiguration.isEditEnabled
      ),
      onChange: (
        row: BaselineDefaultsScalingFormDefaults,
        field: keyof BaselineDefaultsScalingFormDefaults,
        value: any
      ) => {
        const newDefaultsRow = {
          ...row
        };

        newDefaultsRow[field] = value;
        onRowChanged(row, newDefaultsRow);
      }
    };
  };

  const getColumns = function(): any[] {
    const columns = [] as ColumnDefinition<
      BaselineDefaultsScalingFormDefaults,
      keyof BaselineDefaultsScalingFormDefaults
    >[];

    const propertyColumnStyle = { width: "20%" };
    const bedroomTypeColumnStyle = { width: "15%" };

    if (!props.hidePropertyColumn) {
      columns.push({
        header: "Property",
        style: propertyColumnStyle,
        body: getPropertyDefaultsBody
      });
    } else {
      columns.push({ header: "", style: propertyColumnStyle });
    }

    if (!props.hideBedroomTypeColumn) {
      columns.push({
        header: "Bedroom",
        style: bedroomTypeColumnStyle,
        body: getBedroomDefaultsBody
      });
    } else {
      columns.push({ header: "", style: bedroomTypeColumnStyle });
    }

    const monthStyle = { width: "5%" };
    return columns.concat([
      {
        field: "oneMonth",
        header: "1mo",
        style: monthStyle,
        defaultInputEditorData: getDefaultInputEditorData()
      },
      {
        field: "twoMonths",
        header: "2mo",
        style: monthStyle,
        defaultInputEditorData: getDefaultInputEditorData()
      },
      {
        field: "threeMonths",
        header: "3mo",
        style: monthStyle,
        defaultInputEditorData: getDefaultInputEditorData()
      },
      {
        field: "fourMonths",
        header: "4mo",
        style: monthStyle,
        defaultInputEditorData: getDefaultInputEditorData()
      },
      {
        field: "fiveMonths",
        header: "5mo",
        style: monthStyle,
        defaultInputEditorData: getDefaultInputEditorData()
      },
      {
        field: "sixMonths",
        header: "6mo",
        style: monthStyle,
        defaultInputEditorData: getDefaultInputEditorData()
      },
      {
        field: "sevenMonths",
        header: "7mo",
        style: monthStyle,
        defaultInputEditorData: getDefaultInputEditorData()
      },
      {
        field: "eightMonths",
        header: "8mo",
        style: monthStyle,
        defaultInputEditorData: getDefaultInputEditorData()
      },
      {
        field: "nineMonths",
        header: "9mo",
        style: monthStyle,
        defaultInputEditorData: getDefaultInputEditorData()
      },
      {
        field: "tenMonths",
        header: "10mo",
        style: monthStyle,
        defaultInputEditorData: getDefaultInputEditorData()
      },
      {
        field: "elevenMonths",
        header: "11mo",
        style: monthStyle,
        defaultInputEditorData: getDefaultInputEditorData()
      },
      {
        field: "twelveMonths",
        header: "12mo",
        style: monthStyle,
        defaultInputEditorData: getDefaultInputEditorData()
      },
      {
        field: "thirteenMonths",
        header: "13mo",
        style: monthStyle,
        defaultInputEditorData: getDefaultInputEditorData()
      },
      {
        field: "fourteenMonths",
        header: "14mo",
        style: monthStyle,
        defaultInputEditorData: getDefaultInputEditorData()
      },
      {
        field: "fifteenMonths",
        header: "15mo",
        style: monthStyle,
        defaultInputEditorData: getDefaultInputEditorData()
      }
    ]);
  };

  const tableProps: DataTableProps<
    BaselineDefaultsScalingFormDefaults,
    TableState
  > = {
    value: props.defaults || undefined,
    editMode: "cell",
    state: table,
    updateState: setTable,
    paging: props.paging,
    // TODO: This functionality only works when added at this leve. I was unable to get it to work when I moved it up to the createDataTable function
    sortField: table.sortField,
    sortOrder: table.sortOrder,
    onSort: (e: any) => {
      //clear sort column
      if (
        table.sortOrder === -1 &&
        e.sortOrder === 1 &&
        table.sortField === e.sortField
      ) {
        setTable({
          ...table,
          sortField: undefined,
          sortOrder: undefined
        });
      } else {
        setTable({
          ...table,
          sortField: e.sortField,
          sortOrder: e.sortOrder
        });
      }
    },
    onAddRowAction: props.onAddRowAction,
    onRemoveRowAction:
      props.includeRemoveRowColumn === "show" ? onRemoveRow : undefined,
    hasRemoveRowBuffer: props.includeRemoveRowColumn === "buffer",
    emptyMessage: "No defaults have been added."
  };
  const dataTable = createDataTable<
    BaselineDefaultsScalingFormDefaults,
    TableState
  >(getColumns(), tableProps);

  return <div>{dataTable}</div>;
};

export default DefaultsScalingFormTable;
