import React from "react";
import useStyle from "./useStyle";

export interface PanelProps {}

const Panel: React.FC<PanelProps> = function(props) {
  const classes = useStyle(props);

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>{props.children}</div>
    </div>
  );
};

export default Panel;
