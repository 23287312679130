import { createThemedUseStyles } from "theme";
import { SpacerProps } from ".";

export default createThemedUseStyles(theme => ({
  spacer: {
    display: "block",
    width: ({ size, orientation }: SpacerProps) =>
      orientation !== "h"
        ? 0
        : size === "xs"
        ? theme.marginScale.xsmall
        : size === "s"
        ? theme.marginScale.small
        : size === "m"
        ? theme.marginScale.medium
        : size === "l"
        ? theme.marginScale.large
        : size === "xl"
        ? theme.marginScale.xlarge
        : size === "xxl"
        ? theme.marginScale.xxlarge
        : theme.marginScale.medium,

    height: ({ size, orientation }: SpacerProps) =>
      orientation !== "v"
        ? 0
        : size === "xs"
        ? theme.marginScale.xsmall
        : size === "s"
        ? theme.marginScale.small
        : size === "m"
        ? theme.marginScale.medium
        : size === "l"
        ? theme.marginScale.large
        : size === "xl"
        ? theme.marginScale.xlarge
        : size === "xxl"
        ? theme.marginScale.xxlarge
        : theme.marginScale.medium
  }
}));
