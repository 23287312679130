import React, { useState } from "react";
import Page from "components/layouts/Page";
import {
  DefaultsAdminGrowthRatesForm,
  DefaultsAdminGrowthRatesFormClient,
  ApiException
} from "api";
import RequirePermission from "../RequirePermission";
import BaselineDefaultsMenu from "../BaselineDefaultsMenu";
import { useRemoteData, responseHandlers } from "components/useRemoteData";
import { useApiEndpoint } from "components/useApiEndpoint";
import DefaultsGrowthRatesForm from "components/forms/DefaultsGrowthRatesForm";
import Spinner from "components/controls/Spinner";
import useMessageArea from "components/useMessageArea";
import PrimaryButton from "components/controls/PrimaryButton";
import Spacer from "components/controls/Spacer";
import UnsavedChangesPrompt from "components/controls/UnsavedChangesPrompt";
import defaultsUtils, { IsChangeable } from "../defaultsUtils";
import VisibilityToggle from "components/controls/VisibilityToggle";

interface PageState {
  form: DefaultsAdminGrowthRatesForm;
}

const DefaultAdminGrowthRatesPage: React.FC = function() {
  const initialState = {};
  const [page, setPage] = useState<PageState>({
    form: initialState
  });

  const [
    messageAreaState,
    setMessageBasedOnForm,
    setMessageBasedOnApiException
  ] = useMessageArea();

  const setForm = function(form: DefaultsAdminGrowthRatesForm | null) {
    if (!form) {
      return;
    }

    setPage({
      ...page,
      form: {
        ...form
      }
    });
  };

  const [dataState] = useRemoteData(
    useApiEndpoint(
      DefaultsAdminGrowthRatesFormClient,
      "getDefaultsAdminGrowthRatesForm"
    ),
    [],
    {
      autoFetch: true,
      onChange: d => {
        setPage({ ...page, form: d || initialState });
      }
    }
  );

  const [submitState, submit] = useRemoteData(
    useApiEndpoint(
      DefaultsAdminGrowthRatesFormClient,
      "saveDefaultsAdminGrowthRatesForm"
    ),
    [page.form],
    {
      onChange: form => {
        setMessageBasedOnForm(form);
        setForm(form);
      },
      onError: (error: ApiException) => {
        responseHandlers.use({
          "400": (ex: ApiException) => {
            const form = ex.result;
            setMessageBasedOnForm(form);
            setForm(form);
          },
          _: ex => {
            setMessageBasedOnApiException(ex);
          }
        })(error);
      }
    }
  );

  const spinner = (
    <Spinner isVisible={dataState.isFetching || submitState.isFetching} />
  );

  //See T-4258 for refactoring improvements
  if (
    (page.form == null || page.form.divisions == null) &&
    (dataState.isFetching || submitState.isFetching)
  ) {
    return spinner;
  } else if (page.form == null) {
    return messageAreaState.messageArea;
  }

  let isPageDirty =
    defaultsUtils.isFormChanged(page.form.selectedDivision as IsChangeable) ||
    defaultsUtils.areFormsChanged(page.form.divisions as IsChangeable[]);

  const actionArea = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <UnsavedChangesPrompt isVisible={isPageDirty} />
      <Spacer orientation="h" size="xl" />
      <VisibilityToggle
        isVisible={
          page.form.pageConfiguration &&
          page.form.pageConfiguration.isEditEnabled
        }
      >
        <PrimaryButton
          disabled={submitState.isFetching}
          onClick={() => submit([page.form])}
          title="SAVE"
        />
      </VisibilityToggle>
    </div>
  );

  return (
    <Page menu={<BaselineDefaultsMenu actionArea={actionArea} />}>
      <RequirePermission
        permissions={["canViewBaselineDefaultsForms"]}
        accessDenied="page"
      >
        {spinner}
        {messageAreaState.messageArea}
        <DefaultsGrowthRatesForm
          isFetching={submitState.isFetching}
          isSuccessful={submitState.isSuccessful}
          submit={() => submit([page.form])}
          setForm={setForm}
          form={page.form}
          hideSaveButton={true}
          pageConfiguration={page.form.pageConfiguration}
        />
      </RequirePermission>
    </Page>
  );
};

export default DefaultAdminGrowthRatesPage;
