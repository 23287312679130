import React, { useState } from "react";
import {
  Option,
  BaselineDefaultsExceptionsFormPropertyType,
  BaselineDefaultsExceptionsFormDefaults,
  BaselineDefaultsExceptionsFormCountyType,
  DefaultsAdminGrowthRatesFormPropertyType,
  DefaultsPageConfiguration
} from "api";
import createDataTable, {
  IPagedTableState,
  ColumnDefinition,
  DefaultInputEditorData,
  DataTableProps
} from "components/controls/createTable";

import Dropdown from "components/controls/Dropdown";
import listUtils from "utils/listUtils";

export interface DefaultsExceptionsFormTableProps {
  defaults?: BaselineDefaultsExceptionsFormDefaults[] | undefined;
  counties: BaselineDefaultsExceptionsFormCountyType[];
  properties: DefaultsAdminGrowthRatesFormPropertyType[];
  onChange: (defaults: BaselineDefaultsExceptionsFormDefaults[]) => void;
  hidePropertyColumn?: boolean | undefined;
  hideCountyTypeColumn?: boolean | undefined;
  hideRemoveRowColumn?: boolean | undefined;
  paging?: boolean | undefined;
  onAddRowAction?: (() => void) | undefined;
  includeRemoveRowColumn?: "show" | "buffer" | undefined;
  pageConfiguration: DefaultsPageConfiguration | undefined;
}

interface TableState extends IPagedTableState {
  divisionFilter?: Option;
  sortField?: any;
  sortOrder?: any;
  sortState?: "unsorted" | "asc" | "desc" | undefined;
}

const DefaultsExceptionsFormTable: React.FC<DefaultsExceptionsFormTableProps> = function(
  props: DefaultsExceptionsFormTableProps
) {
  const [table, setTable] = useState<TableState>({
    offset: 0,
    limit: 10,
    sortOrder: 1
  });

  const onRowChanged = (
    oldRow: BaselineDefaultsExceptionsFormDefaults,
    newRow: BaselineDefaultsExceptionsFormDefaults
  ) => {
    if (!props.defaults) {
      return;
    }

    props.onChange(
      listUtils.copyListWithNewItem(props.defaults, oldRow, newRow)
    );
  };

  const onRemoveRow = (row: BaselineDefaultsExceptionsFormDefaults) => {
    if (!props.defaults) {
      return;
    }

    const newDefaults = listUtils.copyListWithItemRemoved(props.defaults, row);
    props.onChange(newDefaults);
  };

  const onDefaultsExceptionsFormPropertyChange = (
    defaultsRow: BaselineDefaultsExceptionsFormDefaults
  ) => {
    return (
      selectedProperty: BaselineDefaultsExceptionsFormPropertyType | undefined
    ) => {
      const newDefaultsRow: BaselineDefaultsExceptionsFormDefaults = {
        ...defaultsRow,
        selectedPropertyType: selectedProperty
      };

      onRowChanged(defaultsRow, newDefaultsRow);
    };
  };

  const onDefaultsExceptionsFormCountyChange = (
    defaultsRow: BaselineDefaultsExceptionsFormDefaults
  ) => {
    return (
      selectedCountyType: BaselineDefaultsExceptionsFormCountyType | undefined
    ) => {
      const newDefaultsRow: BaselineDefaultsExceptionsFormDefaults = {
        ...defaultsRow,
        selectedCountyType: selectedCountyType
      };

      onRowChanged(defaultsRow, newDefaultsRow);
    };
  };

  const getPropertyDefaultsBody = function(rowData: any, column: any) {
    const defaults = rowData as BaselineDefaultsExceptionsFormDefaults;

    let dropDown = (
      <Dropdown
        onChange={onDefaultsExceptionsFormPropertyChange(defaults)}
        validationMessage={
          defaults.fieldValidationErrors &&
          defaults.fieldValidationErrors["SelectedPropertyType"]
        }
        selectedValue={defaults.selectedPropertyType}
        options={props.properties}
        style={{ minWidth: "100%", maxWidth: "100%" }}
      />
    );

    let label = (
      <div>
        {defaults.selectedPropertyType &&
          defaults.selectedPropertyType.displayValue}
      </div>
    );

    if (props.pageConfiguration && props.pageConfiguration.isEditEnabled) {
      return dropDown;
    } else {
      return label;
    }
  };

  const getCountyDefaultsBody = function(rowData: any, column: any) {
    const defaults = rowData as BaselineDefaultsExceptionsFormDefaults;

    let dropDown = (
      <Dropdown
        onChange={onDefaultsExceptionsFormCountyChange(defaults)}
        selectedValue={defaults.selectedCountyType}
        options={props.counties}
        style={{ minWidth: "100%", maxWidth: "100%" }}
        validationMessage={
          defaults.fieldValidationErrors &&
          defaults.fieldValidationErrors["SelectedCountyType"]
        }
      />
    );

    let label = (
      <div>
        {defaults.selectedCountyType &&
          defaults.selectedCountyType.displayValue}
      </div>
    );

    if (props.pageConfiguration && props.pageConfiguration.isEditEnabled) {
      return dropDown;
    } else {
      return label;
    }
  };

  const getDefaultInputEditorData = function(): DefaultInputEditorData<BaselineDefaultsExceptionsFormDefaults> {
    return {
      isEditDisabled: !(
        props.pageConfiguration && props.pageConfiguration.isEditEnabled
      ),
      onChange: (
        row: BaselineDefaultsExceptionsFormDefaults,
        field: keyof BaselineDefaultsExceptionsFormDefaults,
        value: any
      ) => {
        const newDefaultsRow = {
          ...row
        };

        newDefaultsRow[field] = value;
        onRowChanged(row, newDefaultsRow);
      }
    };
  };

  const getColumns = function(): any[] {
    const columns = [] as ColumnDefinition<
      BaselineDefaultsExceptionsFormDefaults,
      keyof BaselineDefaultsExceptionsFormDefaults
    >[];

    const countyDropdownColumnStyle = { width: "10%" };
    const propertyDropdownColumnStyle = { width: "20%" };

    if (!props.hidePropertyColumn) {
      columns.push({
        header: "Property",
        style: propertyDropdownColumnStyle,
        body: getPropertyDefaultsBody
      });
    } else {
      columns.push({ header: "", style: propertyDropdownColumnStyle });
    }

    if (!props.hideCountyTypeColumn) {
      columns.push({
        header: "County",
        style: countyDropdownColumnStyle,
        body: getCountyDefaultsBody
      });
    } else {
      columns.push({ header: "", style: countyDropdownColumnStyle });
    }

    return columns.concat([
      {
        field: "propertyAverageBelowPercentage",
        header: "Property Average Below %",
        style: { width: "8%", textAlign: "center" },
        defaultInputEditorData: getDefaultInputEditorData()
      },
      {
        field: "propertyAverageAbovePercentage",
        header: "Property Average Above %",
        style: { width: "8%", textAlign: "center" },
        defaultInputEditorData: getDefaultInputEditorData()
      },
      {
        field: "propertyLtlThresholdPercentage",
        header: "Property LTL Threshold %",
        style: { width: "7%", textAlign: "center" },
        defaultInputEditorData: getDefaultInputEditorData()
      },
      {
        field: "propertyGtlThresholdPercentage",
        header: "Property GTL Threshold %",
        style: { width: "7%", textAlign: "center" },
        defaultInputEditorData: getDefaultInputEditorData()
      },
      {
        field: "unitLtlThresholdPercentage",
        header: "Unit LTL Threshold %",
        style: { width: "7%", textAlign: "center" },
        defaultInputEditorData: getDefaultInputEditorData()
      },
      {
        field: "unitGtlThresholdPercentage",
        header: "Unit GTL Threshold %",
        style: { width: "7%", textAlign: "center" },
        defaultInputEditorData: getDefaultInputEditorData()
      }
    ]);
  };

  const tableProps: DataTableProps<
    BaselineDefaultsExceptionsFormDefaults,
    TableState
  > = {
    value: props.defaults || undefined,
    editMode: "cell",
    state: table,
    updateState: setTable,
    paging: props.paging,
    // TODO: This functionality only works when added at this leve. I was unable to get it to work when I moved it up to the createDataTable function
    sortField: table.sortField,
    sortOrder: table.sortOrder,
    onSort: (e: any) => {
      //clear sort column
      if (
        table.sortOrder === -1 &&
        e.sortOrder === 1 &&
        table.sortField === e.sortField
      ) {
        setTable({
          ...table,
          sortField: undefined,
          sortOrder: undefined
        });
      } else {
        setTable({
          ...table,
          sortField: e.sortField,
          sortOrder: e.sortOrder
        });
      }
    },
    onAddRowAction: props.onAddRowAction,
    onRemoveRowAction:
      props.includeRemoveRowColumn === "show" ? onRemoveRow : undefined,
    hasRemoveRowBuffer: props.includeRemoveRowColumn === "buffer",
    emptyMessage: "No defaults have been added."
  };

  const dataTable = createDataTable<
    BaselineDefaultsExceptionsFormDefaults,
    TableState
  >(getColumns(), tableProps);

  return <div>{dataTable}</div>;
};

export default DefaultsExceptionsFormTable;
