import React from "react";
import useStyle from "./useStyle";
import Image from "../Image";

export type Checked = boolean | "mixed";

export interface TileProps {
  assetName?: string | undefined;
  title?: string | undefined;
  href?: string | undefined;
}

const Tile: React.FC<TileProps> = function(props) {
  const classes = useStyle(props);
  const image = props.assetName ? (
    <Image className={classes.image} assetName={props.assetName} />
  ) : (
    <div />
  );

  return (
    <div className={classes.container}>
      <a className={classes.link} href={props.href}>
        <div className={classes.imageContainer}>{image}</div>
        <div className={classes.contentContainer}>
          <div className={classes.heading}>{props.title}</div>
        </div>
      </a>
    </div>
  );
};

export default Tile;
