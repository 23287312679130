import { Dialog as PrimeDialog } from "primereact/dialog";
import React from "react";

export type Checked = boolean | "mixed";

export interface DialogProps {
  header: string;
  footer: JSX.Element;
  onHide: () => void;
  isVisible: boolean;
}

export const defaultMixedIcon = "\\e90f";

const Dialog: React.FC<DialogProps> = function(props) {
  return (
    <PrimeDialog
      visible={props.isVisible}
      header={props.header}
      footer={props.footer}
      onHide={props.onHide}
      modal={true}
      style={{ width: "50vw", maxWidth: "600px" }}
    >
      {props.children}
    </PrimeDialog>
  );
};

export default Dialog;
