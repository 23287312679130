import {
  ActualResultsSummaryForm,
  ActualResultsSummaryFormClient,
  ActualResultsSummaryFormFilters
} from "api/generated";
import { makeRemoteRequest } from "components/useRemoteData";
import { FunctionPropertyNames } from "components/useApiEndpoint";
import { Identity } from "components/IdentityProvider";
import { AtlasActionTypes } from "../AtlasActionTypes";
import { AtlasHistory } from "../AtlasStateTypes";
import { AtlasActions } from "../AtlasActions";
import { getApiEndpoint } from "components/useApiEndpoint";
import utils from "utils";

export const ActualResultsSummaryPageActionMethods = {
  get: function(
    dispatcher: React.Dispatch<AtlasActions>,
    identity: Identity,
    history: AtlasHistory
  ) {
    const makeFormServerCall = function(
      methodName: FunctionPropertyNames<ActualResultsSummaryFormClient>,
      bestRenewalOffersKey: string,
      form: ActualResultsSummaryForm,
      successCallback: (
        returnValue: ActualResultsSummaryForm | null | undefined
      ) => void
    ) {
      dispatcher({
        type: AtlasActionTypes.UpdateLoadingState,
        loadingState: { isLoading: true }
      });
      makeRemoteRequest(
        getApiEndpoint(identity, ActualResultsSummaryFormClient, methodName),
        [bestRenewalOffersKey, form]
      )
        .then(response => {
          successCallback(response.data);
        })
        .catch(() => {
          dispatcher({
            type: AtlasActionTypes.ShowErrorState,
            messages: []
          });
        });
    };

    const getForm = function(bestRenewalOffersKey: string): void {
      dispatcher({
        type: AtlasActionTypes.UpdateLoadingState,
        loadingState: { isLoading: true }
      });

      makeRemoteRequest(
        getApiEndpoint(identity, ActualResultsSummaryFormClient, "getForm"),
        [bestRenewalOffersKey]
      )
        .then(response => {
          dispatcher({
            type: AtlasActionTypes.ActualResultsSummaryPageSetInitialForm,
            form: response.data as ActualResultsSummaryForm
          });
        })
        .catch(() => {
          dispatcher({
            type: AtlasActionTypes.ShowErrorState,
            messages: []
          });
        });
    };

    const updateFormState = function(form: ActualResultsSummaryForm): void {
      dispatcher({
        type: AtlasActionTypes.ActualResultsSummaryPageUpdateForm,
        form: form
      });
    };

    const updateFilters = function(
      key: string,
      form: ActualResultsSummaryForm,
      filters: ActualResultsSummaryFormFilters
    ): void {
      const mutableForm = utils.cloneObject(form);
      mutableForm.filters = filters;
      makeFormServerCall("getFormForFilters", key, mutableForm, formData => {
        dispatcher({
          type: AtlasActionTypes.ActualResultsSummaryPageUpdateForm,
          form: formData as ActualResultsSummaryForm
        });

        dispatcher({
          type: AtlasActionTypes.UpdateLoadingState,
          loadingState: { isLoading: false }
        });
      });
    };

    return {
      getForm,
      updateFormState,
      updateFilters
    };
  }
};
