// TODO: Extend this functionality to the rest of the Batch Defaults pages
export interface IsChangeable {
  isChanged: boolean;
}

const isFormChanged = (changeable: IsChangeable | undefined): boolean => {
  if (!changeable) {
    return false;
  }

  return changeable.isChanged === true;
};

const areFormsChanged = (changeables: IsChangeable[] | undefined): boolean => {
  if (!changeables) {
    return false;
  }

  return changeables.some(c => isFormChanged(c));
};

export default { isFormChanged, areFormsChanged };
