import React from "react";
import Column, { ColumnSize } from "components/controls/Column";
import Panel from "components/controls/Panel";

export interface ContentAreaProps {
  columnSize: ColumnSize;
}

const ContentArea: React.FC<ContentAreaProps> = function(props) {
  return (
    <Column size={props.columnSize}>
      <Panel>{props.children}</Panel>
    </Column>
  );
};

export default ContentArea;
