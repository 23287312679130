import React from "react";
import VisibilityToggle from "../VisibilityToggle";
import Spacer from "../Spacer";
import Label from "../Label";

interface NavPromptProps {
  isVisible: boolean;
  message: string;
  iconArea?: JSX.Element | undefined;
}

const NavPrompt: React.FC<NavPromptProps> = props => {
  return (
    <VisibilityToggle isVisible={props.isVisible}>
      {props.iconArea}
      <Spacer orientation="h" size="m" />
      <Label label={props.message} />
    </VisibilityToggle>
  );
};

export default NavPrompt;
