import { createUseStyles } from "react-jss";

export default createUseStyles({
  button: {
    cursor: "pointer",
    fontSize: "1.5em",
    display: "block",
    paddingRight: "5px"
  }
});
