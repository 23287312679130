import React from "react";
import useStyle from "./useStyle";
import PageSection from "components/controls/PageSection";

export interface PageProps {
  menu: React.ReactElement;
  overrideSection?: boolean | undefined;
}

const Page: React.FC<PageProps> = function(props) {
  const classes = useStyle();
  let bodyContent = <div>{props.children}</div>;
  if (!props.overrideSection) {
    bodyContent = <PageSection>{props.children}</PageSection>;
  }

  return (
    <div className={classes.page}>
      <div className={classes.menu}>{props.menu}</div>
      <div className={classes.body}>{bodyContent}</div>
    </div>
  );
};

export default Page;
