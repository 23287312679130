import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  innerRoot: {
    padding: "15px",
    minWidth: "150px"
  },
  searchBox: {
    width: "80%"
  },
  resultsMessage: {
    padding: "15px 0",
    fontSize: theme.typeScale.medium,
    fontWeight: "bold"
  }
}));
