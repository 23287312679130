import React from "react";
import useStyle from "./useStyle";
import { BatchDetailBatchType } from "api";
import FormattedBatchTypes from "../FormattedBatchTypes";
import { format } from "date-fns";

export interface BatchInformation {
  division?: string | undefined;
  batchTypes?: BatchDetailBatchType[] | undefined;
  startDate?: string | undefined;
  endDate?: string | undefined;
  version?: string | undefined;
  status?: string | undefined;
  approversVotedCount?: number;
  approversTotalCount?: number;
  lastUpdatedDate?: string | undefined;
}

interface Props {
  batchInformation: BatchInformation;
}

const BatchDefaultsBatchInformationArea: React.FC<Props> = function (
  props: Props
) {
  const classes = useStyle();

  const batchInformation = props.batchInformation;

  const rpmVotes =
    batchInformation.approversVotedCount == null ||
    batchInformation.approversTotalCount == null ? (
      <></>
    ) : (
      <div>
        <div className={classes.header}>RPM Status</div>
        <div>{`${batchInformation.approversVotedCount || 0} / ${
          batchInformation.approversTotalCount || 0
        }`}</div>
      </div>
    );

  const lastUpdatedDate = new Date(batchInformation.lastUpdatedDate as string);
  const adjustedLastUpdatedDate = lastUpdatedDate.setMinutes(
    lastUpdatedDate.getMinutes() - lastUpdatedDate.getTimezoneOffset()
  );

  const lastUpdated =
    batchInformation.lastUpdatedDate == null ? (
      <></>
    ) : (
      <div>
        <div className={classes.header}>Last Updated</div>
        <div className={classes.menuItem}>
          {format(adjustedLastUpdatedDate, "M/dd/yyyy")}
        </div>
      </div>
    );

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.header}>Division</div>
        <div className={classes.menuItem}>
          {batchInformation.division || "-"}
        </div>
      </div>
      <div>
        <div className={classes.header}>Type</div>
        <div className={classes.menuItem}>
          <FormattedBatchTypes batchTypes={batchInformation.batchTypes} />
        </div>
      </div>
      <div>
        <div className={classes.header}>Start</div>
        <div className={classes.menuItem}>
          {batchInformation.startDate || "-"}
        </div>
      </div>
      <div>
        <div className={classes.header}>End</div>
        <div className={classes.menuItem}>
          {batchInformation.endDate || "-"}
        </div>
      </div>
      <div>
        <div className={classes.header}>Version</div>
        <div className={classes.menuItem}>
          {batchInformation.version || "-"}
        </div>
      </div>
      <div>
        <div className={classes.header}>Status</div>
        <div className={classes.menuItem}>{batchInformation.status || "-"}</div>
      </div>
      {rpmVotes}
      {lastUpdated}
    </div>
  );
};

export default BatchDefaultsBatchInformationArea;
