import { createThemedUseStyles } from "theme";
import { ActionButtonProps } from ".";

export default createThemedUseStyles((theme) => ({
  menu: {},
  button: {
    height: (props: ActionButtonProps) =>
      props.isCondensed ? "unset" : "43px",
  },
  active: {
    backgroundColor: theme.pallette.primary,
  },
}));
