import { createThemedUseStyles } from "theme";
import { SpinnerProps } from ".";

export default createThemedUseStyles(theme => {
  const getWidth = (props: SpinnerProps) => {
    if (props.size === "auto") {
      return "auto";
    }

    return "100vw";
  };
  const getHeight = (props: SpinnerProps) => {
    if (props.size === "auto") {
      return "auto";
    }

    return "100vh";
  };
  const getPosition = (props: SpinnerProps) => {
    if (props.size === "auto") {
      return "static";
    }

    return "fixed";
  };
  return {
    root: {
      width: getWidth,
      minWidth: getWidth,
      maxWidth: getWidth,
      height: getHeight,
      minHeight: getHeight,
      maxHeight: getHeight,
      position: getPosition,
      top: "0",
      left: "0",
      zIndex: "10000", // TODO: make sure this is higher than everything prime react does.
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(66, 66, 66, 0.1)"
    }
  };
});
