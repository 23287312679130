import React, { useState } from "react";
import {
  Option,
  BaselineDefaultsIncentivesFormDefaults,
  BaselineDefaultsIncentivesFormBedroomType,
  BaselineDefaultsIncentivesFormPropertyType,
  BaselineDefaultsIncentivesFormIncentiveType,
  DefaultsPageConfiguration
} from "api";
import createDataTable, {
  IPagedTableState,
  ColumnDefinition,
  DefaultInputEditorData,
  DataTableProps
} from "components/controls/createTable";
import listUtils from "utils/listUtils";
import Dropdown from "components/controls/Dropdown";

export interface DivisionDefaultsTableProps {
  defaults?: BaselineDefaultsIncentivesFormDefaults[] | undefined;
  incentiveTypes: BaselineDefaultsIncentivesFormIncentiveType[];
  bedrooms: BaselineDefaultsIncentivesFormBedroomType[];
  properties: BaselineDefaultsIncentivesFormPropertyType[];
  onChange: (defaults: BaselineDefaultsIncentivesFormDefaults[]) => void;
  hideRemoveRowColumn?: boolean | undefined;
  columnOptions: "bedroomOnly" | "propertyOnly" | "propertyAndUnit" | "none";
  paging?: boolean | undefined;
  onAddRowAction?: (() => void) | undefined;
  includeRemoveRowColumn?: "show" | "buffer" | undefined;
  pageConfiguration: DefaultsPageConfiguration | undefined;
}

interface TableState extends IPagedTableState {
  divisionFilter?: Option;
  sortField?: any;
  sortOrder?: any;
  sortState?: "unsorted" | "asc" | "desc" | undefined;
}

const DefaultsIncentivesFormTable: React.FC<DivisionDefaultsTableProps> = props => {
  const [table, setTable] = useState<TableState>({
    offset: 0,
    limit: 10,
    sortOrder: 1
  });

  const onRowChanged = (
    oldRow: BaselineDefaultsIncentivesFormDefaults,
    newRow: BaselineDefaultsIncentivesFormDefaults
  ) => {
    if (!props.defaults) {
      return;
    }

    props.onChange(
      listUtils.copyListWithNewItem(props.defaults, oldRow, newRow)
    );
  };

  const onRemoveRow = (row: BaselineDefaultsIncentivesFormDefaults) => {
    if (!props.defaults) {
      return;
    }

    const newDefaults = listUtils.copyListWithItemRemoved(props.defaults, row);
    props.onChange(newDefaults);
  };

  const onDefaultsPropertyChange = (
    defaultsRow: BaselineDefaultsIncentivesFormDefaults
  ) => {
    return (
      selectedProperty: BaselineDefaultsIncentivesFormPropertyType | undefined
    ) => {
      const newDefaultsRow: BaselineDefaultsIncentivesFormDefaults = {
        ...defaultsRow,
        selectedPropertyType: selectedProperty,
        isChanged: true
      };

      onRowChanged(defaultsRow, newDefaultsRow);
    };
  };

  const onDefaultsBedroomChange = (
    defaultsRow: BaselineDefaultsIncentivesFormDefaults
  ) => {
    return (
      selectedBedroom: BaselineDefaultsIncentivesFormBedroomType | undefined
    ) => {
      const newDefaultsRow: BaselineDefaultsIncentivesFormDefaults = {
        ...defaultsRow,
        selectedBedroomType: selectedBedroom,
        isChanged: true
      };

      onRowChanged(defaultsRow, newDefaultsRow);
    };
  };

  const onDefaultsIncentiveTypeChange = (
    defaultsRow: BaselineDefaultsIncentivesFormDefaults
  ) => {
    return (
      selectedIncentiveType:
        | BaselineDefaultsIncentivesFormIncentiveType
        | undefined
    ) => {
      const newDefaultsRow: BaselineDefaultsIncentivesFormDefaults = {
        ...defaultsRow,
        selectedIncentiveType: selectedIncentiveType,
        isChanged: true
      };

      onRowChanged(defaultsRow, newDefaultsRow);
    };
  };

  const getPropertyDefaultsBody = function(rowData: any, column: any) {
    const defaults = rowData as BaselineDefaultsIncentivesFormDefaults;

    let dropDown = (
      <Dropdown
        onChange={onDefaultsPropertyChange(defaults)}
        validationMessage={
          defaults.fieldValidationErrors &&
          defaults.fieldValidationErrors["SelectedPropertyType"]
        }
        selectedValue={defaults.selectedPropertyType}
        options={props.properties}
        style={{ minWidth: "100%", maxWidth: "100%" }}
      />
    );

    let label = (
      <div>
        {defaults.selectedPropertyType &&
          defaults.selectedPropertyType.displayValue}
      </div>
    );

    if (props.pageConfiguration && props.pageConfiguration.isEditEnabled) {
      return dropDown;
    } else {
      return label;
    }
  };

  const getBedroomDefaultsBody = function(rowData: any, column: any) {
    const defaults = rowData as BaselineDefaultsIncentivesFormDefaults;

    let dropDown = (
      <Dropdown
        onChange={onDefaultsBedroomChange(defaults)}
        selectedValue={defaults.selectedBedroomType}
        options={props.bedrooms}
        validationMessage={
          defaults.fieldValidationErrors &&
          defaults.fieldValidationErrors["SelectedBedroomType"]
        }
        style={{ minWidth: "100%", maxWidth: "100%" }}
      />
    );

    let label = (
      <div>
        {defaults.selectedBedroomType &&
          defaults.selectedBedroomType.displayValue}
      </div>
    );

    if (props.pageConfiguration && props.pageConfiguration.isEditEnabled) {
      return dropDown;
    } else {
      return label;
    }
  };

  const getIncentiveTypeBody = function(rowData: any, column: any) {
    const defaults = rowData as BaselineDefaultsIncentivesFormDefaults;

    let dropDown = (
      <Dropdown
        onChange={onDefaultsIncentiveTypeChange(defaults)}
        selectedValue={defaults.selectedIncentiveType}
        options={props.incentiveTypes}
        style={{ minWidth: "100%", maxWidth: "100%" }}
        hideClear={true}
      />
    );

    let label = (
      <div>
        {defaults.selectedIncentiveType &&
          defaults.selectedIncentiveType.displayValue}
      </div>
    );

    if (props.pageConfiguration && props.pageConfiguration.isEditEnabled) {
      return dropDown;
    } else {
      return label;
    }
  };

  const getDefaultInputEditorData = function(): DefaultInputEditorData<BaselineDefaultsIncentivesFormDefaults> {
    return {
      isEditDisabled: !(
        props.pageConfiguration && props.pageConfiguration.isEditEnabled
      ),
      onChange: (
        row: BaselineDefaultsIncentivesFormDefaults,
        field: keyof BaselineDefaultsIncentivesFormDefaults,
        value: any
      ) => {
        const newDefaultsRow: BaselineDefaultsIncentivesFormDefaults = {
          ...row,
          isChanged: true
        };

        newDefaultsRow[field] = value;
        onRowChanged(row, newDefaultsRow);
      }
    };
  };

  const getColumns = function(): any[] {
    const columns = [] as ColumnDefinition<
      BaselineDefaultsIncentivesFormDefaults,
      keyof BaselineDefaultsIncentivesFormDefaults
    >[];

    const columnOneStyle = { width: "40%" };
    const columnTwoStyle = { width: "20%" };

    const blankColumnOne: ColumnDefinition<
      BaselineDefaultsIncentivesFormDefaults,
      keyof BaselineDefaultsIncentivesFormDefaults
    > = {
      header: "",
      style: columnOneStyle
    };

    const blankColumnTwo: ColumnDefinition<
      BaselineDefaultsIncentivesFormDefaults,
      keyof BaselineDefaultsIncentivesFormDefaults
    > = {
      header: "",
      style: columnTwoStyle
    };

    if (props.columnOptions === "bedroomOnly") {
      columns.push(blankColumnOne);

      columns.push({
        header: "Bedroom",
        style: columnTwoStyle,
        body: getBedroomDefaultsBody
      });
    } else if (props.columnOptions === "propertyOnly") {
      columns.push({
        header: "Property",
        style: columnOneStyle,
        body: getPropertyDefaultsBody
      });

      columns.push(blankColumnTwo);
    } else if (props.columnOptions === "propertyAndUnit") {
      columns.push({
        header: "Property",
        style: columnOneStyle,
        body: getPropertyDefaultsBody
      });

      columns.push({
        field: "unitNumber",
        header: "Unit",
        style: columnTwoStyle,
        defaultInputEditorData: getDefaultInputEditorData()
      });
    } else {
      columns.push(blankColumnOne);
      columns.push(blankColumnTwo);
    }

    return columns.concat([
      {
        field: "selectedIncentiveType",
        header: "$ / %",
        style: { width: "15%" },
        body: getIncentiveTypeBody
      },
      {
        field: "incentiveValue",
        header: "Incentive",
        style: { width: "10%" },
        defaultInputEditorData: getDefaultInputEditorData()
      }
    ]);
  };

  const onTableSort = (e: any) => {
    //clear sort column
    if (
      table.sortOrder === -1 &&
      e.sortOrder === 1 &&
      table.sortField === e.sortField
    ) {
      setTable({
        ...table,
        sortField: undefined,
        sortOrder: undefined
      });
    } else {
      setTable({
        ...table,
        sortField: e.sortField,
        sortOrder: e.sortOrder
      });
    }
  };
  const tableProps: DataTableProps<
    BaselineDefaultsIncentivesFormDefaults,
    TableState
  > = {
    value: props.defaults || undefined,
    editMode: "cell",
    state: table,
    updateState: setTable,
    paging: props.paging,
    // TODO: This functionality only works when added at this leve. I was unable to get it to work when I moved it up to the createDataTable function
    sortField: table.sortField,
    sortOrder: table.sortOrder,
    onSort: onTableSort,
    onAddRowAction: props.onAddRowAction,
    onRemoveRowAction:
      props.includeRemoveRowColumn === "show" ? onRemoveRow : undefined,
    hasRemoveRowBuffer: props.includeRemoveRowColumn === "buffer",
    emptyMessage: "No defaults have been added."
  };
  const dataTable = createDataTable<
    BaselineDefaultsIncentivesFormDefaults,
    TableState
  >(getColumns(), tableProps);

  return <div>{dataTable}</div>;
};

export default DefaultsIncentivesFormTable;
