import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  filterDropdown: {
    padding: "0 20px"
  },
  filterDropdownArea: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  splitContent: {
    display: "flex",
    justifyContent: "space-between"
  },
  approvalArea: {
    display: "flex",
    justifyContent: "space-between",
    width: "21%"
  },
  headerMetaData: {
    width: "100%"
  }
}));
