import React from "react";
import logo from "assets/logo.jpg";
import useStyle from "./useStyle";
import MenuButton from "components/controls/MenuButton";
import { Link } from "react-router-dom";
import routes from "components/routes";
import Spacer from "components/controls/Spacer";
import RequirePermission from "../RequirePermission";
import IconButton, { IconType } from "components/controls/IconButton";
import { HelpFormClient, ApiException, AtlasHelpFormDownload } from "api";
import { useApiEndpoint } from "components/useApiEndpoint";
import { useRemoteData, responseHandlers } from "components/useRemoteData";
import useMessageArea from "components/useMessageArea";
import Spinner from "components/controls/Spinner";
import windowUtils from "utils/windowUtils";
import locationUtils from "utils/locationUtils";

export interface LoadingState {
  isLoading: boolean;
}

const Header: React.FC = function (props: any) {
  const classes = useStyle();

  const [
    messageAreaState,
    setMessageBasedOnForm,
    setMessageBasedOnApiException
  ] = useMessageArea();

  const handleSubmitError = function (error: ApiException) {
    responseHandlers.use({
      "400": (ex: ApiException) => {
        const form = ex.result;
        setMessageBasedOnForm(form);
      },
      _: setMessageBasedOnApiException
    })(error);
  };

  const [downloadAtlasGuideState, downloadAtlasGuide] = useRemoteData(
    useApiEndpoint(HelpFormClient, "getAtlasGuideHelpDownloadForm"),
    [],
    {
      onChange: (form) => {
        const data = form as AtlasHelpFormDownload;
        if (data) {
          windowUtils.openLink(data.fileLocation, true);
        }
      },
      onError: handleSubmitError
    }
  );

  const spinner = <Spinner isVisible={downloadAtlasGuideState.isFetching} />;

  return (
    <div className={classes.outerContainer}>
      <div className={classes.container}>
        <div className={classes.logoWrapper}>
          <Link to="/">
            <img className={classes.logo} src={logo} alt="Essex" />
          </Link>
        </div>
        <div className={classes.buttons}>
          <div className={classes.spacer} />
          <RequirePermission permissions={["canViewBatchListForm"]}>
            <MenuButton
              title="BATCHES"
              to={routes.batchListPage}
              isActive={locationUtils.isRouteCurrentlyActive("batch")}
            />
            <Spacer orientation="h" size="xl" />
          </RequirePermission>
          <RequirePermission permissions={["canViewBaselineDefaultsForms"]}>
            <MenuButton
              title="BASELINE DEFAULTS"
              to={routes.baselinedefaults.growthRates}
              isActive={locationUtils.isRouteCurrentlyActive(
                "baselinedefaults"
              )}
            />
            <Spacer orientation="h" size="xl" />
          </RequirePermission>
          <RequirePermission permissions={["canViewBatchListForm"]}>
            <MenuButton
              title="REGULATIONS"
              to={routes.regulations.bmr}
              isActive={locationUtils.isRouteCurrentlyActive(
                "baselineregulations"
              )}
            />
            <Spacer orientation="h" size="xl" />
          </RequirePermission>
          <RequirePermission permissions={["canViewUserAdminForm"]}>
            <MenuButton
              title="ADMIN"
              to={routes.admin.users}
              isActive={locationUtils.isRouteCurrentlyActive("/admin/")}
            />
            <Spacer orientation="h" size="xl" />
          </RequirePermission>
          <div>
            <MenuButton title="SIGN OUT" to={routes.signOut} />
          </div>
          <Spacer orientation="h" size="l" />
          <div className={classes.helpButton}>
            <IconButton
              icon={IconType.Question}
              onClick={() => downloadAtlasGuide([])}
              onlyShowIcon={true}
              className="essexAtlasHelpDownloadIcon"
            />
          </div>
        </div>
      </div>
      {messageAreaState.messageArea}
      {spinner}
    </div>
  );
};
export default Header;
