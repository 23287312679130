import { Config } from "./ConfigClient";
import { UserPermissions, SiteClient } from "../api";

const getPermissions = async function(
  config: Config,
  accessToken: string
): Promise<UserPermissions | undefined> {
  var client = new SiteClient(config.apiUrl as string, {
    fetch: (req, init) => {
      if (init == null) {
        init = {};
      }
      init = {
        ...init,
        headers: {
          ...init.headers,
          authorization: `bearer ${accessToken}`
        }
      };
      return window.fetch(req, init);
    }
  });

  const sitePayload = await client.getSite();
  return sitePayload.permissions;
};

export default { getPermissions };
