import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
  container: {
    background: theme.pallette.white,
    width: "100%",
    height: "100%",
    boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.2)",
    transition: "transform 250ms ease-in-out"
  },
  contentContainer: {
    padding: "30px",
    background: theme.pallette.white
  }
}));
