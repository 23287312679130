import { createThemedUseStyles } from "theme";
import { GenericFormComment } from "../GenericFormCommentTypes";

export default createThemedUseStyles((theme) => ({
  commentAlignmentContainer: {
    display: "flex",
    justifyContent: (props: GenericFormComment) =>
      props.isMyComment ? "flex-end" : "flex-start",
    textAlign: (props: GenericFormComment) =>
      props.isMyComment ? "right" : "left",
    width: "100%"
  },
  commentContainer: {
    display: "flex",
    flexDirection: "column",
    width: "75%",
    textAlign: (props: GenericFormComment) =>
      props.isMyComment ? "right" : "left"
  },
  commentHeader: {
    fontFamily: theme.fontFamily,
    fontSize: theme.typeScale.medium,
    color: theme.pallette.gray,
    paddingLeft: (props: GenericFormComment) =>
      props.isMyComment ? "0px" : "5px",
    paddingRight: (props: GenericFormComment) =>
      props.isMyComment ? "5px" : "0px"
  },
  commentBody: {
    fontFamily: theme.fontFamilyEmphasis,
    fontWeight: "bold",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: (props: GenericFormComment) =>
      props.isMyComment ? theme.pallette.gray : theme.pallette.lightGray,
    backgroundColor: (props: GenericFormComment) =>
      props.isMyComment
        ? theme.pallette.transparentBlue
        : theme.pallette.faintGray,
    borderRadius: "11px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "15px",
    paddingBottom: "15px",
    textAlign: "left"
  },
  commentDate: {
    fontFamily: theme.fontFamily,
    fontSize: theme.typeScale.xsmall,
    color: theme.pallette.gray,
    paddingLeft: (props: GenericFormComment) =>
      props.isMyComment ? "0px" : "5px",
    paddingRight: (props: GenericFormComment) =>
      props.isMyComment ? "5px" : "0px"
  },
  deleteButton: { display: "flex", flexDirection: "column" }
}));
