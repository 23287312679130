import React, { useState } from "react";
import Checkbox, { Checked } from "components/controls/Checkbox";
import Searchbox from "components/controls/Searchbox";
import useStyle from "./useStyle";
import Spacer from "components/controls/Spacer";
import Label from "components/controls/Label";
import ScrollPanel from "components/layouts/ScrollPanel";
import ValidationWrapper from "components/controls/ValidationWrapper";

type PageState = {
  propertyFilter?: string;
};

type PropertyOption = {
  key?: string;
  displayValue?: string;
};

export interface PropertyChooserProps {
  properties?: PropertyOption[];
  selectedProperties?: PropertyOption[];
  validationMessage?: string | null | undefined;
  singleSelect?: boolean | undefined;
  onChange: (selection: PropertyOption[]) => void;
}

const PropertyChooser: React.FC<PropertyChooserProps> = function (props) {
  const [state, setState] = useState<PageState>({});
  const classes = useStyle();

  const properties =
    props.properties == null
      ? []
      : props.properties.map((p) => ({
          ...p,
          selected:
            props.selectedProperties == null
              ? false
              : props.selectedProperties.find((s) => s.key === p.key) != null
        }));

  const checkSelectAll: Checked = properties.every((p) => p.selected)
    ? true
    : properties.every((p) => !p.selected)
    ? false
    : "mixed";

  const filteredProperties = properties.filter(
    (p) =>
      state.propertyFilter == null ||
      (p.displayValue &&
        p.displayValue
          .toLowerCase()
          .includes(state.propertyFilter.toLowerCase()))
  );

  const toggleSelection = (value: Checked) => {
    if (value === true) {
      props.onChange(props.properties || []);
    } else if (value === false) {
      props.onChange([]);
    }
  };

  const toggleProperty = (key: string | undefined, checked: Checked) => {
    if (checked === false && props.selectedProperties != null) {
      props.onChange(props.selectedProperties.filter((p) => p.key !== key));
    } else if (checked === true) {
      const safeSelection = props.selectedProperties || [];
      props.onChange([
        ...safeSelection,
        ...properties.filter((p) => p.key === key)
      ]);
    } else {
      console.log("Mixed is an unexpected state for property checkbox.");
    }
  };

  let selectAllOption = <></>;
  if (!props.singleSelect) {
    selectAllOption = (
      <Checkbox
        label="Select all"
        labelPosition="right"
        checked={checkSelectAll}
        onChange={toggleSelection}
      />
    );
  }

  const labelText = `Selected properties: ${
    (props.selectedProperties && props.selectedProperties.length) || 0
  }/${(props.properties && props.properties.length) || 0}`;

  return (
    <ValidationWrapper showErrorState={props.validationMessage != null}>
      <div className={classes.container}>
        <Searchbox
          label="Filter"
          value={state.propertyFilter}
          onChange={(v) => setState({ ...state, propertyFilter: v })}
          mode="filter"
        />
        <Spacer orientation="v" />
        <Label label={labelText} />
        <Spacer orientation="v" />
        {selectAllOption}
        <ScrollPanel heightInPixels={250}>
          {filteredProperties.map((p) => (
            <div key={p.key}>
              <Spacer orientation="v" />
              <Checkbox
                label={p.displayValue}
                labelPosition="right"
                checked={p.selected}
                onChange={(checked) => toggleProperty(p.key, checked)}
              />
            </div>
          ))}
        </ScrollPanel>
      </div>
    </ValidationWrapper>
  );
};

export default PropertyChooser;
