import Checkbox, { Checked } from "../Checkbox";
import { LabelPosition } from "../Label";
import React from "react";
import useStyle from "./useStyle";

export interface SelectAllCheckboxesProps {
  checked: Checked;
  onChange: (checked: Checked) => void;
  label?: string | undefined;
  labelPosition?: LabelPosition;
}

export interface SelectAllProps {
  title?: string | undefined;
  onChange: (checked: Checked) => void;
  checkboxes: SelectAllCheckboxesProps[];
  hideSelectAll?: boolean;
}

const SelectAll: React.FC<SelectAllProps> = function(props) {
  const classes = useStyle(props);
  const selectAllIsChecked = props.checkboxes.every(checkbox => {
    return checkbox.checked === true;
  });

  const selectAllCheckbox = (
    <Checkbox
      label={props.title || "Select All"}
      labelPosition={"right"}
      checked={selectAllIsChecked}
      onChange={props.onChange}
    />
  );

  const checkboxes = props.checkboxes.map(checkbox => (
    <Checkbox {...checkbox} key={checkbox.label} />
  ));

  return (
    <div className={classes.root}>
      {props.hideSelectAll ? <div /> : selectAllCheckbox}
      {checkboxes}
    </div>
  );
};

export default SelectAll;
