import React, { useState } from "react";
import MessagesArea from "components/controls/MessageArea";
import { ApiException } from "api";

export interface MessageAreaState {
  messageArea: any;
}

interface GenericForm {
  isValid: boolean;
  formValidationErrors: string[];
}

const setMessageAreaBasedOnForm = function(
  setMessageAreaStateFunc: React.Dispatch<
    React.SetStateAction<MessageAreaState>
  >,
  form: any | undefined
) {
  const genericForm = form as GenericForm;

  if (form && form.hasOwnProperty("isValid") && !genericForm.isValid) {
    let messageArea = MessagesArea.getGenericValidationMessage();
    if (
      form.formValidationErrors != null &&
      form.formValidationErrors.length > 0
    ) {
      const errors = genericForm.formValidationErrors.join("<br />");
      messageArea = MessagesArea.getGenericValidationMessage(errors);
    }

    setMessageAreaStateFunc({
      messageArea: messageArea
    });
  } else if (form) {
    setMessageAreaStateFunc({
      messageArea: MessagesArea.getGenericSaveSuccessMessageArea()
    });
  } else {
    setMessageAreaStateFunc({
      messageArea: MessagesArea.getGenericErrorMessage()
    });
  }
};

const setMessageAreaBasedOnApiException = function(
  setMessageAreaStateFunc: React.Dispatch<
    React.SetStateAction<MessageAreaState>
  >,
  error: ApiException
) {
  setMessageAreaStateFunc({
    messageArea: MessagesArea.getGenericErrorMessage()
  });
};

const useMessageArea = function(): [
  MessageAreaState,
  (form: any) => void,
  (error: ApiException) => void,
  React.Dispatch<React.SetStateAction<MessageAreaState>>
] {
  const [messageAreaState, setMessageArea] = useState<MessageAreaState>({
    messageArea: <div />
  });

  const setMessageBasedOnForm = function(form: any) {
    setMessageAreaBasedOnForm(setMessageArea, form);
  };

  const setMessageBasedOnApiException = function(error: ApiException) {
    setMessageAreaBasedOnApiException(setMessageArea, error);
  };

  return [
    messageAreaState,
    setMessageBasedOnForm,
    setMessageBasedOnApiException,
    setMessageArea
  ];
};

export default useMessageArea;
