import React from "react";
import useStyle from "./useStyle";
import ActionButton, {
  ActionItemProps
} from "components/controls/ActionButton";
import { useHistory } from "react-router";
import PrimaryButton from "components/controls/PrimaryButton";
import NavPrompt from "components/controls/NavPrompt";
import Spacer from "components/controls/Spacer";

export interface WizardMenuItemProps {
  title: string;
  route: string;
  onClick: () => Promise<any>;
  isActive?: boolean;
}

export interface WizardMenuFinishItemProps {
  title: string;
  route: string;
  onClick: () => Promise<any>;
}

export interface WizardMenuProps {
  title: string;
  menuTitle: string;
  navMessage?: string | undefined;
  items: WizardMenuItemProps[];
  finishItem?: WizardMenuFinishItemProps | undefined;
  actionArea?: JSX.Element | undefined;
  isActionButtonCondensed?: boolean | undefined;
}

const WizardMenu = function(props: WizardMenuProps) {
  const classes = useStyle();
  const history = useHistory();
  const navMenuItemProps: ActionItemProps[] = props.items.map(item => {
    return {
      label: item.title,
      isActive: item.isActive,
      command: () => {
        item
          .onClick()
          .then(data => history.push(item.route))
          .catch(ex => {});
      }
    };
  });

  let finishButton = <div />;
  const finishItem = props.finishItem;
  if (finishItem) {
    const onFinishButtonClick = () => {
      finishItem
        .onClick()
        .then(data => history.push(finishItem.route))
        .catch(ex => {});
    };

    finishButton = (
      <PrimaryButton onClick={onFinishButtonClick} title={finishItem.title} />
    );
  }

  let messageArea = <div />;
  if (props.navMessage) {
    messageArea = (
      <>
        <NavPrompt
          isVisible={true}
          message={props.navMessage}
          iconArea={<i className="pi pi-info" style={{ fontSize: "30px" }} />}
        />
        <Spacer orientation="h" size="xl" />
      </>
    );
  }

  return (
    <div className={classes.background}>
      <div className={classes.outerContainer}>
        <div className={classes.container}>
          <div className={classes.titleBar}>
            <span className={classes.title}>{props.title}</span>
            <div className={classes.items}>
              {messageArea}
              <ActionButton
                label={props.menuTitle}
                items={navMenuItemProps}
                isCondensed={props.isActionButtonCondensed}
              />
              <Spacer />
              {props.actionArea}
              {finishButton}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

WizardMenu.defaultProps = { isCondensed: true };

export default WizardMenu;
