import React, { useContext } from "react";
import Menu, { MenuItemProps } from "components/forms/Menu";
import routes from "components/routes";
import { IdentityContext } from "components/IdentityProvider";
import getBatchDefaultMenuItems from "./getBatchDefaultMenuItems";
import { useHistory } from "react-router-dom";
import ActionButton from "components/controls/ActionButton";
import { BatchNavigationConfiguration } from "api";
import locationUtils from "utils/locationUtils";
import getBatchRegulationMenuItems from "./getBatchRegulationMenuItems";

interface MenuProps {
  bestRenewalOffersKey: string;
  batchNavigationConfig: BatchNavigationConfiguration | undefined;
  title?: string;
  actionArea?: JSX.Element;
}

const BestRenewalOffersMenu: React.FC<MenuProps> = function (props) {
  const identity = useContext(IdentityContext);
  const history = useHistory();
  const sampleMenuItems = (bestRenewalOffersKey: string): MenuItemProps[] => {
    const hasBestRenewalOffersPermission =
      identity.permissions["canViewBestRenewalOffersForm"] === true;

    const canViewReviewSummaryForm =
      identity.permissions["canViewReviewSummaryForm"] === true;

    const hasBestRenewalOffersActualsPermission =
      identity.permissions["canViewBestRenewalOffersActualsForm"] === true;

    const canViewRenewalFollowUpFormPermission =
      identity.permissions["canViewRenewalFollowUpForm"] === true;

    // TODO: Unhide actual results summary form
    //const canViewActualResultsSummaryForm =
    //  identity.permissions["canViewActualResultsSummaryForm"] === true;

    const items = [];
    if (hasBestRenewalOffersPermission) {
      const bestRenewalOfferRoute = routes.bestRenewalOffers(
        bestRenewalOffersKey
      );
      items.push({
        title: "OFFERS",
        to: bestRenewalOfferRoute,
        isActive: locationUtils.isRouteCurrentlyActive(bestRenewalOfferRoute)
      });
    }

    if (canViewReviewSummaryForm) {
      const reviewSummaryRoute = routes.reviewSummary(bestRenewalOffersKey);
      items.push({
        title: "SUMMARY",
        to: reviewSummaryRoute,
        isActive: locationUtils.isRouteCurrentlyActive(reviewSummaryRoute)
      });
    }

    if (
      hasBestRenewalOffersActualsPermission &&
      props.batchNavigationConfig &&
      props.batchNavigationConfig.showActualResults
    ) {
      const actualsRoute = routes.bestRenewalOffersActuals(
        bestRenewalOffersKey
      );
      items.push({
        title: "ACTUALS",
        to: actualsRoute,
        isActive: locationUtils.isRouteCurrentlyActive(actualsRoute)
      });
    }

    if (
      canViewRenewalFollowUpFormPermission &&
      props.batchNavigationConfig &&
      props.batchNavigationConfig.showRenewalFollowUp
    ) {
      const renewalFollowUpRoute = routes.renewalFollowUp(bestRenewalOffersKey);
      items.push({
        title: "RPM RENEWAL FOLLOW UP",
        to: renewalFollowUpRoute,
        isActive: locationUtils.isRouteCurrentlyActive(renewalFollowUpRoute)
      });
    }

    // TODO: Unhide actual results summary form
    //if (
    //  canViewActualResultsSummaryForm &&
    //  props.batchNavigationConfig &&
    //  props.batchNavigationConfig.showActualResultsSummary
    //) {
    //  const actualResultsSummaryRoute = routes.actualResultsSummary(
    //    bestRenewalOffersKey
    //  );
    //  items.push({
    //    title: "ACTUAL RESULTS SUMMARY",
    //    to: actualResultsSummaryRoute,
    //    isActive: locationUtils.isRouteCurrentlyActive(
    //      actualResultsSummaryRoute
    //    )
    //  });
    //}

    return items;
  };

  let batchDefaultsMenu = <div />;
  let batchRegulationsMenu = <div />;
  const hasCanViewBatchDefaultsFormsPermissions =
    identity.permissions["canViewBatchDefaultsForms"] === true;
  const canViewRegulations =
    identity.permissions["canAddEditBaselineDefaultsForms"] === true;
  if (
    hasCanViewBatchDefaultsFormsPermissions &&
    props.batchNavigationConfig &&
    props.batchNavigationConfig.showBatchDefaults
  ) {
    const batchDefaultsMenuItems = getBatchDefaultMenuItems(
      props.bestRenewalOffersKey,
      "editBatch"
    ).map((item) => {
      return {
        label: item.title.toUpperCase(),
        command: () => {
          history.push(item.route);
        },
        isActive: locationUtils.isRouteCurrentlyActive(item.route)
      };
    });

    batchDefaultsMenu = (
      <ActionButton label="BATCH DEFAULTS" items={batchDefaultsMenuItems} />
    );

    if (canViewRegulations) {
      const batchRegulationsMenuItems = getBatchRegulationMenuItems(
        props.bestRenewalOffersKey,
        "editBatch"
      ).map((item) => {
        return {
          label: item.title.toUpperCase(),
          command: () => {
            history.push(item.route);
          },
          isActive: locationUtils.isRouteCurrentlyActive(item.route)
        };
      });

      batchRegulationsMenu = (
        <ActionButton
          label="BATCH REGULATIONS"
          items={batchRegulationsMenuItems}
        />
      );
    }
  }

  return (
    <div>
      <Menu
        title={props.title || "BEST RENEWAL OFFERS"}
        items={sampleMenuItems(props.bestRenewalOffersKey)}
        actionArea={props.actionArea}
      >
        {batchDefaultsMenu}
        {batchRegulationsMenu}
      </Menu>
    </div>
  );
};

export default BestRenewalOffersMenu;
