import React from "react";
import IconButton, { IconType } from "../IconButton";

export interface RemoveButtonProps {
  title?: string | undefined;
  disabled?: boolean;
  onClick?: () => void;
}

const RemoveButton: React.FC<RemoveButtonProps> = function(props) {
  return (
    <IconButton
      icon={IconType.Remove}
      onClick={props.onClick}
      disabled={props.disabled}
    />
  );
};

export default RemoveButton;
