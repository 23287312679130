import { AtlasActionTypes } from "../AtlasActionTypes";
import {
  BestRenewalOffersPageAppliedFilterState,
  AtlasState,
  getMutableCopyOfAtlasState,
  BestRenewalOffersPageState
} from "../AtlasStateTypes";
import { AtlasActions } from "../AtlasActions";
import { initialAtlasState, initialPagingState } from "../useAtlasStore";
import utils from "utils";
import MessageUtils from "utils/messageUtils";
import listUtils from "utils/listUtils";
import {
  BestRenewalOffersFormOffer,
  CommonSummaryFormSummary,
  CommonSummaryFormRollup
} from "api";

const updateOfferOnForm = (
  newState: AtlasState,
  updatedOffer: BestRenewalOffersFormOffer
) => {
  if (
    !newState.bestRenewalOffersPageState.form ||
    !newState.bestRenewalOffersPageState.form.offers
  ) {
    return newState;
  }
  const offer = { ...updatedOffer, isChanged: true };
  const existingOffers = newState.bestRenewalOffersPageState.form.offers;

  const newOffers = listUtils.copyListWithNewKeyedItem(existingOffers, offer);
  newState.bestRenewalOffersPageState.form.offers = newOffers;
};

const updateOffersOnForm = (
  newState: AtlasState,
  updatedOffers: BestRenewalOffersFormOffer[]
) => {
  if (
    !newState.bestRenewalOffersPageState.form ||
    !newState.bestRenewalOffersPageState.form.offers
  ) {
    return newState;
  }
  const offers = [...updatedOffers];
  offers.forEach((x) => (x.isChanged = true));

  let newOffers = [...newState.bestRenewalOffersPageState.form.offers];

  updatedOffers.forEach((o) => {
    newOffers = listUtils.copyListWithNewKeyedItem(newOffers, o);
  });
  newState.bestRenewalOffersPageState.form.offers = newOffers;
};

const updateOfferOnFormButKeepOldComments = (
  newState: AtlasState,
  updatedOffer: BestRenewalOffersFormOffer
) => {
  if (
    !newState.bestRenewalOffersPageState.form ||
    !newState.bestRenewalOffersPageState.form.offers
  ) {
    return newState;
  }

  const oldOffer = listUtils.firstOrDefaultByKey(
    newState.bestRenewalOffersPageState.form.offers,
    updatedOffer.key as string
  );

  updateOfferOnForm(newState, updatedOffer);

  const matchingOffer = listUtils.firstOrDefaultByKey(
    newState.bestRenewalOffersPageState.form.offers,
    updatedOffer.key as string
  );

  if (oldOffer && matchingOffer) {
    matchingOffer.comments = oldOffer.comments;
  }
};

const updateOffersOnFormButKeepOldComments = (
  newState: AtlasState,
  updatedOffers: BestRenewalOffersFormOffer[]
) => {
  if (
    !newState.bestRenewalOffersPageState.form ||
    !newState.bestRenewalOffersPageState.form.offers
  ) {
    return newState;
  }

  const newOfferKeys = updatedOffers.map((x) => x.key);
  const oldOffers = newState.bestRenewalOffersPageState.form.offers.filter(
    (x) => newOfferKeys.includes(x.key)
  );

  updateOffersOnForm(newState, updatedOffers);
  updatedOffers.forEach((updatedOffer) => {
    if (
      newState.bestRenewalOffersPageState.form &&
      newState.bestRenewalOffersPageState.form.offers
    ) {
      const matchingOffer = listUtils.firstOrDefaultByKey(
        newState.bestRenewalOffersPageState.form.offers,
        updatedOffer.key as string
      );

      const oldOffer = listUtils.firstOrDefaultByKey(
        oldOffers,
        updatedOffer.key as string
      );

      if (oldOffer && matchingOffer) {
        matchingOffer.comments = oldOffer.comments;
      }
    }
  });
};

const updateOfferOnFormWithOnlyExcpetionFlags = (
  newState: AtlasState,
  updatedOffers: BestRenewalOffersFormOffer[]
) => {
  if (
    !newState.bestRenewalOffersPageState.form ||
    !newState.bestRenewalOffersPageState.form.offers ||
    !updatedOffers
  ) {
    return newState;
  }
  const existingOffersForExceptions =
    newState.bestRenewalOffersPageState.form.offers;

  for (let updatedOffer of updatedOffers) {
    const existingOfferForExceptions = listUtils.firstOrDefaultByKey(
      existingOffersForExceptions,
      updatedOffer.key as string
    );

    if (existingOfferForExceptions) {
      existingOfferForExceptions.isPropertyException =
        updatedOffer.isPropertyException;
      existingOfferForExceptions.isUnitException = updatedOffer.isUnitException;
    }
  }

  newState.bestRenewalOffersPageState.form.offers = existingOffersForExceptions;
};

const updateSinglePropertySummary = (
  newState: AtlasState,
  newProperySummary: CommonSummaryFormSummary | undefined
) => {
  let newRollup =
    newProperySummary &&
    newProperySummary.rollups &&
    newProperySummary.rollups[0];
  const newStatePropertySummary =
    newState.bestRenewalOffersPageState.form &&
    newState.bestRenewalOffersPageState.form.propertySummary;

  if (
    !newStatePropertySummary ||
    !newStatePropertySummary.rollups ||
    !newProperySummary ||
    !newRollup ||
    !newRollup.key
  ) {
    return;
  }

  newStatePropertySummary.rollupTotals = newProperySummary.rollupTotals;
  const newStateMatchingRollup = listUtils.firstOrDefaultByKey(
    newStatePropertySummary.rollups,
    newRollup.key
  );

  newRollup = {
    ...newRollup,
    propertyComments:
      (newStateMatchingRollup && newStateMatchingRollup.propertyComments) || []
  };

  newStatePropertySummary.rollups = listUtils.copyListWithNewKeyedItem(
    newStatePropertySummary.rollups,
    newRollup
  );

  resolveExpandedRowsObjectReferences(newState.bestRenewalOffersPageState, [
    newRollup
  ]);
};

const resolveExpandedRowsObjectReferences = function (
  newMutablePageState: BestRenewalOffersPageState,
  newRollups: CommonSummaryFormRollup[]
) {
  newRollups.forEach((newRollup) => {
    // resolve object references of this rollup in the table state's expanded rows.
    const propertySummaryTableState =
      newMutablePageState.propertySummaryTableState;
    const hasExpandingRows =
      propertySummaryTableState && propertySummaryTableState.expandedRows;

    if (hasExpandingRows) {
      const hasMatchingExpandedRow =
        listUtils.firstOrDefault(
          propertySummaryTableState.expandedRows,
          (row) => newRollup && row.key === newRollup.key
        ) != null;

      if (hasMatchingExpandedRow) {
        propertySummaryTableState.expandedRows =
          listUtils.copyListWithNewKeyedItem(
            propertySummaryTableState.expandedRows,
            newRollup
          );
      }
    }
  });
};

const getValueOrBlank = (value: string | null | undefined): string => {
  if (!utils.stringIsNullOrEmpty(value)) {
    return value as string;
  }

  return "(blank)";
};

const setFilteredOffersAndCounts = function (
  mutableNewState: AtlasState
): void {
  const offers =
    (mutableNewState &&
      mutableNewState.bestRenewalOffersPageState &&
      mutableNewState.bestRenewalOffersPageState.form &&
      mutableNewState.bestRenewalOffersPageState.form.offers) ||
    [];

  const propertyRollups =
    (mutableNewState &&
      mutableNewState.bestRenewalOffersPageState &&
      mutableNewState.bestRenewalOffersPageState.form &&
      mutableNewState.bestRenewalOffersPageState.form.propertySummary &&
      mutableNewState.bestRenewalOffersPageState.form.propertySummary
        .rollups) ||
    [];

  const appliedFields =
    (mutableNewState &&
      mutableNewState.tableState &&
      mutableNewState.tableState.appliedFilters) ||
    [];

  const filteredOffers = offers.filter((offer) => {
    return appliedFields.every((filter) => {
      let fieldValue = offer[filter.field] as any;

      const boolValue = fieldValue as boolean;

      if (boolValue === true || boolValue === false) {
        return (filter.selectedValues as boolean[]).includes(boolValue);
      }

      const stringValue = fieldValue as string;

      if (filter.field === "parameter") {
        return (filter.selectedValues as string[])
          .map((v) => (v.includes(" - ") ? v.split(" - ")[1] : v))
          .includes(
            getValueOrBlank(stringValue).includes(" - ")
              ? getValueOrBlank(stringValue).split(" - ")[1]
              : getValueOrBlank(stringValue)
          );
      } else if (filter.field === "rpm") {
        return (filter.selectedValues as string[]).some((f) =>
          getValueOrBlank(stringValue).includes(f)
        );
      } else {
        return (filter.selectedValues as string[]).includes(
          getValueOrBlank(stringValue)
        );
      }
    });
  });

  mutableNewState.bestRenewalOffersPageState.filteredOffers = filteredOffers;

  mutableNewState.bestRenewalOffersPageState.propertyExceptionCount =
    offers.filter((offer) => offer.isPropertyException).length;

  mutableNewState.bestRenewalOffersPageState.unitExceptionCount = offers.filter(
    (offer) => offer.isUnitException && !offer.isPropertyException
  ).length;

  mutableNewState.bestRenewalOffersPageState.offerTotalCount = offers.length;

  mutableNewState.bestRenewalOffersPageState.propertyTotalCount =
    propertyRollups.length;
};

const BestRenewalOffersPageReducer = (
  oldState: AtlasState,
  action: AtlasActions
): AtlasState => {
  const newState = getMutableCopyOfAtlasState(oldState);

  switch (action.type) {
    case AtlasActionTypes.BestRenewalOffersPageResetState:
      return utils.cloneObject(initialAtlasState);
    case AtlasActionTypes.BestRenewalOffersPageSetInitialForm:
      newState.bestRenewalOffersPageState.form = action.form;
      newState.loadingState.isLoading = false;

      let filters: BestRenewalOffersPageAppliedFilterState[] = [];

      const properties =
        (action.form && action.form.selectedPropertiesForFiltering) || [];
      const propertyNames: string[] = properties
        .map((property) => property.displayValue)
        .filter((propertyName) => propertyName != null) as string[];
      if (propertyNames.length > 0) {
        filters = filters.concat([
          {
            field: "property",
            selectedValues: propertyNames
          }
        ]);
      }

      const pageConfiguration =
        newState.bestRenewalOffersPageState.form &&
        newState.bestRenewalOffersPageState.form.pageConfiguration;
      if (pageConfiguration && pageConfiguration.showExceptionFilters) {
        filters = filters.concat([
          {
            field: "isUnitException",
            selectedValues: [true]
          },
          {
            field: "isPropertyException",
            selectedValues: [false]
          }
        ]);
      }

      newState.tableState.appliedFilters = filters;
      setFilteredOffersAndCounts(newState);

      return newState;
    case AtlasActionTypes.BestRenewalOffersPageShowFormUpdateSuccessful:
      newState.loadingState.isLoading = false;
      newState.messageState.messages.push(
        MessageUtils.getGenericSaveSuccessMessage()
      );
      newState.bestRenewalOffersPageState.form = action.form;

      resolveExpandedRowsObjectReferences(
        newState.bestRenewalOffersPageState,
        (newState.bestRenewalOffersPageState.form &&
          newState.bestRenewalOffersPageState.form.propertySummary &&
          newState.bestRenewalOffersPageState.form.propertySummary.rollups) ||
          []
      );
      setFilteredOffersAndCounts(newState);
      return newState;
    case AtlasActionTypes.BestRenewalOffersPageUpdateForm:
      newState.bestRenewalOffersPageState.form = action.form;

      setFilteredOffersAndCounts(newState);
      return newState;
    case AtlasActionTypes.BestRenewalOffersPageUpdateColumnConfigurations:
      if (!newState.bestRenewalOffersPageState.form) {
        return newState;
      }
      newState.bestRenewalOffersPageState.form.columnConfigurations =
        action.updatedColumnConfigurations;
      return newState;
    case AtlasActionTypes.BestRenewalOffersPageUpdateOffer:
      updateOfferOnForm(newState, action.updatedOffer);
      setFilteredOffersAndCounts(newState);
      return newState;
    case AtlasActionTypes.BestRenewalOffersPageUpdateOffers:
      updateOffersOnForm(newState, action.updatedOffers);
      setFilteredOffersAndCounts(newState);
      return newState;
    case AtlasActionTypes.BestRenewalOffersPageUpdateOffersForProperty:
      updateOfferOnFormButKeepOldComments(newState, action.updatedOffer);
      updateOfferOnFormWithOnlyExcpetionFlags(
        newState,
        action.updatedOffersForExceptionsOnly
      );

      updateSinglePropertySummary(newState, action.properySummary);
      setFilteredOffersAndCounts(newState);
      return newState;
    case AtlasActionTypes.BestRenewalOffersPageUpdateOffersForBatch:
      updateOffersOnFormButKeepOldComments(newState, action.updatedOffers);
      updateOfferOnFormWithOnlyExcpetionFlags(
        newState,
        action.updatedOffersForExceptionsOnly
      );

      action.properySummary &&
        action.properySummary.forEach((o) =>
          updateSinglePropertySummary(newState, o)
        );

      setFilteredOffersAndCounts(newState);
      return newState;
    case AtlasActionTypes.BestRenewalOffersPageAddPropertyComments:
      newState.loadingState.isLoading = false;
      if (!newState.bestRenewalOffersPageState.form) {
        return newState;
      }

      const propertyList = newState.bestRenewalOffersPageState.form.properties;
      const propertyWithNewComments =
        propertyList && propertyList.find((p) => p.key === action.propertyKey);

      if (propertyWithNewComments) {
        propertyWithNewComments.comments = action.comments;
      }

      const propertySummary =
        newState.bestRenewalOffersPageState.form.propertySummary;
      const propertySummaryWithNewComments =
        propertySummary &&
        propertySummary.rollups &&
        propertySummary.rollups.find((p) => p.key === action.propertyKey);

      if (propertySummaryWithNewComments) {
        propertySummaryWithNewComments.propertyComments = action.comments;
      }

      newState.sideBarState.propertyNotesDisplayState.selectedProperty =
        propertyWithNewComments;
      return newState;
    case AtlasActionTypes.BestRenewalOffersPageDeletePropertyComment:
      newState.loadingState.isLoading = false;
      if (!newState.bestRenewalOffersPageState.form) {
        return newState;
      }

      const propertyListz = newState.bestRenewalOffersPageState.form.properties;
      const propertyWithDeletedComment =
        propertyListz &&
        propertyListz.find((p) => p.key === action.propertyKey);

      if (propertyWithDeletedComment) {
        propertyWithDeletedComment.comments = action.comments;
      }

      const newPropertySummary =
        newState.bestRenewalOffersPageState.form.propertySummary;
      const propertySummaryWithDeletedComment =
        newPropertySummary &&
        newPropertySummary.rollups &&
        newPropertySummary.rollups.find((p) => p.key === action.propertyKey);

      if (propertySummaryWithDeletedComment) {
        propertySummaryWithDeletedComment.propertyComments = action.comments;
      }

      newState.sideBarState.propertyNotesDisplayState.selectedProperty =
        propertyWithDeletedComment;
      return newState;
    case AtlasActionTypes.BestRenewalOffersPageAddUnitComment:
      newState.loadingState.isLoading = false;
      if (
        !newState.bestRenewalOffersPageState.form ||
        !newState.bestRenewalOffersPageState.form.offers ||
        !newState.bestRenewalOffersPageState.filteredOffers
      ) {
        return newState;
      }

      const offerForComment = listUtils.firstOrDefaultByKey(
        newState.bestRenewalOffersPageState.form.offers,
        action.unitKey
      );

      if (offerForComment) {
        offerForComment.comments = action.comments;
      }

      const filteredOfferForComment = listUtils.firstOrDefaultByKey(
        newState.bestRenewalOffersPageState.filteredOffers,
        action.unitKey
      );
      if (filteredOfferForComment) {
        filteredOfferForComment.comments = action.comments;
      }

      newState.sideBarState.unitNotesDisplayState.selectedUnit =
        offerForComment;
      return newState;
    case AtlasActionTypes.BestRenewalOffersPageDeleteUnitComment:
      newState.loadingState.isLoading = false;
      if (
        !newState.bestRenewalOffersPageState.form ||
        !newState.bestRenewalOffersPageState.form.offers ||
        !newState.bestRenewalOffersPageState.filteredOffers
      ) {
        return newState;
      }

      const offerForCommentz = listUtils.firstOrDefaultByKey(
        newState.bestRenewalOffersPageState.form.offers,
        action.unitKey
      );

      if (offerForCommentz) {
        offerForCommentz.comments = action.comments;
      }

      const filteredOfferForCommentz = listUtils.firstOrDefaultByKey(
        newState.bestRenewalOffersPageState.filteredOffers,
        action.unitKey
      );
      if (filteredOfferForCommentz) {
        filteredOfferForCommentz.comments = action.comments;
      }

      newState.sideBarState.unitNotesDisplayState.selectedUnit =
        offerForCommentz;
      return newState;
    case AtlasActionTypes.BestRenewalOffersPageUpdateAppliedFilters:
      newState.tableState.appliedFilters = action.appliedFilters;

      newState.tableState.pagingState = initialPagingState;

      setFilteredOffersAndCounts(newState);
      return newState;
    case AtlasActionTypes.BestRenewalOffersPageUpdateFormFilters:
      if (
        !newState.bestRenewalOffersPageState ||
        !newState.bestRenewalOffersPageState.form ||
        !newState.bestRenewalOffersPageState.form.filters
      ) {
        return newState;
      }
      newState.bestRenewalOffersPageState.form.filters = action.filters;
      newState.bestRenewalOffersPageState.hasUnappliedFilters = true;

      setFilteredOffersAndCounts(newState);
      return newState;
    case AtlasActionTypes.BestRenewalOffersPageUpdateExceptionFilter:
      newState.bestRenewalOffersPageState.selectedExceptionFilter =
        action.selectedExceptionFilter;

      setFilteredOffersAndCounts(newState);
      return newState;
    case AtlasActionTypes.BestRenewalOffersPageUpdateRejectionDialogVisibility:
      newState.bestRenewalOffersPageState.rejectionDialogIsVisible =
        action.isVisible;
      return newState;
    case AtlasActionTypes.BestRenewalOffersPageUpdateDeletionDialogVisibility:
      newState.bestRenewalOffersPageState.deletionDialogIsVisible =
        action.isVisible;
      return newState;
    case AtlasActionTypes.BestRenewalOffersPageUpdateApprovalDialogVisibility:
      newState.bestRenewalOffersPageState.approvalDialogIsVisible =
        action.isVisible;
      return newState;
    case AtlasActionTypes.BestRenewalOffersPageUpdateRejectionReason:
      if (!newState.bestRenewalOffersPageState.form) {
        return newState;
      }

      newState.bestRenewalOffersPageState.form.rejectReason =
        action.rejectionReason;
      return newState;

    case AtlasActionTypes.BestRenewalOffersPageUpdateActiveTab:
      if (!newState.bestRenewalOffersPageState.form) {
        return newState;
      }

      newState.bestRenewalOffersPageState.form.activeView =
        action.activeTabView;

      return newState;
    case AtlasActionTypes.BestRenewalOffersPageUpdatePropertySummaryTablePagingState:
      newState.bestRenewalOffersPageState.propertySummaryTableState =
        action.tableState;
      return newState;
  }
  return newState;
};

export default BestRenewalOffersPageReducer;
