import React from "react";
import useStyle from "./useStyle";

export type LabelPosition = "top" | "left" | "bottom" | "right"; // todo: Implement

export interface ActionLinkProps {
  onClick: () => void;
  linkColor?: string | undefined;
  fontSize?: string | undefined;
}

const ActionLink: React.FC<ActionLinkProps> = function (props) {
  const classes = useStyle();
  return (
    <button
      className={classes.root}
      onClick={props.onClick}
      style={{ color: props.linkColor, fontSize: props.fontSize }}
    >
      {props.children}
    </button>
  );
};

export default ActionLink;
