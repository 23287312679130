import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  page: {
    margin: "0 auto"
  },
  menu: {},
  body: {
    margin: "0 auto"
  }
}));
