import React from "react";
import { Route } from "react-router-dom";
import routes from "components/routes";
import BatchDefaultsGrowthRatesPage from "components/forms/BatchDefaultsGrowthRatesPage";
import BatchDefaultsGtlAndLtlPage from "components/forms/BatchDefaultsGtlAndLtlPage";
import BatchDefaultsScalingPage from "components/forms/BatchDefaultsScalingPage";
import BatchDefaultsIncentivesPage from "components/forms/BatchDefaultsIncentivesPages";
import BatchDefaultsBmrPage from "components/forms/BatchDefaultsBmrPage";
import BatchDefaultsExceptionsPage from "components/forms/BatchDefaultsExceptionsPage";
import BatchDefaultsRentControlPage from "components/forms/BatchDefaultsRentControlPage";
import BatchDefaultsExportTermsPage from "components/forms/BatchDefaultsExportTermsPage";
import BatchDefaultsRenewalNoticePeriodPage from "components/forms/BatchDefaultsRenewalNoticePeriodPage";
import BatchDefaultsRegulationsPage from "components/forms/BatchDefaultsRegulationsPage";
import CreateOneOffBatchPage from "components/forms/CreateOneOffBatchPage";

export default () => (
  <>
    <Route
      exact
      path={routes.batchdefaults.growthRates()}
      component={BatchDefaultsGrowthRatesPage}
    />
    <Route
      exact
      path={routes.batchdefaults.gtlAndLtl()}
      component={BatchDefaultsGtlAndLtlPage}
    />
    <Route
      exact
      path={routes.batchdefaults.scaling()}
      component={BatchDefaultsScalingPage}
    />
    <Route
      exact
      path={routes.batchdefaults.incentives()}
      component={BatchDefaultsIncentivesPage}
    />
    <Route
      exact
      path={routes.batchregulations.rentControl()}
      component={BatchDefaultsRentControlPage}
    />
    <Route
      exact
      path={routes.batchregulations.bmr()}
      component={BatchDefaultsBmrPage}
    />
    <Route
      exact
      path={routes.batchdefaults.exceptions()}
      component={BatchDefaultsExceptionsPage}
    />
    <Route
      exact
      path={routes.batchdefaults.exportTerms()}
      component={BatchDefaultsExportTermsPage}
    />
    <Route
      exact
      path={routes.batchdefaults.renewalNotice()}
      component={BatchDefaultsRenewalNoticePeriodPage}
    />
    <Route
      exact
      path={routes.batchregulations.ordinances()}
      component={BatchDefaultsRegulationsPage}
    />
    <Route exact path={routes.createOneOff} component={CreateOneOffBatchPage} />
  </>
);
