import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
  root: {},
  enableArea: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  showLabel: {
    paddingLeft: "5px"
  },
  enableSwitch: {
    paddingLeft: "5px"
  },
  showSwitchArea: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start"
  }
}));
