import { AtlasActionTypes } from "../AtlasActionTypes";
import { AtlasState, getMutableCopyOfAtlasState } from "../AtlasStateTypes";
import { AtlasActions } from "../AtlasActions";

const RenewalFollowUpFormPageReducer = (
  oldState: AtlasState,
  action: AtlasActions
): AtlasState => {
  const newState = getMutableCopyOfAtlasState(oldState);
  switch (action.type) {
    case AtlasActionTypes.RenewalFollowUpPageSetInitialForm:
      newState.renewalFollowUpPageState.form = action.form;
      newState.loadingState.isLoading = false;
      newState.messageState.messages = [];
      return newState;
    case AtlasActionTypes.RenewalFollowUpPageUpdateForm:
      newState.renewalFollowUpPageState.form = action.form;
      return newState;
    case AtlasActionTypes.RenewalFollowUpPageShowFormUpdateSuccessful:
      newState.renewalFollowUpPageState.form = action.form;
      return newState;
  }

  return newState;
};

export default RenewalFollowUpFormPageReducer;
