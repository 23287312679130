import {
  BestRenewalOffersFormOffer,
  BestRenewalOffersFormProperty,
  BestRenewalOfferColumnConfiguration,
  CommonSummaryFormRollup,
  BestRenewalOffersFormBatchComments
} from "api/generated";
import { AtlasActionTypes } from "../AtlasActionTypes";
import { AtlasActions } from "../AtlasActions";

export const SidebarActionMethods = {
  get: function(dispatcher: React.Dispatch<AtlasActions>) {
    const openUnitNotes = function(
      selectedUnit: BestRenewalOffersFormOffer | CommonSummaryFormRollup
    ) {
      dispatcher({
        type: AtlasActionTypes.SidebarOpenUnitNotes,
        selectedUnit: selectedUnit
      });
    };
    const openPropertyComments = function(
      selectedProperty: BestRenewalOffersFormProperty | CommonSummaryFormRollup
    ) {
      dispatcher({
        type: AtlasActionTypes.SidebarOpenPropertyComments,
        selectedProperty: selectedProperty
      });
    };
    const openBatchComments = function(
      batchComments: BestRenewalOffersFormBatchComments
    ) {
      dispatcher({
        type: AtlasActionTypes.SidebarOpenBatchComments,
        batchComments: batchComments
      });
    };
    const openColumnSelector = function(
      columnConfigurations: BestRenewalOfferColumnConfiguration[]
    ) {
      dispatcher({
        type: AtlasActionTypes.SidebarOpenColumnSelector,
        columnConfigurations: columnConfigurations
      });
    };
    const closeSideBars = function() {
      dispatcher({
        type: AtlasActionTypes.SidebarCloseSideBars
      });
    };
    return {
      openUnitNotes,
      openPropertyComments,
      openBatchComments,
      openColumnSelector,
      closeSideBars
    };
  }
};
