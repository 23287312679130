import React from "react";
import useStyle from "./useStyle";
import { GenericFormComment } from "../GenericFormCommentTypes";
import Spacer from "components/controls/Spacer";
import { OverlayPanel } from "primereact/components/overlaypanel/OverlayPanel";
import PrimaryButton from "components/controls/PrimaryButton";
import SecondaryButton from "components/controls/SecondaryButton";

interface CommentProps {
  bestRenewalOffersKey: string;
  comment: GenericFormComment;
  deleteAction?: (
    bestRenewalOffersKey: string,
    unitKey: string,
    commentId: number
  ) => void;
}

const Comment: React.FC<CommentProps> = (props) => {
  const classes = useStyle(props.comment);
  let dateTimeDisplay: string = "";

  if (props && props.comment.timestamp) {
    dateTimeDisplay = new Date(props.comment.timestamp).toLocaleString();
  }

  const deleteComment = () => {
    if (props.comment.isMyComment) {
      if (props.comment.id && props.deleteAction) {
        let sendKey: string = "";
        if (props.comment.unitKey == null) {
          if (props.comment.propertyKey != null) {
            sendKey = props.comment.propertyKey;
          }
        } else {
          sendKey = props.comment.unitKey;
        }
        props.deleteAction(
          props.bestRenewalOffersKey,
          sendKey,
          props.comment.id
        );
      }
    }
  };

  const spacerOrDeleteButton: React.FC<CommentProps> = function (props) {
    let overlayRef: OverlayPanel | null;
    const actionButtons = (
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <PrimaryButton
          onClick={() => {
            deleteComment();
            overlayRef && overlayRef.hide();
          }}
          title="Delete"
        />
        <Spacer />
        <SecondaryButton
          type="notice"
          onClick={() => {
            overlayRef && overlayRef.hide();
          }}
          title="Cancel"
        />
      </div>
    );
    if (props.comment.isMyComment) {
      return (
        <>
          <i
            className={"pi pi-times"}
            onClick={(e) => {
              overlayRef && overlayRef.toggle(e);
            }}
          />
          <OverlayPanel ref={(e) => (overlayRef = e)} appendTo={document.body}>
            <p>Are you sure you want to delete this comment?</p>
            {actionButtons}
          </OverlayPanel>
        </>
      );
    } else {
      return <Spacer orientation="v" size="m" />;
    }
  };

  return (
    <>
      <div className={classes.commentAlignmentContainer}>
        <div className={classes.commentContainer}>
          {spacerOrDeleteButton(props)}
          <div className={classes.commentBody}>
            <span>{props.comment.text}</span>
          </div>
          <Spacer orientation="v" size="m" />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start"
            }}
          >
            <span className={classes.commentDate}>{dateTimeDisplay}</span>
            <Spacer orientation="h" size="m" />
            <span className={classes.commentDate}>{props.comment.author}</span>
          </div>
        </div>
      </div>
      <Spacer orientation="v" size="xl" />
    </>
  );
};

export default Comment;
