import React, { useState } from "react";
import {
  Option,
  BaselineDefaultsRentControlFormDefaults,
  BaselineDefaultsRentControlFormPropertyType,
  DefaultsPageConfiguration
} from "api";
import createDataTable, {
  IPagedTableState,
  ColumnDefinition,
  DefaultInputEditorData,
  DataTableProps
} from "components/controls/createTable";

import Dropdown from "components/controls/Dropdown";
import listUtils from "utils/listUtils";

export interface BaselineDefaultsRentControlFormTableProps {
  defaults?: BaselineDefaultsRentControlFormDefaults[] | undefined;
  properties: BaselineDefaultsRentControlFormPropertyType[];
  onChange: (defaults: BaselineDefaultsRentControlFormDefaults[]) => void;
  hideRemoveRowColumn?: boolean | undefined;
  paging?: boolean | undefined;
  onAddRowAction?: (() => void) | undefined;
  includeRemoveRowColumn?: "show" | "buffer" | undefined;
  hideUnitNumberColumn?: boolean;
  pageConfiguration: DefaultsPageConfiguration | undefined;
}

interface TableState extends IPagedTableState {
  divisionFilter?: Option;
  sortField?: any;
  sortOrder?: any;
  sortState?: "unsorted" | "asc" | "desc" | undefined;
}

const BaselineDefaultsRentControlFormTable: React.FC<BaselineDefaultsRentControlFormTableProps> = function(
  props: BaselineDefaultsRentControlFormTableProps
) {
  const [table, setTable] = useState<TableState>({
    offset: 0,
    limit: 10,
    sortOrder: 1
  });

  const onRowChanged = (
    oldRow: BaselineDefaultsRentControlFormDefaults,
    newRow: BaselineDefaultsRentControlFormDefaults
  ) => {
    if (!props.defaults) {
      return;
    }

    newRow.isChanged = true;

    props.onChange(
      listUtils.copyListWithNewItem(props.defaults, oldRow, newRow)
    );
  };

  const onRemoveRow = (row: BaselineDefaultsRentControlFormDefaults) => {
    if (!props.defaults) {
      return;
    }

    const newDefaults = listUtils.copyListWithItemRemoved(props.defaults, row);
    props.onChange(newDefaults);
  };

  const onDefaultsRentControlFormPropertyChange = (
    defaultsRow: BaselineDefaultsRentControlFormDefaults
  ) => {
    return (
      selectedProperty: BaselineDefaultsRentControlFormPropertyType | undefined
    ) => {
      const newDefaultsRow: BaselineDefaultsRentControlFormDefaults = {
        ...defaultsRow,
        selectedPropertyType: selectedProperty,
        county: selectedProperty && selectedProperty.county
      };

      onRowChanged(defaultsRow, newDefaultsRow);
    };
  };

  const getPropertyDefaultsBody = function(rowData: any) {
    const defaults = rowData as BaselineDefaultsRentControlFormDefaults;

    let dropDown = (
      <Dropdown
        onChange={onDefaultsRentControlFormPropertyChange(defaults)}
        selectedValue={defaults.selectedPropertyType}
        options={props.properties}
        style={{ minWidth: "100%", maxWidth: "100%" }}
        validationMessage={
          defaults.fieldValidationErrors &&
          defaults.fieldValidationErrors["SelectedPropertyType"]
        }
      />
    );

    let label = (
      <div>
        {defaults.selectedPropertyType &&
          defaults.selectedPropertyType.displayValue}
      </div>
    );

    if (props.pageConfiguration && props.pageConfiguration.isEditEnabled) {
      return dropDown;
    } else {
      return label;
    }
  };

  const getDefaultInputEditorData = function(): DefaultInputEditorData<BaselineDefaultsRentControlFormDefaults> {
    return {
      isEditDisabled: !(
        props.pageConfiguration && props.pageConfiguration.isEditEnabled
      ),
      onChange: (
        row: BaselineDefaultsRentControlFormDefaults,
        field: keyof BaselineDefaultsRentControlFormDefaults,
        value: any
      ) => {
        const newDefaultsRow = {
          ...row
        };

        newDefaultsRow[field] = value;
        onRowChanged(row, newDefaultsRow);
      }
    };
  };

  const getColumns = function(): any[] {
    let columns = [] as ColumnDefinition<
      BaselineDefaultsRentControlFormDefaults,
      keyof BaselineDefaultsRentControlFormDefaults
    >[];

    const dropdownColumnStyle = { width: "15%" };

    columns = columns.concat([
      {
        field: "county",
        header: "County",
        style: { width: "10%", textAlign: "center" }
      },
      {
        header: "Property",
        style: dropdownColumnStyle,
        body: getPropertyDefaultsBody
      }
    ]);

    if (!props.hideUnitNumberColumn) {
      columns = columns.concat([
        {
          field: "unitNumber",
          header: "Unit #",
          style: { width: "10%", textAlign: "center" },
          defaultInputEditorData: getDefaultInputEditorData()
        }
      ]);
    } else {
      columns = columns.concat([
        {
          field: "unitNumber",
          header: "",
          style: { width: "10%", textAlign: "center" }
        }
      ]);
    }

    columns = columns.concat([
      {
        field: "maxPercentageByRevenueManager",
        header: "Max % by RM",
        style: { width: "10%", textAlign: "center" },
        defaultInputEditorData: getDefaultInputEditorData()
      },
      {
        field: "maxPercentageByLaw",
        header: "Max % by law",
        style: { width: "10%", textAlign: "center" },
        defaultInputEditorData: getDefaultInputEditorData()
      },
      {
        field: "lastUpdatedDate",
        header: "Last updated date",
        style: { width: "10%", textAlign: "center" }
      },
      {
        field: "lastUpdatedBy",
        header: "Last updated by",
        style: { width: "10%", textAlign: "center" }
      }
    ]);

    return columns;
  };

  const tableProps: DataTableProps<
    BaselineDefaultsRentControlFormDefaults,
    TableState
  > = {
    value: props.defaults || undefined,
    editMode: "cell",
    state: table,
    updateState: setTable,
    paging: props.paging,
    // TODO: This functionality only works when added at this leve. I was unable to get it to work when I moved it up to the createDataTable function
    sortField: table.sortField,
    sortOrder: table.sortOrder,
    onSort: (e: any) => {
      //clear sort column
      if (
        table.sortOrder === -1 &&
        e.sortOrder === 1 &&
        table.sortField === e.sortField
      ) {
        setTable({
          ...table,
          sortField: undefined,
          sortOrder: undefined
        });
      } else {
        setTable({
          ...table,
          sortField: e.sortField,
          sortOrder: e.sortOrder
        });
      }
    },
    onAddRowAction: props.onAddRowAction,
    onRemoveRowAction:
      props.includeRemoveRowColumn === "show" ? onRemoveRow : undefined,
    hasRemoveRowBuffer: props.includeRemoveRowColumn === "buffer",
    emptyMessage: "No defaults have been added."
  };
  const dataTable = createDataTable<
    BaselineDefaultsRentControlFormDefaults,
    TableState
  >(getColumns(), tableProps);

  return <div>{dataTable}</div>;
};

export default BaselineDefaultsRentControlFormTable;
