import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  validationContainerPlaceholder: {
    border: "3px solid transparent"
  },
  validationContainer: {
    border: "3px solid red",
    borderColor: theme.pallette.error,
    borderRadius: "4px"
  }
}));
