import React from "react";
import { TabView as PrimeTabView } from "primereact/tabview";
import { TabPanelProps } from "./TabPanel";

export interface TabViewProps {
  activeTabIndex: number;
  onTabIndexChange: (newTabIndex: number) => void;
  children:
    | React.ReactElement<TabPanelProps>
    | React.ReactElement<TabPanelProps>[];
  className?: string;
}

const TabView: React.FC<TabViewProps> = function (props) {
  return (
    <PrimeTabView
      activeIndex={props.activeTabIndex}
      onTabChange={(e) => props.onTabIndexChange(e.index)}
      className={props.className}
    >
      {props.children}
    </PrimeTabView>
  );
};

export default TabView;
