import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  h1: {
    fontSize: theme.headings.h1.fontSize,
    fontWeight: theme.headings.h1.fontWeight,
    lineHeight: theme.headings.h1.lineHeight
  },
  h2: {
    fontSize: theme.headings.h2.fontSize,
    fontWeight: theme.headings.h2.fontWeight,
    lineHeight: theme.headings.h2.lineHeight
  },
  h3: {
    fontSize: theme.headings.h3.fontSize,
    fontWeight: theme.headings.h3.fontWeight,
    lineHeight: theme.headings.h3.lineHeight
  }
}));
