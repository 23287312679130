import React from "react";
import Page from "components/layouts/Page";
import BestRenewalOffersMenu from "../BestRenewalOffersMenu";
import RequirePermission from "../RequirePermission";
import useStyle from "./useStyle";
import Spacer from "components/controls/Spacer";
import ActionLink from "components/controls/ActionLink";
import ContentArea from "components/layouts/ContentArea";
import { useHistory } from "react-router";
import PrimaryButton from "components/controls/PrimaryButton";
import SecondaryButton from "components/controls/SecondaryButton";
import VisibilityToggle from "components/controls/VisibilityToggle";
import routes from "components/routes";
import useFirstLoadEffect from "utils/useMountEffect";
import Spinner from "components/controls/Spinner";
import BatchDefaultsBatchInformationArea from "components/forms/BatchDefaultsBatchInformationArea";
import BestRenewalOffersTable, {
  BestRenewalOfferColumnConfigurationOverride
} from "./BestRenewalOffersTable";
import SideBars from "./Comments/SideBars";
import NotesButton from "./Comments/CommentsButton";
import { MessageAreaBasic } from "components/controls/MessageArea";
import SelectButton, {
  SelectButtonOption
} from "components/controls/SelectButton";
import useAtlasStore from "components/store/useAtlasStore";
import listUtils from "utils/listUtils";
import {
  ExceptionFilterEnum,
  ExceptionFilterOption
} from "components/store/AtlasStateTypes";
import { BestRenewalOffersPageMode } from "components/store/BestRenewalOffersPage/BestRenewalOffersPageActionMethods";
import IconButton, { IconType } from "components/controls/IconButton";
import Dialog from "components/controls/Dialog";
import Input from "components/controls/Input";
import PageSection from "components/controls/PageSection";
import ActionButton, {
  ActionItemProps
} from "components/controls/ActionButton";
import UnsavedChangesPrompt from "components/controls/UnsavedChangesPrompt";
import {
  CommonSummaryRollupKind,
  CommonSummaryFormRollup,
  ReviewSummaryFormFilter,
  ReviewSummaryFormFilters
} from "api";
import TabView from "components/layouts/TabView";
import CommonSummaryFormSummaryTable from "../CommonSummaryFormSummaryTable";
import TabPanel from "components/layouts/TabView/TabPanel";
import utils from "utils";
import Label from "components/controls/Label";
import ValidationWrapper from "components/controls/ValidationWrapper";
import Dropdown from "components/controls/Dropdown";

interface MatchParams {
  id: string;
}

interface PageProps {
  mode: BestRenewalOffersPageMode;
  id: string;
  frozenColumns?: boolean | undefined;
}

const BestRenewalOffersPage: React.FC<PageProps> = function (props: PageProps) {
  const bestRenewalOffersKey: string = props.id;
  const classes = useStyle();
  const history = useHistory();

  const [appState, appActions] = useAtlasStore();
  const pageState = {
    form: appState.bestRenewalOffersPageState.form,
    exceptionFilterOptions:
      appState.bestRenewalOffersPageState.exceptionFilterOptions,
    selectedExceptionFilter:
      appState.bestRenewalOffersPageState.selectedExceptionFilter,
    ...appState
  };
  const actions = {
    ...appActions.bestRenewalOffersPage,
    ...appActions.sideBar,
    ...appActions.messages,
    ...appActions
  };

  useFirstLoadEffect(() => {
    actions.getForm(bestRenewalOffersKey, props.mode);
  });

  const form = pageState.form;

  let areCommentsEnabled = false;
  let isApprovalEnabled = false;
  let isDeleteEnabled = false;
  let isEditEnabled = false;
  let isSubmitEnabled = false;
  let isExportEnabled = false;
  let canDownloadActualsDataToCsv = false;
  let showExceptionFilters = false;
  let showPropertySummary = false;

  if (form && form.pageConfiguration) {
    areCommentsEnabled = form.pageConfiguration.areCommentsEnabled || false;
    isApprovalEnabled = form.pageConfiguration.isApprovalEnabled || false;
    isDeleteEnabled = form.pageConfiguration.isDeleteEnabled || false;
    isEditEnabled = form.pageConfiguration.isEditEnabled || false;
    isSubmitEnabled = form.pageConfiguration.isSubmitEnabled || false;
    isExportEnabled = form.pageConfiguration.isExportEnabled || false;
    canDownloadActualsDataToCsv =
      form.pageConfiguration.canDownloadBatchToCsv || false;
    showExceptionFilters = form.pageConfiguration.showExceptionFilters || false;
    showPropertySummary = form.pageConfiguration.showPropertySummary || false;
  }

  const openBatchNotes = function () {
    const batchComments = pageState.form && pageState.form.batchComments;
    if (batchComments) {
      actions.openBatchComments(batchComments);
    }
  };

  const spinner = <Spinner isVisible={pageState.loadingState.isLoading} />;
  const messageAreas = pageState.messageState.messages.map((message) => (
    <MessageAreaBasic message={message} key={message.detail} />
  ));

  //See T-4258 for refactoring improvements
  if (
    (form == null ||
      form.offers == null ||
      form.propertySummary == null ||
      form.filters == null) &&
    pageState.loadingState
  ) {
    return spinner;
  } else if (
    form == null ||
    form.propertySummary == null ||
    form.filters == null
  ) {
    return <div>{messageAreas}</div>;
  }

  const filters = form.filters;

  const getExceptionCount = function (
    exceptionFilterEnum: ExceptionFilterEnum
  ): number {
    switch (exceptionFilterEnum) {
      case ExceptionFilterEnum.All:
        return pageState.bestRenewalOffersPageState.offerTotalCount;
      case ExceptionFilterEnum.Property:
        return pageState.bestRenewalOffersPageState.propertyExceptionCount;
      case ExceptionFilterEnum.Unit:
        return pageState.bestRenewalOffersPageState.unitExceptionCount;
      default:
        return 0;
    }
  };

  const exceptionOptions: SelectButtonOption<ExceptionFilterEnum>[] =
    pageState.exceptionFilterOptions.map((option: ExceptionFilterOption) => {
      return {
        value: option.type,
        label: option.name + ` (${getExceptionCount(option.type)})`
      } as SelectButtonOption<ExceptionFilterEnum>;
    });

  const getMatchingExceptionOptionOrFirst = function (
    options: SelectButtonOption<ExceptionFilterEnum>[],
    exceptionFilter: ExceptionFilterEnum
  ) {
    var first = listUtils.firstOrDefault(
      exceptionOptions,
      (option: SelectButtonOption<ExceptionFilterEnum>) => {
        if (!option.value) {
          return false;
        }
        return (option.value as ExceptionFilterEnum) === exceptionFilter;
      }
    );

    if (first) {
      return first as SelectButtonOption<ExceptionFilterEnum>;
    }

    return options[0];
  };

  const selectedExceptionOption = getMatchingExceptionOptionOrFirst(
    exceptionOptions,
    pageState.selectedExceptionFilter
  );

  const getNewPropertyFilters = function (newValue: ReviewSummaryFormFilter) {
    const newFilters = {
      ...filters,
      selectedPropertyFilter: newValue
    };

    if (newValue && filters.propertyFilters) {
      newFilters.propertyFilters = listUtils.copyListWithNewKeyedItem(
        filters.propertyFilters,
        newValue
      );
    }

    return newFilters;
  };

  const updatePropertyFilter = function (
    newValue: ReviewSummaryFormFilter
  ): void {
    const newFilters = getNewPropertyFilters(newValue);
    actions.updateFormFilters(newFilters);
  };

  const getNewCountyFilters = function (
    newValue: ReviewSummaryFormFilter
  ): ReviewSummaryFormFilters {
    const newFilters = {
      ...filters,
      selectedCountyFilter: newValue
    };

    if (newValue && filters.countyFilters) {
      newFilters.countyFilters = listUtils.copyListWithNewKeyedItem(
        filters.countyFilters,
        newValue
      );
    }

    return newFilters;
  };

  const updateCountyFilter = function (newValue: ReviewSummaryFormFilter) {
    const newFilters = getNewCountyFilters(newValue);

    actions.updateFormFilters(newFilters);
  };

  const exceptionFilterOnChange = (
    option: SelectButtonOption<ExceptionFilterEnum> | undefined
  ) => {
    if (option && option.value) {
      actions.updateExceptionFilter(option.value);

      let appliedFilters = listUtils.copyListWithCustomKeyRemoved(
        pageState.tableState.appliedFilters,
        ["isUnitException", "isPropertyException"],
        "field"
      );

      // Switch on these
      switch (option.value) {
        case ExceptionFilterEnum.Property:
          appliedFilters = appliedFilters.concat([
            {
              field: "isPropertyException",
              selectedValues: [true]
            }
          ]);
          break;
        case ExceptionFilterEnum.Unit:
          appliedFilters = appliedFilters.concat([
            {
              field: "isPropertyException",
              selectedValues: [false]
            },
            {
              field: "isUnitException",
              selectedValues: [true]
            }
          ]);
          break;
      }

      actions.updateAppliedFilters(appliedFilters);
    }
  };

  const onConfirmRejectionDialogHide = function () {
    actions.updateRejectionDialogVisisbility(false);
  };

  const confirmRejectionDialogFooter = (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <SecondaryButton
        type="important"
        onClick={() =>
          actions.rejectForm(bestRenewalOffersKey, {
            ...form,
            offers: form.offers && form.offers.filter((o) => o.isChanged)
          })
        }
        title="Reject"
      />
      <Spacer />
      <SecondaryButton
        type="notice"
        onClick={onConfirmRejectionDialogHide}
        title="Cancel"
      />
    </div>
  );

  const getRejectionInput = () => {
    return (
      <div>
        <div>You're about to reject all of the properties assigned to you.</div>
        <Spacer orientation="v" />
        <Input
          value={form.rejectReason}
          onUnfocus={(rejectionReason) => {
            actions.updateRejectionReason(rejectionReason);
          }}
          label="Please provide a rejection reason to continue."
        />
      </div>
    );
  };

  const showDeleteConfirmationDialog = function () {
    actions.updateDeletionDialogVisisbility(true);
  };

  const onDeleteConfirmationDialogHide = function () {
    actions.updateDeletionDialogVisisbility(false);
  };

  const deleteConfirmationDialogFooter = (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <SecondaryButton
        type="important"
        onClick={() =>
          actions.deleteForm(bestRenewalOffersKey, {
            ...form,
            offers: undefined
          })
        }
        title="Delete"
      />
      <Spacer />
      <SecondaryButton
        type="notice"
        onClick={onDeleteConfirmationDialogHide}
        title="Cancel"
      />
    </div>
  );

  const showApproveConfirmationDialog = function () {
    actions.updateApprovalDialogVisisbility(true);
  };

  const onApproveConfirmationDialogHide = function () {
    actions.updateApprovalDialogVisisbility(false);
  };

  const approveConfirmationDialogFooter = (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <PrimaryButton
        onClick={() =>
          actions.approveForm(bestRenewalOffersKey, {
            ...form,
            offers: form.offers && form.offers.filter((o) => o.isChanged)
          })
        }
        title="Approve"
      />
      <Spacer />
      <SecondaryButton
        type="notice"
        onClick={onApproveConfirmationDialogHide}
        title="Cancel"
      />
    </div>
  );

  const batchComments = pageState.form && pageState.form.batchComments;

  const getActionItems = function (): ActionItemProps[] {
    const items: ActionItemProps[] = [];
    if (isEditEnabled) {
      items.push(
        {
          command: () => {
            actions.saveForm(
              bestRenewalOffersKey,
              {
                ...form,
                offers:
                  form.offers && form.offers.filter((o) => o.isChanged === true)
              },
              props.mode
            );
          },
          label: "SAVE"
        },
        {
          command: () => {
            history.push(routes.batchListPage);
          },
          label: "CANCEL"
        }
      );
    }

    if (isDeleteEnabled) {
      items.push({
        command: () => showDeleteConfirmationDialog(),
        label: "DELETE"
      });
    }

    if (isSubmitEnabled) {
      items.push({
        command: () => actions.submitForm(bestRenewalOffersKey, form),
        label: "SUBMIT FOR REVIEW"
      });
    }

    if (isExportEnabled) {
      items.push({
        command: () => actions.exportForm(bestRenewalOffersKey, form),
        label: "EXPORT"
      });
    }

    if (isApprovalEnabled) {
      items.push(
        {
          command: () => actions.updateRejectionDialogVisisbility(true),
          label: "REJECT"
        },
        {
          command: showApproveConfirmationDialog,
          label: "APPROVE"
        }
      );
    }

    return items;
  };

  const getTabIndexFromCommonSummaryRollupKind = function (
    kind?: CommonSummaryRollupKind
  ): number {
    switch (kind) {
      case CommonSummaryRollupKind.Property:
        return 0;
      case CommonSummaryRollupKind.Unit:
        return 1;
    }

    return 0;
  };

  const getCommonSummaryRollupKindFromTabIndex = function (
    index: number
  ): number {
    switch (index) {
      case 0:
        return CommonSummaryRollupKind.Property;
      case 1:
        return CommonSummaryRollupKind.Unit;
    }

    return CommonSummaryRollupKind.Property;
  };

  const getActiveTabIndex = function () {
    return getTabIndexFromCommonSummaryRollupKind(form.activeView);
  };

  const onTabIndexChange = function (newTabIndex: number) {
    actions.updateTablePagingState({
      ...pageState.tableState.pagingState,
      offset: 0
    });
    actions.updateActiveTab(
      getCommonSummaryRollupKindFromTabIndex(newTabIndex)
    );
  };

  const applyPropertyNavigation = function (key: string) {
    const offersForProperty = (
      (pageState.bestRenewalOffersPageState.form &&
        pageState.bestRenewalOffersPageState.form.offers) ||
      []
    ).filter((offer) => offer.propertyKey === key);

    const propertyName =
      offersForProperty &&
      offersForProperty[0] &&
      offersForProperty[0].property;

    if (propertyName != null && !utils.stringIsNullOrEmpty(propertyName)) {
      actions.updateAppliedFilters([
        { field: "property", selectedValues: [propertyName] }
      ]);

      actions.updateActiveTab(CommonSummaryRollupKind.Unit);
      actions.updateExceptionFilter(ExceptionFilterEnum.All);
    }
  };

  const tableFilterArea = (
    <ContentArea columnSize="12">
      <div className={classes.filterDropdownArea}>
        <div className={classes.filterDropdown}>
          <Dropdown
            label="County"
            selectedValue={filters.selectedCountyFilter}
            options={filters.countyFilters}
            onChange={(o) => updateCountyFilter(o as ReviewSummaryFormFilter)}
          />
        </div>
        <div className={classes.filterDropdown}>
          <Dropdown
            label="Properties"
            selectedValue={filters.selectedPropertyFilter}
            options={filters.propertyFilters}
            onChange={(o) => updatePropertyFilter(o as ReviewSummaryFormFilter)}
          />
        </div>
        <div className={classes.filterDropdown}>
          <Dropdown
            label="Bedroom"
            selectedValue={filters.selectedBedroomFilter}
            options={filters.bedroomFilters}
            onChange={(o) => {
              const newValue = o as ReviewSummaryFormFilter;

              const newFilters = {
                ...filters,
                selectedBedroomFilter: newValue
              };

              if (newValue && filters.bedroomFilters) {
                newFilters.bedroomFilters = listUtils.copyListWithNewKeyedItem(
                  filters.bedroomFilters,
                  newValue
                );
              }

              actions.updateFormFilters(newFilters);
            }}
          />
        </div>
        <div className={classes.filterDropdown}>
          <Dropdown
            label="RPM"
            selectedValue={filters.selectedRpmFilter}
            options={filters.rpmFilters}
            onChange={(o) => {
              const newValue = o as ReviewSummaryFormFilter;

              const newFilters = {
                ...filters,
                selectedRpmFilter: newValue
              };

              if (newValue && filters.rpmFilters) {
                newFilters.rpmFilters = listUtils.copyListWithNewKeyedItem(
                  filters.rpmFilters,
                  newValue
                );
              }

              actions.updateFormFilters(newFilters);
            }}
          />
        </div>
        <div className={classes.filterDropdown}>
          <Dropdown
            label="Show Excluded?"
            selectedValue={filters.selectedIsExcludedFilter}
            options={filters.isExcludedFilters}
            onChange={(o) => {
              const newValue = o as ReviewSummaryFormFilter;

              const newFilters = {
                ...filters,
                selectedIsExcludedFilter: newValue
              };

              if (newValue && filters.isExcludedFilters) {
                newFilters.isExcludedFilters =
                  listUtils.copyListWithNewKeyedItem(
                    filters.isExcludedFilters,
                    newValue
                  );
              }

              actions.updateFormFilters(newFilters);
            }}
          />
        </div>
        <div className={classes.filterDropdown}>
          <Dropdown
            label="Exceptions"
            selectedValue={
              filters.onlyHasExceptionsFilter
                ? { key: "True", displayValue: "Exceptions Only" }
                : undefined
            }
            options={[{ key: "True", displayValue: "Exceptions Only" }]}
            onChange={(o) => {
              const newValue = o && o.key === "True" ? true : false;

              const newFilters = {
                ...filters,
                onlyHasExceptionsFilter: newValue
              };

              actions.updateFormFilters(newFilters);
            }}
          />
        </div>
        <div className={classes.applyButtonWrapper}>
          <ValidationWrapper
            showErrorState={
              pageState.bestRenewalOffersPageState &&
              pageState.bestRenewalOffersPageState.hasUnappliedFilters
            }
          >
            <div className={classes.applyButton}>
              <PrimaryButton
                title="Apply"
                onClick={() =>
                  actions.getFilteredForm(
                    bestRenewalOffersKey,
                    props.mode,
                    form.filters as ReviewSummaryFormFilters
                  )
                }
                disabled={false}
              />
            </div>
          </ValidationWrapper>
        </div>
      </div>
    </ContentArea>
  );

  const basicViewTableColumnOverrides: BestRenewalOfferColumnConfigurationOverride[] =
    [
      {
        field: "comments"
      },
      {
        field: "unit"
      },
      {
        field: "unitType"
      },
      {
        field: "bedrooms"
      },
      {
        field: "currentRent"
      },
      {
        field: "recurringConcession"
      },
      {
        field: "currentNetEffectiveRent"
      },
      {
        field: "excluded"
      },
      {
        field: "excludedReason"
      },
      {
        field: "growthRatePercentage"
      },
      {
        field: "adjustedMarketRentDollars"
      },
      {
        field: "parameter"
      },
      {
        field: "bestOfferOverrideDollars"
      },
      {
        field: "bestOfferOverridePercentage"
      },
      {
        field: "bestOfferDollars"
      },
      {
        field: "newRecurringConcession"
      },
      {
        field: "newRecurringConcessionOverride"
      },
      {
        field: "rentChangeDollars"
      },
      {
        field: "rentChangePercentage"
      },
      {
        field: "netEffectiveOffer"
      },
      {
        field: "netEffectiveIncreasePercent"
      }
    ];

  const getExpandableRowElementTemplate = function (
    row: CommonSummaryFormRollup
  ) {
    const offersforRow = (
      (pageState.bestRenewalOffersPageState.form &&
        pageState.bestRenewalOffersPageState.form.offers) ||
      []
    ).filter((offer) => offer.propertyKey === row.key);

    return (
      <div style={{ padding: "30px" }}>
        <BestRenewalOffersTable
          isEditEnabled={isEditEnabled}
          areCommentsEnabled={areCommentsEnabled}
          bestRenewalOffersKey={bestRenewalOffersKey}
          pageMode={props.mode}
          offers={offersforRow || []}
          filteredOffers={offersforRow || []}
          appliedFilters={pageState.tableState.appliedFilters}
          pagingState={pageState.tableState.pagingState}
          columnConfigurations={form.columnConfigurations || []}
          unitNotesDisplayState={pageState.sideBarState.unitNotesDisplayState}
          updateAppliedFilters={actions.updateAppliedFilters}
          updatePagingState={actions.updateTablePagingState}
          updateOffer={actions.updateOffer}
          updateAllOffers={actions.updateAllOffers}
          openUnitNotes={actions.openUnitNotes}
          areFiltersEnabled={false}
          columnConfigurationOverrides={basicViewTableColumnOverrides}
          pagingOptions={[]}
        />
      </div>
    );
  };

  const isPageDirty =
    (form && form.offers && form.offers.some((o) => o.isChanged)) || false;

  let tableRef: any = React.createRef();

  const tableProps = {
    isEditEnabled: isEditEnabled,
    areCommentsEnabled: areCommentsEnabled,
    areFiltersEnabled: true,
    bestRenewalOffersKey: bestRenewalOffersKey,
    pageMode: props.mode,
    offers: form.offers || [],
    filteredOffers: pageState.bestRenewalOffersPageState.filteredOffers || [],
    appliedFilters: pageState.tableState.appliedFilters,
    pagingState: pageState.tableState.pagingState,
    columnConfigurations: form.columnConfigurations || [],
    unitNotesDisplayState: pageState.sideBarState.unitNotesDisplayState,
    updateAppliedFilters: actions.updateAppliedFilters,
    updatePagingState: actions.updateTablePagingState,
    updateOffer: actions.updateOffer,
    updateAllOffers: actions.updateAllOffers,
    openUnitNotes: actions.openUnitNotes,
    frozenColumns: props.frozenColumns
  };

  const bestRenewalOffersTableArea = (
    <div className={classes.tableContentAreaContainer}>
      {/* TODO T-5050 Refactor this into an ExceptionFilterComponent for read-ability */}
      <Spacer orientation="v" size="xl" />
      <VisibilityToggle isVisible={showExceptionFilters}>
        <div className={classes.bestRenewalOffersTableFiltering}>
          {/* TODO: remove remaining logic for property exception filters after stable production release.
          We removed these filters in 5490, but we left the code in case we need to add them back.  */}
          <SelectButton
            selectedValue={selectedExceptionOption}
            options={exceptionOptions}
            onChange={exceptionFilterOnChange}
          ></SelectButton>
          <Spacer orientation="h" size="xl" />
        </div>
      </VisibilityToggle>
      <ContentArea columnSize={"12"}>
        <div className={classes.bestRenewalOffersTableFiltersContainer}>
          <div className={classes.bestRenewalOffersTableDownloadAndColumns}>
            <IconButton
              icon={IconType.Download}
              disabled={pageState.loadingState.isLoading}
              onClick={() => {
                if (canDownloadActualsDataToCsv) {
                  actions.downloadToCsv(bestRenewalOffersKey);
                } else {
                  tableRef &&
                    tableRef.current &&
                    tableRef.current.exportCSV &&
                    tableRef.current.exportCSV();
                }
              }}
            />
            <Spacer orientation="h" size="xl" />

            <div>
              <ActionLink
                onClick={() => {
                  actions.openColumnSelector(
                    (pageState.form && pageState.form.columnConfigurations) ||
                      []
                  );
                }}
              >
                Show / Hide Columns
              </ActionLink>
            </div>
          </div>
        </div>
        <Spacer orientation="v" size="l" />
        <div className={classes.bestRenewalOffersTableContainer}>
          <div className={classes.bestRenewalOffersTable}>
            <div className={classes.bestRenewalOffersTableWrapper}>
              <BestRenewalOffersTable {...tableProps} />
              <div className={classes.hidden}>
                <BestRenewalOffersTable
                  {...tableProps}
                  offers={tableProps.filteredOffers}
                  pagingState={{ limit: 1, offset: 0 }}
                  areFiltersEnabled={false}
                  ref={tableRef}
                />
              </div>
            </div>
          </div>
        </div>
      </ContentArea>
    </div>
  );

  const commonSummaryFormSummaryTableProps = {
    batchKey: bestRenewalOffersKey,
    pagingState: pageState.tableState.pagingState,
    updatePagingState: actions.updateTablePagingState,
    openUnitNotes: actions.openUnitNotes,
    openPropertyNotes: actions.openPropertyComments,
    updateRollupSelection: (
      rollupKind: CommonSummaryRollupKind,
      selectedRollups: CommonSummaryFormRollup[]
    ) => {},
    applyCountyNavigation: (key: string) => {},
    applyPropertyNavigation: applyPropertyNavigation
  };

  const tabView = (
    <TabView
      activeTabIndex={getActiveTabIndex()}
      onTabIndexChange={onTabIndexChange}
    >
      <TabPanel
        header={`Properties (${pageState.bestRenewalOffersPageState.propertyTotalCount})`}
      >
        {tableFilterArea}
        <Spacer size="s"></Spacer>
        <CommonSummaryFormSummaryTable
          {...commonSummaryFormSummaryTableProps}
          summary={form.propertySummary || {}}
          mode={CommonSummaryRollupKind.Property}
          isApprovalEnabled={false}
          getExpandableRowElementTemplate={getExpandableRowElementTemplate}
          tableState={
            pageState.bestRenewalOffersPageState.propertySummaryTableState
          }
          updateTableState={actions.updatePropertySummaryTableState}
          summaryRowTitle="Summary"
          includeRpm={true}
        />

        <Spacer orientation="v" size="m" />
      </TabPanel>
      <TabPanel
        header={`Units (${pageState.bestRenewalOffersPageState.offerTotalCount})`}
      >
        {bestRenewalOffersTableArea}
      </TabPanel>
    </TabView>
  );

  const tablesView = showPropertySummary ? tabView : bestRenewalOffersTableArea;
  let tableViewArea = tablesView;

  if (form.activeView === CommonSummaryRollupKind.Property) {
    tableViewArea = (
      <div className={classes.tablePageSection}>{tablesView}</div>
    );
  }

  return (
    <Page
      overrideSection={true}
      menu={
        <BestRenewalOffersMenu
          bestRenewalOffersKey={bestRenewalOffersKey}
          batchNavigationConfig={form.navigationConfiguration}
          actionArea={
            <div style={{ display: "flex", alignItems: "center" }}>
              <UnsavedChangesPrompt isVisible={isPageDirty} />
              <Spacer orientation="h" size="xl" />
              <ActionButton label="ACTIONS" items={getActionItems()} />{" "}
            </div>
          }
        />
      }
    >
      <RequirePermission
        permissions={["canViewBestRenewalOffersForm"]}
        accessDenied="page"
      >
        <PageSection>
          {spinner}
          {messageAreas}
          <div className={classes.splitContent}>
            <div className={classes.headerMetaDataLeft}>
              <BatchDefaultsBatchInformationArea
                batchInformation={{
                  ...form.batchDetail,
                  approversVotedCount: form.approversVotedCount,
                  approversTotalCount: form.approversTotalCount
                }}
              />
            </div>
            <VisibilityToggle isVisible={areCommentsEnabled}>
              <Spacer orientation="h" size="xl" />
              <div>
                <NotesButton
                  label={`Batch comments (${
                    (form.batchComments && form.batchComments.totalCount) || 0
                  })`}
                  alt="Batch comments"
                  className={classes.batchNotes}
                  onClick={openBatchNotes}
                  isHighlighted={
                    batchComments != null &&
                    batchComments.totalCount != null &&
                    batchComments.totalCount > 0
                  }
                />
              </div>
            </VisibilityToggle>
          </div>
          <Spacer orientation="v" size="m" />
        </PageSection>

        {tableViewArea}
        <PageSection>
          <SideBars
            bestRenewalOffersKey={bestRenewalOffersKey}
            sideBarState={pageState.sideBarState}
            closeSideBars={actions.closeSideBars}
            updateColumnConfigurations={actions.updateColumnConfigurations}
            addPropertyComment={actions.addPropertyComment}
            addUnitComment={actions.addUnitComment}
            deletePropertyComment={actions.deletePropertyComment}
            deleteUnitComment={actions.deleteUnitComment}
          />
          <Dialog
            header="Alert"
            isVisible={
              pageState.bestRenewalOffersPageState.rejectionDialogIsVisible
            }
            onHide={() => onConfirmRejectionDialogHide()}
            footer={confirmRejectionDialogFooter}
          >
            {getRejectionInput()}
          </Dialog>

          <Dialog
            header="Alert"
            isVisible={
              pageState.bestRenewalOffersPageState.deletionDialogIsVisible
            }
            onHide={onDeleteConfirmationDialogHide}
            footer={deleteConfirmationDialogFooter}
          >
            <Label label="Are you sure you want to permanently delete this batch?" />
          </Dialog>

          <Dialog
            header="Alert"
            isVisible={
              pageState.bestRenewalOffersPageState.approvalDialogIsVisible
            }
            onHide={onApproveConfirmationDialogHide}
            footer={approveConfirmationDialogFooter}
          >
            <div>
              <div>
                You're about to approve all of the properties assigned to you.
              </div>
              <Spacer orientation="v" />
              <Label label="Are you sure you want to continue?" />
            </div>
          </Dialog>
        </PageSection>
      </RequirePermission>
    </Page>
  );
};
export default BestRenewalOffersPage;
