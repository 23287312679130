import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  root: {
    color: theme.pallette.primary,
    cursor: "pointer",
    textDecoration: "underline",
    background: "none",
    border: "none",
    padding: "0",
    textAlign: "left",
    paddingBottom: "2px",
    fontSize: theme.typeScale.medium,
    fontWeight: "bold",
    lineHeight: "1.35em"
  }
}));
