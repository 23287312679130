import React from "react";

export interface VisibilityToggleProps {
  isVisible: boolean | undefined;
}

const VisibilityToggle: React.FC<VisibilityToggleProps> = function(props) {
  if (props.isVisible) {
    return <>{props.children}</>;
  }
  return <></>;
};

export default VisibilityToggle;
