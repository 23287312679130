import React from "react";
import useStyle from "./useStyle";
import MenuItem, { MenuItemProps } from "./MenuItem";
import cssUtils from "utils/cssUtils";

export * from "./MenuItem";

export interface MenuProps {
  title: string;
  items?: MenuItemProps[];
  actionArea?: JSX.Element;
}

const Menu: React.FC<MenuProps> = function(props) {
  const classes = useStyle();
  return (
    <div className={classes.background}>
      <div className={classes.outerContainer}>
        <div className={classes.container}>
          <div className={classes.titleBar}>
            <span className={classes.title}>{props.title}</span>
            <div className={classes.items}>
              <div
                className={cssUtils.join(classes.mainItems, "mainMenuItems")}
              >
                {props.items &&
                  props.items.map(i => <MenuItem key={i.title} {...i} />)}
                {props.children}
              </div>
              <div className={classes.actionItems}>{props.actionArea}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Menu;
