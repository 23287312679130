import { createUseStyles } from "react-jss";
import { CheckboxProps, defaultMixedIcon } from ".";

export default createUseStyles({
  checkbox: {
    "& span.pi-check:before": {
      content: (props: CheckboxProps) =>
        props.checked === "mixed"
          ? `'${props.mixedIcon || defaultMixedIcon}'`
          : "'\\e909'"
    }
  }
});
