import React from "react";
import useStyle from "./useStyle";

export interface ValidationWrapperProps {
  showErrorState: boolean;
  children: any;
}

const ValidationWrapper: React.FC<ValidationWrapperProps> = function(props) {
  const classes = useStyle();

  if (!props.showErrorState)
    return (
      <div className={classes.validationContainerPlaceholder}>
        {props.children}
      </div>
    );

  return <div className={classes.validationContainer}>{props.children}</div>;
};

export default ValidationWrapper;
