import React from "react";
import { RouteComponentProps } from "react-router";
import BestRenewalOffersPage from "./";
import { BestRenewalOffersPageMode } from "components/store/BestRenewalOffersPage/BestRenewalOffersPageActionMethods";

interface MatchParams {
  id: string;
}

interface PageProps extends RouteComponentProps<MatchParams> {}

const BestRenewalOffersActualsPage: React.FC<PageProps> = function (
  props: PageProps
) {
  const bestRenewalOffersKey: string = props.match.params.id;
  return (
    <BestRenewalOffersPage
      id={bestRenewalOffersKey}
      mode={BestRenewalOffersPageMode.Actuals}
      frozenColumns={false}
    />
  );
};

export default BestRenewalOffersActualsPage;
