import { AtlasActionTypes } from "../AtlasActionTypes";
import { AtlasState, getMutableCopyOfAtlasState } from "../AtlasStateTypes";
import { AtlasActions } from "../AtlasActions";
import MessageUtils from "utils/messageUtils";
const MessagesReducer = (
  oldState: AtlasState,
  action: AtlasActions
): AtlasState => {
  const newState = getMutableCopyOfAtlasState(oldState);
  switch (action.type) {
    case AtlasActionTypes.ShowErrorState:
      newState.messageState.messages.push(
        MessageUtils.getGenericErrorMessage()
      );
      newState.loadingState.isLoading = false;
      return newState;
    case AtlasActionTypes.ShowValidationErrorState:
      newState.messageState.messages.push(
        MessageUtils.getGenericValidationMessage()
      );
      newState.loadingState.isLoading = false;
      return newState;
  }
  return newState;
};

export default MessagesReducer;
