import React from "react";
import {
  Option,
  CommonSummaryFormSummary,
  CommonSummaryFormRollup,
  CommonSummaryRollupKind
} from "api";
import createDataTable, {
  IPagedTableState,
  ColumnDefinition,
  DataTableProps
} from "components/controls/createTable";

import useTableStyle from "./useTableStyle";
import NotesButton from "../BestRenewalOffersPage/Comments/CommentsButton";
import {
  GenericFormUnitWithComments,
  GenericFormPropertyWithComments
} from "../BestRenewalOffersPage/Comments/GenericFormCommentTypes";
import ActionLink from "components/controls/ActionLink";
import { ExpandableTableState } from "components/store/AtlasStateTypes";
import { useSiteTheme } from "theme";

export interface CommonSummaryFormSummaryTableProps {
  summary: CommonSummaryFormSummary;
  batchKey: string;
  tableState: ExpandableTableState;
  summaryRowTitle?: string;
  updateRollupSelection: (
    rollupKind: CommonSummaryRollupKind,
    selectedRollups: CommonSummaryFormRollup[]
  ) => void;
  openUnitNotes: (selectedUnit: GenericFormUnitWithComments) => void;
  openPropertyNotes: (
    selectedProperty: GenericFormPropertyWithComments
  ) => void;
  applyCountyNavigation: (key: string) => void;
  applyPropertyNavigation: (key: string) => void;
  mode: CommonSummaryRollupKind;
  areCommentsDisabled?: boolean;
  isApprovalEnabled?: boolean;
  getExpandableRowElementTemplate?: (
    row: CommonSummaryFormRollup
  ) => JSX.Element;
  updateTableState: (tableState: ExpandableTableState) => void;
  includeRpm?: boolean | undefined;
}

interface TableState extends IPagedTableState {
  divisionFilter?: Option;
  sortField?: any;
  sortOrder?: any;
  sortState?: "unsorted" | "asc" | "desc" | undefined;
}

type SummaryColumnDefinition = ColumnDefinition<
  CommonSummaryFormRollup,
  keyof CommonSummaryFormRollup
>;

const CommonSummaryFormSummaryTable: React.FC<CommonSummaryFormSummaryTableProps> =
  function (props) {
    const setExpanderState = function (rows: any[]) {
      props.updateTableState({
        appliedFilters: [],
        expandedRows: rows,
        pagingState: props.tableState.pagingState
      });
    };

    const tableClasses = useTableStyle();
    const theme = useSiteTheme();

    const frozenTotalsRows: CommonSummaryFormRollup[] = props.summary
      .rollupTotals
      ? [props.summary.rollupTotals]
      : [];

    const isFrozenRow = function (row: any) {
      return frozenTotalsRows.indexOf(row) >= 0;
    };

    const getCountyBody = function () {
      return (rowData: any) => {
        if (
          isFrozenRow(rowData) &&
          props.mode === CommonSummaryRollupKind.County
        ) {
          return <div>{props.summaryRowTitle}</div>;
        }

        const rollup = rowData as CommonSummaryFormRollup;
        if (!rollup) {
          return <div />;
        }

        return (
          <ActionLink
            onClick={() => props.applyCountyNavigation(rollup.countyKey || "")}
            fontSize={"13px"}
          >
            {rollup.county}
          </ActionLink>
        );
      };
    };

    const getOffersCountyBody = function () {
      return (rowData: any) => {
        if (
          isFrozenRow(rowData) &&
          props.mode === CommonSummaryRollupKind.County
        ) {
          return <div>{props.summaryRowTitle}</div>;
        }

        const rollup = rowData as CommonSummaryFormRollup;
        if (!rollup) {
          return <div />;
        }

        return rollup.county;
      };
    };

    const getPropertyBody = function () {
      return (rowData: any) => {
        if (
          isFrozenRow(rowData) &&
          (props.mode === CommonSummaryRollupKind.Property ||
            props.mode === CommonSummaryRollupKind.Unit)
        ) {
          return <div>{props.summaryRowTitle}</div>;
        }

        const rollup = rowData as CommonSummaryFormRollup;
        if (!rollup) {
          return <div />;
        }

        if (props.mode === CommonSummaryRollupKind.Unit) {
          return <div>{rollup.property}</div>;
        }

        return (
          <ActionLink
            onClick={() =>
              props.applyPropertyNavigation(rollup.propertyKey || "")
            }
            linkColor={
              rollup.isPropertyException ? theme.pallette.black : undefined
            }
            fontSize={"13px"}
          >
            {rollup.property}
          </ActionLink>
        );
      };
    };

    const getRpmColumnTemplate = function () {
      return (rowData: any) => {
        const offer = rowData as CommonSummaryFormRollup;
        return (
          <div>
            {offer.rpm &&
              offer.rpm.split("|").map((i, key) => {
                return (
                  <div key={key} style={{ display: "block", padding: "1px" }}>
                    {i}
                  </div>
                );
              })}
          </div>
        );
      };
    };

    const getCommentsColumnTemplate = function () {
      if (props.areCommentsDisabled) {
        return;
      }

      return (rowData: any) => {
        if (isFrozenRow(rowData)) {
          return <div />;
        }

        const row = rowData as CommonSummaryFormRollup;
        const unitComments = row && row.unitComments;
        const hasUnitComments = unitComments != null && unitComments.length > 0;
        const propertyComments = row && row.propertyComments;
        const hasPropertyComments =
          propertyComments != null && propertyComments.length > 0;
        const modeIsUnitMode = props.mode === CommonSummaryRollupKind.Unit;

        return (
          <div className={tableClasses.unitNotesColumnBody}>
            <NotesButton
              alt={
                props.mode === CommonSummaryRollupKind.Unit
                  ? "Unit comments"
                  : "Property comments"
              }
              className={tableClasses.unitNotesImage}
              isHighlighted={
                modeIsUnitMode ? hasUnitComments : hasPropertyComments
              }
              onClick={() => {
                if (modeIsUnitMode) {
                  props.openUnitNotes({ ...row, comments: unitComments });
                } else {
                  props.openPropertyNotes({
                    ...row,
                    displayValue: row.property,
                    comments: propertyComments
                  });
                }
              }}
            />
          </div>
        );
      };
    };

    const getColumns = function (): any[] {
      const showAverageVerbiage =
        props.mode === CommonSummaryRollupKind.County ||
        props.mode === CommonSummaryRollupKind.Property;
      const showCountyColumn = props.mode === CommonSummaryRollupKind.County;
      const showPropertyColumn =
        props.mode === CommonSummaryRollupKind.Property ||
        props.mode === CommonSummaryRollupKind.Unit;
      const showNotes =
        props.mode === CommonSummaryRollupKind.Property ||
        props.mode === CommonSummaryRollupKind.Unit;
      const showUnitColumn = props.mode === CommonSummaryRollupKind.Unit;
      const showExpandable = props.getExpandableRowElementTemplate != null;
      const showOffersCountyColumn =
        CommonSummaryRollupKind.Property && showExpandable;

      let columns = [] as ColumnDefinition<
        CommonSummaryFormRollup,
        keyof CommonSummaryFormRollup
      >[];

      if (showExpandable) {
        columns = columns.concat([
          {
            expander: true,
            style: { width: "2em" }
          }
        ]);
      }

      if (showNotes) {
        columns = columns.concat([
          {
            className: tableClasses.unitNotesColumn,
            body: getCommentsColumnTemplate()
          }
        ]);
      } else {
        columns = columns.concat([
          {
            className: tableClasses.unitNotesColumn
          }
        ]);
      }

      const blankCountyColumn: SummaryColumnDefinition = {
        header: undefined,
        style: { width: "10%", textAlign: "left" }
      };

      const blankPropertyColumn: SummaryColumnDefinition = {
        header: undefined,
        style: { width: "15%", textAlign: "left" }
      };

      const blankRpmColumn: SummaryColumnDefinition = {
        header: undefined,
        style: { width: "12%", textAlign: "left" }
      };

      const blankUnitColumn: SummaryColumnDefinition = {
        header: undefined,
        style: { width: "6%", textAlign: "left" }
      };

      if (!showCountyColumn && !props.includeRpm) {
        blankPropertyColumn.style = { width: "20%", textAlign: "left" };
      }
      if (!showPropertyColumn) {
      }
      if (!showUnitColumn) {
      }

      if (showCountyColumn) {
        const countyColumn: SummaryColumnDefinition = {
          ...blankCountyColumn,
          field: "county",
          header: "County",
          body: getCountyBody()
        };
        columns = columns.concat([countyColumn]);
      }

      if (showOffersCountyColumn) {
        const countyColumn: SummaryColumnDefinition = {
          ...blankCountyColumn,
          field: "county",
          header: "County",
          body: getOffersCountyBody()
        };
        columns = columns.concat([countyColumn]);
      }

      if (showPropertyColumn) {
        const propertyColumn: SummaryColumnDefinition = {
          ...blankPropertyColumn,
          field: "property",
          header: "Property"
        };

        propertyColumn.body = getPropertyBody();

        columns = columns.concat([propertyColumn]);
      }

      if (props.includeRpm) {
        const rpmColumn: SummaryColumnDefinition = {
          ...blankRpmColumn,
          field: "rpm",
          header: "Rpm",
          body: getRpmColumnTemplate()
        };

        columns = columns.concat([rpmColumn]);
      }

      if (showUnitColumn) {
        const unitColumn: SummaryColumnDefinition = {
          ...blankUnitColumn,
          field: "unit",
          header: "Unit"
        };
        columns = columns.concat([unitColumn]);
      }

      columns = columns.concat([
        {
          field: "renewals",
          header: "Renewals",
          style: { width: "6%", textAlign: "right" }
        },
        {
          field: "currentRentDollars",
          header: "Current Rent $",
          style: { width: "9.5%", textAlign: "right" }
        },
        {
          field: "marketRentDollars",
          header: "Market Rent $",
          style: { width: "9.5%", textAlign: "right" }
        },
        {
          field: "adjustedMarketRentDollars",
          header: "Adj Market $",
          style: { width: "8%", textAlign: "right" }
        },
        {
          field: "bestOfferDollars",
          header: "Best Offer $",
          style: { width: "8%", textAlign: "right" }
        },
        {
          field: "averageRecurringConcession",
          header: "Avg Recur Concession",
          style: { width: "7%", textAlign: "right" }
        },
        {
          field: "averageIncreasePercentage",
          header: showAverageVerbiage ? "Avg Incr %" : "Increase %",
          style: { width: "5%", textAlign: "right" }
        },
        {
          field: "netEffectiveIncreasePercentage",
          header: "Net Eff Incr %",
          style: { width: "6%", textAlign: "right" }
        },
        {
          field: "netEffectiveAdjustedMarketRentPercentage",
          header: "Net Eff Adj MR LTL/GTL %",
          style: { width: "9%", textAlign: "right" }
        },
        {
          field: "offerToAdjustedMarketRentPercentage",
          header: "Offer Adj MR LTL/GTL %",
          style: { width: "8%", textAlign: "right" }
        },
        {
          header: undefined,
          style: { width: "2%" }
        }
      ]);

      return columns;
    };

    const getRowClassName = function (rowData: any): object {
      const rollup = rowData as CommonSummaryFormRollup;
      if (!rollup) {
        return {};
      }

      return {
        highlightRowForPropertyException:
          props.mode === CommonSummaryRollupKind.Property &&
          rollup.isPropertyException,
        highlightRowForUnitException:
          props.mode === CommonSummaryRollupKind.Unit && rollup.isUnitException
      };
    };

    const rollups = props.summary.rollups || [];

    let expanderTableProps = null;
    if (props.getExpandableRowElementTemplate != null) {
      expanderTableProps = {
        expandedRows: props.tableState.expandedRows,
        onRowToggle: (e: any) => setExpanderState(e.data),
        rowExpansionTemplate: props.getExpandableRowElementTemplate
      };
    }

    const tableProps: DataTableProps<CommonSummaryFormRollup, TableState> = {
      ...expanderTableProps,
      value: rollups,
      frozenValue: frozenTotalsRows,
      state: props.tableState.pagingState,
      updateState: (state) =>
        props.updateTableState({
          expandedRows: props.tableState.expandedRows,
          appliedFilters: [],
          pagingState: state
        }),
      scrollable: true,
      autoLayout: true,
      emptyMessage: "No results.",
      rowClassName: getRowClassName
    };

    const dataTable = createDataTable<CommonSummaryFormRollup, TableState>(
      getColumns(),
      tableProps
    );

    return <div className={tableClasses.tableContainer}>{dataTable}</div>;
  };

export default CommonSummaryFormSummaryTable;
