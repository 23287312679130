import React, { useState } from "react";
import Checkbox, { Checked } from "components/controls/Checkbox";
import Searchbox from "components/controls/Searchbox";
import useStyle from "./useStyle";
import Spacer from "components/controls/Spacer";
import Label from "components/controls/Label";
import ScrollPanel from "components/layouts/ScrollPanel";
import ValidationWrapper from "components/controls/ValidationWrapper";

type PageState = {
  unitFilter?: string;
};

type UnitOption = {
  key?: string;
  displayValue?: string;
};

export interface UnitChooserProps {
  units?: UnitOption[];
  selectedUnits?: UnitOption[];
  validationMessage?: string | null | undefined;
  onChange: (selection: UnitOption[]) => void;
}

const UnitChooser: React.FC<UnitChooserProps> = function (props) {
  const [state, setState] = useState<PageState>({});
  const classes = useStyle();

  const properties =
    props.units == null
      ? []
      : props.units.map((p) => ({
          ...p,
          selected:
            props.selectedUnits == null
              ? false
              : props.selectedUnits.find((s) => s.key === p.key) != null
        }));

  const checkSelectAll: Checked =
    properties.length > 0 && properties.every((p) => p.selected)
      ? true
      : properties.every((p) => !p.selected)
      ? false
      : "mixed";

  const filteredProperties = properties.filter(
    (p) =>
      state.unitFilter == null ||
      (p.displayValue &&
        p.displayValue.toLowerCase().includes(state.unitFilter.toLowerCase()))
  );

  const toggleSelection = (value: Checked) => {
    if (value === true) {
      props.onChange(props.units || []);
    } else if (value === false) {
      props.onChange([]);
    }
  };

  const toggleProperty = (key: string | undefined, checked: Checked) => {
    if (checked === false && props.selectedUnits != null) {
      props.onChange(props.selectedUnits.filter((p) => p.key !== key));
    } else if (checked === true) {
      const safeSelection = props.selectedUnits || [];
      props.onChange([
        ...safeSelection,
        ...properties.filter((p) => p.key === key)
      ]);
    } else {
      console.log("Mixed is an unexpected state for unit checkbox.");
    }
  };

  const labelText = `Selected units: ${
    (props.selectedUnits && props.selectedUnits.length) || 0
  }/${(props.units && props.units.length) || 0}`;

  return (
    <ValidationWrapper showErrorState={props.validationMessage != null}>
      <div className={classes.container}>
        <Searchbox
          label="Filter"
          value={state.unitFilter}
          onChange={(v) => setState({ ...state, unitFilter: v })}
          mode="filter"
        />
        <Spacer orientation="v" />
        <Label label={labelText} />
        <Spacer orientation="v" />
        <Checkbox
          label="Select all"
          labelPosition="right"
          checked={checkSelectAll}
          onChange={toggleSelection}
        />
        <ScrollPanel heightInPixels={250}>
          {filteredProperties.map((p) => (
            <div key={p.key}>
              <Spacer orientation="v" />
              <Checkbox
                label={p.displayValue}
                labelPosition="right"
                checked={p.selected}
                onChange={(checked) => toggleProperty(p.key, checked)}
              />
            </div>
          ))}
        </ScrollPanel>
      </div>
    </ValidationWrapper>
  );
};

export default UnitChooser;
