import React from "react";
import useStyle from "./useStyle";
import { Link } from "react-router-dom";
import cssUtils from "utils/cssUtils";

export interface ButtonTypeProps {
  type: "neutral" | "notice" | "important";
}

export interface SecondaryButtonProps extends ButtonTypeProps {
  title: string;
  disabled?: boolean;
  onClick?: () => void;
  to?: string;
  className?: string | undefined;
}

const SecondaryButton: React.FC<SecondaryButtonProps> = function(props) {
  const classes = useStyle(props);

  if (props.to != null) {
    return (
      <Link
        className={cssUtils.join(props.className, classes.button)}
        to={props.to}
        onClick={() => !props.disabled && props.onClick}
      >
        <span className={classes.title}>{props.title}</span>
      </Link>
    );
  } else {
    return (
      <button
        className={cssUtils.join(props.className, classes.button)}
        onClick={props.onClick}
      >
        <span className={classes.title}>{props.title}</span>
      </button>
    );
  }
};

export default SecondaryButton;
