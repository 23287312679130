import React from "react";
import IconButton, { IconType } from "../IconButton";

export interface AddButtonProps {
  title?: string | undefined;
  disabled?: boolean;
  onClick?: () => void;
}

const AddButton: React.FC<AddButtonProps> = function(props) {
  return (
    <IconButton
      icon={IconType.Add}
      onClick={props.onClick}
      disabled={props.disabled}
    />
  );
};

export default AddButton;
