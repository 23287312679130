import {
  BestRenewalOffersForm,
  BestRenewalOffersFormOffer,
  BestRenewalOfferColumnConfiguration,
  ReviewSummaryForm,
  RenewalFollowUpForm,
  ActualResultsSummaryForm,
  BestRenewalOffersFormBatchComments
} from "api";
import { MessageInfo } from "utils/messageUtils";
import { IPagedTableState } from "components/controls/createTable";
import utils from "utils";
import {
  GenericFormUnitWithComments,
  GenericFormPropertyWithComments
} from "components/forms/BestRenewalOffersPage/Comments/GenericFormCommentTypes";

export interface AtlasState {
  bestRenewalOffersPageState: BestRenewalOffersPageState;
  reviewSummaryPageState: ReviewSummaryPageState;
  renewalFollowUpPageState: RenewalFollowUpPageState;
  actualResultsSummaryPageState: ActualResultsSummaryPageState;
  sideBarState: SideBarState;
  messageState: MessageState;
  loadingState: LoadingState;
  tableState: TableState;
}

export interface MessageState {
  messages: MessageInfo[];
}

export interface LoadingState {
  isLoading: boolean;
}

interface TableState {
  pagingState: IPagedTableState;
  appliedFilters: BestRenewalOffersPageAppliedFilterState[];
}

export interface ExpandableTableState extends TableState {
  expandedRows: any[];
}

export interface SideBarState {
  unitNotesDisplayState: SidebarUnitNotesDisplayState;
  propertyNotesDisplayState: SidebarPropertyNotesDisplayState;
  batchNotesDisplayState: SidebarBatchNotesDisplayState;
  columnSelectorDisplayState: SidebarColumnSelectorDisplayState;
}

export enum ExceptionFilterEnum {
  Property = 1,
  Unit = 2,
  All = 3
}

export interface ExceptionFilterOption {
  name: string;
  type: ExceptionFilterEnum;
}

export interface BestRenewalOfferFieldValues {
  field: keyof BestRenewalOffersFormOffer;
  values: string[];
}

/* BEST RENEWAL OFFERS STATE */
export interface BestRenewalOffersPageState {
  form: BestRenewalOffersForm | null | undefined;
  exceptionFilterOptions: ExceptionFilterOption[];
  selectedExceptionFilter: ExceptionFilterEnum;
  rejectionDialogIsVisible: boolean;
  deletionDialogIsVisible: boolean;
  approvalDialogIsVisible: boolean;
  filteredOffers: BestRenewalOffersFormOffer[];
  propertySummaryTableState: ExpandableTableState;
  propertyTotalCount: number;
  offerTotalCount: number;
  propertyExceptionCount: number;
  unitExceptionCount: number;
  hasUnappliedFilters: boolean;
}

export interface BestRenewalOffersPageAppliedFilterState {
  field: keyof BestRenewalOffersFormOffer;
  selectedValues: string[] | boolean[];
}

export interface ReviewSummaryPageState {
  form: ReviewSummaryForm | null | undefined;
  hasUnappliedFilters: boolean;
  confirmApprovalDialogIsVisible: boolean;
  confirmRejectionDialogIsVisible: boolean;
}

export interface RenewalFollowUpPageState {
  form: RenewalFollowUpForm | null | undefined;
}

export interface ActualResultsSummaryPageState {
  form: ActualResultsSummaryForm | null | undefined;
}

export interface SidebarUnitNotesDisplayState {
  isVisible: boolean;
  selectedUnit?: GenericFormUnitWithComments;
}

export interface SidebarPropertyNotesDisplayState {
  isVisible: boolean;
  selectedProperty?: GenericFormPropertyWithComments;
}

export interface SidebarBatchNotesDisplayState {
  isVisible: boolean;
  batchComments?: BestRenewalOffersFormBatchComments;
}

export interface SidebarColumnSelectorDisplayState {
  isVisible: boolean;
  columnConfigurations: BestRenewalOfferColumnConfiguration[];
}

export interface AtlasHistory {
  push: (url: string) => void;
}

export const getMutableCopyOfAtlasState = function (
  state: AtlasState
): AtlasState {
  return utils.cloneObject(state);
};
