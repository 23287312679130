import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  logo: { height: "54px" },
  content: {
    display: "flex",
    justifyContent: "center",
    margin: "100px 0"
  },
  items: {
    fontFamily: theme.fontFamily,
    padding: "20px"
  }
}));
