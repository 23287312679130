import React from "react";
import { BatchTypeKind } from "api";
import useStyle from "./useStyle";
import { useSiteTheme } from "theme";

export interface BatchType {
  batchTypeKind?: BatchTypeKind;
  displayValue?: string | undefined;
}

export interface BatchTypeIconProps {
  batchType: BatchType;
  showDisplayValue?: boolean;
}

const BatchTypeIcon: React.FC<BatchTypeIconProps> = function (props) {
  const getIconByBatchType = function (batchTypeKind: BatchTypeKind): string {
    switch (batchTypeKind) {
      case BatchTypeKind.Conventional:
        return "fas fa-building";
      case BatchTypeKind.ShortTermConventional:
        return "fas fa-building";
      case BatchTypeKind.BelowMarketRent:
        return "fas fa-donate";
      case BatchTypeKind.RentControl:
        return "fas fa-file-signature";
      case BatchTypeKind.ShortTermRentControl:
        return "fas fa-file-signature";
      case BatchTypeKind.OneOff:
        return "fas fa-house-chimney-window";
    }
  };

  const getColorByBatchType = function (
    batchTypeKind: BatchTypeKind,
    defaultColor: string,
    shortTermColor: string
  ): string {
    switch (batchTypeKind) {
      case BatchTypeKind.OneOff:
      case BatchTypeKind.Conventional:
      case BatchTypeKind.BelowMarketRent:
      case BatchTypeKind.RentControl:
        return defaultColor;
      case BatchTypeKind.ShortTermConventional:
      case BatchTypeKind.ShortTermRentControl:
        return shortTermColor;
    }
  };

  const classes = useStyle();
  const theme = useSiteTheme();
  const batchTypeKind = props.batchType.batchTypeKind;

  if (batchTypeKind == null) {
    return <div />;
  }

  let displayValueArea = <span />;
  if (props.showDisplayValue) {
    displayValueArea = (
      <span className={classes.displayValueText}>
        ({props.batchType.displayValue})
      </span>
    );
  }

  return (
    <div className={classes.root}>
      <span
        title={props.batchType.displayValue}
        className={getIconByBatchType(batchTypeKind)}
        style={{
          color: getColorByBatchType(
            batchTypeKind,
            theme.pallette.dark,
            theme.pallette.blue
          )
        }}
      />
      {displayValueArea}
    </div>
  );
};

export default BatchTypeIcon;
