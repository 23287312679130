import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
  sideBarContainer: {
    marginTop: theme.sizeScale.small,
    height: "95vh",
  },
  tabViewContainer: {
    height: "80vh",
  },
  container: {
    paddingTop: theme.sizeScale.xxsmall,
    paddingRight: theme.sizeScale.xxsmall,
    paddingLeft: theme.sizeScale.xxsmall,
    marginBottom: "30px",
  },
  contentAreaContainer: {
    paddingTop: theme.sizeScale.xxsmall,
    paddingRight: theme.sizeScale.xxsmall,
    paddingBottom: "0",
    paddingLeft: theme.sizeScale.xxsmall,
  },
  scrollContainer: {
    marginBottom: "-30px",
  },
  sidebarScrollContainer: {
    paddingBottom: "30px",
  },
  propertyLabel: {
    fontSize: theme.typeScale.large,
  },
  unitLabel: {
    fontSize: theme.typeScale.medium,
  },
}));
