import React from "react";
import Page from "components/layouts/Page";
import RequirePermission from "../RequirePermission";
import useStyle from "./useStyle";
import Spacer from "components/controls/Spacer";
import ContentArea from "components/layouts/ContentArea";
import { RouteComponentProps } from "react-router";
import useFirstLoadEffect from "utils/useMountEffect";
import {
  ActualResultsSummaryFormFilter,
  ActualResultsSummaryFormFilters
} from "api/generated";
import Spinner from "components/controls/Spinner";
import BatchDefaultsBatchInformationArea from "components/forms/BatchDefaultsBatchInformationArea";
import { MessageAreaBasic } from "components/controls/MessageArea";
import useAtlasStore from "components/store/useAtlasStore";
import listUtils from "utils/listUtils";
import Dropdown from "components/controls/Dropdown";
import BestRenewalOffersMenu from "../BestRenewalOffersMenu";
import ActualResultsSummaryRenewalStatusTable from "./ActualResultsSummaryRenewalStatusTable";

interface MatchParams {
  id: string;
}

interface PageProps extends RouteComponentProps<MatchParams> {}

const ActualResultsSummaryPage: React.FC<PageProps> = function (
  props: PageProps
) {
  const batchKey: string = props.match.params.id;
  const classes = useStyle();

  const [appState, appActions] = useAtlasStore();
  const pageState = {
    form: appState.actualResultsSummaryPageState.form,
    ...appState
  };
  const actions = {
    ...appActions.actualResultsSummaryPage,
    ...appActions.sideBar,
    ...appActions.messages,
    ...appActions
  };

  useFirstLoadEffect(() => {
    actions.getForm(batchKey);
  });

  const spinner = <Spinner isVisible={pageState.loadingState.isLoading} />;
  const messageAreas = pageState.messageState.messages.map((message) => (
    <MessageAreaBasic message={message} key={message.detail} />
  ));

  const form = pageState.form;

  //See T-4258 for refactoring improvements
  if (
    (form == null ||
      form.batchDetail == null ||
      form.filters == null ||
      form.renewalStatusSummary == null ||
      form.renewalStatusSummary.renewalStatusRollups == null ||
      form.renewalStatusSummary.rollupTotals == null) &&
    pageState.loadingState
  ) {
    return spinner;
  } else if (
    form == null ||
    form.batchDetail == null ||
    form.filters == null ||
    form.renewalStatusSummary == null ||
    form.renewalStatusSummary.renewalStatusRollups == null ||
    form.renewalStatusSummary.rollupTotals == null
  ) {
    return <div>{messageAreas}</div>;
  }

  const filters = form.filters;

  const getNewPropertyFilters = function (
    newValue: ActualResultsSummaryFormFilter
  ): ActualResultsSummaryFormFilters {
    const newFilters: ActualResultsSummaryFormFilters = {
      ...filters,
      selectedPropertyFilter: newValue
    };

    if (
      newValue &&
      filters &&
      filters.propertyFilters &&
      newFilters.propertyFilters
    ) {
      newFilters.propertyFilters = listUtils.copyListWithNewKeyedItem(
        filters.propertyFilters,
        newValue
      );
    }

    return newFilters;
  };

  const updatePropertyFilter = function (
    newValue: ActualResultsSummaryFormFilter
  ) {
    const newFilters = getNewPropertyFilters(newValue);
    actions.updateFilters(batchKey, form, newFilters);
  };

  return (
    <Page
      menu={
        <BestRenewalOffersMenu
          bestRenewalOffersKey={batchKey}
          batchNavigationConfig={form.navigationConfiguration}
        />
      }
    >
      <RequirePermission
        permissions={["canViewActualResultsSummaryForm"]}
        accessDenied="page"
      >
        {spinner}
        {messageAreas}
        <div className={classes.splitContent}>
          <div className={classes.headerMetaData}>
            <BatchDefaultsBatchInformationArea
              batchInformation={{ ...form.batchDetail }}
            />
          </div>
        </div>
        {/* TODO: refactor this into a separate component */}
        <ContentArea columnSize="12">
          <div className={classes.filterDropdownArea}>
            <div className={classes.filterDropdown}>
              <Dropdown
                label="Property"
                selectedValue={filters.selectedPropertyFilter}
                hideClear={true}
                options={filters.propertyFilters}
                onChange={(o) =>
                  updatePropertyFilter(o as ActualResultsSummaryFormFilter)
                }
              />
            </div>
          </div>
        </ContentArea>
        <Spacer orientation="v" size="xl" />
        <ContentArea columnSize="12">
          <ActualResultsSummaryRenewalStatusTable
            rollups={form.renewalStatusSummary.renewalStatusRollups}
            totalsRow={form.renewalStatusSummary.rollupTotals}
            batchKey={batchKey}
          />
        </ContentArea>
      </RequirePermission>
    </Page>
  );
};
export default ActualResultsSummaryPage;
