import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  commentListContainer: {
    marginRight: theme.marginScale.large,
    marginBottom: theme.marginScale.medium,
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  commentContainer: {
    display: "flex",
    justifyContent: "space-between",
    borderStyle: "solid",
    borderWidth: "1px",
    padding: "10px"
  },
  commentLabel: {
    fontSize: theme.typeScale.xlarge
  }
}));
