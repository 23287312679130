import React from "react";
import nameof from "nameof";
import { MultiSelect as PrimeMultiSelect } from "primereact/multiselect";
import { withLabel } from "../Label";
import useStyle from "./useStyle";
import listUtils from "utils/listUtils";
import ValidationWrapper from "../ValidationWrapper";

export interface DropdownOption {
  key?: string;
  displayValue?: string;
}

export interface MultiSelectProps {
  placeholder?: string;
  selectedValues: DropdownOption[] | undefined;
  options: DropdownOption[] | undefined;
  onChange: (value: DropdownOption[] | undefined) => void;
  hideClear?: boolean | undefined;
  style?: object | undefined;
  showTextOfMultipleSelections?: boolean | undefined;
  multipleSelectionsDefaultText?: string | undefined;
  validationMessage?: string | undefined;
}

const optionLabel = nameof<DropdownOption>("displayValue");

const MultiSelect: React.FC<MultiSelectProps> = function(props) {
  // note: this is hacky, but might be the only way to accomplish this with Prime React.
  const selectedItemTemplate = function(
    option: DropdownOption | undefined
  ): JSX.Element {
    // used to inflate the size of the dropdown when no value is present.
    const noText = <span>&nbsp;</span>;

    if (!option) {
      return <span>{noText}</span>;
    }

    if (!props.selectedValues) {
      return <span>{option.displayValue}</span>;
    }

    const manyAreSelected =
      props.selectedValues && props.selectedValues.length > 1;

    const indexOfOption = listUtils.indexOfKeyedItem(
      props.selectedValues,
      option
    );

    if (indexOfOption === 0) {
      if (props.showTextOfMultipleSelections) {
        return (
          <span>
            {props.selectedValues.map(value => value.displayValue).join(", ")}
          </span>
        );
      }

      if (manyAreSelected) {
        return (
          <span>
            {props.multipleSelectionsDefaultText || "Multiple Selections"}
          </span>
        );
      } else {
        return <span>{option.displayValue}</span>;
      }
    }

    return <span>{noText}</span>;
  };

  const classes = useStyle();
  const selectedValues =
    (props &&
      props.options &&
      props.options.filter(
        o =>
          props &&
          props.selectedValues &&
          props.selectedValues.some(
            selectedValue => selectedValue.key === o.key
          )
      )) ||
    undefined;

  return (
    <ValidationWrapper showErrorState={props.validationMessage != null}>
      <PrimeMultiSelect
        selectedItemTemplate={selectedItemTemplate}
        className={classes.root}
        value={selectedValues}
        options={props.options}
        optionLabel={optionLabel}
        onChange={e => props.onChange(e.value)}
        placeholder={`-- ${props.placeholder || "Any"} --`}
        style={props.style}
        dataKey="key"
      />
    </ValidationWrapper>
  );
};
export default withLabel(MultiSelect);
