import React, { createContext, useState, useContext } from "react";
import { UserPermissions } from "api";
import permissionRetrievalUtils from "./permissionRetrievalUtils";
import Spinner from "components/controls/Spinner";
import { AtlasConfigContext } from "./AtlasConfigProvider";
import { Config, DefaultConfigConstant } from "./ConfigClient";
import utils from "utils";
import useMountEffect from "utils/useMountEffect";

export type IdentityRole = "admin" | "user";

export interface IdentityProviderState {
  readonly roles: IdentityRole[];
  readonly permissions: UserPermissions;
  readonly isLoaded: boolean;
}

export interface IdentityProviderActions {
  readonly getAccessToken: () => Promise<string>;
  readonly getPermissions: () => UserPermissions;
  readonly setPermissions: (permissions: UserPermissions) => void;
  readonly getConfig: () => Config;
  readonly logoutAction: () => void;
}

export type Identity = IdentityProviderActions & IdentityProviderState;

interface IdentityProviderProps {
  readonly getAccessTokenAction: () => Promise<string>;
  readonly logoutAction: () => void;
  readonly children: any | any[];
}

const AnonymousIdentityState: IdentityProviderState = {
  roles: [],
  permissions: {},
  isLoaded: false
};

const AnonymousIdentityActions: IdentityProviderActions = {
  setPermissions: () => {},
  getPermissions: () => {
    return {};
  },
  getAccessToken: async () => {
    return "";
  },
  getConfig: () => {
    return DefaultConfigConstant;
  },
  logoutAction: () => {}
};

const AnonymousIdentity = {
  ...AnonymousIdentityState,
  ...AnonymousIdentityActions
};

const IdentityContext = createContext<Identity>(AnonymousIdentity);

const IdentityProvider: React.FC<IdentityProviderProps> = function(
  this: any,
  props: IdentityProviderProps
) {
  const [state, setState] = useState<IdentityProviderState>(
    AnonymousIdentityState
  );

  const config = useContext(AtlasConfigContext);

  const setPermissions = function(permissions: UserPermissions) {
    setState({ ...state, permissions: permissions || {} });
  };

  const getAccessToken = async function(): Promise<string> {
    return await props.getAccessTokenAction();
  };

  const getPermissions = function(): UserPermissions {
    return state.permissions || {};
  };

  const getConfig = function() {
    return config;
  };

  useMountEffect(() => {
    props.getAccessTokenAction().then(token => {
      permissionRetrievalUtils.getPermissions(config, token).then(permissions =>
        setState({
          permissions: permissions || {},
          roles: [],
          isLoaded: true
        })
      );
    });
  });

  if (utils.isObjectEmpty(state.permissions)) {
    return <Spinner isVisible={true} />;
  }

  return (
    <IdentityContext.Provider
      value={{
        ...state,
        setPermissions: setPermissions,
        getPermissions: getPermissions,
        getAccessToken: getAccessToken,
        getConfig: getConfig,
        logoutAction: props.logoutAction
      }}
    >
      {props.children}
    </IdentityContext.Provider>
  );
};

export { IdentityContext, AnonymousIdentity };
export default IdentityProvider;
