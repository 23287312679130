import React, { useState } from "react";
import Page from "components/layouts/Page";
import {
  BatchDefaultsBmrFormClient,
  BaselineDefaultsBmrForm,
  BatchDefaultsBmrForm,
  ApiException
} from "api";
import RequirePermission from "../RequirePermission";
import {
  useRemoteData,
  responseHandlers,
  makeRemoteRequest
} from "components/useRemoteData";
import { useApiEndpoint, getApiEndpoint } from "components/useApiEndpoint";
import DefaultsBmrForm from "components/forms/DefaultsBmrForm";
import { RouteComponentProps } from "react-router";
import { useContext } from "react";
import { IdentityContext } from "components/IdentityProvider";
import BatchDefaultsBatchInformationArea from "components/forms/BatchDefaultsBatchInformationArea";
import Spinner from "components/controls/Spinner";
import useMessageArea from "components/useMessageArea";
import { BatchDefaultsEditMode } from "components/routes";
import utils from "utils";
import defaultsUtils, { IsChangeable } from "../defaultsUtils";
import BatchRegulationsMenu from "../BatchRegulationsMenu";

interface MatchParams {
  id: string;
  mode: BatchDefaultsEditMode;
}

interface PageProps extends RouteComponentProps<MatchParams> {}

interface PageState {
  form: BatchDefaultsBmrForm | undefined;
}

interface LoadingState {
  isLoading: boolean;
}

const BatchDefaultsBmrPage: React.FC<PageProps> = function (props: PageProps) {
  const batchKey: string = props.match.params.id;
  const batchDefaultsEditMode: BatchDefaultsEditMode = props.match.params.mode;

  const [page, setPage] = useState<PageState>({
    form: undefined
  });

  const [
    messageAreaState,
    setMessageBasedOnForm,
    setMessageBasedOnApiException
  ] = useMessageArea();

  const setDefaultsForm = function (
    defaultsForm: BaselineDefaultsBmrForm | null
  ) {
    if (!defaultsForm) {
      return;
    }

    setPage({
      ...page,
      form: {
        ...page.form,
        defaults: {
          ...defaultsForm
        }
      }
    });
  };

  const onUpdate = function (
    form: BatchDefaultsBmrForm | undefined | null
  ): void {
    setPage({
      ...page,
      form: {
        ...form,
        defaults: {
          ...(form && form.defaults)
        }
      }
    });
  };

  const [dataState] = useRemoteData(
    useApiEndpoint(BatchDefaultsBmrFormClient, "getBatchDefaultsBmrForm"),
    [batchKey],
    {
      autoFetch: true,
      onChange: (form) => {
        onUpdate(form);
      }
    }
  );

  const handleSubmitError = function (error: ApiException) {
    responseHandlers.use({
      "400": (ex: ApiException) => {
        const form = ex.result;
        setMessageBasedOnForm(form);
        setPage({ ...page, form: form });
      },
      _: (ex) => {
        setMessageBasedOnApiException(ex);
      }
    })(error);
  };
  const submitEndpoint = "saveBatchDefaultsBmrForm";
  const [submitState, submit] = useRemoteData(
    useApiEndpoint(BatchDefaultsBmrFormClient, submitEndpoint),
    [batchKey, page.form || {}],
    {
      onChange: (form) => {
        setMessageBasedOnForm(form);
        onUpdate(form);
      },
      onError: handleSubmitError
    }
  );

  const [loadingState, setLoadingState] = useState<LoadingState>({
    isLoading: false
  });

  const identity = useContext(IdentityContext);
  const saveFormPromise = (): Promise<any> => {
    var selectedDivision =
      page.form && page.form.defaults && page.form.defaults.selectedDivision;

    if (!defaultsUtils.isFormChanged(selectedDivision as IsChangeable)) {
      return utils.getEmptyPromise();
    }

    setLoadingState({ isLoading: true });

    return makeRemoteRequest(
      getApiEndpoint(identity, BatchDefaultsBmrFormClient, submitEndpoint),
      [batchKey, page.form || {}]
    )
      .catch((error) => {
        handleSubmitError(error);
        return utils.getRejectedPromise();
      })
      .finally(() => setLoadingState({ isLoading: false }));
  };

  const batch = page.form && page.form.batchDetail;
  const spinner = (
    <Spinner
      isVisible={
        dataState.isFetching || submitState.isFetching || loadingState.isLoading
      }
    />
  );

  //See T-4258 for refactoring improvements
  if (page.form == null && (dataState.isFetching || submitState.isFetching)) {
    return spinner;
  } else if (page.form == null) {
    return messageAreaState.messageArea;
  }

  if (!page.form.defaults) {
    return <span>Error with current defaults.</span>;
  }

  if (!batch) {
    return <span>Error with batch information.</span>;
  }

  const menu = (
    <BatchRegulationsMenu
      onClick={saveFormPromise}
      batchKey={batchKey}
      title={"BMR"}
      batchDefaultsEditMode={batchDefaultsEditMode}
      hasUnsavedChanges={defaultsUtils.isFormChanged(
        page.form.defaults.selectedDivision as IsChangeable
      )}
      pageConfiguration={page.form.defaults.pageConfiguration}
    />
  );

  return (
    <Page menu={menu}>
      <RequirePermission
        permissions={["canViewBatchDefaultsForms"]}
        accessDenied="page"
      >
        {spinner}
        {messageAreaState.messageArea}
        <BatchDefaultsBatchInformationArea batchInformation={{ ...batch }} />
        <DefaultsBmrForm
          isFetching={submitState.isFetching}
          submit={() => submit([batchKey, page.form || {}])}
          setForm={setDefaultsForm}
          form={page.form.defaults}
          hideDivisions={true}
          hideSaveButton={true}
          pageConfiguration={page.form.defaults.pageConfiguration}
        />
      </RequirePermission>
    </Page>
  );
};

export default BatchDefaultsBmrPage;
