import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  pageWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%"
  },
  column: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: "64px"
  }
}));
