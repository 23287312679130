import React, { useState } from "react";
import {
  BaselineDefaultsGtlAndLtlFormDropRateDefaults,
  BaselineDefaultsGtlAndLtlFormDropRateDefaultsRange,
  BaselineDefaultsGtlAndLtlFormDropRateRanges,
  DefaultsPageConfiguration
} from "api";
import createDataTable, {
  IPagedTableState,
  ColumnDefinition,
  DefaultInputEditorData,
  DataTableProps
} from "components/controls/createTable";
import useStyle from "./useStyle";
import InputSwitch from "components/controls/InputSwitch";
import Label from "components/controls/Label";
import ContentArea from "components/layouts/ContentArea";
import Checkbox, { Checked } from "components/controls/Checkbox";

export interface DefaultsGtlAndLtlFormDropRateRangesAreaProps {
  defaults?: BaselineDefaultsGtlAndLtlFormDropRateDefaults | undefined;
  pageConfiguration: DefaultsPageConfiguration | undefined;
  onChange: (defaults: BaselineDefaultsGtlAndLtlFormDropRateDefaults) => void;
  onIsEnabledChanged: (value: boolean) => void;
}

interface TableState extends IPagedTableState {}
interface TableRow extends BaselineDefaultsGtlAndLtlFormDropRateDefaultsRange {}

interface State {
  isContentVisible: boolean;
}

const DefaultsGtlAndLtlFormDropRateRangesArea: React.FC<DefaultsGtlAndLtlFormDropRateRangesAreaProps> = function (
  props: DefaultsGtlAndLtlFormDropRateRangesAreaProps
) {
  const getRangeList = function (): TableRow[] {
    const defaultEmptyRanges: TableRow[] = [];
    const ranges = props.defaults && props.defaults.ranges;

    if (
      !(
        ranges &&
        ranges.dropRateOneRange &&
        ranges.dropRateTwoRange &&
        ranges.dropRateThreeRange
      )
    ) {
      return defaultEmptyRanges;
    }

    const rangeList: TableRow[] = [
      ranges.dropRateOneRange,
      ranges.dropRateTwoRange,
      ranges.dropRateThreeRange
    ];

    return rangeList;
  };

  const onRangeListChange = function (newRangeList: TableRow[]): void {
    const newRanges: BaselineDefaultsGtlAndLtlFormDropRateRanges = {
      ...(props.defaults && props.defaults.ranges),
      dropRateOneRange: newRangeList[0],
      dropRateTwoRange: newRangeList[1],
      dropRateThreeRange: newRangeList[2]
    };

    const defaults: BaselineDefaultsGtlAndLtlFormDropRateDefaults = {
      ...props.defaults,
      ranges: newRanges
    };

    props.onChange(defaults);
  };

  const getIsEditDisabled = function () {
    return !(props.pageConfiguration && props.pageConfiguration.isEditEnabled);
  };

  const onChange = function (row: TableRow, field: keyof TableRow, value: any) {
    const newRangeList = getRangeList();
    const currentRowPosition = newRangeList.indexOf(row);
    if (currentRowPosition < 0) {
      return;
    }

    const fieldName = field as keyof BaselineDefaultsGtlAndLtlFormDropRateDefaultsRange;
    const newRow: TableRow = { ...row } as TableRow;
    newRow[fieldName] = value;

    newRangeList[currentRowPosition] = newRow as TableRow;

    onRangeListChange(newRangeList);
  };

  const getDefaultInputEditorData = function (): DefaultInputEditorData<TableRow> {
    return {
      isEditDisabled: getIsEditDisabled(),
      isEditDisabledForCell: (row: TableRow, field: keyof TableRow) => {
        const ranges = props.defaults && props.defaults.ranges;
        if (!ranges || !ranges.dropRateThreeRange) {
          return true;
        }

        return row === ranges.dropRateThreeRange && field === "topRange";
      },
      onChange: onChange
    };
  };

  const getColumns = function (): any[] {
    const columns = [
      {
        field: "label",
        header: "",
        style: { width: "10%", textAlign: "center" }
      },
      {
        field: "bottomRange",
        header: "Bottom Range",
        style: { width: "10%", textAlign: "center" },
        defaultInputEditorData: getDefaultInputEditorData()
      },
      {
        field: "topRange",
        header: "Top Range",
        style: { width: "10%", textAlign: "center" },
        defaultInputEditorData: getDefaultInputEditorData()
      }
    ] as ColumnDefinition<TableRow, keyof TableRow>[];

    return columns;
  };

  const [table, setTable] = useState<TableState>({
    offset: 0,
    limit: 10
  });

  const classes = useStyle();
  const [state, setState] = useState<State>({ isContentVisible: false });

  if (!props.defaults || !props.defaults.ranges) {
    return <div />;
  }

  const rangeList = getRangeList();

  const tableProps: DataTableProps<TableRow, TableState> = {
    value: rangeList,
    editMode: "cell",
    state: table,
    updateState: setTable,
    paging: false,
    emptyMessage: "No defaults have been added."
  };

  const dataTable = createDataTable<TableRow, TableState>(
    getColumns(),
    tableProps
  );

  const isEnabledSwitch = (
    <Checkbox
      onChange={(checked: Checked) =>
        props.onIsEnabledChanged(checked === true)
      }
      checked={props.defaults.isEnabled || false}
      label="Enable Drop Rates"
      labelPosition="right"
    />
  );

  const showSwitchArea = (
    <div className={classes.showSwitchArea}>
      <InputSwitch
        onChange={(value: boolean) => setState({ isContentVisible: value })}
        checked={state.isContentVisible}
      />
      <Label className={classes.showLabel} label="Show Drop Rates" />
    </div>
  );

  const enableArea = (
    <div className={classes.enableArea}>
      {showSwitchArea}
      <div className={classes.enableSwitch}>
        {state.isContentVisible ? isEnabledSwitch : <div />}
      </div>
    </div>
  );

  let content = (
    <div className={classes.root}>
      {enableArea}
      {state.isContentVisible && props.defaults.isEnabled ? dataTable : <div />}
    </div>
  );

  if (state.isContentVisible) {
    content = <ContentArea columnSize={"4"}>{content}</ContentArea>;
  }

  return content;
};

export default DefaultsGtlAndLtlFormDropRateRangesArea;
