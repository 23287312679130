import {
  RenewalFollowUpForm,
  RenewalFollowUpFormClient,
  RenewalFollowUpFormFilters
} from "api/generated";
import { makeRemoteRequest } from "components/useRemoteData";
import { FunctionPropertyNames } from "components/useApiEndpoint";
import { Identity } from "components/IdentityProvider";
import { AtlasActionTypes } from "../AtlasActionTypes";
import { AtlasHistory } from "../AtlasStateTypes";
import { AtlasActions } from "../AtlasActions";
import { getApiEndpoint } from "components/useApiEndpoint";
import utils from "utils";

export const RenewalFollowUpPageActionMethods = {
  get: function(
    dispatcher: React.Dispatch<AtlasActions>,
    identity: Identity,
    history: AtlasHistory
  ) {
    const makeFormServerCall = function(
      methodName: FunctionPropertyNames<RenewalFollowUpFormClient>,
      bestRenewalOffersKey: string,
      form: RenewalFollowUpForm,
      successCallback: (
        returnValue: RenewalFollowUpForm | null | undefined
      ) => void
    ) {
      dispatcher({
        type: AtlasActionTypes.UpdateLoadingState,
        loadingState: { isLoading: true }
      });
      makeRemoteRequest(
        getApiEndpoint(identity, RenewalFollowUpFormClient, methodName),
        [bestRenewalOffersKey, form]
      )
        .then(response => {
          successCallback(response.data);
        })
        .catch(() => {
          dispatcher({
            type: AtlasActionTypes.ShowErrorState,
            messages: []
          });
        });
    };

    const getForm = function(bestRenewalOffersKey: string): void {
      dispatcher({
        type: AtlasActionTypes.UpdateLoadingState,
        loadingState: { isLoading: true }
      });

      makeRemoteRequest(
        getApiEndpoint(identity, RenewalFollowUpFormClient, "getForm"),
        [bestRenewalOffersKey]
      )
        .then(response => {
          dispatcher({
            type: AtlasActionTypes.RenewalFollowUpPageSetInitialForm,
            form: response.data as RenewalFollowUpForm
          });
        })
        .catch(() => {
          dispatcher({
            type: AtlasActionTypes.ShowErrorState,
            messages: []
          });
        });
    };

    const updateFormState = function(form: RenewalFollowUpForm): void {
      dispatcher({
        type: AtlasActionTypes.RenewalFollowUpPageUpdateForm,
        form: form
      });
    };

    const updateFilters = function(
      key: string,
      form: RenewalFollowUpForm,
      filters: RenewalFollowUpFormFilters
    ): void {
      const mutableForm = utils.cloneObject(form);
      mutableForm.filters = filters;
      makeFormServerCall("getFormForFilters", key, mutableForm, formData => {
        dispatcher({
          type: AtlasActionTypes.RenewalFollowUpPageUpdateForm,
          form: formData as RenewalFollowUpForm
        });

        dispatcher({
          type: AtlasActionTypes.UpdateLoadingState,
          loadingState: { isLoading: false }
        });
      });
    };

    return {
      getForm,
      updateFormState,
      updateFilters
    };
  }
};
