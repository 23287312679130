import React from "react";
import Spacer from "components/controls/Spacer";
import {
  BaselineDefaultsBmrForm,
  BaselineDefaultsBmrFormDivision,
  BaselineDefaultsBmrFormDefaults,
  DefaultsPageConfiguration
} from "api";
import Dropdown from "components/controls/Dropdown";
import Label from "components/controls/Label";
import listUtils from "utils/listUtils";
import utils from "utils";
import PrimaryButton from "components/controls/PrimaryButton";
import Section from "components/controls/Section";
import Heading from "components/controls/Heading";
import ContentArea from "components/layouts/ContentArea";
import Column from "components/controls/Column";
import DefaultsBmrFormTable from "../DefaultsBmrFormTable";

interface PageState {
  form: BaselineDefaultsBmrForm | null | undefined;
}

export interface DefaultsBmrFormProps {
  setForm: (form: BaselineDefaultsBmrForm) => any;
  form: BaselineDefaultsBmrForm;
  submit: () => void;
  isFetching: boolean;
  onAddRowAction?: (() => void) | undefined;
  hideDivisions?: boolean | undefined;
  hideSaveButton?: boolean | undefined;
  pageConfiguration: DefaultsPageConfiguration | undefined;
}

const DefaultsBmrForm: React.FC<DefaultsBmrFormProps> = function(
  props: DefaultsBmrFormProps
) {
  const cloneForm = function(): BaselineDefaultsBmrForm {
    return utils.cloneObject(props.form);
  };

  const setForm = function(form: BaselineDefaultsBmrForm) {
    props.setForm(form);
  };

  const onDefaultsChange = (defaults: BaselineDefaultsBmrFormDefaults[]) => {
    if (!props.form.selectedDivision) {
      return;
    }

    onDivisionChanged({
      ...props.form.selectedDivision,
      propertyBedroomAffordabilityDefaults: defaults || [],
      isChanged: true
    });
  };

  const addDefaultsRow = function(): void {
    const selectedDivision = props.form && props.form.selectedDivision;
    const newRow = {};
    const currentRows =
      (selectedDivision &&
        selectedDivision.propertyBedroomAffordabilityDefaults) ||
      [];

    onDefaultsChange(currentRows.concat(newRow));
  };

  const onDivisionChanged = (
    division: BaselineDefaultsBmrFormDivision | undefined
  ) => {
    const divisions = (props.form && props.form.divisions) || undefined;
    let newDivisionsList = divisions;

    if (divisions && division) {
      newDivisionsList =
        listUtils.copyListWithNewKeyedItem(divisions, division) || undefined;
    }

    const form = cloneForm();

    form.selectedDivision = division;
    form.divisions = newDivisionsList;

    setForm(form);
  };

  let selectedDivision = undefined;
  let fieldValidationErrors:
    | {
        [key: string]: string;
      }
    | undefined = undefined;
  const form = props && props.form;
  if (form) {
    selectedDivision = form.selectedDivision;
    fieldValidationErrors = form.fieldValidationErrors;
  }

  const getDivisionDropdownOrLabel = function(): JSX.Element {
    if (props.hideDivisions) {
      return <div />;
    }

    const divisions = props.form.divisions;
    const selectedDivision = props.form.selectedDivision;
    if (divisions && divisions.length > 1) {
      return (
        <Dropdown
          onChange={onDivisionChanged}
          hideClear={true}
          options={divisions}
          selectedValue={selectedDivision}
          validationMessage={
            fieldValidationErrors && fieldValidationErrors["SelectedDivision"]
          }
        />
      );
    } else if (selectedDivision) {
      return <Label label={selectedDivision.displayValue} />;
    } else {
      return <div />;
    }
  };

  const getSaveButton = function(): JSX.Element {
    if (props.hideSaveButton) {
      return <div />;
    }

    return (
      <PrimaryButton
        disabled={props.isFetching}
        onClick={() => props.submit()}
        title="SAVE"
      />
    );
  };

  const divisionsArea = getDivisionDropdownOrLabel();
  const saveButton = getSaveButton();

  return (
    <div>
      <Section title="BMR" />

      <Column size="2">{divisionsArea}</Column>

      <Spacer size="l" orientation="v" />

      <ContentArea columnSize={"12"}>
        <Heading level="3">By Property</Heading>
        <DefaultsBmrFormTable
          defaults={
            (props &&
              props.form &&
              props.form.selectedDivision &&
              props.form.selectedDivision
                .propertyBedroomAffordabilityDefaults) ||
            undefined
          }
          onChange={onDefaultsChange}
          onAddRowAction={
            props.form.pageConfiguration &&
            props.form.pageConfiguration.isEditEnabled
              ? addDefaultsRow
              : props.onAddRowAction
          }
          hideRemoveRowColumn={
            !(
              props.form.pageConfiguration &&
              props.form.pageConfiguration.isEditEnabled
            )
          }
          bedrooms={(selectedDivision && selectedDivision.bedroomTypes) || []}
          properties={
            (selectedDivision && selectedDivision.propertyTypes) || []
          }
          affordabilityType={
            (selectedDivision && selectedDivision.affordabilityTypes) || []
          }
          includeRemoveRowColumn={
            props.form.pageConfiguration &&
            props.form.pageConfiguration.isEditEnabled
              ? "show"
              : undefined
          }
          pageConfiguration={props.pageConfiguration}
        />
      </ContentArea>

      <Spacer size="l" orientation="v" />

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {saveButton}
      </div>
    </div>
  );
};

export default DefaultsBmrForm;
