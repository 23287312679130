import React from "react";
import { JssProvider } from "react-jss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { SiteThemeProvider, siteDefaultTheme } from "theme";
import Main from "components/layouts/Main";
import AtlasConfigProvider from "components/AtlasConfigProvider";
import routes from "components/routes";
import LoginPage from "components/forms/LoginPage";

const App: React.FC = () => {
  return (
    <JssProvider>
      <SiteThemeProvider theme={siteDefaultTheme}>
        <AtlasConfigProvider>
          <Router>
            <Switch>
              <Route exact path={routes.signin} component={LoginPage} />
              <Route component={Main}></Route>
            </Switch>
          </Router>
        </AtlasConfigProvider>
      </SiteThemeProvider>
    </JssProvider>
  );
};

export default App;
