export interface Config {
  apiUrl: string | null;
  azureInstance: string | null;
  azureTenantId: string | null;
  azureClientId: string | null;
  azureCallbackPath: string | null;
  azureTokenRefreshPath: string | null;
  azureSignoutRedirectPath: string | null;
  azureAtlasScope: string | null;
  isLoaded: boolean;
}

export const DefaultConfigConstant: Config = {
  apiUrl: null,
  azureInstance: null,
  azureTenantId: null,
  azureClientId: null,
  azureCallbackPath: null,
  azureSignoutRedirectPath: null,
  azureTokenRefreshPath: null,
  azureAtlasScope: null,
  isLoaded: false,
};

export class ConfigClient {
  protected jsonParseReviver:
    | ((key: string, value: any) => any)
    | undefined = undefined;

  getConfig(): Promise<Config> {
    let baseUrl = `${process.env.PUBLIC_URL}/config.json`;

    let options: RequestInit = {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    };

    return fetch(baseUrl, options).then((_response: Response) => {
      return _response.json();
    });
  }
}

export default ConfigClient;
