import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(() => ({
  container: {
    height: 415,
    minWidth: 160,
    padding: "8px",
    overflow: "hidden"
  }
}));
