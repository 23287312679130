import React from "react";
import useStyle from "./useStyle";
import { ProgressSpinner } from "primereact/progressspinner";

export type SpinnerSize = "auto" | "full-screen";

export interface SpinnerProps {
  size?: SpinnerSize | undefined;
  isVisible: boolean;
}

const Spinner: React.FC<SpinnerProps> = function(props) {
  const classes = useStyle(props);

  if (!props.isVisible) {
    return <div />;
  }

  return (
    <div className={classes.root}>
      <ProgressSpinner />
    </div>
  );
};

export default Spinner;
