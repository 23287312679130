import React from "react";
import { InputSwitch as PrimeInputSwitch } from "primereact/inputswitch";

export interface InputSwitchProps {
  checked: boolean | undefined;
  onChange: (checked: boolean) => void;
}

const InputSwitch: React.FC<InputSwitchProps> = function (props) {
  return (
    <div>
      <PrimeInputSwitch
        checked={props.checked}
        onChange={(e) => props.onChange(e.value)}
      />
    </div>
  );
};

export default InputSwitch;
