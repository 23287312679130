import React from "react";
import Menu, { MenuItemProps } from "components/forms/Menu";
import routes from "components/routes";
import locationUtils from "utils/locationUtils";

export interface MenuProps {
  title?: string | undefined;
  items?: MenuItemProps[];
  actionArea?: JSX.Element;
}

const RegulationsMenu: React.FC<MenuProps> = function (props: MenuProps) {
  const sampleMenuItems: MenuItemProps[] = [
    {
      title: "BMR",
      to: routes.regulations.bmr,
      isActive: locationUtils.isRouteCurrentlyActive(routes.regulations.bmr)
    },
    {
      title: "ORDINANCES",
      to: routes.regulations.ordinances,
      isActive: locationUtils.isRouteCurrentlyActive(
        routes.regulations.ordinances
      )
    },
    {
      title: "RENT CONTROL",
      to: routes.regulations.rentControl,
      isActive: locationUtils.isRouteCurrentlyActive(
        routes.regulations.rentControl
      )
    }
  ];

  return <Menu {...props} title="REGULATIONS" items={sampleMenuItems} />;
};

export default RegulationsMenu;
