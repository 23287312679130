import { AtlasActionTypes } from "../AtlasActionTypes";
import { AtlasActions } from "../AtlasActions";
import { MessageInfo } from "utils/messageUtils";

export const MessagesActionMethods = {
  get: function(dispatcher: React.Dispatch<AtlasActions>) {
    const showErrorState = function(messages: MessageInfo[]) {
      dispatcher({
        type: AtlasActionTypes.ShowErrorState,
        messages: messages
      });
    };
    const showValidationErrorState = function(messages: MessageInfo[]) {
      dispatcher({
        type: AtlasActionTypes.ShowValidationErrorState,
        messages: messages
      });
    };
    return { showErrorState, showValidationErrorState };
  }
};
