import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  root: {
    lineHeight: "2.2em"
  },
  topLabel: {
    fontSize: theme.typeScale.medium,
    fontWeight: "bold"
  },
  rightLabel: {
    fontSize: theme.typeScale.medium,
    fontWeight: "bold",
    paddingTop: theme.paddingScale.xsmall
  },
  leftLabel: {
    fontSize: theme.typeScale.medium,
    fontWeight: "bold",
    paddingTop: theme.paddingScale.xsmall
  },
  bottomLabel: {
    fontSize: theme.typeScale.medium,
    fontWeight: "bold"
  },
  withLabel: {
    display: "flex",
    alignItems: "center"
  }
}));
