import React, { useState } from "react";
import Checkbox, { Checked } from "components/controls/Checkbox";
import Searchbox from "components/controls/Searchbox";
import useStyle from "./useStyle";
import Spacer from "components/controls/Spacer";
import Label from "components/controls/Label";
import ScrollPanel from "components/layouts/ScrollPanel";
import ValidationWrapper from "components/controls/ValidationWrapper";

type PageState = {
  propertyFilter?: string;
};

type PropertyOption = {
  key?: string;
  displayValue?: string;
};

export interface SinglePropertyChooserProps {
  properties?: PropertyOption[];
  selectedProperty?: PropertyOption;
  validationMessage?: string | null | undefined;
  onChange: (selection: PropertyOption) => void;
}

const PropertyChooser: React.FC<SinglePropertyChooserProps> = function (props) {
  const [state, setState] = useState<PageState>({});
  const classes = useStyle();

  const properties =
    props.properties == null
      ? []
      : props.properties.map((p) => ({
          ...p,
          selected:
            props.selectedProperty == null
              ? false
              : props.selectedProperty.key === p.key
        }));

  const filteredProperties = properties.filter(
    (p) =>
      state.propertyFilter == null ||
      (p.displayValue &&
        p.displayValue
          .toLowerCase()
          .includes(state.propertyFilter.toLowerCase()))
  );

  const toggleProperty = (key: string | undefined, checked: Checked) => {
    if (checked === false && props.selectedProperty != null) {
      props.onChange({});
    } else if (checked === true) {
      props.onChange(
        (props.properties && props.properties.find((p) => p.key === key)) || {}
      );
    } else {
      console.log("Mixed is an unexpected state for property checkbox.");
    }
  };

  const labelText = `Selected property: ${
    (props.selectedProperty && props.selectedProperty.displayValue) || ""
  }`;

  return (
    <ValidationWrapper showErrorState={props.validationMessage != null}>
      <div className={classes.container}>
        <Searchbox
          label="Filter"
          value={state.propertyFilter}
          onChange={(v) => setState({ ...state, propertyFilter: v })}
          mode="filter"
        />
        <Spacer orientation="v" />
        <Label label={labelText} />
        <Spacer orientation="v" />
        <ScrollPanel heightInPixels={284}>
          {filteredProperties.map((p) => (
            <div key={p.key}>
              <Spacer orientation="v" />
              <Checkbox
                label={p.displayValue}
                labelPosition="right"
                checked={p.selected}
                onChange={(checked) => toggleProperty(p.key, checked)}
              />
            </div>
          ))}
        </ScrollPanel>
      </div>
    </ValidationWrapper>
  );
};

export default PropertyChooser;
