import React from "react";
import { BatchTypeKind } from "api";
import BatchTypeIcon from "../BatchTypeIcon";
import useStyle from "./useStyle";

export interface BatchType {
  batchTypeKind?: BatchTypeKind;
  displayValue?: string | undefined;
}

export interface FormattedBatchTypesProps {
  batchTypes: BatchType[] | undefined | null;
}

const FormattedBatchTypes: React.FC<FormattedBatchTypesProps> = function (
  props
) {
  const getBatchTypeIcon = function (
    batchType: BatchType,
    showIconDisplayValue: boolean
  ) {
    return (
      <div className={classes.batchType} key={batchType.displayValue}>
        <BatchTypeIcon
          batchType={batchType}
          showDisplayValue={showIconDisplayValue}
        />
      </div>
    );
  };

  const classes = useStyle();

  if (!props.batchTypes) {
    return <div />;
  }

  const showDisplayValueForSingleBatchType = false;
  const icons = props.batchTypes.map((batchType) =>
    getBatchTypeIcon(batchType, showDisplayValueForSingleBatchType)
  );

  return <div className={classes.root}>{icons}</div>;
};

export default FormattedBatchTypes;
