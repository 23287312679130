const isRouteCurrentlyActive = function(route: string | undefined): boolean {
  return (
    route != null &&
    window.location.href.toLowerCase().includes(route.toLowerCase())
  );
};

export default {
  isRouteCurrentlyActive
};
