export type BatchDefaultsEditMode = "editBatch" | "createBatch";

export default {
  auth: "/auth-token",
  tokenCallback: "/implicit/callback",
  signOut: "/sign-out",
  signin: "/login",
  home: "/",
  batchListPage: "/batches",
  bestRenewalOffers: (key?: string) => {
    return `/batches/${key || ":id"}/best-renewal-offer`;
  },
  bestRenewalOffersActuals: (key?: string) => {
    return `/batches/${key || ":id"}/actuals`;
  },
  reviewSummary: (key?: string) => {
    return `/batches/${key || ":id"}/review-summary`;
  },
  renewalFollowUp: (key?: string) => {
    return `/batches/${key || ":id"}/renewal-follow-up`;
  },
  actualResultsSummary: (key?: string) => {
    return `/batches/${key || ":id"}/actual-results-summary`;
  },
  admin: {
    users: "/admin/users",
    editUser: (key?: string) => {
      return `/admin/users/${key || ":id"}`;
    },
    addUser: "/admin/add-user"
  },
  baselinedefaults: {
    growthRates: "/baselinedefaults/growth-rates",
    gtlAndLtl: "/baselinedefaults/ltl-and-gtl",
    scaling: "/baselinedefaults/scaling",
    incentives: "/baselinedefaults/incentives",
    exceptions: "/baselinedefaults/exceptions",
    exportTerms: "/baselinedefaults/export-terms",
    renewalNotice: "/baselinedefaults/renewal-notice-period"
  },
  regulations: {
    bmr: "/baselineregulations/bmr",
    ordinances: "/baselineregulations/ordinances",
    rentControl: "/baselineregulations/rent-control"
  },
  batchdefaults: {
    growthRates: (key?: string, mode?: BatchDefaultsEditMode) => {
      return `/batchdefaults/${key || ":id"}/growth-rates/${mode || ":mode"}`;
    },
    gtlAndLtl: (key?: string, mode?: BatchDefaultsEditMode) => {
      return `/batchdefaults/${key || ":id"}/ltl-and-gtl/${mode || ":mode"}`;
    },
    scaling: (key?: string, mode?: BatchDefaultsEditMode) => {
      return `/batchdefaults/${key || ":id"}/scaling/${mode || ":mode"}`;
    },
    incentives: (key?: string, mode?: BatchDefaultsEditMode) => {
      return `/batchdefaults/${key || ":id"}/incentives/${mode || ":mode"}`;
    },
    exceptions: (key?: string, mode?: BatchDefaultsEditMode) => {
      return `/batchdefaults/${key || ":id"}/exceptions/${mode || ":mode"}`;
    },
    exportTerms: (key?: string, mode?: BatchDefaultsEditMode) => {
      return `/batchdefaults/${key || ":id"}/export-terms/${mode || ":mode"}`;
    },
    renewalNotice: (key?: string, mode?: BatchDefaultsEditMode) => {
      return `/batchdefaults/${key || ":id"}/renewal-notice-period/${
        mode || ":mode"
      }`;
    }
  },
  batchregulations: {
    bmr: (key?: string, mode?: BatchDefaultsEditMode) => {
      return `/batchregulations/${key || ":id"}/bmr/${mode || ":mode"}`;
    },
    rentControl: (key?: string, mode?: BatchDefaultsEditMode) => {
      return `/batchregulations/${key || ":id"}/rent-control/${
        mode || ":mode"
      }`;
    },
    ordinances: (key?: string, mode?: BatchDefaultsEditMode) => {
      return `/batchregulations/${key || ":id"}/ordinances/${mode || ":mode"}`;
    }
  },
  createBatch: "/create-batch",
  createOneOff: "/create-one-off"
};
