import { createThemedUseStyles } from "theme";
import { ColumnProps } from ".";

export default createThemedUseStyles(theme => {
  const coumnWidth = (props: ColumnProps) => {
    if (props.size === "1") {
      return theme.columnSize.cols1;
    }
    if (props.size === "2") {
      return theme.columnSize.cols2;
    }
    if (props.size === "3") {
      return theme.columnSize.cols3;
    }
    if (props.size === "4") {
      return theme.columnSize.cols4;
    }
    if (props.size === "5") {
      return theme.columnSize.cols5;
    }
    if (props.size === "6") {
      return theme.columnSize.cols6;
    }
    if (props.size === "7") {
      return theme.columnSize.cols7;
    }
    if (props.size === "8") {
      return theme.columnSize.cols8;
    }
    if (props.size === "9") {
      return theme.columnSize.cols9;
    }
    if (props.size === "10") {
      return theme.columnSize.cols10;
    }
    if (props.size === "11") {
      return theme.columnSize.cols11;
    }

    return theme.columnSize.cols12;
  };
  return {
    root: {
      width: coumnWidth,
      minWidth: coumnWidth,
      maxWidth: coumnWidth
    },
    contentContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      padding: "30px",
      overflow: "auto"
    }
  };
});
