import {
  BestRenewalOffersForm,
  BestRenewalOffersFormOffer,
  BestRenewalOffersFormComment,
  BestRenewalOffersFormClient,
  BestRenewalOfferColumnConfiguration,
  BestRenewalOffersActualsFormClient,
  BestRenewalOffersFormDownload,
  ApiException,
  CommonSummaryRollupKind,
  BestRenewalOffersFormCalculationResult,
  CommonSummaryFormSummary,
  ReviewSummaryFormFilters
} from "api/generated";
import { makeRemoteRequest, responseHandlers } from "components/useRemoteData";
import {
  getApiEndpoint,
  FunctionPropertyNames
} from "components/useApiEndpoint";
import { Identity } from "components/IdentityProvider";
import routes from "components/routes";
import { AtlasActionTypes } from "../AtlasActionTypes";
import {
  BestRenewalOffersPageAppliedFilterState,
  AtlasHistory,
  ExceptionFilterEnum,
  ExpandableTableState
} from "../AtlasStateTypes";
import { AtlasActions } from "../AtlasActions";
import listUtils from "utils/listUtils";
import windowUtils from "utils/windowUtils";

export enum BestRenewalOffersPageMode {
  Default = 0,
  Actuals = 1
}

export const BestRenewalOffersPageActionMethods = {
  get: function (
    dispatcher: React.Dispatch<AtlasActions>,
    identity: Identity,
    history: AtlasHistory
  ) {
    const handleValidationAndErrorResponses = function (error: ApiException) {
      return responseHandlers.use({
        "400": (ex: ApiException) => {
          dispatcher({
            type: AtlasActionTypes.ShowValidationErrorState,
            messages: [ex.result && ex.result.formValidationErrors]
          });

          const form = ex.result as BestRenewalOffersForm;
          if (form) {
            dispatcher({
              type: AtlasActionTypes.BestRenewalOffersPageUpdateForm,
              form: form
            });
          }
        },
        _: () => {
          dispatcher({
            type: AtlasActionTypes.ShowErrorState,
            messages: []
          });
        }
      })(error);
    };

    const makeFormServerCall = function (
      methodName: FunctionPropertyNames<BestRenewalOffersFormClient>,
      bestRenewalOffersKey: string,
      form: BestRenewalOffersForm,
      successCallback: (
        returnValue:
          | BestRenewalOffersForm
          | BestRenewalOffersFormComment[]
          | null
          | undefined
      ) => void
    ) {
      dispatcher({
        type: AtlasActionTypes.UpdateLoadingState,
        loadingState: { isLoading: true }
      });

      makeRemoteRequest(
        getApiEndpoint(identity, BestRenewalOffersFormClient, methodName),
        [bestRenewalOffersKey, form]
      )
        .then((response) => {
          successCallback(response && response.data);
        })
        .catch((error) => {
          handleValidationAndErrorResponses(error);
        });
    };

    const callFormDispatcher = function (
      formData:
        | BestRenewalOffersForm
        | BestRenewalOffersFormComment[]
        | null
        | undefined,
      callback?: () => void | undefined
    ) {
      dispatcher({
        type: AtlasActionTypes.BestRenewalOffersPageShowFormUpdateSuccessful,
        form: formData as BestRenewalOffersForm
      });
      callback && callback();
    };

    const getForm = function (
      bestRenewalOffersKey: string,
      mode: BestRenewalOffersPageMode
    ): void {
      let endpoint = getApiEndpoint(
        identity,
        BestRenewalOffersFormClient,
        "getForm"
      );

      if (mode === BestRenewalOffersPageMode.Actuals) {
        endpoint = getApiEndpoint(
          identity,
          BestRenewalOffersActualsFormClient,
          "getForm"
        );
      }

      dispatcher({
        type: AtlasActionTypes.BestRenewalOffersPageResetState
      });

      dispatcher({
        type: AtlasActionTypes.UpdateLoadingState,
        loadingState: { isLoading: true }
      });

      makeRemoteRequest(endpoint, [bestRenewalOffersKey])
        .then((response) => {
          dispatcher({
            type: AtlasActionTypes.BestRenewalOffersPageSetInitialForm,
            form: response.data as BestRenewalOffersForm
          });
        })
        .catch(() => {
          dispatcher({
            type: AtlasActionTypes.ShowErrorState,
            messages: []
          });
        });
    };
    const getFilteredForm = function (
      bestRenewalOffersKey: string,
      mode: BestRenewalOffersPageMode,
      filters: ReviewSummaryFormFilters
    ): void {
      let endpoint = getApiEndpoint(
        identity,
        BestRenewalOffersFormClient,
        "getFilteredForm"
      );

      if (mode === BestRenewalOffersPageMode.Actuals) {
        endpoint = getApiEndpoint(
          identity,
          BestRenewalOffersActualsFormClient,
          "getForm"
        );
      }

      dispatcher({
        type: AtlasActionTypes.BestRenewalOffersPageResetState
      });

      dispatcher({
        type: AtlasActionTypes.UpdateLoadingState,
        loadingState: { isLoading: true }
      });

      makeRemoteRequest(endpoint, [bestRenewalOffersKey, filters])
        .then((response) => {
          dispatcher({
            type: AtlasActionTypes.BestRenewalOffersPageSetInitialForm,
            form: {
              ...(response.data as BestRenewalOffersForm),
              filters: filters
            }
          });
        })
        .catch(() => {
          dispatcher({
            type: AtlasActionTypes.ShowErrorState,
            messages: []
          });
        });
    };
    const updateFormState = function (form: BestRenewalOffersForm): void {
      dispatcher({
        type: AtlasActionTypes.BestRenewalOffersPageUpdateForm,
        form: form
      });
    };
    const updateCalculatedOffers = function (
      updatedOffer: BestRenewalOffersFormOffer,
      offers: BestRenewalOffersFormOffer[],
      summary: CommonSummaryFormSummary | undefined
    ) {
      const matchingOffer = listUtils.firstOrDefaultByKey(
        offers,
        updatedOffer.key as string
      );

      if (matchingOffer) {
        dispatcher({
          type: AtlasActionTypes.BestRenewalOffersPageUpdateOffersForProperty,
          updatedOffer: matchingOffer,
          updatedOffersForExceptionsOnly: offers,
          properySummary: summary
        });
      }
    };

    const updateAllCalculatedOffers = function (
      updatedOffers: BestRenewalOffersFormOffer[],
      offers: BestRenewalOffersFormOffer[],
      summary: CommonSummaryFormSummary[] | undefined
    ) {
      const offerKeys = updatedOffers.map((x) => x.key);
      const matchingOffers = [
        ...offers.filter((x) => offerKeys.includes(x.key))
      ];

      if (matchingOffers.length > 0) {
        dispatcher({
          type: AtlasActionTypes.BestRenewalOffersPageUpdateOffersForBatch,
          updatedOffers: matchingOffers,
          updatedOffersForExceptionsOnly: offers,
          properySummary: summary
        });
      }
    };

    const updateOffer = function (
      bestRenewalOffersKey: string,
      updatedOffer: BestRenewalOffersFormOffer,
      offers: BestRenewalOffersFormOffer[],
      pageMode: BestRenewalOffersPageMode
    ): void {
      dispatcher({
        type: AtlasActionTypes.BestRenewalOffersPageUpdateOffer,
        updatedOffer: updatedOffer
      });
      if (pageMode === BestRenewalOffersPageMode.Default) {
        let endpoint = getApiEndpoint(
          identity,
          BestRenewalOffersFormClient,
          "calculateOffer"
        );
        dispatcher({
          type: AtlasActionTypes.UpdateLoadingState,
          loadingState: { isLoading: true }
        });

        var propertyOffers = offers.filter(
          (o) => o.propertyKey === updatedOffer.propertyKey
        );

        var oldOffer = propertyOffers.find((o) => o.key === updatedOffer.key);

        var propertyOffersWithUpdatedOffer = listUtils.copyListWithNewItem(
          propertyOffers,
          oldOffer,
          updatedOffer
        );

        makeRemoteRequest(endpoint, [
          bestRenewalOffersKey,
          propertyOffersWithUpdatedOffer as BestRenewalOffersFormOffer[]
        ])
          .then((response) => {
            var calcResult =
              response.data as BestRenewalOffersFormCalculationResult;

            calcResult.offers &&
              calcResult.offers.forEach((o) => (o.rpm = propertyOffers[0].rpm));

            calcResult.propertySummary &&
              calcResult.propertySummary.rollups &&
              calcResult.propertySummary.rollups
                .filter((o) => o.propertyKey === updatedOffer.propertyKey)
                .forEach((o) => (o.rpm = propertyOffers[0].rpm));

            updateCalculatedOffers(
              updatedOffer,
              calcResult.offers || [],
              calcResult.propertySummary
            );
          })
          .catch((error) => {
            responseHandlers.use({
              "400": (ex: ApiException) => {
                dispatcher({
                  type: AtlasActionTypes.ShowValidationErrorState,
                  messages: [ex.result && ex.result.formValidationErrors]
                });

                var offers = ex.result as BestRenewalOffersFormOffer[];

                offers &&
                  offers.forEach((o) => (o.rpm = propertyOffers[0].rpm));

                updateCalculatedOffers(updatedOffer, offers || [], undefined);
              },
              _: () => {
                dispatcher({
                  type: AtlasActionTypes.ShowErrorState,
                  messages: []
                });
              }
            })(error);
          })
          .finally(() => {
            dispatcher({
              type: AtlasActionTypes.UpdateLoadingState,
              loadingState: { isLoading: false }
            });
          });
      }
    };
    const updateAllOffers = function (
      bestRenewalOffersKey: string,
      updatedOffers: BestRenewalOffersFormOffer[],
      offers: BestRenewalOffersFormOffer[],
      pageMode: BestRenewalOffersPageMode
    ): void {
      dispatcher({
        type: AtlasActionTypes.BestRenewalOffersPageUpdateOffers,
        updatedOffers: updatedOffers
      });
      if (pageMode === BestRenewalOffersPageMode.Default) {
        let endpoint = getApiEndpoint(
          identity,
          BestRenewalOffersFormClient,
          "calculateMultipleOffers"
        );
        dispatcher({
          type: AtlasActionTypes.UpdateLoadingState,
          loadingState: { isLoading: true }
        });

        var propertyKeys = new Array<string | undefined>();
        var updatedOfferProperties = new Set(
          updatedOffers.map((x) => x.propertyKey)
        );
        updatedOfferProperties.forEach((x) => propertyKeys.push(x));

        var propertyOffers = offers.filter((o) =>
          propertyKeys.includes(o.propertyKey)
        );

        const propertyOffersWithUpdatedOffer = [...propertyOffers];

        updatedOffers.forEach((offer) => {
          const index = propertyOffersWithUpdatedOffer.findIndex(
            (o) => o.key === offer.key
          );

          propertyOffersWithUpdatedOffer[index] = offer;
        });

        makeRemoteRequest(endpoint, [
          bestRenewalOffersKey,
          propertyOffersWithUpdatedOffer as BestRenewalOffersFormOffer[]
        ])
          .then((response) => {
            var calcResult =
              response.data as BestRenewalOffersFormCalculationResult[];

            const resultOffers: BestRenewalOffersFormOffer[] = [];
            const resultPropertySummaries: CommonSummaryFormSummary[] = [];

            calcResult.forEach((r) => {
              r.offers &&
                r.offers.forEach((o) => {
                  if (
                    o.propertyKey &&
                    propertyOffers &&
                    propertyOffers.some((x) => x.propertyKey === o.propertyKey)
                  ) {
                    o.rpm = propertyOffers.find(
                      (x) => x.propertyKey === o.propertyKey
                    )!.rpm;
                  }
                });

              r.propertySummary &&
                r.propertySummary.rollups &&
                r.propertySummary.rollups.forEach((o) => {
                  if (
                    o.propertyKey &&
                    propertyOffers &&
                    propertyOffers.some((x) => x.propertyKey === o.propertyKey)
                  ) {
                    o.rpm = propertyOffers.find(
                      (x) => x.propertyKey === o.propertyKey
                    )!.rpm;
                  }
                });

              if (r.offers) {
                r.offers.forEach((o) => resultOffers.push(o));
              }

              if (r.propertySummary) {
                resultPropertySummaries.push(r.propertySummary);
              }
            });

            updateAllCalculatedOffers(
              updatedOffers,
              resultOffers || [],
              resultPropertySummaries
            );
          })
          .catch((error) => {
            responseHandlers.use({
              "400": (ex: ApiException) => {
                dispatcher({
                  type: AtlasActionTypes.ShowValidationErrorState,
                  messages: [ex.result && ex.result.formValidationErrors]
                });

                var offers = ex.result as BestRenewalOffersFormOffer[];

                offers &&
                  offers.forEach((o) => (o.rpm = propertyOffers[0].rpm));
                updatedOffers.forEach((o) => {
                  updateCalculatedOffers(o, offers || [], undefined);
                });
              },
              _: () => {
                dispatcher({
                  type: AtlasActionTypes.ShowErrorState,
                  messages: []
                });
              }
            })(error);
          })
          .finally(() => {
            dispatcher({
              type: AtlasActionTypes.UpdateLoadingState,
              loadingState: { isLoading: false }
            });
          });
      }
    };
    const updateColumnConfigurations = function (
      columnConfigurations: BestRenewalOfferColumnConfiguration[]
    ) {
      dispatcher({
        type: AtlasActionTypes.BestRenewalOffersPageUpdateColumnConfigurations,
        updatedColumnConfigurations: columnConfigurations
      });
    };
    const saveForm = function (
      bestRenewalOffersKey: string,
      form: BestRenewalOffersForm,
      mode: BestRenewalOffersPageMode
    ): void {
      let client:
        | typeof BestRenewalOffersFormClient
        | typeof BestRenewalOffersActualsFormClient =
        BestRenewalOffersFormClient;
      if (mode === BestRenewalOffersPageMode.Actuals) {
        client = BestRenewalOffersActualsFormClient;
      }

      dispatcher({
        type: AtlasActionTypes.UpdateLoadingState,
        loadingState: { isLoading: true }
      });

      makeRemoteRequest(getApiEndpoint(identity, client, "saveForm"), [
        bestRenewalOffersKey,
        form
      ])
        .then((response) => {
          callFormDispatcher(response.data);
        })
        .catch((error: ApiException) => {
          handleValidationAndErrorResponses(error);
        });
    };
    const submitForm = function (
      bestRenewalOffersKey: string,
      form: BestRenewalOffersForm
    ): void {
      makeFormServerCall("submitForm", bestRenewalOffersKey, form, (data) =>
        callFormDispatcher(data, () => history.push(routes.batchListPage))
      );
    };

    const exportForm = function (
      bestRenewalOffersKey: string,
      form: BestRenewalOffersForm
    ): void {
      makeFormServerCall("exportForm", bestRenewalOffersKey, form, (data) =>
        callFormDispatcher(data, () => history.push(routes.batchListPage))
      );
    };

    const deleteForm = function (
      bestRenewalOffersKey: string,
      form: BestRenewalOffersForm
    ): void {
      makeFormServerCall("deleteForm", bestRenewalOffersKey, form, () => {
        history.push(routes.batchListPage);
      });
    };
    const approveForm = function (
      bestRenewalOffersKey: string,
      form: BestRenewalOffersForm
    ): void {
      makeFormServerCall("approveForm", bestRenewalOffersKey, form, (data) =>
        callFormDispatcher(data, () => history.push(routes.batchListPage))
      );
    };
    const rejectForm = function (
      bestRenewalOffersKey: string,
      form: BestRenewalOffersForm
    ): void {
      makeFormServerCall("rejectForm", bestRenewalOffersKey, form, (data) =>
        callFormDispatcher(data, () => history.push(routes.batchListPage))
      );
    };
    const addUnitComment = function (
      bestRenewalOffersKey: string,
      unitKey: string,
      comment: string
    ): void {
      dispatcher({
        type: AtlasActionTypes.UpdateLoadingState,
        loadingState: { isLoading: true }
      });

      makeRemoteRequest(
        getApiEndpoint(identity, BestRenewalOffersFormClient, "addUnitComment"),
        [bestRenewalOffersKey, unitKey, comment]
      )
        .then((response) => {
          const data = response.data;
          if (data) {
            dispatcher({
              type: AtlasActionTypes.BestRenewalOffersPageAddUnitComment,
              unitKey: unitKey,
              comments: data
            });
          }
        })
        .catch((error) => {
          handleValidationAndErrorResponses(error);
        });
    };
    const deleteUnitComment = function (
      bestRenewalOffersKey: string,
      unitKey: string,
      commentId: number
    ): void {
      dispatcher({
        type: AtlasActionTypes.UpdateLoadingState,
        loadingState: { isLoading: true }
      });

      makeRemoteRequest(
        getApiEndpoint(
          identity,
          BestRenewalOffersFormClient,
          "deleteUnitComment"
        ),
        [bestRenewalOffersKey, unitKey, commentId]
      )
        .then((response) => {
          const data = response.data;
          if (data) {
            dispatcher({
              type: AtlasActionTypes.BestRenewalOffersPageDeleteUnitComment,
              unitKey: unitKey,
              comments: data
            });
          }
        })
        .catch((error) => {
          handleValidationAndErrorResponses(error);
        });
    };
    const addPropertyComment = function (
      bestRenewalOffersKey: string,
      propertyKey: string,
      comment: string
    ) {
      dispatcher({
        type: AtlasActionTypes.UpdateLoadingState,
        loadingState: { isLoading: true }
      });

      makeRemoteRequest(
        getApiEndpoint(
          identity,
          BestRenewalOffersFormClient,
          "addPropertyComment"
        ),
        [bestRenewalOffersKey, propertyKey, comment]
      )
        .then((response) => {
          const data = response.data;
          if (data) {
            dispatcher({
              type: AtlasActionTypes.BestRenewalOffersPageAddPropertyComments,
              propertyKey: propertyKey,
              comments: data
            });
          }
        })
        .catch((error) => {
          handleValidationAndErrorResponses(error);
        });
    };
    const deletePropertyComment = function (
      bestRenewalOffersKey: string,
      propertyKey: string,
      commentId: number
    ) {
      dispatcher({
        type: AtlasActionTypes.UpdateLoadingState,
        loadingState: { isLoading: true }
      });

      makeRemoteRequest(
        getApiEndpoint(
          identity,
          BestRenewalOffersFormClient,
          "deletePropertyComment"
        ),
        [bestRenewalOffersKey, propertyKey, commentId]
      )
        .then((response) => {
          const data = response.data;
          if (data) {
            dispatcher({
              type: AtlasActionTypes.BestRenewalOffersPageDeletePropertyComment,
              propertyKey: propertyKey,
              comments: data
            });
          }
        })
        .catch((error) => {
          handleValidationAndErrorResponses(error);
        });
    };
    const updateAppliedFilters = function (
      appliedFilters: BestRenewalOffersPageAppliedFilterState[]
    ) {
      dispatcher({
        type: AtlasActionTypes.BestRenewalOffersPageUpdateAppliedFilters,
        appliedFilters: appliedFilters
      });
    };
    const updateFormFilters = function (newFilters: ReviewSummaryFormFilters) {
      dispatcher({
        type: AtlasActionTypes.BestRenewalOffersPageUpdateFormFilters,
        filters: newFilters
      });
    };
    const updateExceptionFilter = function (
      exceptionFilter: ExceptionFilterEnum
    ) {
      dispatcher({
        type: AtlasActionTypes.BestRenewalOffersPageUpdateExceptionFilter,
        selectedExceptionFilter: exceptionFilter
      });
    };

    const downloadToCsv = function (bestRenewalOffersKey: string) {
      dispatcher({
        type: AtlasActionTypes.UpdateLoadingState,
        loadingState: { isLoading: true }
      });

      makeRemoteRequest(
        getApiEndpoint(
          identity,
          BestRenewalOffersActualsFormClient,
          "downloadForm"
        ),
        [bestRenewalOffersKey]
      )
        .then((response) => {
          const data = response.data as BestRenewalOffersFormDownload;
          if (data) {
            windowUtils.openLink(data.fileLocation, false);
          }
        })
        .catch(() => {
          dispatcher({
            type: AtlasActionTypes.ShowErrorState,
            messages: []
          });
        })
        .finally(() => {
          dispatcher({
            type: AtlasActionTypes.UpdateLoadingState,
            loadingState: { isLoading: false }
          });
        });
    };

    const updateRejectionReason = function (rejectionReason: string) {
      dispatcher({
        type: AtlasActionTypes.BestRenewalOffersPageUpdateRejectionReason,
        rejectionReason: rejectionReason
      });
    };

    const updateRejectionDialogVisisbility = function (isVisible: boolean) {
      dispatcher({
        type: AtlasActionTypes.BestRenewalOffersPageUpdateRejectionDialogVisibility,
        isVisible: isVisible
      });
    };

    const updateDeletionDialogVisisbility = function (isVisible: boolean) {
      dispatcher({
        type: AtlasActionTypes.BestRenewalOffersPageUpdateDeletionDialogVisibility,
        isVisible: isVisible
      });
    };

    const updateApprovalDialogVisisbility = function (isVisible: boolean) {
      dispatcher({
        type: AtlasActionTypes.BestRenewalOffersPageUpdateApprovalDialogVisibility,
        isVisible: isVisible
      });
    };

    const updateActiveTab = function (activeTabView: CommonSummaryRollupKind) {
      dispatcher({
        type: AtlasActionTypes.BestRenewalOffersPageUpdateActiveTab,
        activeTabView: activeTabView
      });
    };

    const updatePropertySummaryTableState = function (
      tableState: ExpandableTableState
    ) {
      dispatcher({
        type: AtlasActionTypes.BestRenewalOffersPageUpdatePropertySummaryTablePagingState,
        tableState: tableState
      });
    };

    return {
      getForm,
      getFilteredForm,
      updateFormState,
      updateOffer,
      updateAllOffers,
      updateColumnConfigurations,
      saveForm,
      submitForm,
      exportForm,
      deleteForm,
      approveForm,
      rejectForm,
      addUnitComment,
      addPropertyComment,
      deletePropertyComment,
      deleteUnitComment,
      updateAppliedFilters,
      updateFormFilters,
      updateExceptionFilter,
      updateRejectionDialogVisisbility,
      updateDeletionDialogVisisbility,
      updateApprovalDialogVisisbility,
      updateRejectionReason,
      downloadToCsv,
      updateActiveTab,
      updatePropertySummaryTableState
    };
  }
};
