import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  container: {
    cursor: "pointer",
    background: theme.pallette.white,
    maxWidth: "280px",
    width: "100%",
    height: "309px",
    overflow: "hidden",
    margin: "30px",
    boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.2)",
    transform: "translateZ(0)",
    transition: "transform 250ms ease-in-out",
    "&:hover": {
      boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.2)",
      transform: "scale(1.048) translateZ(0)",
      backfaceVisibility: "hidden"
    }
  },
  imageContainer: {
    overflow: "hidden",
    height: "50%"
  },
  image: {
    width: "100%",
    objectFit: "cover",
    objectPosition: "center",
    height: "187px"
  },
  contentContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50%",
    padding: "0 30px"
  },
  link: {
    textDecoration: "none",
    color: theme.pallette.primary
  },
  heading: {
    fontSize: theme.typeScale.xlarge,
    lineHeight: `${theme.typeScale.xxlarge}px`,
    color: theme.pallette.primary
  },
  icon: {
    width: "1.5em",
    height: "1.5em",
    paddingLeft: theme.marginScale.small
  }
}));
