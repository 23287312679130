import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  columns: {
    display: "flex",
    flexDirection: "row"
  },
  column1: {
    display: "flex",
    flexDirection: "row"
  },
  column2: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-start"
  },
  typeDropdown: {
    minWidth: "260px"
  }
}));
