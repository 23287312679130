import React from "react";
import Page from "components/layouts/Page";
import RequirePermission from "../RequirePermission";
import useStyle from "./useStyle";
import Spacer from "components/controls/Spacer";
import ContentArea from "components/layouts/ContentArea";
import { RouteComponentProps } from "react-router";
import useFirstLoadEffect from "utils/useMountEffect";
import {
  RenewalFollowUpFormFilter,
  RenewalFollowUpFormFilters
} from "api/generated";
import Spinner from "components/controls/Spinner";
import BatchDefaultsBatchInformationArea from "components/forms/BatchDefaultsBatchInformationArea";
import { MessageAreaBasic } from "components/controls/MessageArea";
import useAtlasStore from "components/store/useAtlasStore";
import RenewalFollowUpTable from "./RenewalFollowUpTable";
import listUtils from "utils/listUtils";
import Dropdown from "components/controls/Dropdown";
import BestRenewalOffersMenu from "../BestRenewalOffersMenu";

interface MatchParams {
  id: string;
}

interface PageProps extends RouteComponentProps<MatchParams> {}

const RenewalFollowUpPage: React.FC<PageProps> = function (props: PageProps) {
  const batchKey: string = props.match.params.id;
  const classes = useStyle();

  const [appState, appActions] = useAtlasStore();
  const pageState = {
    form: appState.renewalFollowUpPageState.form,
    ...appState
  };
  const actions = {
    ...appActions.renewalFollowUpPage,
    ...appActions.sideBar,
    ...appActions.messages,
    ...appActions
  };

  useFirstLoadEffect(() => {
    actions.getForm(batchKey);
  });

  const spinner = <Spinner isVisible={pageState.loadingState.isLoading} />;
  const messageAreas = pageState.messageState.messages.map((message) => (
    <MessageAreaBasic message={message} key={message.detail} />
  ));

  const form = pageState.form;

  //See T-4258 for refactoring improvements
  if (
    (form == null ||
      form.batchDetail == null ||
      form.filters == null ||
      form.rollups == null ||
      form.rollupTotals == null) &&
    pageState.loadingState
  ) {
    return spinner;
  } else if (
    form == null ||
    form.batchDetail == null ||
    form.filters == null ||
    form.rollups == null ||
    form.rollupTotals == null
  ) {
    return <div>{messageAreas}</div>;
  }

  const filters = form.filters;

  const getNewRPMFilters = function (
    newValue: RenewalFollowUpFormFilter
  ): RenewalFollowUpFormFilters {
    const newFilters: RenewalFollowUpFormFilters = {
      ...filters,
      selectedRpmFilter: newValue
    };

    if (newValue && filters && filters.rpmFilters && newFilters.rpmFilters) {
      newFilters.rpmFilters = listUtils.copyListWithNewKeyedItem(
        filters.rpmFilters,
        newValue
      );
    }

    return newFilters;
  };

  const updateRpmFilter = function (newValue: RenewalFollowUpFormFilter) {
    const newFilters = getNewRPMFilters(newValue);
    actions.updateFilters(batchKey, form, newFilters);
  };

  return (
    <Page
      menu={
        <BestRenewalOffersMenu
          bestRenewalOffersKey={batchKey}
          batchNavigationConfig={form.navigationConfiguration}
        />
      }
    >
      <RequirePermission
        permissions={["canViewRenewalFollowUpForm"]}
        accessDenied="page"
      ></RequirePermission>
      {spinner}
      {messageAreas}
      <div className={classes.splitContent}>
        <div className={classes.headerMetaData}>
          <BatchDefaultsBatchInformationArea
            batchInformation={{ ...form.batchDetail }}
          />
        </div>
      </div>
      {/* TODO: refactor this into a separate component */}
      <ContentArea columnSize="12">
        <div className={classes.filterDropdownArea}>
          <div className={classes.filterDropdown}>
            <Dropdown
              label="RPM"
              selectedValue={filters.selectedRpmFilter}
              options={filters.rpmFilters}
              onChange={(o) => updateRpmFilter(o as RenewalFollowUpFormFilter)}
            />
          </div>
        </div>
      </ContentArea>
      <Spacer orientation="v" size="xl" />
      <ContentArea columnSize="12">
        <RenewalFollowUpTable
          rollups={form.rollups}
          rollupTotals={form.rollupTotals}
          batchKey={batchKey}
          pagingState={pageState.tableState.pagingState}
          updatePagingState={actions.updateTablePagingState}
        />
      </ContentArea>
    </Page>
  );
};
export default RenewalFollowUpPage;
