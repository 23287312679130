import React from "react";
import NavPrompt from "../NavPrompt";

interface UnsavedChangesProps {
  isVisible: boolean;
}

const UnsavedChangesPrompt: React.FC<UnsavedChangesProps> = props => {
  return (
    <NavPrompt
      isVisible={props.isVisible}
      message="You have unsaved changes"
      iconArea={<i className="pi pi-save" style={{ fontSize: "30px" }} />}
    />
  );
};

export default UnsavedChangesPrompt;
