import React from "react";
import useStyle from "./useStyle";

export interface PageSectionProps {
  overrideWidth?: string | undefined;
}

const PageSection: React.FC<PageSectionProps> = function(props) {
  const classes = useStyle(props);

  return <div className={classes.container}>{props.children}</div>;
};

export default PageSection;
