import React, { useState, useContext } from "react";
import Menu from "../Menu";
import PrimaryButton from "components/controls/PrimaryButton";
import Page from "components/layouts/Page";
import Spacer from "components/controls/Spacer";
import useStyle from "./useStyle";
import Section from "components/controls/Section";
import {
  BatchListForm,
  BatchListFormBatchDetail,
  Option,
  BatchListFormClient
} from "api";
import {
  createFormattedDate,
  createUtcFormattedDate
} from "components/controls/FormattedDate";
import { matchesDivision, matchesYear, matchesVersion } from "../filters";
import createDataTable, {
  IPagedTableState,
  DataTableProps
} from "components/controls/createTable";
import RequirePermission from "../RequirePermission";
import { useApiEndpoint } from "components/useApiEndpoint";
import { useRemoteData } from "components/useRemoteData";
import routes from "components/routes";
import Dropdown from "components/controls/Dropdown";
import { useHistory } from "react-router";
import ContentArea from "components/layouts/ContentArea";
import Spinner from "components/controls/Spinner";
import { IdentityContext } from "components/IdentityProvider";
import permissionsUtils from "utils/permissionsUtils";
import Label from "components/controls/Label";
import FormattedBatchTypes from "components/forms/FormattedBatchTypes";
import listUtils from "utils/listUtils";

interface PageState {
  form: BatchListForm | null | undefined;
}

interface TableState extends IPagedTableState {
  divisionFilter?: Option;
  batchTypeFilter?: Option;
  yearFilter?: Option;
  versionFilter?: Option;
}

function createFormattedBatchTypes(rowData: any, column: any) {
  const batchListDetail = rowData as BatchListFormBatchDetail;
  return (
    <FormattedBatchTypes
      batchTypes={batchListDetail.batchTypes && batchListDetail.batchTypes}
    />
  );
}

function matchesBatchType(
  batchTypeFilter: Option | undefined | null,
  BatchListFormBatchDetail: BatchListFormBatchDetail | undefined | null
): boolean {
  return (
    batchTypeFilter == null ||
    (BatchListFormBatchDetail != null &&
      BatchListFormBatchDetail.batchTypes != null &&
      listUtils.any(
        BatchListFormBatchDetail.batchTypes,
        (batchType) => batchType.key === batchTypeFilter.key
      ))
  );
}

const BatchListPage: React.FC = function () {
  const classes = useStyle();
  const history = useHistory();
  const identity = useContext(IdentityContext);

  const [page, setState] = useState<PageState>({
    form: undefined
  });

  const [table, setTable] = useState<TableState>({
    offset: 0,
    limit: 10,
    yearFilter: undefined,
    divisionFilter: undefined,
    batchTypeFilter: undefined,
    versionFilter: undefined
  });

  const [dataState] = useRemoteData(
    useApiEndpoint(BatchListFormClient, "getBatchList"),
    [],
    {
      autoFetch: true,
      onChange: (d) => {
        setState({ ...page, form: d });
        setTable({
          ...table,
          yearFilter: (d && d.selectedYear) || undefined,
          divisionFilter: (d && d.selectedDivision) || undefined,
          batchTypeFilter: (d && d.selectedBatchType) || undefined,
          versionFilter: (d && d.selectedVersion) || undefined
        });
      }
    }
  );

  const spinner = <Spinner isVisible={dataState.isFetching} />;

  //See T-4258 for refactoring improvements
  if (page.form == null && dataState.isFetching) {
    return spinner;
  } else if (page.form == null) {
    return <div>Error</div>;
  }

  const tableProps: DataTableProps<BatchListFormBatchDetail, TableState> = {
    value: page.form.batches,
    state: table,
    updateState: setTable,
    deps: [page.form],
    selectionMode: "single",
    onSelectionChange: (e: any) => {
      const row: BatchListFormBatchDetail = e.value;

      if (row && row.key) {
        if (
          permissionsUtils.shouldUserNavigateToSummaryPageByDefault(identity)
        ) {
          history.push(routes.reviewSummary(row.key));
        } else {
          history.push(routes.bestRenewalOffers(row.key));
        }
      }
    },
    emptyMessage: "No batches have been created."
  };

  const dataTable = createDataTable<BatchListFormBatchDetail, TableState>(
    [
      {
        field: "division",
        header: "Division",
        hidden:
          page.form.batchListFormConfiguration &&
          page.form.batchListFormConfiguration.hideDivision,
        sortable: permissionsUtils.isAdmin(identity)
      },
      {
        field: "batchTypes",
        header: "Type",
        body: createFormattedBatchTypes
      },
      {
        field: "startDate",
        header: "Start",
        body: createFormattedDate,
        sortable: permissionsUtils.isAdmin(identity)
      },
      {
        field: "endDate",
        header: "End",
        body: createFormattedDate,
        style: {
          width: "8%"
        },
        sortable: permissionsUtils.isAdmin(identity)
      },
      {
        field: "properties",
        header: "Properties",
        style: {
          width: "12%",
          textAlign: "right",
          paddingRight: "4%"
        }
      },
      {
        field: "units",
        header: "Units",
        style: {
          textAlign: "right",
          paddingRight: "5%"
        }
      },
      {
        field: "version",
        header: "Version",
        hidden:
          page.form.batchListFormConfiguration &&
          page.form.batchListFormConfiguration.hideVersion,
        sortable: permissionsUtils.isAdmin(identity)
      },
      {
        field: "status",
        header: "Status",
        style: {
          width: "12%"
        },
        sortable: permissionsUtils.isAdmin(identity)
      },
      {
        field: "rpmApprovals",
        header:
          page.form.batchListFormConfiguration &&
          page.form.batchListFormConfiguration.rpmApprovalsHeader,
        hidden:
          page.form.batchListFormConfiguration &&
          page.form.batchListFormConfiguration.hideRpmApprovals
      },
      {
        field: "createdDate",
        header: "Created",
        body: createUtcFormattedDate,
        sortable: permissionsUtils.isAdmin(identity)
      },
      {
        field: "lastUpdatedDate",
        header: "Last Updated",
        body: createUtcFormattedDate,
        sortable: permissionsUtils.isAdmin(identity)
      }
    ],
    tableProps,
    {
      yearFilter: (v, r) =>
        matchesYear(v && v.displayValue, undefined, r.createdDate),
      divisionFilter: (v, r) =>
        matchesDivision(v && v.displayValue, r.division),
      batchTypeFilter: (v, r) => matchesBatchType(v, r),
      versionFilter: (v, r) => matchesVersion(v && v.displayValue, r.version)
    }
  );

  return (
    <Page menu={<Menu title="BATCHES" />}>
      <RequirePermission
        permissions={["canViewBatchListForm"]}
        accessDenied="page"
      >
        <Section title="BATCHES" />
        <div className={classes.columns}>
          <div className={classes.column1}>
            {page.form.batchListFormConfiguration &&
            page.form.batchListFormConfiguration.hideDivision ? (
              <div>
                <div>
                  <Label label="DIVISION" />
                  <Spacer orientation="v" size="m" />
                </div>
                <div>
                  {page.form.selectedDivision &&
                    page.form.selectedDivision.displayValue}
                </div>
              </div>
            ) : (
              <Dropdown
                label="DIVISION"
                selectedValue={page.form.selectedDivision}
                options={page.form.divisions}
                onChange={(o) => {
                  setState({
                    ...page,
                    form: { ...page.form, selectedDivision: o }
                  });
                  setTable({
                    ...table,
                    divisionFilter: o
                  });
                }}
              />
            )}
            <Spacer orientation="h" size="xl" />
            <div className={classes.typeDropdown}>
              <Dropdown
                label="TYPE"
                selectedValue={page.form.selectedBatchType}
                options={page.form.batchTypes}
                onChange={(o) => {
                  setState({
                    ...page,
                    form: { ...page.form, selectedBatchType: o }
                  });
                  setTable({
                    ...table,
                    batchTypeFilter: o
                  });
                }}
              />
            </div>
            <Spacer orientation="h" size="xl" />
            <Dropdown
              label="CREATED YEAR"
              selectedValue={page.form.selectedYear}
              options={page.form.years}
              onChange={(o) => {
                setState({ ...page, form: { ...page.form, selectedYear: o } });
                setTable({
                  ...table,
                  yearFilter: o
                });
              }}
            />
            <Spacer orientation="h" size="xl" />
            {page.form.batchListFormConfiguration &&
            page.form.batchListFormConfiguration.hideVersion ? (
              <span />
            ) : (
              <Dropdown
                label="VERSION"
                selectedValue={page.form.selectedVersion}
                options={page.form.versions}
                onChange={(o) => {
                  setState({
                    ...page,
                    form: { ...page.form, selectedVersion: o }
                  });
                  setTable({
                    ...table,
                    versionFilter: o
                  });
                }}
              />
            )}
          </div>
          <div className={classes.column2}>
            <RequirePermission permissions={["canCreateBatch"]}>
              <PrimaryButton title="CREATE BATCH" to={routes.createBatch} />
            </RequirePermission>
          </div>
        </div>
        <Spacer size="l" orientation="v" />

        <ContentArea columnSize={"12"}>
          <div>{dataTable}</div>
        </ContentArea>
        <Spacer size="l" orientation="v" />
      </RequirePermission>
    </Page>
  );
};

export default BatchListPage;
