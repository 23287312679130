import React from "react";
import useStyle from "./useStyle";

interface LoadingProps {
  message?: string | undefined;
}

//See T-4258 for refactoring improvements
const LoadingPage: React.FC<LoadingProps> = props => {
  const classes = useStyle();
  return (
    <div className={classes.container}>
      <div>
        <span>{props.message}</span>
      </div>
    </div>
  );
};

LoadingPage.defaultProps = {
  message: "Loading..."
};

export default LoadingPage;
