import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  root: {
    fontFamily: theme.fontFamily,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: `${theme.marginScale.large}px 0`
  },
  header: {
    fontFamily: theme.fontFamily,
    fontWeight: "bold",
    lineHeight: `${theme.typeScale.xxlarge}px`,
    minWidth: "50px",
    paddingRight: "40px"
  },
  menuItem: {
    paddingRight: "40px"
  }
}));
