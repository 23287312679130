import React from "react";
import { Button } from "primereact/button";
import cssUtils from "utils/cssUtils";

export enum IconType {
  Download = 1,
  Add = 2,
  Remove = 3,
  Question = 4
}

export interface IconButtonProps {
  title?: string | undefined;
  disabled?: boolean;
  icon: IconType;
  onClick?: () => void;
  onlyShowIcon?: boolean | undefined;
  className?: string | undefined;
}

const IconButton: React.FC<IconButtonProps> = function(props) {
  let iconClass = "pi-plus";

  switch (props.icon) {
    case IconType.Download:
      iconClass = "pi-download";
      break;
    case IconType.Add:
      iconClass = "pi-plus";
      break;
    case IconType.Remove:
      iconClass = "pi-minus";
      break;
    case IconType.Question:
      iconClass = "pi-question-circle";
      break;
  }

  const className = props.onlyShowIcon ? "essexAtlasLinkButton" : undefined;

  return (
    <Button
      className={cssUtils.join(className, props.className)}
      label={props.title}
      icon={`pi ${iconClass}`}
      onClick={() => {
        props.onClick && props.onClick();
      }}
    />
  );
};

export default IconButton;
