import React from "react";
import useStyle from "./useStyle";
import { CreateBatchFormBatchType } from "api";
import listUtils from "utils/listUtils";
import Checkbox, { Checked } from "components/controls/Checkbox";
import Label from "components/controls/Label";
import ValidationWrapper from "components/controls/ValidationWrapper";

interface BatchTypeSelectionAreaProps {
  batchTypes: CreateBatchFormBatchType[];
  selectedBatchTypes: CreateBatchFormBatchType[];
  onSelectedBatchTypesChange: (batchTypes: CreateBatchFormBatchType[]) => void;
  validationMessage: string | undefined;
}

const BatchTypeSelectionArea: React.FC<BatchTypeSelectionAreaProps> = function (
  props: BatchTypeSelectionAreaProps
) {
  const classes = useStyle();
  const getOnBatchTypesSelectionChange = function (
    batchTypeKey: string | undefined
  ) {
    return (checked: Checked) => {
      if (!batchTypeKey) {
        return;
      }

      let newSelectedBatchTypes = props.selectedBatchTypes || [];

      if (checked) {
        const batchTypeToAdd = listUtils.firstOrDefaultByKey(
          props.batchTypes,
          batchTypeKey
        );

        if (!batchTypeToAdd) {
          return;
        }

        newSelectedBatchTypes = newSelectedBatchTypes.concat([batchTypeToAdd]);
      } else {
        const batchTypeToRemove = listUtils.firstOrDefaultByKey(
          newSelectedBatchTypes,
          batchTypeKey
        );

        if (batchTypeToRemove) {
          newSelectedBatchTypes = listUtils.copyListWithItemRemoved(
            newSelectedBatchTypes,
            batchTypeToRemove
          );
        }
      }

      props.onSelectedBatchTypesChange(newSelectedBatchTypes);
    };
  };

  const batchTypes = props.batchTypes || [];
  const selectedBatchTypes = props.selectedBatchTypes || [];
  const checkboxes = batchTypes.map((batchType) => (
    <Checkbox
      label={batchType.displayValue}
      labelPosition="right"
      checked={listUtils.anyKey(selectedBatchTypes, batchType.key)}
      onChange={getOnBatchTypesSelectionChange(batchType.key)}
    />
  ));

  return (
    <ValidationWrapper showErrorState={props.validationMessage != null}>
      <div className={classes.batchTypesArea}>
        <div className={classes.batchTypesHeader}>
          <Label label="Batch Types" />
        </div>
        <div className={classes.batchTypesList}>{checkboxes}</div>
      </div>
    </ValidationWrapper>
  );
};

export default BatchTypeSelectionArea;
