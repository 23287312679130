import React from "react";
import useStyle from "./useStyle";

export interface SectionProps {
  title: string;
}

const Section: React.FC<SectionProps> = function(props) {
  const classes = useStyle();
  return (
    <>
      <div className={classes.container}>
        <span className={classes.title}>{props.title}</span>
        <div className={classes.break}></div>
      </div>
      {props.children}
    </>
  );
};

export default Section;
