import React from "react";
import {
  DefaultsAdminGrowthRatesForm,
  DefaultsAdminGrowthRatesFormBedroom,
  DefaultsAdminGrowthRatesFormPropertyBedroom,
  DefaultsAdminGrowthRatesFormDivision,
  DefaultsPageConfiguration
} from "api";
import Section from "components/controls/Section";
import Dropdown from "components/controls/Dropdown";
import Spacer from "components/controls/Spacer";
import Label from "components/controls/Label";
import PrimaryButton from "components/controls/PrimaryButton";
import listUtils from "utils/listUtils";
import utils from "utils";
import DefaultsGrowthRatesTable from "components/forms/DefaultsGrowthRatesFormTable";
import Heading from "components/controls/Heading";
import ContentArea from "components/layouts/ContentArea";
import Column from "components/controls/Column";
import Input from "components/controls/Input";
import validationUtils from "utils/validationUtils";
import useStyle from "./useStyle";

export interface DefaultsAdminGrowthRatesFormProps {
  setForm: (form: DefaultsAdminGrowthRatesForm) => any;
  form: DefaultsAdminGrowthRatesForm;
  submit: () => void;
  isFetching: boolean;
  isSuccessful: boolean;
  onAddRowAction?: (() => void) | undefined;
  hideDivisions?: boolean | undefined;
  hideSaveButton?: boolean | undefined;
  pageConfiguration: DefaultsPageConfiguration | undefined;
}

const DefaultsGrowthRatesForm: React.FC<DefaultsAdminGrowthRatesFormProps> = function (
  props: DefaultsAdminGrowthRatesFormProps
) {
  const cloneForm = function (): DefaultsAdminGrowthRatesForm {
    return utils.cloneObject(props.form);
  };

  const classes = useStyle();

  const setForm = function (form: DefaultsAdminGrowthRatesForm) {
    props.setForm(form);
  };

  const onDivisionChanged = function (
    division: DefaultsAdminGrowthRatesFormDivision | undefined
  ) {
    const divisions = (props.form && props.form.divisions) || undefined;
    let newDivisionsList = divisions;

    if (divisions && division) {
      newDivisionsList =
        listUtils.copyListWithNewKeyedItem(divisions, division) || undefined;
    }

    const form = cloneForm();

    form.selectedDivision = division;
    form.divisions = newDivisionsList;

    setForm(form);
  };

  const onDivisionGrowthRateChanged = function (value: string) {
    const newDivision = utils.cloneObject(props.form.selectedDivision);

    if (newDivision && newDivision.defaultPercentage !== value) {
      newDivision.defaultPercentage = value;
      newDivision.isChanged = true;
    }

    onDivisionChanged(newDivision);
  };

  const onBedroomDefaultsChange = (
    defaults: DefaultsAdminGrowthRatesFormBedroom[]
  ) => {
    onDivisionChanged({
      ...props.form.selectedDivision,
      bedroomRateDefaults: defaults,
      isChanged: true
    });
  };

  const onPropertyBedroomDefaultsChange = (
    defaults: DefaultsAdminGrowthRatesFormPropertyBedroom[]
  ) => {
    onDivisionChanged({
      ...props.form.selectedDivision,
      propertyBedroomRateDefaults: defaults,
      isChanged: true
    });
  };

  const addDefaultsByBedroomRow = function (): void {
    const selectedDivision = props.form.selectedDivision;
    const newRow = {
      growthRatePercentage:
        selectedDivision && selectedDivision.defaultPercentage
    };
    const currentRows =
      (selectedDivision && selectedDivision.bedroomRateDefaults) || [];

    onBedroomDefaultsChange(currentRows.concat(newRow));
  };

  const addDefaultsByBedroomProperty = function (): void {
    const selectedDivision = props.form.selectedDivision;
    const newRow = {
      growthRatePercentage:
        selectedDivision && selectedDivision.defaultPercentage
    };
    const currentRows =
      (selectedDivision && selectedDivision.propertyBedroomRateDefaults) || [];

    onPropertyBedroomDefaultsChange(currentRows.concat(newRow));
  };

  const getDivisionDropdownOrLabel = function (): JSX.Element {
    if (props.hideDivisions) {
      return <div />;
    }

    const divisions = props.form.divisions;
    const selectedDivision = props.form.selectedDivision;
    if (divisions && divisions.length > 1) {
      return (
        <Dropdown
          label="Division"
          selectedValue={selectedDivision}
          options={divisions}
          onChange={onDivisionChanged}
          hideClear={true}
        />
      );
    } else if (selectedDivision) {
      return <Label label={selectedDivision.displayValue} />;
    } else {
      return <div />;
    }
  };

  const getSaveButton = function (): JSX.Element {
    if (props.hideSaveButton) {
      return <div />;
    }

    return (
      <PrimaryButton
        disabled={props.isFetching}
        onClick={() => props.submit()}
        title="SAVE"
      />
    );
  };

  if (props.form == null && props.isSuccessful === false && props.isFetching) {
    return <div />;
  } else if (props.form == null) {
    return <div>Error</div>;
  }

  // TODO Revisit this check for setting the value on the "Default %" Input field.
  if (!props.form.selectedDivision) {
    if (props.form.divisions && props.form.divisions.length > 0) {
      props.setForm({
        ...props.form,
        selectedDivision: listUtils.firstOrDefault(props.form.divisions)
      });
    }
  }

  let validationMessage = null;
  if (
    props.form.selectedDivision &&
    props.form.selectedDivision.fieldValidationErrors
  ) {
    validationMessage = validationUtils.getErrorForField(
      props.form.selectedDivision.fieldValidationErrors,
      "defaultPercentage"
    );
  }

  const divisionsArea = getDivisionDropdownOrLabel();
  const saveButton = getSaveButton();

  return (
    <div>
      <Section title="GROWTH RATES" />

      <Column size="2">
        {divisionsArea}
        <Spacer orientation="v" size="l" />
        {props.pageConfiguration && props.pageConfiguration.isEditEnabled ? (
          <Input
            label="Default %"
            value={
              props.form.selectedDivision &&
              props.form.selectedDivision.defaultPercentage
            }
            onChange={onDivisionGrowthRateChanged}
            validationMessage={validationMessage}
          />
        ) : (
          <div>
            <div>
              <div className={classes.header}>Default %</div>
            </div>
            <div>
              {props.form.selectedDivision &&
                props.form.selectedDivision.defaultPercentage}
            </div>
            <Spacer orientation="v" size="l" />
          </div>
        )}
      </Column>

      <Spacer orientation="v" size="l" />

      <ContentArea columnSize={"7"}>
        <Heading level="3">By Bedroom</Heading>
        <DefaultsGrowthRatesTable
          defaults={
            props.form.selectedDivision &&
            props.form.selectedDivision.bedroomRateDefaults
          }
          onChange={onBedroomDefaultsChange}
          hidePropertyColumn={true}
          hideBedroomTypeColumn={false}
          hideRemoveRowColumn={
            !(
              props.form.pageConfiguration &&
              props.form.pageConfiguration.isEditEnabled
            )
          }
          bedrooms={
            (props.form.selectedDivision &&
              props.form.selectedDivision.bedroomTypesForBedroomDefaults) ||
            []
          }
          properties={
            (props.form.selectedDivision &&
              props.form.selectedDivision.propertyTypes) ||
            []
          }
          onAddRowAction={
            props.form.pageConfiguration &&
            props.form.pageConfiguration.isEditEnabled
              ? addDefaultsByBedroomRow
              : props.onAddRowAction
          }
          pageConfiguration={props.form.pageConfiguration}
        />
      </ContentArea>

      <Spacer orientation="v" size="l" />

      <ContentArea columnSize={"7"}>
        <Heading level="3">By Property</Heading>
        <DefaultsGrowthRatesTable
          defaults={
            props.form.selectedDivision &&
            props.form.selectedDivision.propertyBedroomRateDefaults
          }
          onChange={onPropertyBedroomDefaultsChange}
          hidePropertyColumn={false}
          hideBedroomTypeColumn={false}
          hideRemoveRowColumn={
            !(
              props.form.pageConfiguration &&
              props.form.pageConfiguration.isEditEnabled
            )
          }
          bedrooms={
            (props.form.selectedDivision &&
              props.form.selectedDivision
                .bedroomTypesForPropertyBedroomDefaults) ||
            []
          }
          properties={
            (props.form.selectedDivision &&
              props.form.selectedDivision.propertyTypes) ||
            []
          }
          onAddRowAction={
            props.form.pageConfiguration &&
            props.form.pageConfiguration.isEditEnabled
              ? addDefaultsByBedroomProperty
              : props.onAddRowAction
          }
          pageConfiguration={props.pageConfiguration}
        />
      </ContentArea>

      <Spacer size="l" orientation="v" />

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {saveButton}
      </div>
    </div>
  );
};

export default DefaultsGrowthRatesForm;
