import { createContext } from "react";
import { createTheming, createUseStyles } from "react-jss";
import { StyleSheetFactoryOptions } from "jss";
import { Theming } from "theming";

const pallette = {
  // Essex approved pallette
  primary: "#c75109",
  primaryAlternateState: "#ab4202",
  foregroundContrast: "#ffffff",
  inactive: "#666666",
  softRed: "#C10404",
  dark: "#2e2f31",

  alternatingRowGray: "#f9f9f9",
  black: "#000",
  emphasis: "#66cccc",
  blue: "#00838F",
  transparentBlue: "#00838f29",
  darkBlue: "rgb(0, 65, 71)",
  gray: "#3e3e3e",
  darkGray: "#555",
  lightGray: "#ccc",
  faintGray: "#eee",
  background: "#ffffff",
  grayBackground: "#F8F8F8",
  bodyBackground: "#dfdfdf",
  transparent: "transparent",
  error: "red",
  white: "#fff"
};

const typeScale = {
  xsmall: 10,
  small: 12,
  medium: 14,
  large: 18,
  xlarge: 24,
  xxlarge: 32
};

const typeWeight = {
  bold: "bold"
};

const siteDefaultTheme = {
  fontFamily: ["Open Sans", "Helvetica Neue", "sans-serif"], //["'raleway-regular'", "Arial", "Helvetica", "sans-serif"],
  fontFamilyEmphasis: ["'raleway-bold'", "Arial", "Helvetica", "sans-serif"],
  color: pallette.gray,
  pallette,
  text: {},
  marginScale: {
    none: 0,
    xsmall: 2,
    small: 4,
    medium: 8,
    large: 16,
    xlarge: 32,
    xxlarge: 64
  },
  paddingScale: {
    none: 0,
    xsmall: 3,
    small: 6
  },
  sizeScale: {
    xxsmall: 8,
    xsmall: 16,
    small: 32,
    medium: 64,
    large: 128,
    xlarge: 256,
    xxlarge: 512
  },
  typeScale: typeScale,
  columnSize: {
    cols1: "8.333%",
    cols2: "16.667%",
    cols3: "25.000%",
    cols4: "33.333%",
    cols5: "41.667%",
    cols6: "50.000%",
    cols7: "58.333%",
    cols8: "66.667%",
    cols9: "75.000%",
    cols10: "83.333%",
    cols11: "91.667%",
    cols12: "100.000%"
  },
  navHeight: "64px",
  maxScreenWidth: "1440px",
  headings: {
    h1: {
      fontSize: typeScale.xxlarge,
      fontWeight: typeWeight.bold,
      lineHeight: "1.2em"
    },
    h2: {
      fontSize: typeScale.xlarge,
      fontWeight: typeWeight.bold,
      lineHeight: "1.2em"
    },
    h3: {
      fontSize: typeScale.large,
      fontWeight: typeWeight.bold,
      lineHeight: "1.2em"
    }
  },
  buttons: {
    primary: {
      border: pallette.primary,
      borderFocus: "unset", //pallette.emphasis,
      background: pallette.primary,
      backgroundFocus: pallette.primaryAlternateState,
      foreground: pallette.foregroundContrast,
      foregroundFocus: pallette.foregroundContrast
    },
    secondary: {
      border: pallette.white,
      borderFocus: pallette.white,
      background: pallette.transparent,
      backgroundFocus: pallette.transparent,
      foreground: pallette.white,
      foregroundFocus: pallette.white
    },
    secondaryWarn: {
      border: pallette.gray,
      borderFocus: pallette.darkGray,
      background: pallette.softRed,
      backgroundFocus: pallette.darkGray,
      foreground: pallette.foregroundContrast,
      foregroundFocus: pallette.background
    },
    secondaryImportant: {
      border: pallette.transparent,
      borderFocus: pallette.transparent,
      background: pallette.softRed,
      backgroundFocus: pallette.softRed,
      foreground: pallette.foregroundContrast,
      foregroundFocus: pallette.foregroundContrast
    },
    secondaryNotice: {
      border: pallette.primary,
      borderFocus: pallette.primaryAlternateState,
      background: pallette.transparent,
      backgroundFocus: pallette.transparent,
      foreground: pallette.primary,
      foregroundFocus: pallette.primaryAlternateState
    },
    secondaryContrast: {
      border: pallette.background,
      borderFocus: pallette.background,
      background: pallette.transparent,
      backgroundFocus: pallette.darkGray,
      foreground: pallette.background,
      foregroundFocus: pallette.background
    },
    minimal: {
      border: pallette.transparent,
      borderFocus: pallette.emphasis,
      background: pallette.background,
      backgroundFocus: pallette.background,
      foreground: pallette.gray,
      foregroundFocus: pallette.gray
    }
  }
};

const themeContext = createContext(siteDefaultTheme);
const siteTheming = createTheming(themeContext);
const { ThemeProvider, useTheme } = siteTheming;

const createUseStylesFactory = function <T>(theming: Theming<T>) {
  return function <C extends string>(
    styles: (theme: T) => Record<C, object | T | string>,
    options?: {
      index?: number;
      name?: string;
    } & StyleSheetFactoryOptions
  ) {
    return createUseStyles(styles, { theming } as any);
  };
};

var createThemedUseStyles = createUseStylesFactory(siteTheming);

export {
  ThemeProvider as SiteThemeProvider,
  useTheme as useSiteTheme,
  siteDefaultTheme,
  siteTheming,
  createThemedUseStyles
};
