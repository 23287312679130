import React, { useState } from "react";
import {
  Option,
  DefaultsAdminGtlAndLtlFormPropertyType,
  DefaultsAdminGtlAndLtlFormBedroomType,
  DefaultsAdminGtlAndLtlFormDefaults,
  DefaultsAdminGrowthRatesFormBedroomType,
  DefaultsAdminGrowthRatesFormPropertyType,
  DefaultsPageConfiguration,
  BaselineDefaultsGtlAndLtlFormDropRateDefaults,
  DefaultsAdminGtlAndLtlFormCalculationMethodType
} from "api";
import createDataTable, {
  IPagedTableState,
  ColumnDefinition,
  DefaultInputEditorData,
  DataTableProps
} from "components/controls/createTable";

import Dropdown from "components/controls/Dropdown";
import listUtils from "utils/listUtils";
import utils from "utils";

export interface GtlAndLtlDefaultsTableProps {
  defaults?: DefaultsAdminGtlAndLtlFormDefaults[] | undefined;
  bedrooms: DefaultsAdminGrowthRatesFormBedroomType[];
  properties: DefaultsAdminGrowthRatesFormPropertyType[];
  calculationMethods: DefaultsAdminGtlAndLtlFormCalculationMethodType[];
  onChange: (defaults: DefaultsAdminGtlAndLtlFormDefaults[]) => void;
  hidePropertyColumn?: boolean | undefined;
  hideBedroomTypeColumn?: boolean | undefined;
  hideRemoveRowColumn?: boolean | undefined;
  paging?: boolean | undefined;
  onAddRowAction?: (() => void) | undefined;
  includeRemoveRowColumn?: "show" | "buffer" | undefined;
  pageConfiguration: DefaultsPageConfiguration | undefined;
  dropRateDefaults: BaselineDefaultsGtlAndLtlFormDropRateDefaults | undefined;
}

interface TableState extends IPagedTableState {
  divisionFilter?: Option;
  sortField?: any;
  sortOrder?: any;
  sortState?: "unsorted" | "asc" | "desc" | undefined;
}

const DefaultsGtlAndLtlFormTable: React.FC<GtlAndLtlDefaultsTableProps> =
  function (props: GtlAndLtlDefaultsTableProps) {
    const [table, setTable] = useState<TableState>({
      offset: 0,
      limit: 10,
      sortOrder: 1
    });

    const onRowChanged = (
      oldRow: DefaultsAdminGtlAndLtlFormDefaults,
      newRow: DefaultsAdminGtlAndLtlFormDefaults
    ) => {
      if (!props.defaults) {
        return;
      }

      props.onChange(
        listUtils.copyListWithNewItem(props.defaults, oldRow, newRow)
      );
    };

    const onRemoveRow = (row: DefaultsAdminGtlAndLtlFormDefaults) => {
      if (!props.defaults) {
        return;
      }

      const newDefaults = listUtils.copyListWithItemRemoved(
        props.defaults,
        row
      );
      props.onChange(newDefaults);
    };

    const onGtlAndLtlDefaultsFormPropertyChange = (
      defaultsRow: DefaultsAdminGtlAndLtlFormDefaults
    ) => {
      return (
        selectedProperty: DefaultsAdminGtlAndLtlFormPropertyType | undefined
      ) => {
        const newDefaultsRow = utils.cloneObject(defaultsRow);

        if (
          (newDefaultsRow.selectedPropertyType &&
            newDefaultsRow.selectedPropertyType.key) !==
          (selectedProperty && selectedProperty.key)
        ) {
          newDefaultsRow.selectedPropertyType = selectedProperty;

          onRowChanged(defaultsRow, newDefaultsRow);
        }
      };
    };

    const onGtlAndLtlDefaultsFormBedroomChange = (
      defaultsRow: DefaultsAdminGtlAndLtlFormDefaults
    ) => {
      return (
        selectedBedroom: DefaultsAdminGtlAndLtlFormBedroomType | undefined
      ) => {
        const newDefaultsRow: DefaultsAdminGtlAndLtlFormDefaults = {
          ...defaultsRow,
          selectedBedroomType: selectedBedroom
        };

        onRowChanged(defaultsRow, newDefaultsRow);
      };
    };

    const onGtlAndLtlDefaultsFormCalculationMethodChange = (
      defaultsRow: DefaultsAdminGtlAndLtlFormDefaults
    ) => {
      return (
        calculationMethod:
          | DefaultsAdminGtlAndLtlFormCalculationMethodType
          | undefined
      ) => {
        const newDefaultsRow: DefaultsAdminGtlAndLtlFormDefaults = {
          ...defaultsRow,
          calculationMethodType: calculationMethod
        };

        onRowChanged(defaultsRow, newDefaultsRow);
      };
    };

    const getPropertyDefaultsBody = function (rowData: any, column: any) {
      const defaults = rowData as DefaultsAdminGtlAndLtlFormDefaults;

      let dropDown = (
        <Dropdown
          onChange={onGtlAndLtlDefaultsFormPropertyChange(defaults)}
          validationMessage={
            defaults.fieldValidationErrors &&
            defaults.fieldValidationErrors["SelectedPropertyType"]
          }
          selectedValue={defaults.selectedPropertyType}
          options={props.properties}
          style={{ minWidth: "100%", maxWidth: "100%" }}
        />
      );

      let label = (
        <div>
          {defaults.selectedPropertyType &&
            defaults.selectedPropertyType.displayValue}
        </div>
      );

      if (props.pageConfiguration && props.pageConfiguration.isEditEnabled) {
        return dropDown;
      } else {
        return label;
      }
    };

    const getBedroomDefaultsBody = function (rowData: any, column: any) {
      const defaults = rowData as DefaultsAdminGtlAndLtlFormDefaults;

      let dropDown = (
        <Dropdown
          onChange={onGtlAndLtlDefaultsFormBedroomChange(defaults)}
          selectedValue={defaults.selectedBedroomType}
          options={props.bedrooms}
          validationMessage={
            defaults.fieldValidationErrors &&
            defaults.fieldValidationErrors["SelectedBedroomType"]
          }
          style={{ minWidth: "100%", maxWidth: "100%" }}
        />
      );

      let label = (
        <div>
          {defaults.selectedBedroomType &&
            defaults.selectedBedroomType.displayValue}
        </div>
      );

      if (props.pageConfiguration && props.pageConfiguration.isEditEnabled) {
        return dropDown;
      } else {
        return label;
      }
    };

    const getCalculationMethodDefaultsBody = function (
      rowData: any,
      column: any
    ) {
      const defaults = rowData as DefaultsAdminGtlAndLtlFormDefaults;

      let dropDown = (
        <Dropdown
          onChange={onGtlAndLtlDefaultsFormCalculationMethodChange(defaults)}
          selectedValue={defaults.calculationMethodType}
          options={props.calculationMethods}
          validationMessage={
            defaults.fieldValidationErrors &&
            defaults.fieldValidationErrors["CalculationMethodType"]
          }
          style={{ minWidth: "100%", maxWidth: "100%" }}
        />
      );

      let label = (
        <div>
          {defaults.calculationMethodType &&
            defaults.calculationMethodType.displayValue}
        </div>
      );

      if (props.pageConfiguration && props.pageConfiguration.isEditEnabled) {
        return dropDown;
      } else {
        return label;
      }
    };

    const getDefaultInputEditorData =
      function (): DefaultInputEditorData<DefaultsAdminGtlAndLtlFormDefaults> {
        return {
          isEditDisabled: !(
            props.pageConfiguration && props.pageConfiguration.isEditEnabled
          ),
          onChange: (
            row: DefaultsAdminGtlAndLtlFormDefaults,
            field: keyof DefaultsAdminGtlAndLtlFormDefaults,
            value: any
          ) => {
            const newDefaultsRow = {
              ...row
            };

            newDefaultsRow[field] = value;
            onRowChanged(row, newDefaultsRow);
          },
          isEditDisabledForCell: (
            row: DefaultsAdminGtlAndLtlFormDefaults,
            field: keyof DefaultsAdminGtlAndLtlFormDefaults
          ) => {
            const areDropRatesEnabled =
              (props.dropRateDefaults && props.dropRateDefaults.isEnabled) ||
              false;

            if (areDropRatesEnabled) {
              return false;
            }

            const fieldsToDisableFor: (keyof DefaultsAdminGtlAndLtlFormDefaults)[] =
              [
                "dropRate1Percentage",
                "dropRate2Percentage",
                "dropRate3Percentage"
              ];

            return fieldsToDisableFor.includes(field);
          }
        };
      };

    const getDropRateColumns = function (): any[] {
      const dropRateRanges =
        props.dropRateDefaults && props.dropRateDefaults.ranges;

      const dropRateIsEnabled =
        (props.dropRateDefaults && props.dropRateDefaults.isEnabled) || false;

      const dropRateValues = {
        dropRateOneRangeBottom:
          (dropRateRanges &&
            dropRateRanges.dropRateOneRange &&
            dropRateRanges.dropRateOneRange.bottomRange) ||
          "20",
        dropRateOneRangeTop:
          (dropRateRanges &&
            dropRateRanges.dropRateOneRange &&
            dropRateRanges.dropRateOneRange.topRange) ||
          "25",
        dropRateTwoRangeBottom:
          (dropRateRanges &&
            dropRateRanges.dropRateTwoRange &&
            dropRateRanges.dropRateTwoRange.bottomRange) ||
          "25",
        dropRateTwoRangeTop:
          (dropRateRanges &&
            dropRateRanges.dropRateTwoRange &&
            dropRateRanges.dropRateTwoRange.topRange) ||
          "30",
        dropRateThreeRangeBottom:
          (dropRateRanges &&
            dropRateRanges.dropRateThreeRange &&
            dropRateRanges.dropRateThreeRange.bottomRange) ||
          "30"
      };

      let dropRateOneHeader = "DR1 %";
      let dropRateTwoHeader = "DR2 %";
      let dropRateThreeHeader = "DR3 %";

      if (dropRateIsEnabled) {
        dropRateOneHeader = `DR1 % (GTL ${dropRateValues.dropRateOneRangeBottom}% - ${dropRateValues.dropRateOneRangeTop}%)`;
        dropRateTwoHeader = `DR2 % (GTL ${dropRateValues.dropRateTwoRangeBottom}% - ${dropRateValues.dropRateTwoRangeTop}%)`;
        dropRateThreeHeader = `DR3 % (GTL ${dropRateValues.dropRateThreeRangeBottom}%+)`;
      }

      const columnStyle = { width: "10%", textAlign: "center" };
      return [
        {
          field: "dropRate1Percentage",
          header: dropRateOneHeader,
          style: columnStyle,
          defaultInputEditorData: getDefaultInputEditorData()
        },
        {
          field: "dropRate2Percentage",
          header: dropRateTwoHeader,
          style: columnStyle,
          defaultInputEditorData: getDefaultInputEditorData()
        },
        {
          field: "dropRate3Percentage",
          header: dropRateThreeHeader,
          style: columnStyle,
          defaultInputEditorData: getDefaultInputEditorData()
        }
      ];
    };

    const getColumns = function (): any[] {
      let columns = [] as ColumnDefinition<
        DefaultsAdminGtlAndLtlFormDefaults,
        keyof DefaultsAdminGtlAndLtlFormDefaults
      >[];

      const propertyColumnStyle = { width: "20%" };
      const bedroomTypeColumnStyle = { width: "15%" };
      const calcMethodColumnStyle = { width: "200px" };

      if (!props.hidePropertyColumn) {
        columns.push({
          header: "Property",
          style: propertyColumnStyle,
          body: getPropertyDefaultsBody
        });
      } else {
        columns.push({ header: "", style: propertyColumnStyle });
      }

      if (!props.hideBedroomTypeColumn) {
        columns.push({
          header: "Bedroom",
          style: bedroomTypeColumnStyle,
          body: getBedroomDefaultsBody
        });
      } else {
        columns.push({ header: "", style: bedroomTypeColumnStyle });
      }

      const columnStyle = { width: "7.5%", textAlign: "center" };
      columns = columns.concat([
        {
          header: "Calculation Method",
          style: calcMethodColumnStyle,
          body: getCalculationMethodDefaultsBody,
          hidden: !(props.dropRateDefaults && props.dropRateDefaults.isEnabled),
          headerStyle: { width: "200px", textAlign: "center" }
        },
        {
          field: "ltlRecapPercentage",
          header: "LTL Recap %",
          style: columnStyle,
          defaultInputEditorData: getDefaultInputEditorData()
        },
        {
          field: "minPercentage",
          header: "Min %",
          style: columnStyle,
          defaultInputEditorData: getDefaultInputEditorData()
        },
        {
          field: "maxPercentage",
          header: "Max %",
          style: columnStyle,
          defaultInputEditorData: getDefaultInputEditorData()
        },
        {
          field: "netEffectiveMaxPercentage",
          header: "Net Eff Max %",
          style: columnStyle,
          defaultInputEditorData: getDefaultInputEditorData()
        },
        {
          field: "gtlRecapPercentage",
          header: "GTL Recap %",
          style: columnStyle,
          defaultInputEditorData: getDefaultInputEditorData()
        }
      ]);

      columns = columns.concat(getDropRateColumns());

      return columns;
    };

    const tableProps: DataTableProps<
      DefaultsAdminGtlAndLtlFormDefaults,
      TableState
    > = {
      value: props.defaults || undefined,
      editMode: "cell",
      state: table,
      updateState: setTable,
      paging: props.paging,
      // TODO: This functionality only works when added at this leve. I was unable to get it to work when I moved it up to the createDataTable function
      sortField: table.sortField,
      sortOrder: table.sortOrder,
      onSort: (e: any) => {
        //clear sort column
        if (
          table.sortOrder === -1 &&
          e.sortOrder === 1 &&
          table.sortField === e.sortField
        ) {
          setTable({
            ...table,
            sortField: undefined,
            sortOrder: undefined
          });
        } else {
          setTable({
            ...table,
            sortField: e.sortField,
            sortOrder: e.sortOrder
          });
        }
      },
      onAddRowAction: props.onAddRowAction,
      onRemoveRowAction:
        props.includeRemoveRowColumn === "show" ? onRemoveRow : undefined,
      hasRemoveRowBuffer: props.includeRemoveRowColumn === "buffer",
      emptyMessage: "No defaults have been added."
    };
    const dataTable = createDataTable<
      DefaultsAdminGtlAndLtlFormDefaults,
      TableState
    >(getColumns(), tableProps);

    return <div>{dataTable}</div>;
  };

export default DefaultsGtlAndLtlFormTable;
