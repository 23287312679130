import React from "react";
import {
  BaselineDefaultsIncentivesForm,
  BaselineDefaultsIncentivesFormDefaults,
  BaselineDefaultsIncentivesFormDivision,
  DefaultsPageConfiguration
} from "api";
import utils from "utils";
import listUtils from "utils/listUtils";
import Dropdown from "components/controls/Dropdown";
import Label from "components/controls/Label";
import PrimaryButton from "components/controls/PrimaryButton";
import Section from "components/controls/Section";
import Spacer from "components/controls/Spacer";
import Column from "components/controls/Column";
import ContentArea from "components/layouts/ContentArea";
import Heading from "components/controls/Heading";
import DefaultsIncentivesFormTable from "../DefaultsIncentivesFormTable";

interface PageState {
  form: BaselineDefaultsIncentivesForm | null | undefined;
}

interface DefaultsIncentivesFormProps {
  setForm: (form: BaselineDefaultsIncentivesForm) => any;
  form: BaselineDefaultsIncentivesForm;
  submit: () => void;
  isFetching: boolean;
  onAddRowAction?: (() => void) | undefined;
  hideDivisions?: boolean | undefined;
  hideSaveButton?: boolean | undefined;
  pageConfiguration: DefaultsPageConfiguration | undefined;
}

const DefaultsIncentivesForm: React.FC<DefaultsIncentivesFormProps> = props => {
  const cloneForm = function (): BaselineDefaultsIncentivesForm {
    return utils.cloneObject(props.form);
  };

  const setForm = function (form: BaselineDefaultsIncentivesForm) {
    props.setForm(form);
  };

  const onDivisionDefaultsChange = (
    defaults: BaselineDefaultsIncentivesFormDefaults[]
  ) => {
    onDivisionChanged({
      ...props.form.selectedDivision,
      defaults: defaults.length === 1 ? defaults[0] : undefined,
      isChanged: true
    });
  };

  const onBedroomsDefaultsChange = (
    defaults: BaselineDefaultsIncentivesFormDefaults[]
  ) => {
    onDivisionChanged({
      ...props.form.selectedDivision,
      bedroomDefaults: defaults,
      isChanged: true
    });
  };

  const onPropertiesDefaultsChange = (
    defaults: BaselineDefaultsIncentivesFormDefaults[]
  ) => {
    onDivisionChanged({
      ...props.form.selectedDivision,
      propertyDefaults: defaults,
      isChanged: true
    });
  };

  const addBedroomDefaultsRow = function (): void {
    const selectedDivision = props.form && props.form.selectedDivision;
    const newRow: BaselineDefaultsIncentivesFormDefaults = {
      ...(selectedDivision && selectedDivision.defaults),
      incentiveValue: "",
      formValidationErrors: undefined,
      fieldValidationErrors: undefined,
      isValid: false,
      isChanged: true
    };
    const currentRows =
      (selectedDivision && selectedDivision.bedroomDefaults) || [];

    onBedroomsDefaultsChange(currentRows.concat(newRow));
  };

  const addPropertyDefaultsRow = function (): void {
    const selectedDivision = props.form && props.form.selectedDivision;
    const newRow: BaselineDefaultsIncentivesFormDefaults = {
      ...(selectedDivision && selectedDivision.defaults),
      incentiveValue: "",
      formValidationErrors: undefined,
      fieldValidationErrors: undefined,
      isValid: false,
      isChanged: true
    };
    const currentRows =
      (selectedDivision && selectedDivision.propertyDefaults) || [];

    onPropertiesDefaultsChange(currentRows.concat(newRow));
  };

  const onDivisionChanged = (
    division: BaselineDefaultsIncentivesFormDivision | undefined
  ) => {
    const divisions = (props.form && props.form.divisions) || undefined;
    let newDivisionsList = divisions;

    if (divisions && division) {
      newDivisionsList =
        listUtils.copyListWithNewKeyedItem(divisions, division) || undefined;
    }

    const form = cloneForm();

    form.selectedDivision = division;
    form.divisions = newDivisionsList;

    setForm(form);
  };

  if (!props.form) {
    return <></>;
  }
  let selectedDivision = props.form.selectedDivision;
  let fieldValidationErrors = props.form.fieldValidationErrors;

  const getDivisionDropdownOrLabel = function (): JSX.Element {
    if (props.hideDivisions) {
      return <div />;
    }

    const divisions = props.form.divisions;
    const selectedDivision = props.form.selectedDivision;
    if (divisions && divisions.length > 1) {
      return (
        <Dropdown
          onChange={onDivisionChanged}
          hideClear={true}
          options={divisions}
          selectedValue={selectedDivision}
          validationMessage={
            fieldValidationErrors && fieldValidationErrors["SelectedDivision"]
          }
        />
      );
    } else if (selectedDivision) {
      return <Label label={selectedDivision.displayValue} />;
    } else {
      return <div />;
    }
  };

  const getSaveButton = function (): JSX.Element {
    if (props.hideSaveButton) {
      return <div />;
    }

    return (
      <PrimaryButton
        disabled={props.isFetching}
        onClick={() => props.submit()}
        title="SAVE"
      />
    );
  };

  const divisionsArea = getDivisionDropdownOrLabel();
  const saveButton = getSaveButton();
  const incentiveTypes =
    (props.form.selectedDivision &&
      props.form.selectedDivision.incentiveTypes) ||
    [];

  const bedroomTypes =
    (selectedDivision && selectedDivision.bedroomTypes) || [];

  const propertyTypes =
    (selectedDivision && selectedDivision.propertyTypes) || [];

  return (
    <div>
      <Section title="INCENTIVES" />

      <Column size="2">{divisionsArea}</Column>

      <Spacer size="l" orientation="v" />

      <Column size="7">
        <ContentArea columnSize={"12"}>
          <Heading level="3">Division Defaults</Heading>
          <DefaultsIncentivesFormTable
            defaults={
              (props.form.selectedDivision &&
                props.form.selectedDivision.defaults && [
                  props.form.selectedDivision.defaults
                ]) || [{}]
            }
            onChange={onDivisionDefaultsChange}
            hideRemoveRowColumn={
              !(
                props.form.pageConfiguration &&
                props.form.pageConfiguration.isEditEnabled
              )
            }
            paging={false}
            columnOptions="none"
            incentiveTypes={incentiveTypes}
            bedrooms={[]}
            properties={[]}
            includeRemoveRowColumn="buffer"
            pageConfiguration={props.pageConfiguration}
          />
        </ContentArea>

        <Spacer size="l" orientation="v" />
        <ContentArea columnSize={"12"}>
          <Heading level="3">By Bedroom</Heading>
          <DefaultsIncentivesFormTable
            defaults={
              (props.form.selectedDivision &&
                props.form.selectedDivision.bedroomDefaults) ||
              []
            }
            onChange={onBedroomsDefaultsChange}
            hideRemoveRowColumn={
              !(
                props.form.pageConfiguration &&
                props.form.pageConfiguration.isEditEnabled
              )
            }
            paging={false}
            columnOptions="bedroomOnly"
            incentiveTypes={incentiveTypes}
            bedrooms={bedroomTypes &&
              bedroomTypes.filter((bt) => { return bt.displayValue && bt.displayValue.toLowerCase() !== "all"; })}
            properties={propertyTypes}
            onAddRowAction={
              props.form.pageConfiguration &&
                props.form.pageConfiguration.isEditEnabled
                ? addBedroomDefaultsRow
                : props.onAddRowAction
            }
            includeRemoveRowColumn={
              props.form.pageConfiguration &&
                props.form.pageConfiguration.isEditEnabled
                ? "show"
                : undefined
            }
            pageConfiguration={props.pageConfiguration}
          />
        </ContentArea>

        <Spacer size="l" orientation="v" />

        <ContentArea columnSize={"12"}>
          <Heading level="3">By Property</Heading>
          <DefaultsIncentivesFormTable
            defaults={
              (props.form.selectedDivision &&
                props.form.selectedDivision.propertyDefaults) ||
              []
            }
            onChange={onPropertiesDefaultsChange}
            hideRemoveRowColumn={
              !(
                props.form.pageConfiguration &&
                props.form.pageConfiguration.isEditEnabled
              )
            }
            columnOptions="propertyOnly"
            incentiveTypes={incentiveTypes}
            bedrooms={bedroomTypes}
            properties={propertyTypes}
            onAddRowAction={
              props.form.pageConfiguration &&
                props.form.pageConfiguration.isEditEnabled
                ? addPropertyDefaultsRow
                : props.onAddRowAction
            }
            includeRemoveRowColumn={
              props.form.pageConfiguration &&
                props.form.pageConfiguration.isEditEnabled
                ? "show"
                : undefined
            }
            pageConfiguration={props.pageConfiguration}
          />
        </ContentArea>
      </Column>

      <Spacer size="l" orientation="v" />

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {saveButton}
      </div>
    </div>
  );
};

export default DefaultsIncentivesForm;
