import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  button: {
    display: "block",
    backgroundColor: theme.pallette.white,
    cursor: "pointer",
    borderColor: theme.pallette.transparent,
    borderWidth: [2, 0, 0, 0],
    borderStyle: "solid",
    textDecoration: "none",
    position: "relative",
    height: "auto",
    "&:hover": {
      "&:before": {
        backgroundSize: "100% 2px"
      }
    },
    "&:before": {
      content: '""',
      position: "absolute",
      top: "0",
      left: "0",
      zIndex: "0",
      width: "100%",
      height: "100%",
      backgroundImage: "linear-gradient(120deg,#c75109,#c75109)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "0 2px",
      backgroundPosition: "center bottom",
      transform: "translateY(.4rem)",
      transitionProperty: "transform,color,background-color,background-size",
      transitionDuration: "200ms",
      transitionTimingFunction: "ease-in-out"
    }
  },
  active: {
    "&:before": {
      backgroundSize: "100% 2px"
    }
  },
  title: {
    display: "inline",
    fontSize: theme.typeScale.medium,
    paddingTop: theme.marginScale.large + theme.marginScale.medium,
    fontWeight: "bold",
    color: theme.pallette.dark
  }
}));
