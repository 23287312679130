import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  root: {
    display: "flex",
    width: "100%",
    maxWidth: "100%",
    minWidth: "12em" // prime react dropdown default
  }
}));
