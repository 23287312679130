import React, { useEffect, useContext } from "react";
import { IdentityContext, Identity } from "components/IdentityProvider";

const SignOut: React.FC = function() {
  const identity: Identity = useContext(IdentityContext);

  useEffect(() => {
    identity.logoutAction && identity.logoutAction();
  }, [identity]);

  return <span>Signing out.</span>;
};

export default SignOut;
