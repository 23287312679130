import React from "react";
import { Route } from "react-router-dom";
import AdminUserPage from "components/forms/UserAdminPage";
import routes from "components/routes";
import UserAddPage from "components/forms/UserAddPage";
import UserEditPage from "components/forms/UserEditPage";

export default () => (
  <>
    <Route exact path={routes.admin.users} component={AdminUserPage} />
    <Route exact path={routes.admin.addUser} component={UserAddPage} />
    <Route exact path={routes.admin.editUser()} component={UserEditPage} />
  </>
);
