import React from "react";
import nameof from "nameof";
import { Dropdown as PrimeDropdown } from "primereact/dropdown";
import { withLabel } from "../Label";
import ValidationWrapper from "../ValidationWrapper";

export interface DropdownOption {
  key?: string;
  displayValue?: string;
}

export interface DropdownProps {
  placeholder?: string;
  selectedValue: DropdownOption | undefined;
  options: DropdownOption[] | undefined;
  onChange: (value: DropdownOption | undefined) => void;
  hideClear?: boolean | undefined;
  style?: object | undefined;
  validationMessage?: string | undefined;
}

const optionLabel = nameof<DropdownOption>("displayValue");

// BUG: See T-4249

const Dropdown: React.FC<DropdownProps> = function(props) {
  const selectedValue =
    (props &&
      props.options &&
      props.options.find(
        o => o.key === (props && props.selectedValue && props.selectedValue.key)
      )) ||
    undefined;

  return (
    <ValidationWrapper showErrorState={props.validationMessage != null}>
      <PrimeDropdown
        showClear={!props.hideClear}
        value={selectedValue}
        options={props.options}
        tooltip={props.validationMessage}
        tooltipOptions={{ event: "hover" }}
        optionLabel={optionLabel}
        onChange={e => props.onChange(e.value)}
        placeholder={`-- ${props.placeholder || "Any"} --`}
        style={props.style}
        appendTo={document.body}
      />
    </ValidationWrapper>
  );
};
export default withLabel(Dropdown);
