import React, { useState } from "react";
import { Sidebar } from "primereact/sidebar";
import ScrollPanel from "components/layouts/ScrollPanel";
import { BestRenewalOffersFormBatchComments } from "api";
import CommentList from "../CommentList";
import listUtils from "utils/listUtils";
import Spacer from "components/controls/Spacer";
import Label from "components/controls/Label";
import useStyle from "./useStyle";
import TabView from "components/layouts/TabView";
import TabPanel from "components/layouts/TabView/TabPanel";

export interface BatchCommentsSideBarProps {
  bestRenewalOffersKey: string;
  isVisible: boolean;
  batchComments: BestRenewalOffersFormBatchComments | undefined;
  onCancel: () => void;
  deletePropertyComment: (
    bestRenewalOffersKey: string,
    propertyId: string,
    commentId: number
  ) => void;
  deleteUnitComment: (
    bestRenewalOffersKey: string,
    unitKey: string,
    commentId: number
  ) => void;
}

enum ActiveTab {
  BatchComments = 0,
  PropertyComments = 1,
  UnitComments = 2
}

const BatchCommentsSideBar: React.FC<BatchCommentsSideBarProps> = function (
  props
) {
  const [state, setState] = useState<ActiveTab>(ActiveTab.BatchComments);

  const getNoCommentsArea = function () {
    return (
      <div>
        <div>No comments</div>
      </div>
    );
  };

  const getBatchCommentsCount = function (
    batchComments: BestRenewalOffersFormBatchComments
  ): number {
    return (
      (batchComments.approvalReasons && batchComments.approvalReasons.length) ||
      0
    );
  };

  const getPropertyCommentsCount = function (
    batchComments: BestRenewalOffersFormBatchComments
  ): number {
    const listsOfCommentLists =
      batchComments.propertyComments &&
      listUtils.filterNullsAndFlatten(
        batchComments.propertyComments.map((pc) => pc.comments)
      );

    return (listsOfCommentLists && listsOfCommentLists.length) || 0;
  };

  const getUnitCommentsCount = function (
    batchComments: BestRenewalOffersFormBatchComments
  ): number {
    const listsOfCommentLists =
      batchComments.unitCommentsByProperty &&
      listUtils.filterNullsAndFlatten(
        // flatten the internal list too.
        batchComments.unitCommentsByProperty.map(
          (ucbp) =>
            ucbp.unitComments &&
            listUtils.filterNullsAndFlatten(
              ucbp.unitComments.map((uc) => uc.comments)
            )
        )
      );

    return (listsOfCommentLists && listsOfCommentLists.length) || 0;
  };

  const getBatchCommentsTabContent = function (
    batchComments: BestRenewalOffersFormBatchComments
  ) {
    if (
      !batchComments.approvalReasons ||
      batchComments.approvalReasons.length <= 0
    ) {
      return getNoCommentsArea();
    }

    return (
      <ScrollPanel heightInViewportUnits={80}>
        <CommentList
          bestRenewalOffersKey={props.bestRenewalOffersKey}
          list={batchComments.approvalReasons}
          hideHeading={true}
        />
      </ScrollPanel>
    );
  };

  const getPropertyCommentsArea = function (
    batchComments: BestRenewalOffersFormBatchComments
  ) {
    if (
      !batchComments.propertyComments ||
      batchComments.propertyComments.length <= 0
    ) {
      return getNoCommentsArea();
    }

    const propertyComments: JSX.Element[] = listUtils.filterNulls(
      batchComments.propertyComments.map(
        (pc) =>
          pc.comments && (
            <div>
              <Label label={pc.name} className={classes.propertyLabel} />
              <CommentList
                bestRenewalOffersKey={props.bestRenewalOffersKey}
                list={pc.comments}
                hideHeading={true}
                deleteAction={props.deletePropertyComment}
              />
            </div>
          )
      )
    );

    return (
      <ScrollPanel heightInViewportUnits={80}>{propertyComments}</ScrollPanel>
    );
  };

  const getUnitCommentsArea = function (
    batchComments: BestRenewalOffersFormBatchComments
  ) {
    if (
      !batchComments.unitCommentsByProperty ||
      batchComments.unitCommentsByProperty.length <= 0
    ) {
      return getNoCommentsArea();
    }

    const unitComments: JSX.Element[] = listUtils.filterNulls(
      batchComments.unitCommentsByProperty.map((ucbp) => {
        if (!ucbp.unitComments || !ucbp.propertyName) {
          return <></>;
        }

        const propertyLabel = (
          <>
            <Label
              label={ucbp.propertyName}
              className={classes.propertyLabel}
            />
            <Spacer orientation="v" />
          </>
        );

        const commentsArea = listUtils.filterNulls(
          ucbp.unitComments.map((uc) =>
            uc.comments ? (
              <>
                <Label label={uc.unitName} className={classes.unitLabel} />
                <CommentList
                  bestRenewalOffersKey={props.bestRenewalOffersKey}
                  list={uc.comments}
                  hideHeading={true}
                  deleteAction={props.deleteUnitComment}
                />
              </>
            ) : null
          )
        );

        return (
          <div>
            {propertyLabel}
            {commentsArea}
          </div>
        );
      })
    );

    return <ScrollPanel heightInViewportUnits={80}>{unitComments}</ScrollPanel>;
  };

  const classes = useStyle(props);
  if (
    !props.batchComments ||
    !props.batchComments.totalCount ||
    props.batchComments.totalCount <= 0
  ) {
    return <div />;
  }

  const activeTabIndex = state as number;

  return (
    <Sidebar
      visible={props.isVisible}
      position="right"
      className="ui-sidebar-sm"
      onHide={() => props.onCancel()}
      style={{ width: "50em" }}
    >
      <div className={classes.sideBarContainer}>
        <TabView
          activeTabIndex={activeTabIndex}
          onTabIndexChange={(e) => setState(e)}
          className={classes.tabViewContainer}
        >
          <TabPanel
            header={`Batch (${getBatchCommentsCount(props.batchComments)})`}
          >
            <div className={classes.container}>
              {getBatchCommentsTabContent(props.batchComments)}
            </div>
          </TabPanel>
          <TabPanel
            header={`Property (${getPropertyCommentsCount(
              props.batchComments
            )})`}
          >
            <div className={classes.container}>
              {getPropertyCommentsArea(props.batchComments)}
            </div>
          </TabPanel>
          <TabPanel
            header={`Unit (${getUnitCommentsCount(props.batchComments)})`}
          >
            <div className={classes.container}>
              {getUnitCommentsArea(props.batchComments)}
            </div>
          </TabPanel>
        </TabView>
      </div>
    </Sidebar>
  );
};

export default BatchCommentsSideBar;
