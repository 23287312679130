import { Menu } from "primereact/menu";
import { Button } from "primereact/button";
import React from "react";
import useStyle from "./useStyle";
import cssUtils from "utils/cssUtils";

export interface ActionButtonProps {
  label: string;
  items: ActionItemProps[];
  alwaysShow?: boolean | undefined;
  isCondensed?: boolean | undefined;
  className?: string | undefined;
}

export interface ActionItemProps {
  label: string;
  command: () => void;
  onShow?(e: Event): void;
  onHide?(e: Event): void;
  className?: string | undefined;
  isActive?: boolean;
  disabled?: boolean;
}

const ActionButton: React.FC<ActionButtonProps> = function(props) {
  const classes = useStyle(props);
  let menuElement: Menu | null = null;

  if (!props.items || props.items.length == null || props.items.length <= 0) {
    return <div />;
  }

  for (const item of props.items) {
    const activeClass = item.isActive ? classes.active : undefined;
    item.className = cssUtils.join(activeClass, item.className);
    item.disabled = item.disabled || item.isActive;
  }

  return (
    <div className={props.className}>
      <Menu
        model={props.items}
        className={classes.menu}
        popup={!props.alwaysShow}
        ref={el => (menuElement = el)}
      />
      <Button
        label={props.label}
        icon="pi pi-angle-down"
        iconPos="right"
        onClick={e => menuElement && menuElement.toggle(e)}
        className={classes.button}
      />
    </div>
  );
};

ActionButton.defaultProps = { isCondensed: true };

export default ActionButton;
