import React from "react";
import useStyle from "./useStyle";
import { Link } from "react-router-dom";
import cssUtils from "utils/cssUtils";

export interface MenuProps {
  title: string;
  to: string;
  isActive?: boolean;
}

const MenuButton: React.FC<MenuProps> = function(props) {
  var classes = useStyle();

  return (
    <Link
      to={props.to}
      className={cssUtils.join(
        classes.button,
        cssUtils.conditionClass(classes.active, props.isActive)
      )}
    >
      <div className={classes.title}>{props.title}</div>
    </Link>
  );
};

export default MenuButton;
