import React from "react";
import useStyle from "./useStyle";

export interface SpacerProps {
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
  orientation?: "h" | "v";
}

const Spacer: React.FC<SpacerProps> = function(props) {
  const classes = useStyle(props);
  return <span className={classes.spacer} />;
};
Spacer.defaultProps = {
  size: "m",
  orientation: "h"
};

export default Spacer;
