import { createThemedUseStyles } from "theme";
import { PrimaryButtonProps } from ".";

export default createThemedUseStyles(theme => ({
  button: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: theme.sizeScale.large,
    fontSize: theme.typeScale.medium,
    padding: "12px 14px",
    width: "auto",
    cursor: "pointer",
    textAlign: "center",
    minHeight: "43px",
    border: "none",
    fontWeight: "700",
    letterSpacing: "normal",
    textDecoration: "none",
    transition: "background-color, transform .2s ease-in-out",
    borderRadius: "2px",
    backgroundColor: (props: PrimaryButtonProps) =>
      props.disabled
        ? theme.pallette.inactive
        : theme.buttons.primary.background,
    "&:hover": {
      backgroundColor: (props: PrimaryButtonProps) =>
        props.disabled
          ? theme.pallette.inactive
          : theme.buttons.primary.backgroundFocus,
      transform: "scale(1.01) perspective(1px)",
      color: theme.buttons.primary.foreground
    },
    color: theme.buttons.primary.foreground
  },
  title: {}
}));
