import {
  ErrPolicy,
  RetryInterval,
  ResponseHandlers,
  AllResponseHanderKeys
} from "./types";
import { ApiException } from "api";

type ResponseCodes = 400 | 401 | 404 | 500;
type ErrorCodes = 300 | 400 | 401 | 404 | 500;

export type GenericResponseType = "_";

const responseHandlers = createResponseHandlers<
  ResponseCodes | GenericResponseType
>();
export { responseHandlers };

const retryPolicy = createErrorPolicy<ErrorCodes>(
  {
    "300": ex => false,
    "500": ex => 1000
  },
  (ex, c) => {
    // TODO: Re-enable retry logic, if needed.
    // if (c < 5) return 1000;
    return false;
  }
);
export { retryPolicy };

function createErrorPolicy<E extends number>(
  policy: ErrPolicy<E>,
  fallback: (ex: unknown, retryCount: number) => RetryInterval
) {
  function handler(
    ex: unknown,
    retryCount: number,
    p: ErrPolicy<E>
  ): RetryInterval {
    if (ApiException.isApiException(ex)) {
      const key = ex.status + "";
      if (key in p) {
        return (p as any)[key](ex, retryCount) as RetryInterval;
      }
    } else {
      return fallback(ex, retryCount);
    }
    return false;
  }

  const result = (ex: unknown, retryCount: number) =>
    handler(ex, retryCount, policy);
  result.extend = (update: ErrPolicy<E>) => {
    return (ex: unknown, retryCount: number) =>
      handler(ex, retryCount, { ...policy, ...update });
  };

  result.replace = (update: ErrPolicy<E>) => {
    return (ex: unknown, retryCount: number) => handler(ex, retryCount, update);
  };

  return result;
}

function createResponseHandlers<E extends AllResponseHanderKeys>() {
  return {
    use: function(handlers: ResponseHandlers<E>) {
      return function handle(ex: ApiException) {
        const key = ex.status + "";
        const catchAllKey = "_";
        if (key in handlers) {
          return (handlers as any)[key](ex);
        } else if (catchAllKey in handlers) {
          return (handlers as any)[catchAllKey](ex);
        }
      };
    }
  };
}
