import React from "react";
import useStyle from "./useStyle";
import Spacer from "components/controls/Spacer";
import { GenericFormComment } from "../GenericFormCommentTypes";
import Label from "components/controls/Label";
import Comment from "../Comment";

interface GenericFormCommentProps {
  comment: GenericFormComment;
}

interface GenericFormCommentListProps {
  bestRenewalOffersKey: string;
  list: GenericFormComment[];
  hideHeading?: boolean;
  deleteAction?: (
    bestRenewalOffersKey: string,
    unitKey: string,
    commentId: number
  ) => void;
}

const CommentList: React.FC<GenericFormCommentListProps> = (props) => {
  const classes = useStyle();
  let heading = (
    <div>
      <Label
        label={`Comments (${props.list.length})`}
        className={classes.commentLabel}
      />
      <hr />
      <Spacer orientation="v" size="xl" />
    </div>
  );

  if (props.hideHeading) {
    heading = <div />;
  }

  return (
    <div>
      {heading}
      <div className={classes.commentListContainer}>
        {props.list.map((item: GenericFormComment, index: number) => (
          <Comment
            bestRenewalOffersKey={props.bestRenewalOffersKey}
            key={index}
            comment={{ ...item }}
            deleteAction={props.deleteAction}
          />
        ))}
      </div>
    </div>
  );
};

export default CommentList;
