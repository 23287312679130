import React from "react";
import { SelectButton as PrimeReactSelectButton } from "primereact/selectbutton";
import listUtils from "utils/listUtils";
import { withLabel } from "../Label";

export interface SelectButtonOption<T> {
  value?: T;
  label?: string;
}

export interface SelectButtonProps<T> {
  selectedValue: SelectButtonOption<T>;
  options: SelectButtonOption<T>[] | [];
  onChange: (value: SelectButtonOption<T>) => void;
}

const SelectButton: React.FC<SelectButtonProps<any>> = function<T>(
  props: SelectButtonProps<T>
) {
  return (
    <PrimeReactSelectButton
      value={props.selectedValue.value}
      options={props.options}
      onChange={e => {
        if (props.options.length <= 0) {
          return;
        }

        let selectButtonOption = listUtils.firstOrDefault(
          props.options,
          o => o.value === e.value
        );

        if (selectButtonOption) {
          props.onChange(selectButtonOption as SelectButtonOption<T>);
        }
      }}
    />
  );
};

export default withLabel(SelectButton);
