import React from "react";
import { withLabel } from "../Label";

interface ImageProps {
  assetName: string;
  alt?: string;
  className?: string | undefined;
}
const Image: React.FC<ImageProps> = props => {
  return (
    <img
      className={props.className}
      src={props.assetName}
      alt={props.alt}
    ></img>
  );
};

export default withLabel(Image);
