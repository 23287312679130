import React, { useState } from "react";
import { Option, ActualResultsSummaryFormRenewalStatusRollup } from "api";
import createDataTable, {
  IPagedTableState,
  ColumnDefinition,
  DataTableProps
} from "components/controls/createTable";

export interface ActualResultsSummaryTableProps {
  rollups: ActualResultsSummaryFormRenewalStatusRollup[];
  totalsRow: ActualResultsSummaryFormRenewalStatusRollup;
  batchKey: string;
}

interface TableState extends IPagedTableState {
  divisionFilter?: Option;
  sortField?: any;
  sortOrder?: any;
  sortState?: "unsorted" | "asc" | "desc" | undefined;
}

const ActualResultsSummaryRenewalStatusTable: React.FC<ActualResultsSummaryTableProps> = function (
  props
) {
  const [table, setTable] = useState<TableState>({
    offset: 0,
    limit: 10,
    sortOrder: 1
  });

  const getColumns = function (): any[] {
    let columns = [] as ColumnDefinition<
      ActualResultsSummaryFormRenewalStatusRollup,
      keyof ActualResultsSummaryFormRenewalStatusRollup
    >[];

    columns = columns.concat([
      {
        field: "status",
        header: "",
        style: { width: "10%", textAlign: "center" }
      },
      {
        field: "count",
        header: "Count",
        style: { width: "10%", textAlign: "center" }
      },
      {
        field: "capturePercentage",
        header: "Capture %",
        style: { width: "10%", textAlign: "center" }
      },
      {
        field: "averageDaysToFinalize",
        header: "Avg Days\rto Finalize",
        style: { width: "10%", textAlign: "center" }
      },
      {
        field: "percentageFinalizedWithinGoal",
        header: "% Finalized\rwithin goal",
        style: { width: "10%", textAlign: "center" }
      },
      {
        field: "ltlGtlMarketRentDollars",
        header: "LTL/GTL $",
        style: { width: "10%", textAlign: "center" }
      },
      {
        field: "ltlGtlMarketRentPercentage",
        header: "LTL/GTL %",
        style: { width: "10%", textAlign: "center" }
      },
      {
        field: "rentIncreaseOfferDollars",
        header: "Rent Increase $\r(Offer)",
        style: { width: "10%", textAlign: "center" }
      },
      {
        field: "rentIncreaseOfferPercentage",
        header: "Rent Increase %\r(Offer)",
        style: { width: "10%", textAlign: "center" }
      },
      {
        field: "rentIncreaseActualDollars",
        header: "Rent Increase $\r(Actual)",
        style: { width: "10%", textAlign: "center" }
      },
      {
        field: "rentIncreaseActualPercentage",
        header: "Rent Increase %\r(Actual)",
        style: { width: "10%", textAlign: "center" }
      },
      {
        field: "deviationFromOffer",
        header: "Deviation from\rOffer",
        style: { width: "10%", textAlign: "center" }
      },
      {
        field: "deviationCount",
        header: "Deviation Count",
        style: { width: "10%", textAlign: "center" }
      }
    ]);

    return columns;
  };

  const rollups = props.rollups || [];

  const tableProps: DataTableProps<
    ActualResultsSummaryFormRenewalStatusRollup,
    TableState
  > = {
    value: rollups,
    state: table,
    updateState: setTable,
    paging: false,
    // TODO: This functionality only works when added at this leve. I was unable to get it to work when I moved it up to the createDataTable function
    sortField: table.sortField,
    sortOrder: table.sortOrder,
    frozenValue: [props.totalsRow],
    scrollable: true,
    scrollHeight: "400px",
    onSort: (e: any) => {
      //clear sort column
      if (
        table.sortOrder === -1 &&
        e.sortOrder === 1 &&
        table.sortField === e.sortField
      ) {
        setTable({
          ...table,
          sortField: undefined,
          sortOrder: undefined
        });
      } else {
        setTable({
          ...table,
          sortField: e.sortField,
          sortOrder: e.sortOrder
        });
      }
    },
    emptyMessage: "No results."
  };

  const dataTable = createDataTable(getColumns(), tableProps);

  return <div>{dataTable}</div>;
};

export default ActualResultsSummaryRenewalStatusTable;
