import routes, { BatchDefaultsEditMode } from "components/routes";
import locationUtils from "utils/locationUtils";

interface MenuItem {
  route: string;
  title: string;
  isActive: boolean;
}

const getBatchRegulationMenuItems = function (
  batchKey: string,
  mode: BatchDefaultsEditMode
): MenuItem[] {
  const bmrRoute = routes.batchregulations.bmr(batchKey, mode);
  const rentControlRoute = routes.batchregulations.rentControl(batchKey, mode);
  const regulationsRoute = routes.batchregulations.ordinances(batchKey, mode);

  return [
    {
      title: "BMR",
      route: bmrRoute,
      isActive: locationUtils.isRouteCurrentlyActive(bmrRoute)
    },
    {
      title: "Ordinances",
      route: regulationsRoute,
      isActive: locationUtils.isRouteCurrentlyActive(regulationsRoute)
    },
    {
      title: "Rent Control",
      route: rentControlRoute,
      isActive: locationUtils.isRouteCurrentlyActive(rentControlRoute)
    }
  ];
};

export default getBatchRegulationMenuItems;
