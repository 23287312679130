export enum AtlasActionTypes {
  BestRenewalOffersPageResetState,
  BestRenewalOffersPageSetInitialForm,
  BestRenewalOffersPageShowFormUpdateSuccessful,
  BestRenewalOffersPageUpdateForm,
  BestRenewalOffersPageUpdateFormFilters,
  BestRenewalOffersPageUpdateOffer,
  BestRenewalOffersPageUpdateOffers,
  BestRenewalOffersPageUpdateOffersForProperty,
  BestRenewalOffersPageUpdateOffersForBatch,
  BestRenewalOffersPageUpdateColumnConfigurations,
  BestRenewalOffersPageAddPropertyComments,
  BestRenewalOffersPageAddUnitComment,
  BestRenewalOffersPageDeletePropertyComment,
  BestRenewalOffersPageDeleteUnitComment,
  BestRenewalOffersPageUpdateAppliedFilters,
  BestRenewalOffersPageUpdateExceptionFilter,
  BestRenewalOffersPageUpdateRejectionDialogVisibility,
  BestRenewalOffersPageUpdateDeletionDialogVisibility,
  BestRenewalOffersPageUpdateApprovalDialogVisibility,
  BestRenewalOffersPageUpdateRejectionReason,
  BestRenewalOffersPageDownloadToCsv,
  BestRenewalOffersPageUpdateActiveTab,
  BestRenewalOffersPageUpdatePropertySummaryTablePagingState,

  ReviewSummaryPageResetState,
  ReviewSummaryPageSetInitialForm,
  ReviewSummaryPageApplyFiltersAndUpdateForm,
  ReviewSummaryPageUpdateForm,
  ReviewSummaryPageUpdateFilters,
  ReviewSummaryPageAddPropertyComments,
  ReviewSummaryPageAddUnitComment,
  ReviewSummaryPageDeletePropertyComment,
  ReviewSummaryPageDeleteUnitComment,
  ReviewSummaryPageUpdateActiveTab,
  ReviewSummaryPageUpdateRollupSelection,
  ReviewSummaryPageConfirmApprovalDialog,
  ReviewSummaryPageUpdateRejectionReason,
  ReviewSummaryPageConfirmRejectionDialog,

  RenewalFollowUpPageSetInitialForm,
  RenewalFollowUpPageUpdateForm,
  RenewalFollowUpPageShowFormUpdateSuccessful,

  ActualResultsSummaryPageSetInitialForm,
  ActualResultsSummaryPageUpdateForm,
  ActualResultsSummaryPageShowFormUpdateSuccessful,

  SidebarOpenUnitNotes,
  SidebarOpenPropertyComments,
  SidebarOpenBatchComments,
  SidebarOpenColumnSelector,
  SidebarCloseSideBars,

  UpdateTablePagingState,

  UpdateLoadingState,
  ShowErrorState,
  ShowValidationErrorState
}
