import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
  splitContent: {
    display: "flex",
    justifyContent: "space-between"
  },
  bestRenewalOffersTableContainer: {
    display: "flex",
    width: "100%"
  },
  bestRenewalOffersTableFiltersContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  bestRenewalOffersTableDownloadAndColumns: {
    display: "flex",
    alignItems: "center"
  },
  bestRenewalOffersTableFiltering: {
    display: "flex",
    alignItems: "center"
  },
  bestRenewalOffersTable: {
    display: "flex",
    minWidth: "1280px"
  },
  bestRenewalOffersTableWrapper: {
    maxWidth: "100%",
    overflowY: "visible",
    overflowX: "auto",
    // Note: this is a hack to give enough room for the paging dropdown to overflow correctly.
    // CSS does not allow you to specify overflow visible y and auto x at the same time.
    // Adding arbitrary amount of padding larger than content that is removed by margin to fix this issue.
    paddingBottom: "300px",
    marginBottom: "-300px"
  },
  headerMetaDataLeft: {
    width: "80%"
  },
  headerMetaDataRight: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    color: theme.pallette.softRed,
    width: "20%"
  },
  headerButton: {
    display: "flex"
  },
  propertyNotesSection: {
    display: "flex",
    justifyContent: "start"
  },
  saveCancelSection: {
    display: "flex",
    justifyContent: "start"
  },
  submitSection: {
    display: "flex",
    justifyContent: "end"
  },
  propertyNotes: {
    width: "35px",
    height: "35px"
  },
  batchNotes: {
    width: "50px",
    height: "35px",
    whiteSpace: "nowrap"
  },
  notesArea: {
    width: "100%",
    height: "150px"
  },
  columnSelectionSidePanelRoot: {
    padding: "20px 40px 0 40px",
    height: "100%"
  },
  columnSelectionSidePanelContent: {
    width: "120%",
    height: "100%"
  },
  formErrors: {
    fontFamily: theme.fontFamilyEmphasis,
    color: theme.pallette.error
  },
  notesButton: {
    cursor: "pointer",
    display: "flex",
    border: "none",
    background: "none",
    padding: "none",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%"
  },
  notesButtonImage: {
    minWidth: "30px",
    padding: "5px"
  },
  notesButtonLabel: {
    padding: "10px"
  },
  approvalArea: {
    display: "flex",
    justifyContent: "space-between"
  },
  approvalButton: {
    margin: "0 5px"
  },
  tableContentAreaContainer: {
    padding: "0 30px"
  },
  tablePageSection: {
    maxWidth: "1600px",
    margin: "auto"
  },
  hidden: { display: "none" },
  filterDropdown: {
    padding: "0 20px"
  },
  filterDropdownArea: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  applyButtonWrapper: {
    padding: "5px",
    paddingTop: "25px"
  },
  applyButton: { padding: "5px" }
}));
