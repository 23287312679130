import React from "react";
import { ScrollPanel as PrimeScrollPanel } from "primereact/scrollpanel";

export interface ScrollPanelProps {
  heightInPixels?: number | undefined;
  heightInViewportUnits?: number | undefined;
  className?: string | undefined;
}

type Style = {
  width?: string | undefined;
  height?: string | undefined;
};

const ScrollPanel: React.FC<ScrollPanelProps> = function(props) {
  let style: Style = { width: "100%", height: "100%" };
  if (props.heightInPixels) {
    style = { ...style, height: `${props.heightInPixels}px` };
  }

  if (props.heightInViewportUnits) {
    style = { ...style, height: `${props.heightInViewportUnits}vh` };
  }

  return (
    <PrimeScrollPanel className={props.className} style={style}>
      {props.children}
    </PrimeScrollPanel>
  );
};

export default ScrollPanel;
