import { createThemedUseStyles } from "theme";
// import { UnitNotesDisplayState } from ".";

export default createThemedUseStyles(theme => ({
  unitNotesImage: {},
  unitNotesColumn: {
    width: "50px"
  },
  unitNotesColumnBody: {
    display: "flex",
    justifyContent: "center"
  },
  tableContainer: {
    padding: "10px 15px"
  }
}));
