import React, { useState } from "react";
import Spinner from "../components/controls/Spinner";
import ConfigClient, {
  Config,
  DefaultConfigConstant
} from "../components/ConfigClient";
import useMountEffect from "utils/useMountEffect";

export const AtlasConfigContext = React.createContext<Config>(
  DefaultConfigConstant
);
interface AtlasConfigProviderProps {
  children: any | any[];
}

const AtlasConfigProvider: React.FC<AtlasConfigProviderProps> = function(
  props: AtlasConfigProviderProps
) {
  const [config, setConfig] = useState<Config>({} as Config);

  useMountEffect(() => {
    async function retrieveConfig() {
      const configClient = new ConfigClient();
      const config = await configClient.getConfig();

      setConfig({ ...config, isLoaded: true });
    }

    if (!config.isLoaded) {
      retrieveConfig();
    }
  });

  if (!config.isLoaded) {
    return <Spinner isVisible={true} />;
  }

  return (
    <AtlasConfigContext.Provider value={config}>
      {props.children}
    </AtlasConfigContext.Provider>
  );
};

export default AtlasConfigProvider;
