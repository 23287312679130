import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  background: {
    backgroundColor: theme.pallette.blue
  },
  outerContainer: {
    display: "flex",
    justifyContent: "center",
    backgroundSize: "cover",
    background: "rgba(0,0,0,0.5)"
  },
  container: {
    maxWidth: theme.maxScreenWidth,
    width: "100%"
  },
  titleBar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: [[theme.marginScale.large, theme.marginScale.none]],
    color: theme.pallette.foregroundContrast
  },
  title: {
    fontFamily: theme.fontFamilyEmphasis,
    marginRight: theme.marginScale.xxlarge,
    whiteSpace: "nowrap"
  },
  items: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%"
  },
  item: {
    marginRight: theme.marginScale.xlarge,
    fontSize: theme.typeScale.small,
    textDecoration: "none",
    color: theme.pallette.lightGray,

    "&:visited": {
      color: theme.pallette.lightGray
    },
    "&:hover": {
      color: theme.pallette.foregroundContrast,
      textDecoration: "underline"
    },
    "&:active": {
      color: theme.pallette.foregroundContrast
    }
  }
}));
