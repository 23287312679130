import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  columns: {
    display: "flex",
    flexDirection: "row"
  },
  controlRow: {
    display: "flex",
    flexDirection: "row"
  }
}));
