import { AsyncFunction, PromiseType } from "./types";
import { useEffect, useState } from "react";
import {
  RemoteDataState,
  RetryInterval,
  RemoteDataResponse,
  RemoteDataType,
  RemoteStateOptions,
  FetchRemoteData
} from "./types";
import { retryPolicy } from "./policy";

export * from "./policy";
export * from "./types";

interface InternalRemoteDataState<T> extends RemoteDataState<T> {
  nonce: number;
  prevNonce: number;
  overrides: [];
}

const makeRemoteRequest = async function<
  T extends AsyncFunction<RemoteDataResponse>,
  R extends PromiseType<ReturnType<T>>
>(
  endpoint: T,
  parameters: Parameters<T>,
  overrides: [] = []
): Promise<RemoteDataResponse<RemoteDataType<T> | null | undefined>> {
  return new Promise(async function(resolve, reject) {
    try {
      const args = [...parameters];
      if (overrides != null) {
        for (const key in overrides) {
          if (overrides[key] !== undefined) {
            args[key] = overrides[key];
          }
        }
      }

      const result = await endpoint(...args);
      const r = result as R;
      resolve(r);
    } catch (ex) {
      reject(ex);
    }
  });
};

const useRemoteData = function<
  T extends AsyncFunction<RemoteDataResponse>,
  R extends PromiseType<ReturnType<T>>,
  D extends RemoteDataType<T>
>(
  endpoint: T,
  parameters: Parameters<T>,
  options: RemoteStateOptions<R, D>
): [RemoteDataState<R>, FetchRemoteData<T>] {
  const [state, setState] = useState<InternalRemoteDataState<R>>({
    isFetching: false,
    nonce: 0,
    prevNonce: 0,
    retryCount: 0,
    isSuccessful: false,
    isCanceled: false,
    isFailed: false,
    overrides: []
  });

  useEffect(() => {
    function execute() {
      makeRemoteRequest(endpoint, parameters, state.overrides)
        .then(result => {
          const r = result as R;
          setState(s => ({
            ...s,
            result: r,
            isFetching: false,
            isSuccessful: true,
            isFailed: false,
            isCanceled: false,
            retryCount: 0,
            overrides: []
          }));
          let data = result.data;
          if (options.onSuccess != null) {
            data = options.onSuccess(r);
          }
          if (options.onChange != null) {
            options.onChange(data as D);
          }
        })
        .catch(ex => {
          let retryInterval: RetryInterval = false;
          retryInterval =
            options.onFail == null
              ? retryPolicy(ex, state.retryCount)
              : options.onFail(ex, state.retryCount);
          if (retryInterval === false) {
            setState(s => ({
              ...s,
              error: ex,
              isFetching: false,
              isSuccessful: false,
              isFailed: true,
              retryCount: 0,
              overrides: []
            }));
            if (options.onError != null) {
              options.onError(ex);
            }
          } else {
            setTimeout(() => {
              setState(s => ({
                ...s,
                nonce: s.nonce + 1,
                retryCount: s.retryCount + 1
              }));
            });
          }
        });
    }
    if (state.nonce > state.prevNonce || options.autoFetch === true) {
      setState(s => ({ ...s, prevNonce: s.nonce, isFetching: true }));
      execute();
    }
    return () => {
      setState(s => ({ ...s, isCanceled: true }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.nonce, ...parameters]);

  const fetch: FetchRemoteData<T> = (args: any) => {
    setState(s => ({
      ...s,
      nonce: s.nonce + 1,
      overrides: args
    }));
  };

  return [state, fetch];
};
export { useRemoteData, makeRemoteRequest };
