import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
  filterDropdown: {
    padding: "0 20px"
  },
  filterDropdownArea: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  applyButtonWrapper: {
    padding: "20px"
  },
  applyButton: {
    padding: "5px"
  },
  unappliedFiltersMessage: {
    color: "red",
    lineHeight: "24px",
    padding: "5px"
  },
  splitContent: {
    display: "flex",
    justifyContent: "space-between"
  },
  approvalArea: {
    display: "flex",
    justifyContent: "space-between",
    width: "21%"
  },
  approvalButton: {
    margin: "0 5px"
  },
  bottomArea: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%"
  },
  headerMetaData: {
    width: "100%"
  },
  batchNotes: {
    whiteSpace: "nowrap"
  }
}));
