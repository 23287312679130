import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  commentsButton: {
    cursor: "pointer",
    display: "flex",
    border: "none",
    background: "none",
    padding: "none",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%"
  },
  commentsButtonImage: {
    minWidth: "30px",
    padding: "5px"
  },
  commentsButtonLabel: {
    padding: "10px"
  },
  commentsButtonIsHighlighted: {
    color: theme.pallette.primary
  },
  cancelButton: {
    marginRight: "18px"
  },
  buttonsArea: {
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: "60px"
  },
  commentsArea: {
    width: "100%",
    height: "150px"
  }
}));
