import { useReducer, useContext } from "react";
import { Identity, IdentityContext } from "components/IdentityProvider";
import { useHistory } from "react-router";
import { IPagedTableState } from "components/controls/createTable";
import { AtlasActionTypes } from "./AtlasActionTypes";
import {
  SideBarState,
  AtlasState,
  getMutableCopyOfAtlasState,
  ExceptionFilterEnum,
  ExceptionFilterOption
} from "./AtlasStateTypes";
import { AtlasActions } from "./AtlasActions";
import SidebarReducer from "./Sidebar/SidebarReducer";
import { BestRenewalOffersPageActionMethods } from "./BestRenewalOffersPage/BestRenewalOffersPageActionMethods";
import { MessagesActionMethods } from "./Messages/MessagesActionMethods";
import { SidebarActionMethods } from "./Sidebar/SidebarActionMethods";
import BestRenewalOffersPageReducer from "./BestRenewalOffersPage/BestRenewalOffersPageReducer";
import MessagesReducer from "./Messages/MessagesReducer";
import { ReviewSummaryPageActionMethods } from "./ReviewSummaryPage/ReviewSummaryPageActionMethods";
import RenewalFollowUpPageReducer from "./RenewalFollowUpPage/RenewalFollowUpPageReducer";
import ActualResultsSummaryPageReducer from "./ActualResultsSummaryPage/ActualResultsSummaryPageReducer";
import ReviewSummaryPageReducer from "./ReviewSummaryPage/ReviewSummaryPageReducer";
import { RenewalFollowUpPageActionMethods } from "./RenewalFollowUpPage/RenewalFollowUpPageActionMethods";
import { ActualResultsSummaryPageActionMethods } from "./ActualResultsSummaryPage/ActualResultsSummaryPageActionMethods";

const initialSidebarState: SideBarState = {
  unitNotesDisplayState: { isVisible: false },
  propertyNotesDisplayState: { isVisible: false },
  batchNotesDisplayState: { isVisible: false },
  columnSelectorDisplayState: { isVisible: false, columnConfigurations: [] }
};

export const initialPagingState = {
  offset: 0,
  limit: 10,
  scrollable: true
};

const exceptionFilters: ExceptionFilterOption[] = [
  {
    type: ExceptionFilterEnum.Unit,
    name: "Unit Exceptions"
  },
  {
    type: ExceptionFilterEnum.All,
    name: "All Units"
  }
];

export const initialAtlasState: AtlasState = {
  bestRenewalOffersPageState: {
    form: {},
    filteredOffers: [],
    exceptionFilterOptions: exceptionFilters,
    selectedExceptionFilter: exceptionFilters[0].type,
    hasUnappliedFilters: false,
    rejectionDialogIsVisible: false,
    deletionDialogIsVisible: false,
    approvalDialogIsVisible: false,
    propertySummaryTableState: {
      pagingState: { ...initialPagingState },
      appliedFilters: [],
      expandedRows: []
    },
    propertyTotalCount: 0,
    offerTotalCount: 0,
    propertyExceptionCount: 0,
    unitExceptionCount: 0
  },
  reviewSummaryPageState: {
    form: {},
    hasUnappliedFilters: false,
    confirmApprovalDialogIsVisible: false,
    confirmRejectionDialogIsVisible: false
  },
  renewalFollowUpPageState: {
    form: {}
  },
  actualResultsSummaryPageState: {
    form: {}
  },
  sideBarState: initialSidebarState,
  messageState: { messages: [] },
  loadingState: { isLoading: false },
  tableState: {
    appliedFilters: [],
    pagingState: { ...initialPagingState }
  }
};

const atlasReducer = (
  oldState: AtlasState,
  action: AtlasActions
): AtlasState => {
  const newState = getMutableCopyOfAtlasState(oldState);

  switch (action.type) {
    case AtlasActionTypes.BestRenewalOffersPageResetState:
    case AtlasActionTypes.BestRenewalOffersPageSetInitialForm:
    case AtlasActionTypes.BestRenewalOffersPageShowFormUpdateSuccessful:
    case AtlasActionTypes.BestRenewalOffersPageUpdateForm:
    case AtlasActionTypes.BestRenewalOffersPageUpdateColumnConfigurations:
    case AtlasActionTypes.BestRenewalOffersPageUpdateOffer:
    case AtlasActionTypes.BestRenewalOffersPageUpdateOffers:
    case AtlasActionTypes.BestRenewalOffersPageUpdateOffersForProperty:
    case AtlasActionTypes.BestRenewalOffersPageUpdateOffersForBatch:
    case AtlasActionTypes.BestRenewalOffersPageAddPropertyComments:
    case AtlasActionTypes.BestRenewalOffersPageAddUnitComment:
    case AtlasActionTypes.BestRenewalOffersPageDeletePropertyComment:
    case AtlasActionTypes.BestRenewalOffersPageDeleteUnitComment:
    case AtlasActionTypes.BestRenewalOffersPageUpdateAppliedFilters:
    case AtlasActionTypes.BestRenewalOffersPageUpdateFormFilters:
    case AtlasActionTypes.BestRenewalOffersPageUpdateExceptionFilter:
    case AtlasActionTypes.BestRenewalOffersPageUpdateRejectionDialogVisibility:
    case AtlasActionTypes.BestRenewalOffersPageUpdateDeletionDialogVisibility:
    case AtlasActionTypes.BestRenewalOffersPageUpdateApprovalDialogVisibility:
    case AtlasActionTypes.BestRenewalOffersPageUpdateRejectionReason:
    case AtlasActionTypes.BestRenewalOffersPageUpdateActiveTab:
    case AtlasActionTypes.BestRenewalOffersPageUpdatePropertySummaryTablePagingState:
      return BestRenewalOffersPageReducer(newState, action);

    case AtlasActionTypes.ReviewSummaryPageResetState:
    case AtlasActionTypes.ReviewSummaryPageSetInitialForm:
    case AtlasActionTypes.ReviewSummaryPageApplyFiltersAndUpdateForm:
    case AtlasActionTypes.ReviewSummaryPageUpdateForm:
    case AtlasActionTypes.ReviewSummaryPageUpdateFilters:
    case AtlasActionTypes.ReviewSummaryPageAddPropertyComments:
    case AtlasActionTypes.ReviewSummaryPageAddUnitComment:
    case AtlasActionTypes.ReviewSummaryPageDeletePropertyComment:
    case AtlasActionTypes.ReviewSummaryPageDeleteUnitComment:
    case AtlasActionTypes.ReviewSummaryPageUpdateActiveTab:
    case AtlasActionTypes.ReviewSummaryPageUpdateRollupSelection:
    case AtlasActionTypes.ReviewSummaryPageConfirmApprovalDialog:
    case AtlasActionTypes.ReviewSummaryPageUpdateRejectionReason:
    case AtlasActionTypes.ReviewSummaryPageConfirmRejectionDialog:
      return ReviewSummaryPageReducer(newState, action);

    case AtlasActionTypes.RenewalFollowUpPageSetInitialForm:
    case AtlasActionTypes.RenewalFollowUpPageUpdateForm:
    case AtlasActionTypes.RenewalFollowUpPageShowFormUpdateSuccessful:
      return RenewalFollowUpPageReducer(newState, action);

    case AtlasActionTypes.ActualResultsSummaryPageSetInitialForm:
    case AtlasActionTypes.ActualResultsSummaryPageUpdateForm:
    case AtlasActionTypes.ActualResultsSummaryPageShowFormUpdateSuccessful:
      return ActualResultsSummaryPageReducer(newState, action);

    case AtlasActionTypes.UpdateLoadingState:
      newState.loadingState = action.loadingState;
      return newState;

    case AtlasActionTypes.ShowErrorState:
    case AtlasActionTypes.ShowValidationErrorState:
      return MessagesReducer(newState, action);

    case AtlasActionTypes.UpdateTablePagingState:
      newState.tableState.pagingState = action.pagingState;
      return newState;

    case AtlasActionTypes.SidebarOpenUnitNotes:
    case AtlasActionTypes.SidebarOpenPropertyComments:
    case AtlasActionTypes.SidebarOpenBatchComments:
    case AtlasActionTypes.SidebarOpenColumnSelector:
    case AtlasActionTypes.SidebarCloseSideBars:
      newState.sideBarState = SidebarReducer(newState.sideBarState, action);
      return newState;
  }

  return newState;
};

const useAtlasStore = function (): [
  AtlasState,
  ReturnType<typeof getAtlasActionMethods>
] {
  const identity = useContext(IdentityContext);
  const history = useHistory();
  const [AtlasState, dispatcher] = useReducer(atlasReducer, {
    ...initialAtlasState
  });

  const getAtlasActionMethods = function (identity: Identity) {
    const updateTablePagingState = function (pagingState: IPagedTableState) {
      dispatcher({
        type: AtlasActionTypes.UpdateTablePagingState,
        pagingState: pagingState
      });
    };

    return {
      bestRenewalOffersPage: BestRenewalOffersPageActionMethods.get(
        dispatcher,
        identity,
        history
      ),
      reviewSummaryPage: ReviewSummaryPageActionMethods.get(
        dispatcher,
        identity,
        history
      ),
      renewalFollowUpPage: RenewalFollowUpPageActionMethods.get(
        dispatcher,
        identity,
        history
      ),
      actualResultsSummaryPage: ActualResultsSummaryPageActionMethods.get(
        dispatcher,
        identity,
        history
      ),
      messages: MessagesActionMethods.get(dispatcher),
      sideBar: SidebarActionMethods.get(dispatcher),
      updateTablePagingState
    };
  };

  return [AtlasState, getAtlasActionMethods(identity)];
};

export default useAtlasStore;
