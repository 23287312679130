const openLink = function(
  link: string | undefined,
  openInNewTab: boolean
): void {
  window.open(
    link || "",
    openInNewTab ? "_blank" : "_self",
    "noopener,noreferrer"
  );
};

export default {
  openLink
};
