import React from "react";
import Spacer from "components/controls/Spacer";
import {
  BaselineDefaultsRegulationsForm,
  BaselineDefaultsRegulationsFormDefaults,
  BaselineDefaultsRegulationsFormDivision,
  DefaultsPageConfiguration
} from "api";
import BaselineDefaultsRegulationsFormTable from "components/forms/BaselineDefaultsRegulationsFormTable";
import Dropdown from "components/controls/Dropdown";
import Label from "components/controls/Label";
import listUtils from "utils/listUtils";
import utils from "utils";
import PrimaryButton from "components/controls/PrimaryButton";
import Section from "components/controls/Section";
import Heading from "components/controls/Heading";
import ContentArea from "components/layouts/ContentArea";
import Column from "components/controls/Column";

interface PageState {
  form: BaselineDefaultsRegulationsForm | null | undefined;
}

export interface BaselineDefaultsRegulationsFormProps {
  setForm: (form: BaselineDefaultsRegulationsForm) => any;
  form: BaselineDefaultsRegulationsForm;
  submit: () => void;
  isFetching: boolean;
  hideRemoveRowColumn?: boolean | undefined;
  onAddRowAction?: (() => void) | undefined;
  hideDivisions?: boolean | undefined;
  hideSaveButton?: boolean | undefined;
  pageConfiguration: DefaultsPageConfiguration | undefined;
}

const DefaultsRegulationsForm: React.FC<BaselineDefaultsRegulationsFormProps> = function (
  props: BaselineDefaultsRegulationsFormProps
) {
  const cloneForm = function (): BaselineDefaultsRegulationsForm {
    return utils.cloneObject(props.form);
  };

  const setForm = function (form: BaselineDefaultsRegulationsForm) {
    props.setForm(form);
  };

  const onCountyDefaultsChange = (
    defaults: BaselineDefaultsRegulationsFormDefaults[]
  ) => {
    onDivisionChanged({
      ...props.form.selectedDivision,
      countyDefaults: defaults,
      isChanged: true
    });
  };

  const onPropertiesDefaultsChange = (
    defaults: BaselineDefaultsRegulationsFormDefaults[]
  ) => {
    onDivisionChanged({
      ...props.form.selectedDivision,
      propertyDefaults: defaults,
      isChanged: true
    });
  };

  const addCountyDefaultsRow = function (): void {
    const selectedDivision = props.form && props.form.selectedDivision;
    const newRow = {
      ...(selectedDivision &&
        selectedDivision.countyDefaults &&
        selectedDivision.countyDefaults[0]),
      formValidationErrors: undefined,
      fieldValidationErrors: undefined,
      isValid: false,
      isChanged: true
    };
    const currentRows =
      (selectedDivision && selectedDivision.countyDefaults) || [];

    onCountyDefaultsChange(currentRows.concat(newRow));
  };

  const addPropertyDefaultsRow = function (): void {
    const selectedDivision = props.form && props.form.selectedDivision;
    const newRow = {
      ...(selectedDivision &&
        selectedDivision.countyDefaults &&
        selectedDivision.countyDefaults[0]),
      formValidationErrors: undefined,
      fieldValidationErrors: undefined,
      isValid: false,
      isChanged: true
    };
    const currentRows =
      (selectedDivision && selectedDivision.propertyDefaults) || [];

    onPropertiesDefaultsChange(currentRows.concat(newRow));
  };

  const onDivisionChanged = (
    division: BaselineDefaultsRegulationsFormDivision | undefined
  ) => {
    const divisions = (props.form && props.form.divisions) || undefined;
    let newDivisionsList = divisions;

    if (divisions && division) {
      newDivisionsList =
        listUtils.copyListWithNewKeyedItem(divisions, division) || undefined;
    }

    const form = cloneForm();

    form.selectedDivision = division;
    form.divisions = newDivisionsList;

    setForm(form);
  };

  let selectedDivision = undefined;
  let fieldValidationErrors:
    | {
        [key: string]: string;
      }
    | undefined = undefined;
  const form = props && props.form;
  if (form) {
    selectedDivision = form.selectedDivision;
    fieldValidationErrors = form.fieldValidationErrors;
  }

  const getDivisionDropdownOrLabel = function (): JSX.Element {
    if (props.hideDivisions) {
      return <div />;
    }

    const divisions = props.form.divisions;
    const selectedDivision = props.form.selectedDivision;
    if (divisions && divisions.length > 1) {
      return (
        <Dropdown
          onChange={onDivisionChanged}
          hideClear={true}
          options={divisions}
          selectedValue={selectedDivision}
          validationMessage={
            fieldValidationErrors && fieldValidationErrors["SelectedDivision"]
          }
        />
      );
    } else if (selectedDivision) {
      return <Label label={selectedDivision.displayValue} />;
    } else {
      return <div />;
    }
  };

  const getSaveButton = function (): JSX.Element {
    if (props.hideSaveButton) {
      return <div />;
    }

    return (
      <PrimaryButton
        disabled={props.isFetching}
        onClick={() => props.submit()}
        title="SAVE"
      />
    );
  };

  const divisionsArea = getDivisionDropdownOrLabel();
  const saveButton = getSaveButton();

  return (
    <div>
      <Section title="Ordinances" />

      <Column size="2">{divisionsArea}</Column>

      <Spacer size="l" orientation="v" />

      <ContentArea columnSize={"12"}>
        <Heading level="3">By County</Heading>
        <Spacer size="s" orientation="v" />
        <BaselineDefaultsRegulationsFormTable
          defaults={
            (props &&
              props.form &&
              props.form.selectedDivision &&
              props.form.selectedDivision.countyDefaults) ||
            undefined
          }
          onChange={onCountyDefaultsChange}
          hidePropertyColumn={true}
          hideRemoveRowColumn={
            !(
              props.form.pageConfiguration &&
              props.form.pageConfiguration.isEditEnabled
            )
          }
          paging={false}
          regulations={
            (selectedDivision && selectedDivision.regulationTypes) || []
          }
          counties={(selectedDivision && selectedDivision.countyTypes) || []}
          properties={
            (selectedDivision && selectedDivision.propertyTypes) || []
          }
          onAddRowAction={
            props.form.pageConfiguration &&
            props.form.pageConfiguration.isEditEnabled
              ? addCountyDefaultsRow
              : props.onAddRowAction
          }
          includeRemoveRowColumn={
            props.form.pageConfiguration &&
            props.form.pageConfiguration.isEditEnabled
              ? "show"
              : undefined
          }
          pageConfiguration={props.pageConfiguration}
        />
      </ContentArea>

      <Spacer size="l" orientation="v" />

      <ContentArea columnSize={"12"}>
        <Heading level="3">By Property</Heading>
        <Spacer size="s" orientation="v" />
        <BaselineDefaultsRegulationsFormTable
          defaults={
            (props &&
              props.form &&
              props.form.selectedDivision &&
              props.form.selectedDivision.propertyDefaults) ||
            undefined
          }
          onChange={onPropertiesDefaultsChange}
          hideRemoveRowColumn={
            !(
              props.form.pageConfiguration &&
              props.form.pageConfiguration.isEditEnabled
            )
          }
          regulations={
            (selectedDivision && selectedDivision.regulationTypes) || []
          }
          counties={(selectedDivision && selectedDivision.countyTypes) || []}
          properties={
            (selectedDivision && selectedDivision.propertyTypes) || []
          }
          onAddRowAction={
            props.form.pageConfiguration &&
            props.form.pageConfiguration.isEditEnabled
              ? addPropertyDefaultsRow
              : props.onAddRowAction
          }
          hideCountyTypeColumn={true}
          includeRemoveRowColumn={
            props.form.pageConfiguration &&
            props.form.pageConfiguration.isEditEnabled
              ? "show"
              : undefined
          }
          pageConfiguration={props.pageConfiguration}
        />
      </ContentArea>

      <Spacer size="l" orientation="v" />

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {saveButton}
      </div>
    </div>
  );
};

export default DefaultsRegulationsForm;
