import { AtlasActionTypes } from "../AtlasActionTypes";
import {
  SideBarState,
  SidebarUnitNotesDisplayState,
  SidebarPropertyNotesDisplayState,
  SidebarBatchNotesDisplayState,
  SidebarColumnSelectorDisplayState
} from "../AtlasStateTypes";
import {
  OpenColumnSelectorAction,
  OpenUnitNotesAction,
  OpenPropertyCommentsAction,
  OpenBatchCommentsAction,
  CloseSideBarsAction
} from "./SidebarActions";

const SidebarReducer = (
  state: SideBarState,
  action:
    | OpenUnitNotesAction
    | OpenPropertyCommentsAction
    | OpenBatchCommentsAction
    | OpenColumnSelectorAction
    | CloseSideBarsAction
): SideBarState => {
  const hideUnitNotesDisplayState: SidebarUnitNotesDisplayState = {
    isVisible: false
  };
  const hidePropertyNotesDisplayState: SidebarPropertyNotesDisplayState = {
    isVisible: false
  };
  const hideBatchNotesDisplayState: SidebarBatchNotesDisplayState = {
    isVisible: false
  };
  const hideColumnSelectorDisplayState: SidebarColumnSelectorDisplayState = {
    isVisible: false,
    columnConfigurations: []
  };

  switch (action.type) {
    case AtlasActionTypes.SidebarOpenUnitNotes:
      if (action.selectedUnit) {
        return {
          batchNotesDisplayState: hideBatchNotesDisplayState,
          propertyNotesDisplayState: hidePropertyNotesDisplayState,
          unitNotesDisplayState: {
            isVisible: true,
            selectedUnit: action.selectedUnit
          },
          columnSelectorDisplayState: hideColumnSelectorDisplayState
        };
      }
      break;
    case AtlasActionTypes.SidebarOpenPropertyComments:
      const selectedProperty = action.selectedProperty;
      if (selectedProperty) {
        return {
          unitNotesDisplayState: hideUnitNotesDisplayState,
          batchNotesDisplayState: hideBatchNotesDisplayState,
          propertyNotesDisplayState: {
            isVisible: true,
            selectedProperty: selectedProperty
          },
          columnSelectorDisplayState: hideColumnSelectorDisplayState
        };
      }
      return { ...state };
    case AtlasActionTypes.SidebarOpenBatchComments:
      return {
        unitNotesDisplayState: hideUnitNotesDisplayState,
        propertyNotesDisplayState: hidePropertyNotesDisplayState,
        batchNotesDisplayState: {
          isVisible: true,
          batchComments: action.batchComments
        },
        columnSelectorDisplayState: hideColumnSelectorDisplayState
      };
    case AtlasActionTypes.SidebarCloseSideBars:
      return {
        unitNotesDisplayState: hideUnitNotesDisplayState,
        propertyNotesDisplayState: hidePropertyNotesDisplayState,
        batchNotesDisplayState: hideBatchNotesDisplayState,
        columnSelectorDisplayState: hideColumnSelectorDisplayState
      };
    case AtlasActionTypes.SidebarOpenColumnSelector:
      return {
        unitNotesDisplayState: hideUnitNotesDisplayState,
        propertyNotesDisplayState: hidePropertyNotesDisplayState,
        batchNotesDisplayState: hideBatchNotesDisplayState,
        columnSelectorDisplayState: {
          isVisible: true,
          columnConfigurations: action.columnConfigurations
        }
      };
  }
  return { ...state };
};

export default SidebarReducer;
