import React, { useState, useEffect } from "react";
import OverlayPanel from "../OverlayPanel";
import FilteredSelections, {
  FilteredSelectionsItemProps
} from "../FilteredSelections";
import useStyle from "./useStyle";

export interface FilterDropdownProps {
  isActive: boolean;
  items: FilteredSelectionsItemProps[];
  onItemsChange: (items: FilteredSelectionsItemProps[]) => void;
}

type ComponentState = {
  items: FilteredSelectionsItemProps[];
};

const FilterDropdown: React.FC<FilterDropdownProps> = function(props) {
  const classes = useStyle(props);

  const [state, setState] = useState<ComponentState>({ items: props.items });

  useEffect(() => {
    setState({ items: props.items });
  }, [props.items]);

  const onItemsStateChange = function(
    items: FilteredSelectionsItemProps[]
  ): void {
    setState({ items: items });
  };

  return (
    <OverlayPanel
      buttonTitle=""
      buttonIcon="pi pi-sliders-h"
      className={classes.filter}
      onHide={() => props.onItemsChange(state.items)}
    >
      <FilteredSelections
        items={state.items}
        onItemsChange={onItemsStateChange}
      />
    </OverlayPanel>
  );
};

export default FilterDropdown;
