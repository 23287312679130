import listUtils from "utils/listUtils";
import utils from "utils";
import { AtlasActionTypes } from "../AtlasActionTypes";
import { AtlasState, getMutableCopyOfAtlasState } from "../AtlasStateTypes";
import { AtlasActions } from "../AtlasActions";
import { initialAtlasState } from "../useAtlasStore";
import { CommonSummaryRollupKind, CommonSummaryFormRollup } from "api";

const ReviewSummaryPageReducer = (
  oldState: AtlasState,
  action: AtlasActions
): AtlasState => {
  const newState = getMutableCopyOfAtlasState(oldState);
  switch (action.type) {
    case AtlasActionTypes.ReviewSummaryPageResetState:
      return utils.cloneObject(initialAtlasState);
    case AtlasActionTypes.ReviewSummaryPageSetInitialForm:
      newState.reviewSummaryPageState.form = action.form;
      newState.loadingState.isLoading = false;
      newState.reviewSummaryPageState.hasUnappliedFilters = false;
      newState.messageState.messages = [];
      return newState;
    case AtlasActionTypes.ReviewSummaryPageApplyFiltersAndUpdateForm:
      newState.loadingState.isLoading = false;
      newState.reviewSummaryPageState.form = action.form;
      newState.reviewSummaryPageState.hasUnappliedFilters = false;
      newState.tableState.pagingState.offset = 0;
      return newState;
    case AtlasActionTypes.ReviewSummaryPageUpdateForm:
      newState.reviewSummaryPageState.form = action.form;
      return newState;
    case AtlasActionTypes.ReviewSummaryPageUpdateFilters:
      if (!newState.reviewSummaryPageState.form) {
        return newState;
      }

      newState.reviewSummaryPageState.form.filters = action.filters;
      newState.reviewSummaryPageState.hasUnappliedFilters = true;
      return newState;
    case AtlasActionTypes.ReviewSummaryPageAddPropertyComments:
      if (
        !newState.reviewSummaryPageState.form ||
        !newState.reviewSummaryPageState.form.propertySummary ||
        !newState.reviewSummaryPageState.form.propertySummary.rollups
      ) {
        return newState;
      }

      const propertyRollupForComment = listUtils.firstOrDefaultByKey(
        newState.reviewSummaryPageState.form.propertySummary.rollups,
        action.propertyKey
      );

      if (!propertyRollupForComment) {
        return newState;
      }

      propertyRollupForComment.propertyComments = action.comments;
      if (newState.sideBarState.propertyNotesDisplayState.selectedProperty) {
        newState.sideBarState.propertyNotesDisplayState.selectedProperty.comments =
          action.comments;
      }

      newState.loadingState.isLoading = false;
      return newState;
    case AtlasActionTypes.ReviewSummaryPageDeletePropertyComment:
      if (
        !newState.reviewSummaryPageState.form ||
        !newState.reviewSummaryPageState.form.propertySummary ||
        !newState.reviewSummaryPageState.form.propertySummary.rollups
      ) {
        return newState;
      }

      const propertyRollupForCommentz = listUtils.firstOrDefaultByKey(
        newState.reviewSummaryPageState.form.propertySummary.rollups,
        action.propertyKey
      );

      if (!propertyRollupForCommentz) {
        return newState;
      }

      propertyRollupForCommentz.propertyComments = action.comments;
      if (newState.sideBarState.propertyNotesDisplayState.selectedProperty) {
        newState.sideBarState.propertyNotesDisplayState.selectedProperty.comments =
          action.comments;
      }

      newState.loadingState.isLoading = false;
      return newState;
    case AtlasActionTypes.ReviewSummaryPageAddUnitComment:
      if (
        !newState.reviewSummaryPageState.form ||
        !newState.reviewSummaryPageState.form.unitSummary ||
        !newState.reviewSummaryPageState.form.unitSummary.rollups
      ) {
        return newState;
      }

      const unitRollupForComment = listUtils.firstOrDefaultByKey(
        newState.reviewSummaryPageState.form.unitSummary.rollups,
        action.unitKey
      );

      if (!unitRollupForComment) {
        return newState;
      }

      unitRollupForComment.unitComments = action.comments;
      if (newState.sideBarState.unitNotesDisplayState.selectedUnit) {
        newState.sideBarState.unitNotesDisplayState.selectedUnit.comments =
          action.comments;
      }

      newState.loadingState.isLoading = false;
      return newState;
    case AtlasActionTypes.ReviewSummaryPageDeleteUnitComment:
      if (
        !newState.reviewSummaryPageState.form ||
        !newState.reviewSummaryPageState.form.unitSummary ||
        !newState.reviewSummaryPageState.form.unitSummary.rollups
      ) {
        return newState;
      }

      const unitRollupForCommentz = listUtils.firstOrDefaultByKey(
        newState.reviewSummaryPageState.form.unitSummary.rollups,
        action.unitKey
      );

      if (!unitRollupForCommentz) {
        return newState;
      }

      unitRollupForCommentz.unitComments = action.comments;
      if (newState.sideBarState.unitNotesDisplayState.selectedUnit) {
        newState.sideBarState.unitNotesDisplayState.selectedUnit.comments =
          action.comments;
      }

      newState.loadingState.isLoading = false;
      return newState;
    case AtlasActionTypes.ReviewSummaryPageUpdateActiveTab:
      if (!newState.reviewSummaryPageState.form) {
        return newState;
      }

      newState.reviewSummaryPageState.form.activeView = action.activeTabView;

      return newState;
    case AtlasActionTypes.ReviewSummaryPageUpdateRollupSelection:
      if (
        !newState.reviewSummaryPageState.form ||
        !newState.reviewSummaryPageState.form.countySummary ||
        !newState.reviewSummaryPageState.form.propertySummary ||
        !newState.reviewSummaryPageState.form.unitSummary ||
        !newState.reviewSummaryPageState.form.countySummary.rollups ||
        !newState.reviewSummaryPageState.form.propertySummary.rollups ||
        !newState.reviewSummaryPageState.form.unitSummary.rollups
      ) {
        return newState;
      }

      let rollupsToUpdate: CommonSummaryFormRollup[] = [];

      switch (action.rollupKind) {
        case CommonSummaryRollupKind.County:
          rollupsToUpdate =
            newState.reviewSummaryPageState.form.countySummary.rollups;
          break;
        case CommonSummaryRollupKind.Property:
          rollupsToUpdate =
            newState.reviewSummaryPageState.form.propertySummary.rollups;
          break;
        case CommonSummaryRollupKind.Unit:
          rollupsToUpdate =
            newState.reviewSummaryPageState.form.unitSummary.rollups;
          break;
      }

      rollupsToUpdate.forEach((r) => {
        const selectedRollup = listUtils.firstOrDefaultKeyedItem(
          action.selectedRollups,
          r
        );

        r.isSelected = selectedRollup != null;
      });

      return newState;

    case AtlasActionTypes.ReviewSummaryPageConfirmApprovalDialog:
      newState.reviewSummaryPageState.confirmApprovalDialogIsVisible =
        action.isVisible;
      return newState;

    case AtlasActionTypes.ReviewSummaryPageUpdateRejectionReason:
      if (!newState.reviewSummaryPageState.form) {
        return newState;
      }

      newState.reviewSummaryPageState.form.rejectReason =
        action.rejectionReason;
      return newState;

    case AtlasActionTypes.ReviewSummaryPageConfirmRejectionDialog:
      newState.reviewSummaryPageState.confirmRejectionDialogIsVisible =
        action.isVisible;
      return newState;
  }

  return newState;
};

export default ReviewSummaryPageReducer;
