import React from "react";
import useStyle from "./useStyle";
import cssUtils from "utils/cssUtils";

export type ColumnSize =
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "10"
  | "11"
  | "12";

export interface ColumnProps {
  size: ColumnSize;
  className?: string | undefined;
}

const Column: React.FC<ColumnProps> = function(props) {
  const classes = useStyle(props);

  return (
    <div className={cssUtils.join(classes.root, props.className)}>
      {props.children}
    </div>
  );
};

export default Column;
