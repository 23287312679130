import React, { useState } from "react";
import Page from "components/layouts/Page";
import Dropdown from "components/controls/Dropdown";
import Spacer from "components/controls/Spacer";
import useStyle from "./useStyle";
import parseDate from "date-fns/parseISO";
import PrimaryButton from "components/controls/PrimaryButton";
import { useRemoteData, responseHandlers } from "components/useRemoteData";
import {
  CreateBatchFormClient,
  ApiException,
  CreateBatchFormBatchType,
  CreateOneOffBatchForm
} from "api";
import { useApiEndpoint } from "components/useApiEndpoint";
import Validation from "components/controls/Validation";
import Menu from "../Menu";
import routes from "components/routes";
import { useHistory } from "react-router";
import ContentArea from "components/layouts/ContentArea";
import RequirePermission from "../RequirePermission";
import Spinner from "components/controls/Spinner";
import useMessageArea from "components/useMessageArea";
import utils from "utils";
import Column from "components/controls/Column";
import BatchTypeSelectionArea from "components/forms/BatchTypeSelectionArea";
import UnitChooser from "components/parts/UnitChooser";
import SinglePropertyChooser from "components/parts/SinglePropertyChooser";

interface PageState {
  form: CreateOneOffBatchForm;
}

const CreateOneOffBatchPage: React.FC = function () {
  const classes = useStyle();
  const history = useHistory();
  const [page, setPage] = useState<PageState>({
    form: {}
  });

  const [
    messageAreaState,
    setMessageBasedOnForm,
    setMessageBasedOnApiException
  ] = useMessageArea();

  const setForm = function (form: CreateOneOffBatchForm) {
    const mutableForm = utils.cloneObject(form);
    mutableForm.startDate = mutableForm.startDate
      ? parseDate(mutableForm.startDate as any)
      : undefined;
    mutableForm.endDate = mutableForm.endDate
      ? parseDate(mutableForm.endDate as any)
      : undefined;

    setPage({
      ...page,
      form: mutableForm
    });
  };

  const [dataState] = useRemoteData(
    useApiEndpoint(CreateBatchFormClient, "getCreateOneOffBatchForm"),
    [],
    {
      autoFetch: true,
      onChange: (d) =>
        d &&
        setForm({
          ...d,
          selectedDivision: {
            ...d.selectedDivision,
            selectedProperty: undefined
          },
          selectedBatchTypes: [
            {
              key: "587698A9-C216-4A5C-878C-54DF6B748DF1",
              displayValue: "One-Off"
            }
          ]
        })
    }
  );

  const [submitState, submit] = useRemoteData(
    useApiEndpoint(CreateBatchFormClient, "createOneOffBatch"),
    [page.form],
    {
      onChange: (d) => {
        setMessageBasedOnForm(d);
        d && setForm(d);

        if (!d) {
          return;
        }

        history.push(routes.batchdefaults.growthRates(d.key, "createBatch"));
      },

      onError: (error: ApiException) => {
        responseHandlers.use({
          "400": (ex: ApiException) => {
            const form = ex.result;
            setMessageBasedOnForm(form);
            setForm(form);
          },
          _: (ex) => {
            setMessageBasedOnApiException(ex);
          }
        })(error);
      }
    }
  );

  const onSubmit = function () {
    submit([]);
  };

  const spinner = (
    <Spinner isVisible={dataState.isFetching || submitState.isFetching} />
  );

  //See T-4258 for refactoring improvements
  if (
    (page.form == null ||
      page.form.divisions == null ||
      page.form.batchTypes == null) &&
    (dataState.isFetching || submitState.isFetching)
  ) {
    return spinner;
  } else if (page.form == null) {
    return messageAreaState.messageArea;
  }

  // const startDate = page.form.startDate as any;
  // const endDate = page.form.endDate as any;

  const submitButton = (
    <PrimaryButton
      disabled={submitState.isFetching}
      onClick={() => onSubmit()}
      title="SUBMIT"
    />
  );

  const getBatchTypes = function () {
    if (page.form.batchTypes) {
      return page.form.batchTypes.filter((b) => b.displayValue === "One-Off");
    }
    return [];
  };

  const onSelectedBatchTypesChange = function (
    selectedBatchTypes: CreateBatchFormBatchType[]
  ) {};

  return (
    <Page menu={<Menu title="CREATE ONE-OFF" actionArea={submitButton} />}>
      <RequirePermission permissions={["canCreateBatch"]} accessDenied="page">
        {spinner}
        {messageAreaState.messageArea}
        <Column className={classes.mainContentColumn} size="12">
          <div className={classes.contentContainer}>
            <ContentArea columnSize={"3"}>
              <div>
                {page.form.formValidationErrors &&
                  page.form.formValidationErrors.map((e) => (
                    <Validation message={e} />
                  ))}
                <Dropdown
                  label="Division"
                  // TODO Move this functionality to the server (required division)?
                  hideClear={true}
                  validationMessage={
                    page.form.fieldValidationErrors &&
                    page.form.fieldValidationErrors["SelectedDivision"]
                  }
                  selectedValue={page.form.selectedDivision}
                  options={page.form.divisions}
                  onChange={(v) =>
                    setForm({
                      ...page.form,
                      selectedDivision: { ...v }
                    })
                  }
                />
                <Spacer orientation="v" size="l" />
                <BatchTypeSelectionArea
                  batchTypes={getBatchTypes()}
                  selectedBatchTypes={page.form.selectedBatchTypes || []}
                  onSelectedBatchTypesChange={onSelectedBatchTypesChange}
                  validationMessage={
                    page.form.fieldValidationErrors &&
                    page.form.fieldValidationErrors["SelectedBatchTypes"]
                  }
                />
                <Spacer orientation="v" size="l" />
              </div>
            </ContentArea>
            <Spacer orientation="h" size="xxl" />
            <ContentArea columnSize={"5"}>
              <SinglePropertyChooser
                properties={
                  page.form.selectedDivision &&
                  page.form.selectedDivision.properties
                }
                selectedProperty={
                  page.form.selectedDivision &&
                  page.form.selectedDivision.selectedProperty
                }
                validationMessage={
                  page.form.selectedDivision &&
                  page.form.selectedDivision.fieldValidationErrors &&
                  page.form.selectedDivision.fieldValidationErrors["Properties"]
                }
                onChange={(p) => {
                  const property = p;
                  if (page.form.selectedDivision != null) {
                    setForm({
                      ...page.form,
                      selectedDivision: {
                        ...page.form.selectedDivision,
                        selectedProperty: property //TODO: fix
                      }
                    });
                  }
                }}
              />
            </ContentArea>
            <Spacer orientation="h" size="xxl" />

            <ContentArea columnSize={"3"}>
              <UnitChooser
                units={
                  page.form.selectedDivision &&
                  page.form.selectedDivision.selectedProperty &&
                  page.form.selectedDivision.selectedProperty.units
                }
                selectedUnits={
                  page.form.selectedDivision &&
                  page.form.selectedDivision.selectedProperty &&
                  page.form.selectedDivision.selectedProperty.selectedUnits
                }
                validationMessage={
                  page.form.selectedDivision &&
                  page.form.selectedDivision.fieldValidationErrors &&
                  page.form.selectedDivision.fieldValidationErrors["Units"]
                }
                onChange={(p) => {
                  if (page.form.selectedDivision != null) {
                    setForm({
                      ...page.form,
                      selectedDivision: {
                        ...page.form.selectedDivision,
                        selectedProperty: {
                          ...page.form.selectedDivision.selectedProperty,
                          selectedUnits: p
                        }
                      }
                    });
                  }
                }}
              />
            </ContentArea>
          </div>
        </Column>
      </RequirePermission>
    </Page>
  );
};

export default CreateOneOffBatchPage;
