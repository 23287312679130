import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
  root: {
    display: "flex"
  },
  batchType: {
    paddingRight: "5px"
  }
}));
