import React from "react";
import Spacer from "../Spacer";
import useStyle from "./useStyle";

export interface ValidationProps {
  message: string | null | undefined;
}

const Validation: React.FC<ValidationProps> = function(props) {
  const classes = useStyle();

  if (props.message == null) return null;

  return <span className={classes.validation}>{props.message}</span>;
};
export default Validation;

export interface WithValidationProps {
  validationMessage?: string | null | undefined;
  position?: "top" | "left" | "bottom" | "right";
}

export function withValidation<P extends {}>(
  component: React.ComponentClass<P> | React.FC<P>
) {
  const Inner = component;
  const hoc = (props: P & WithValidationProps) => {
    return (
      <div>
        <div>
          <Inner {...props} />
        </div>
        <Spacer size="xs" />
        <div>
          <Validation message={props.validationMessage} />
        </div>
      </div>
    );
  };
  hoc.displayName = `withValidation(${component.name})`;
  return hoc;
}
