import React, { useState } from "react";
import {
  Option,
  BaselineDefaultsRegulationsFormPropertyType,
  BaselineDefaultsRegulationsFormDefaults,
  BaselineDefaultsRegulationsFormCountyType,
  DefaultsAdminGrowthRatesFormPropertyType,
  DefaultsPageConfiguration,
  BaselineDefaultsRegulationsFormRegulationType
} from "api";
import createDataTable, {
  IPagedTableState,
  ColumnDefinition,
  DefaultInputEditorData,
  DataTableProps
} from "components/controls/createTable";

import Dropdown from "components/controls/Dropdown";
import listUtils from "utils/listUtils";
import { Calendar } from "primereact/calendar";
import { format } from "date-fns";
import ValidationWrapper from "components/controls/ValidationWrapper";
import useStyle from "./useStyle";

export interface DefaultsRegulationsFormTableProps {
  defaults?: BaselineDefaultsRegulationsFormDefaults[] | undefined;
  regulations: BaselineDefaultsRegulationsFormRegulationType[];
  counties: BaselineDefaultsRegulationsFormCountyType[];
  properties: DefaultsAdminGrowthRatesFormPropertyType[];
  onChange: (defaults: BaselineDefaultsRegulationsFormDefaults[]) => void;
  hidePropertyColumn?: boolean | undefined;
  hideCountyTypeColumn?: boolean | undefined;
  hideRemoveRowColumn?: boolean | undefined;
  paging?: boolean | undefined;
  onAddRowAction?: (() => void) | undefined;
  includeRemoveRowColumn?: "show" | "buffer" | undefined;
  pageConfiguration: DefaultsPageConfiguration | undefined;
}

interface TableState extends IPagedTableState {
  divisionFilter?: Option;
  sortField?: any;
  sortOrder?: any;
  sortState?: "unsorted" | "asc" | "desc" | undefined;
}

const DefaultsRegulationsFormTable: React.FC<DefaultsRegulationsFormTableProps> = function (
  props: DefaultsRegulationsFormTableProps
) {
  const [table, setTable] = useState<TableState>({
    offset: 0,
    limit: 10,
    sortOrder: 1
  });

  const classes = useStyle();

  const onRowChanged = (
    oldRow: BaselineDefaultsRegulationsFormDefaults,
    newRow: BaselineDefaultsRegulationsFormDefaults
  ) => {
    if (!props.defaults) {
      return;
    }

    props.onChange(
      listUtils.copyListWithNewItem(props.defaults, oldRow, newRow)
    );
  };

  const onRemoveRow = (row: BaselineDefaultsRegulationsFormDefaults) => {
    if (!props.defaults) {
      return;
    }

    const newDefaults = listUtils.copyListWithItemRemoved(props.defaults, row);
    props.onChange(newDefaults);
  };

  const onDefaultsRegulationsFormRegulationChange = (
    defaultsRow: BaselineDefaultsRegulationsFormDefaults
  ) => {
    return (
      selectedRegulation:
        | BaselineDefaultsRegulationsFormRegulationType
        | undefined
    ) => {
      const newDefaultsRow: BaselineDefaultsRegulationsFormDefaults = {
        ...defaultsRow,
        regulationType: selectedRegulation
      };

      onRowChanged(defaultsRow, newDefaultsRow);
    };
  };

  const onDefaultsRegulationsFormPropertyChange = (
    defaultsRow: BaselineDefaultsRegulationsFormDefaults
  ) => {
    return (
      selectedProperty: BaselineDefaultsRegulationsFormPropertyType | undefined
    ) => {
      const newDefaultsRow: BaselineDefaultsRegulationsFormDefaults = {
        ...defaultsRow,
        selectedPropertyType: selectedProperty
      };

      onRowChanged(defaultsRow, newDefaultsRow);
    };
  };

  const onDefaultsRegulationsFormCountyChange = (
    defaultsRow: BaselineDefaultsRegulationsFormDefaults
  ) => {
    return (
      selectedCountyType: BaselineDefaultsRegulationsFormCountyType | undefined
    ) => {
      const newDefaultsRow: BaselineDefaultsRegulationsFormDefaults = {
        ...defaultsRow,
        selectedCountyType: selectedCountyType
      };

      onRowChanged(defaultsRow, newDefaultsRow);
    };
  };

  const getRegulationTypeDefaultsBody = function (rowData: any, column: any) {
    const defaults = rowData as BaselineDefaultsRegulationsFormDefaults;

    let dropDown = (
      <Dropdown
        onChange={onDefaultsRegulationsFormRegulationChange(defaults)}
        validationMessage={
          defaults.fieldValidationErrors &&
          defaults.fieldValidationErrors["RegulationType"]
        }
        selectedValue={defaults.regulationType}
        options={props.regulations}
        style={{ minWidth: "100%", maxWidth: "100%" }}
      />
    );

    let label = (
      <div>
        {defaults.regulationType && defaults.regulationType.displayValue}
      </div>
    );

    if (props.pageConfiguration && props.pageConfiguration.isEditEnabled) {
      return dropDown;
    } else {
      return label;
    }
  };

  const getStartDateBody = function (rowData: any, column: any) {
    const defaults = rowData as BaselineDefaultsRegulationsFormDefaults;
    let dropDown = (
      <ValidationWrapper
        showErrorState={
          defaults.fieldValidationErrors != null &&
          defaults.fieldValidationErrors["StartDate"] != null
        }
      >
        <Calendar
          value={defaults.startDate && new Date(defaults.startDate)}
          tooltip={
            defaults.fieldValidationErrors &&
            defaults.fieldValidationErrors["StartDate"]
          }
          readOnlyInput={true}
          onChange={(e) => {
            onRowChanged(defaults, {
              ...defaults,
              startDate: (e.value as Date) || undefined
            });
          }}
          appendTo={document.getElementById("root")}
        ></Calendar>
      </ValidationWrapper>
    );

    let label = (
      <div>
        {defaults.startDate &&
          format(new Date(defaults.startDate), "MM/dd/yyyy")}
      </div>
    );

    if (props.pageConfiguration && props.pageConfiguration.isEditEnabled) {
      return dropDown;
    } else {
      return label;
    }
  };

  const getEndDateBody = function (rowData: any, column: any) {
    const defaults = rowData as BaselineDefaultsRegulationsFormDefaults;
    let dropDown = (
      <ValidationWrapper
        showErrorState={
          defaults.fieldValidationErrors != null &&
          defaults.fieldValidationErrors["EndDate"] != null
        }
      >
        <Calendar
          value={defaults.endDate && new Date(defaults.endDate)}
          tooltip={
            defaults.fieldValidationErrors &&
            defaults.fieldValidationErrors["EndDate"]
          }
          clearButtonStyleClass={classes.whiteFont}
          todayButtonClassName={classes.disabled}
          showButtonBar={true}
          readOnlyInput={true}
          onChange={(e) =>
            onRowChanged(defaults, {
              ...defaults,
              endDate: (e.value as Date) || undefined
            })
          }
          appendTo={document.getElementById("root")}
        ></Calendar>
      </ValidationWrapper>
    );

    let label = (
      <div>
        {defaults.endDate && format(new Date(defaults.endDate), "MM/dd/yyyy")}
      </div>
    );

    if (props.pageConfiguration && props.pageConfiguration.isEditEnabled) {
      return dropDown;
    } else {
      return label;
    }
  };

  const getPropertyDefaultsBody = function (rowData: any, column: any) {
    const defaults = rowData as BaselineDefaultsRegulationsFormDefaults;

    let dropDown = (
      <Dropdown
        onChange={onDefaultsRegulationsFormPropertyChange(defaults)}
        validationMessage={
          defaults.fieldValidationErrors &&
          defaults.fieldValidationErrors["SelectedPropertyType"]
        }
        selectedValue={defaults.selectedPropertyType}
        options={props.properties}
        style={{ minWidth: "100%", maxWidth: "100%" }}
      />
    );

    let label = (
      <div>
        {defaults.selectedPropertyType &&
          defaults.selectedPropertyType.displayValue}
      </div>
    );

    if (props.pageConfiguration && props.pageConfiguration.isEditEnabled) {
      return dropDown;
    } else {
      return label;
    }
  };

  const getCountyDefaultsBody = function (rowData: any, column: any) {
    const defaults = rowData as BaselineDefaultsRegulationsFormDefaults;

    let dropDown = (
      <Dropdown
        onChange={onDefaultsRegulationsFormCountyChange(defaults)}
        selectedValue={defaults.selectedCountyType}
        options={props.counties}
        style={{ minWidth: "100%", maxWidth: "100%" }}
        validationMessage={
          defaults.fieldValidationErrors &&
          defaults.fieldValidationErrors["SelectedCountyType"]
        }
      />
    );

    let label = (
      <div>
        {defaults.selectedCountyType &&
          defaults.selectedCountyType.displayValue}
      </div>
    );

    if (props.pageConfiguration && props.pageConfiguration.isEditEnabled) {
      return dropDown;
    } else {
      return label;
    }
  };

  const getDefaultInputEditorData = function (): DefaultInputEditorData<BaselineDefaultsRegulationsFormDefaults> {
    return {
      isEditDisabled: !(
        props.pageConfiguration && props.pageConfiguration.isEditEnabled
      ),
      onChange: (
        row: BaselineDefaultsRegulationsFormDefaults,
        field: keyof BaselineDefaultsRegulationsFormDefaults,
        value: any
      ) => {
        const newDefaultsRow = {
          ...row
        };

        newDefaultsRow[field] = value === "" ? null : value;
        onRowChanged(row, newDefaultsRow);
      }
    };
  };

  const getColumns = function (): any[] {
    const columns = [] as ColumnDefinition<
      BaselineDefaultsRegulationsFormDefaults,
      keyof BaselineDefaultsRegulationsFormDefaults
    >[];

    const countyDropdownColumnStyle = { width: "10%" };
    const propertyDropdownColumnStyle = { width: "20%" };

    if (!props.hidePropertyColumn) {
      columns.push({
        header: "Property",
        style: propertyDropdownColumnStyle,
        body: getPropertyDefaultsBody
      });
    } else {
      columns.push({ header: "", style: propertyDropdownColumnStyle });
    }

    if (!props.hideCountyTypeColumn) {
      columns.push({
        header: "County",
        style: countyDropdownColumnStyle,
        body: getCountyDefaultsBody
      });
    } else {
      columns.push({ header: "", style: countyDropdownColumnStyle });
    }

    return columns.concat([
      {
        field: "regulationType",
        header: "Regulation Type",
        style: { width: "8%" },
        headerStyle: { width: "8%", textAlign: "center" },
        body: getRegulationTypeDefaultsBody
      },
      {
        field: "startDate",
        header: "Start Date",
        style: { width: "8%", textAlign: "center" },
        body: getStartDateBody
      },
      {
        field: "endDate",
        header: "End Date",
        style: { width: "7%", textAlign: "center" },
        body: getEndDateBody
      },
      {
        field: "percentageCap",
        header: "Max %",
        style: { width: "7%", textAlign: "center" },
        defaultInputEditorData: getDefaultInputEditorData()
      }
    ]);
  };

  const tableProps: DataTableProps<
    BaselineDefaultsRegulationsFormDefaults,
    TableState
  > = {
    value: props.defaults || undefined,
    editMode: "cell",
    state: table,
    updateState: setTable,
    paging: props.paging,
    // TODO: This functionality only works when added at this leve. I was unable to get it to work when I moved it up to the createDataTable function
    sortField: table.sortField,
    sortOrder: table.sortOrder,
    onSort: (e: any) => {
      //clear sort column
      if (
        table.sortOrder === -1 &&
        e.sortOrder === 1 &&
        table.sortField === e.sortField
      ) {
        setTable({
          ...table,
          sortField: undefined,
          sortOrder: undefined
        });
      } else {
        setTable({
          ...table,
          sortField: e.sortField,
          sortOrder: e.sortOrder
        });
      }
    },
    onAddRowAction: props.onAddRowAction,
    onRemoveRowAction:
      props.includeRemoveRowColumn === "show" ? onRemoveRow : undefined,
    hasRemoveRowBuffer: props.includeRemoveRowColumn === "buffer",
    emptyMessage: "No defaults have been added."
  };

  const dataTable = createDataTable<
    BaselineDefaultsRegulationsFormDefaults,
    TableState
  >(getColumns(), tableProps);

  return <div>{dataTable}</div>;
};

export default DefaultsRegulationsFormTable;
