import React from "react";
import format from "date-fns/format";
import parse from "date-fns/parseISO";

export interface FormattedDateProps {
  value: string | Date | undefined;
  useUtcDate?: boolean;
}

const FormattedDate: React.FC<FormattedDateProps> = function (props) {
  const addUtcMinutesIfApplicable = (date: Date) => {
    // TODO T-4614 - Look for a more elegant solution
    // 1. Server has a UTC datetime and cuts off time
    // 2. Browser converts UTC to local datetime
    // 3. Browser formats the date from the local datetime
    // Per #3, we aren't getting the right date because it's UTC less hours for United States timezones
    if (props.useUtcDate) {
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    }
  };

  if (props.value == null) {
    return <span></span>;
  } else if (typeof props.value === "string") {
    const date = parse(props.value);
    addUtcMinutesIfApplicable(date);
    return <span>{format(date, "MM/dd/yyyy")}</span>;
  } else {
    addUtcMinutesIfApplicable(props.value);
    return <span>{format(props.value, "MM/dd/yyyy")}</span>;
  }
};

export function createFormattedDate(rowData: any, column: any) {
  return <FormattedDate value={rowData[column.field]} useUtcDate={false} />;
}

export function createUtcFormattedDate(rowData: any, column: any) {
  return <FormattedDate value={rowData[column.field]} useUtcDate={true} />;
}

FormattedDate.defaultProps = {
  useUtcDate: false
};

export default FormattedDate;
