import React, { useState, useEffect } from "react";
import { Sidebar } from "primereact/sidebar";
import useStyle from "../useStyle";
import Spacer from "components/controls/Spacer";
import SecondaryButton from "components/controls/SecondaryButton";
import Heading from "components/controls/Heading";
import { BestRenewalOfferColumnConfiguration } from "api/generated";
import listUtils from "utils/listUtils";
import { Checked } from "components/controls/Checkbox";
import SelectAll, {
  SelectAllCheckboxesProps
} from "components/controls/SelectAll";

import ScrollPanel from "components/layouts/ScrollPanel";

export interface ColumnSelectorSideBarProps {
  isVisible: boolean;
  onClose: (
    columnConfigurations: BestRenewalOfferColumnConfiguration[]
  ) => void;
  columnConfigurations: BestRenewalOfferColumnConfiguration[];
}

interface ColumnSelectorSideBarState {
  columnConfigurations: BestRenewalOfferColumnConfiguration[];
}

const ColumnSelectorSideBar: React.FC<ColumnSelectorSideBarProps> = function (
  props
) {
  const classes = useStyle();
  const [state, setState] = useState<ColumnSelectorSideBarState>({
    columnConfigurations: props.columnConfigurations
  });

  useEffect(() => {
    setState({
      columnConfigurations: props.columnConfigurations
    });
  }, [props.isVisible, props.columnConfigurations]);

  const getColumnCheckboxes = function () {
    const checkboxes: SelectAllCheckboxesProps[] = state.columnConfigurations
      .filter(
        (c) =>
          c.field &&
          !["comments", "unit", "property", "rpm"].includes(
            c.field.toLowerCase()
          )
      )
      .map((config) => {
        return {
          label: config.header,
          checked: !config.isHidden,
          labelPosition: "right",
          onChange: (checked: Checked) =>
            setState({
              ...state,
              columnConfigurations: listUtils.copyListWithNewCustomKeyedItem(
                state.columnConfigurations,
                { ...config, isHidden: checked !== true },
                "field"
              )
            })
        };
      });

    return (
      <SelectAll
        checkboxes={checkboxes}
        onChange={(checked: Checked) =>
          setState({
            ...state,
            columnConfigurations: state.columnConfigurations.map((config) => {
              return { ...config, isHidden: checked !== true };
            })
          })
        }
      />
    );
  };

  return (
    <Sidebar
      visible={props.isVisible}
      position="left"
      className="ui-sidebar-sm"
      onHide={() => props.onClose(state.columnConfigurations)}
      style={{ width: "25em" }}
    >
      <div className={classes.columnSelectionSidePanelRoot}>
        <div className={classes.columnSelectionSidePanelContent}>
          <Heading level="1">Select Columns</Heading>
          <Spacer orientation="v" size="l" />
          <div style={{ height: "82.5%" }}>
            <ScrollPanel>{getColumnCheckboxes()}</ScrollPanel>
          </div>
          <Spacer orientation="v" size="l" />
          <div>
            <SecondaryButton
              title="Close"
              type="notice"
              onClick={() => {
                props.onClose(state.columnConfigurations);
              }}
            />
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default ColumnSelectorSideBar;
