import { Identity } from "components/IdentityProvider";
import { UserPermissions } from "api";

type PermissionName = keyof UserPermissions;

const hasPermission = function (
  identity: Identity,
  permissionName: PermissionName
): boolean {
  return identity.getPermissions()[permissionName] === true;
};

const shouldUserNavigateToSummaryPageByDefault = function (
  identity: Identity
): boolean {
  return hasPermission(identity, "prefersSummaryView");
};

const isAdmin = function (identity: Identity): boolean {
  return hasPermission(identity, "canAddEditDeleteUser");
};

export default {
  shouldUserNavigateToSummaryPageByDefault,
  isAdmin
};
