import React, { useState } from "react";
import { Option, RenewalFollowUpFormRollup } from "api";
import createDataTable, {
  IPagedTableState,
  ColumnDefinition,
  DataTableProps
} from "components/controls/createTable";

export interface RenewalFollowUpTableProps {
  rollups: RenewalFollowUpFormRollup[];
  rollupTotals: RenewalFollowUpFormRollup;
  batchKey: string;
  pagingState: IPagedTableState;
  updatePagingState: (state: IPagedTableState) => void;
}

interface TableState extends IPagedTableState {
  divisionFilter?: Option;
  sortField?: any;
  sortOrder?: any;
  sortState?: "unsorted" | "asc" | "desc" | undefined;
}

const RenewalFollowUpTable: React.FC<RenewalFollowUpTableProps> = function (
  props
) {
  const [table, setTable] = useState<TableState>({
    offset: 0,
    limit: 10,
    sortOrder: 1
  });

  const frozenTotalsRows: RenewalFollowUpFormRollup[] = props.rollupTotals
    ? [props.rollupTotals]
    : [];

  const getColumns = function (): any[] {
    let columns = [] as ColumnDefinition<
      RenewalFollowUpFormRollup,
      keyof RenewalFollowUpFormRollup
    >[];

    columns = columns.concat([
      {
        field: "propertyName",
        header: "Property",
        style: { width: "10%", textAlign: "center" }
      },
      {
        field: "totalCount",
        header: "Total",
        style: { width: "10%", textAlign: "center" }
      },
      {
        field: "renewedCount",
        header: "Renewed",
        style: { width: "10%", textAlign: "center" }
      },
      {
        field: "noticeToVacateCount",
        header: "NTV",
        style: { width: "10%", textAlign: "center" }
      },
      {
        field: "monthToMonthCount",
        header: "MTM",
        style: { width: "10%", textAlign: "center" }
      },
      {
        field: "transferCount",
        header: "Transfer",
        style: { width: "10%", textAlign: "center" }
      },
      {
        field: "toBeDeterminedCount",
        header: "TBD",
        style: { width: "10%", textAlign: "center" }
      },
      {
        field: "averageDaysToFinalizeRenewals",
        header: "Avg Days to Finalize Renewals",
        style: { width: "10%", textAlign: "center" }
      },
      {
        field: "averageDaysToFinalizeNoticeToVacate",
        header: "Avg Days to Finalize NTV",
        style: { width: "10%", textAlign: "center" }
      },
      {
        field: "renewalsWithinGoalPercentage",
        header: "% Renewals within goal (45days)",
        style: { width: "10%", textAlign: "center" }
      },
      {
        field: "noticeToVacateWithinGoalPercentage",
        header: "% NTV within goal (45days)",
        style: { width: "10%", textAlign: "center" }
      },
      {
        field: "averageDeviationFromOfferDollars",
        header: "$Avg Deviation from offer",
        style: { width: "10%", textAlign: "center" }
      }
    ]);

    return columns;
  };

  const rollups = props.rollups || [];

  const tableProps: DataTableProps<RenewalFollowUpFormRollup, TableState> = {
    value: rollups,
    frozenValue: frozenTotalsRows,
    state: table,
    updateState: setTable,
    // TODO: This functionality only works when added at this leve. I was unable to get it to work when I moved it up to the createDataTable function
    sortField: table.sortField,
    sortOrder: table.sortOrder,
    onSort: (e: any) => {
      //clear sort column
      if (
        table.sortOrder === -1 &&
        e.sortOrder === 1 &&
        table.sortField === e.sortField
      ) {
        setTable({
          ...table,
          sortField: undefined,
          sortOrder: undefined
        });
      } else {
        setTable({
          ...table,
          sortField: e.sortField,
          sortOrder: e.sortOrder
        });
      }
    },
    emptyMessage: "No results."
  };

  const dataTable = createDataTable<RenewalFollowUpFormRollup, TableState>(
    getColumns(),
    tableProps
  );

  return <div>{dataTable}</div>;
};

export default RenewalFollowUpTable;
