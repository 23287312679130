import React from "react";
import { Route } from "react-router-dom";
import routes from "components/routes";
import BestRenewalOffersDefaultPage from "components/forms/BestRenewalOffersPage/BestRenewalOffersDefaultPage";
import BestRenewalOffersActualsPage from "components/forms/BestRenewalOffersPage/BestRenewalOffersActualsPage";
import ReviewSummaryPage from "components/forms/ReviewSummaryPage";
import RenewalFollowUpPage from "components/forms/RenewalFollowUpPage";
import ActualResultsSummaryPage from "components/forms/ActualResultsSummaryPage";

export default () => (
  <>
    <Route
      exact
      path={routes.bestRenewalOffers()}
      component={BestRenewalOffersDefaultPage}
    />
    <Route exact path={routes.reviewSummary()} component={ReviewSummaryPage} />
    <Route
      exact
      path={routes.bestRenewalOffersActuals()}
      component={BestRenewalOffersActualsPage}
    />
    <Route
      exact
      path={routes.renewalFollowUp()}
      component={RenewalFollowUpPage}
    />
    <Route
      exact
      path={routes.actualResultsSummary()}
      component={ActualResultsSummaryPage}
    />
  </>
);
