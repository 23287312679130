import React from "react";
import { Route } from "react-router-dom";
import BatchListPage from "components/forms/BatchListPage";
import AdminRoutes from "./AdminRoutes";
import DefaultsAdminRoutes from "./DefaultsAdminRoutes";
import BatchDefaultsRoutes from "./BatchDefaultsRoutes";
import Header from "components/forms/Header";
import useStyle from "./useStyle";
import CreateBatchPage from "components/forms/CreateBatchPage";
import HomePage from "components/forms/HomePage";
import routes from "components/routes";
import BestRenewalOffersRoutes from "./BestRenewalOffersRoutes";
import SignOut from "components/forms/SignOut";
import AzureADIdentityProvider from "components/AzureADIdentityProvider";

const Main: React.FC = function() {
  const classes = useStyle();

  return (
    <AzureADIdentityProvider>
      <div className={classes.main}>
        <div className={classes.headerContent}>
          <Header />
        </div>
        <div className={classes.mainContent}>
          <Route exact path={routes.home} component={HomePage} />
          <Route exact path={routes.createBatch} component={CreateBatchPage} />
          <Route exact path={routes.batchListPage} component={BatchListPage} />
          <Route exact path={routes.signOut} component={SignOut} />
          <AdminRoutes />
          <DefaultsAdminRoutes />
          <BatchDefaultsRoutes />
          <BestRenewalOffersRoutes />
        </div>
      </div>
    </AzureADIdentityProvider>
  );
};
export default Main;
