import React, { useState } from "react";
import useStyle from "./useStyle";
import { Checked } from "../Checkbox";
import listUtils from "utils/listUtils";
import SelectAll, { SelectAllCheckboxesProps } from "../SelectAll";
import Searchbox from "../Searchbox";
import utils from "utils";
import ScrollPanel from "components/layouts/ScrollPanel";

export interface FilteredSelectionsItemProps {
    value: string;
    isSelected: boolean;
}

export interface FilterDropdownProps {
    items: FilteredSelectionsItemProps[];
    onItemsChange: (items: FilteredSelectionsItemProps[]) => void;
}

type ComponentState = {
    valueFilter?: string;
};

const FilteredSelections: React.FC<FilterDropdownProps> = function (props) {
    const [state, setState] = useState<ComponentState>({});
    const classes = useStyle(props);
    const filteredItems = props.items.filter(item =>
        utils.stringContainsCaseInsensitive(item.value, state.valueFilter || "")
    );
    var listarray = [...props.items]; // make a separate copy of the array
    const checkboxes: SelectAllCheckboxesProps[] = filteredItems.map(item => {
        listarray = listarray.filter(function (el) {
            return !filteredItems.includes(el);
        });

        listarray = props.items.map(item => {
            let item2 = listarray.find(i2 => i2.value === item.value);
            if (item2) {
                item.isSelected = false;
            } else {
                //assign nothing
            } 
            return item2 ? { value: item.value, isSelected: item.isSelected } : item;
        });

        return {
            label: item.value,
            checked: item.isSelected,
            labelPosition: "right",
            onChange: (checked: Checked) => {
                const isSelected = checked === true;
                const newItems = listUtils.copyListWithNewCustomKeyedItem(
                    props.items,
                    { ...item, isSelected: isSelected },
                    "value"
                );

                props.onItemsChange(
                    listUtils.sortItemsBySingleKeyedItem(newItems, "value")
                );
            }
        };
    });

  const numberOfSelectedItems = props.items.filter(item => item.isSelected)
    .length;
  const numberOfItemsTotal = filteredItems.length;
  const informationText =
    numberOfItemsTotal > 0
      ? `${numberOfSelectedItems} selected`
      : "No results found";

  return (
    <div className={classes.innerRoot}>
      <div className={classes.searchBox}>
        <Searchbox
          label="Filter"
          value={state.valueFilter}
          onChange={v => setState({ ...state, valueFilter: v })}
          mode="filter"
        />
      </div>
      <ScrollPanel heightInPixels={250}>
        <div className={classes.resultsMessage}>{informationText}</div>
        <SelectAll
          checkboxes={listUtils.sortItemsBySingleKeyedItem(checkboxes, "label")}
          onChange={(checked: Checked) => {
            const isSelected = checked === true;
            const newItems = props.items.map(item => {
              return { ...item, isSelected: isSelected };
            });

            props.onItemsChange(
              listUtils.sortItemsBySingleKeyedItem(newItems, "value")
            );
          }}
          hideSelectAll={!utils.stringIsNullOrEmpty(state.valueFilter)}
        />
      </ScrollPanel>
    </div>
  );
};

export default FilteredSelections;
