import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end"
  },
  buttonContainer: { display: "flex", justifyContent: "flex-end" },
  calendar: { width: "100%" },
  mainContentColumn: { margin: "20px auto 0 auto" }
}));
