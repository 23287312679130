import { useContext } from "react";
import { IdentityContext } from "components/IdentityProvider";
import React from "react";
import utils from "utils";
import { UserPermissions } from "api";
import Spinner from "components/controls/Spinner";

type PermissionName = keyof UserPermissions;

export interface RequirePermissionProps {
  permissions: PermissionName[];
  accessDenied?: "page" | React.ReactElement | null | undefined;
}

const RequirePermission: React.FC<RequirePermissionProps> = function(props) {
  const identity = useContext(IdentityContext);
  const permissions = identity.getPermissions();
  if (utils.isObjectEmpty(permissions)) {
    return <Spinner isVisible={true} />;
  }

  for (const name of props.permissions) {
    const hasPermission = permissions[name] === true;
    if (!hasPermission) {
      if (props.accessDenied === "page")
        return <div>You do not have permission to view this page.</div>;
      if (props.accessDenied == null) return null;
      return props.accessDenied;
    }
  }

  return <>{props.children}</>;
};

export default RequirePermission;
