import React, { useState } from "react";
import Page from "components/layouts/Page";
import Dropdown from "components/controls/Dropdown";
import Spacer from "components/controls/Spacer";
import useStyle from "./useStyle";
import parseDate from "date-fns/parseISO";
import PrimaryButton from "components/controls/PrimaryButton";
import { useRemoteData, responseHandlers } from "components/useRemoteData";
import {
  CreateBatchFormClient,
  CreateBatchForm,
  ApiException,
  CreateBatchFormBatchType
} from "api";
import PropertyChooser from "components/parts/PropertyChooser";
import { useApiEndpoint } from "components/useApiEndpoint";
import Validation from "components/controls/Validation";
import Menu from "../Menu";
import routes from "components/routes";
import { useHistory } from "react-router";
import ContentArea from "components/layouts/ContentArea";
import RequirePermission from "../RequirePermission";
import Spinner from "components/controls/Spinner";
import useMessageArea from "components/useMessageArea";
import utils from "utils";
import Column from "components/controls/Column";
import Calendar, { CalendarType } from "components/controls/Calendar";
import listUtils from "utils/listUtils";
import BatchTypeSelectionArea from "components/forms/BatchTypeSelectionArea";

interface PageState {
  form: CreateBatchForm;
}

const CreateBatchPage: React.FC = function () {
  const classes = useStyle();
  const history = useHistory();
  const [page, setPage] = useState<PageState>({
    form: {}
  });

  const [
    messageAreaState,
    setMessageBasedOnForm,
    setMessageBasedOnApiException
  ] = useMessageArea();

  const setForm = function (form: CreateBatchForm) {
    const mutableForm = utils.cloneObject(form);
    mutableForm.startDate = mutableForm.startDate
      ? parseDate(mutableForm.startDate as any)
      : undefined;
    mutableForm.endDate = mutableForm.endDate
      ? parseDate(mutableForm.endDate as any)
      : undefined;

    setPage({
      ...page,
      form: mutableForm
    });
  };

  const [dataState] = useRemoteData(
    useApiEndpoint(CreateBatchFormClient, "getCreateBatchForm"),
    [],
    {
      autoFetch: true,
      onChange: (d) => d && setForm(d)
    }
  );

  const [submitState, submit] = useRemoteData(
    useApiEndpoint(CreateBatchFormClient, "createBatch"),
    [page.form],
    {
      onChange: (d) => {
        setMessageBasedOnForm(d);
        d && setForm(d);

        if (!d) {
          return;
        }

        history.push(routes.batchdefaults.growthRates(d.key, "createBatch"));
      },

      onError: (error: ApiException) => {
        responseHandlers.use({
          "400": (ex: ApiException) => {
            const form = ex.result;
            setMessageBasedOnForm(form);
            setForm(form);
          },
          _: (ex) => {
            setMessageBasedOnApiException(ex);
          }
        })(error);
      }
    }
  );

  const onSubmit = function () {
    submit([]);
  };

  const spinner = (
    <Spinner isVisible={dataState.isFetching || submitState.isFetching} />
  );

  //See T-4258 for refactoring improvements
  if (
    (page.form == null ||
      page.form.divisions == null ||
      page.form.batchTypes == null) &&
    (dataState.isFetching || submitState.isFetching)
  ) {
    return spinner;
  } else if (page.form == null) {
    return messageAreaState.messageArea;
  }

  // const startDate = page.form.startDate as any;
  // const endDate = page.form.endDate as any;

  const submitButton = (
    <PrimaryButton
      disabled={submitState.isFetching}
      onClick={() => onSubmit()}
      title="SUBMIT"
    />
  );

  const requiresStartDate =
    page.form.selectedBatchTypes &&
    listUtils.firstOrDefault(
      page.form.selectedBatchTypes,
      (item) => item.requiresStartDate
    );

  const updateStartAndEndDates = (
    firstDate: Date | null | undefined,
    secondDate: Date | null | undefined
  ) => {
    if (!(page && page.form)) {
      return;
    }

    const startDate = requiresStartDate ? firstDate : null;
    const endDate = requiresStartDate ? secondDate : firstDate;

    setPage({
      ...page,
      form: {
        ...page.form,
        startDate: startDate || undefined,
        endDate: endDate || undefined
      }
    });
  };

  const getBatchTypes = function () {
    if (page.form.batchTypes) {
      return page.form.batchTypes.filter((b) => b.displayValue !== "One-Off");
    }
    return [];
  };

  const onSelectedBatchTypesChange = function (
    selectedBatchTypes: CreateBatchFormBatchType[]
  ) {
    setForm({
      ...page.form,
      selectedBatchTypes: selectedBatchTypes
    });
  };

  return (
    <Page menu={<Menu title="CREATE BATCH" actionArea={submitButton} />}>
      <RequirePermission permissions={["canCreateBatch"]} accessDenied="page">
        {spinner}
        {messageAreaState.messageArea}
        <Column className={classes.mainContentColumn} size="8">
          <div className={classes.contentContainer}>
            <ContentArea columnSize={"4"}>
              <div>
                {page.form.formValidationErrors &&
                  page.form.formValidationErrors.map((e) => (
                    <Validation message={e} />
                  ))}
                <Dropdown
                  label="Division"
                  // TODO Move this functionality to the server (required division)?
                  hideClear={true}
                  validationMessage={
                    page.form.fieldValidationErrors &&
                    page.form.fieldValidationErrors["SelectedDivision"]
                  }
                  selectedValue={page.form.selectedDivision}
                  options={page.form.divisions}
                  onChange={(v) =>
                    setForm({ ...page.form, selectedDivision: v })
                  }
                />
                <Spacer orientation="v" size="l" />
                <BatchTypeSelectionArea
                  batchTypes={getBatchTypes()}
                  selectedBatchTypes={page.form.selectedBatchTypes || []}
                  onSelectedBatchTypesChange={onSelectedBatchTypesChange}
                  validationMessage={
                    page.form.fieldValidationErrors &&
                    page.form.fieldValidationErrors["SelectedBatchTypes"]
                  }
                />
                <Spacer orientation="v" size="l" />
                <Calendar
                  type={
                    requiresStartDate
                      ? CalendarType.RangeSelection
                      : CalendarType.SingleSelection
                  }
                  validationMessage={
                    page.form.fieldValidationErrors &&
                    (page.form.fieldValidationErrors["StartDate"] ||
                      page.form.fieldValidationErrors["EndDate"])
                  }
                  onChange={updateStartAndEndDates}
                />
                <Spacer orientation="v" size="xxl" />
              </div>
            </ContentArea>
            <Spacer orientation="h" size="xxl" />
            <ContentArea columnSize={"7"}>
              <PropertyChooser
                properties={
                  page.form.selectedDivision &&
                  page.form.selectedDivision.properties
                }
                selectedProperties={
                  page.form.selectedDivision &&
                  page.form.selectedDivision.selectedProperties
                }
                validationMessage={
                  page.form.selectedDivision &&
                  page.form.selectedDivision.fieldValidationErrors &&
                  page.form.selectedDivision.fieldValidationErrors["Properties"]
                }
                onChange={(p) => {
                  if (page.form.selectedDivision != null) {
                    setForm({
                      ...page.form,
                      selectedDivision: {
                        ...page.form.selectedDivision,
                        selectedProperties: p
                      }
                    });
                  }
                }}
              />
            </ContentArea>
          </div>
        </Column>
      </RequirePermission>
    </Page>
  );
};

export default CreateBatchPage;
