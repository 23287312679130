import {
  ReviewSummaryForm,
  CommonSummaryFormComment,
  ReviewSummaryFormClient,
  ReviewSummaryFormFilters,
  CommonSummaryRollupKind,
  CommonSummaryFormRollup
} from "api/generated";
import { makeRemoteRequest } from "components/useRemoteData";
import { FunctionPropertyNames } from "components/useApiEndpoint";
import { Identity } from "components/IdentityProvider";
import routes from "components/routes";
import { AtlasActionTypes } from "../AtlasActionTypes";
import { AtlasHistory } from "../AtlasStateTypes";
import { AtlasActions } from "../AtlasActions";
import { getApiEndpoint } from "components/useApiEndpoint";

export const ReviewSummaryPageActionMethods = {
  get: function (
    dispatcher: React.Dispatch<AtlasActions>,
    identity: Identity,
    history: AtlasHistory
  ) {
    const makeFormServerCall = function (
      methodName: FunctionPropertyNames<ReviewSummaryFormClient>,
      bestRenewalOffersKey: string,
      form: ReviewSummaryForm,
      successCallback: (
        returnValue:
          | ReviewSummaryForm
          | CommonSummaryFormComment[]
          | null
          | undefined
      ) => void
    ) {
      dispatcher({
        type: AtlasActionTypes.UpdateLoadingState,
        loadingState: { isLoading: true }
      });
      makeRemoteRequest(
        getApiEndpoint(identity, ReviewSummaryFormClient, methodName),
        [bestRenewalOffersKey, form]
      )
        .then((response) => {
          successCallback(response.data);
        })
        .catch(() => {
          dispatcher({
            type: AtlasActionTypes.ShowErrorState,
            messages: []
          });
        });
    };
    const callFormDispatcher = function (
      formData:
        | ReviewSummaryForm
        | CommonSummaryFormComment[]
        | null
        | undefined,
      callback?: () => void | undefined
    ) {
      dispatcher({
        type: AtlasActionTypes.ReviewSummaryPageApplyFiltersAndUpdateForm,
        form: formData as ReviewSummaryForm
      });
      callback && callback();
    };
    const getForm = function (bestRenewalOffersKey: string): void {
      dispatcher({
        type: AtlasActionTypes.ReviewSummaryPageResetState
      });
      dispatcher({
        type: AtlasActionTypes.UpdateLoadingState,
        loadingState: { isLoading: true }
      });

      makeRemoteRequest(
        getApiEndpoint(identity, ReviewSummaryFormClient, "getForm"),
        [bestRenewalOffersKey]
      )
        .then((response) => {
          dispatcher({
            type: AtlasActionTypes.ReviewSummaryPageSetInitialForm,
            form: response.data as ReviewSummaryForm
          });
        })
        .catch(() => {
          dispatcher({
            type: AtlasActionTypes.ShowErrorState,
            messages: []
          });
        });
    };
    const updateFormState = function (form: ReviewSummaryForm): void {
      dispatcher({
        type: AtlasActionTypes.ReviewSummaryPageUpdateForm,
        form: form
      });
    };

    const applyFilters = function (key: string, form: ReviewSummaryForm): void {
      makeFormServerCall("getFormForFilters", key, form, (formData) => {
        dispatcher({
          type: AtlasActionTypes.ReviewSummaryPageApplyFiltersAndUpdateForm,
          form: formData as ReviewSummaryForm
        });

        dispatcher({
          type: AtlasActionTypes.UpdateLoadingState,
          loadingState: { isLoading: false }
        });
      });
    };
    const updateFilters = function (
      bestRenewalOffersKey: string,
      filters: ReviewSummaryFormFilters
    ): void {
      dispatcher({
        type: AtlasActionTypes.ReviewSummaryPageUpdateFilters,
        filters: filters
      });
    };
    const approveForm = function (
      bestRenewalOffersKey: string,
      form: ReviewSummaryForm
    ): void {
      makeFormServerCall("approveForm", bestRenewalOffersKey, form, (data) =>
        callFormDispatcher(data, () => history.push(routes.batchListPage))
      );
    };
    const rejectForm = function (
      bestRenewalOffersKey: string,
      form: ReviewSummaryForm
    ): void {
      makeFormServerCall("rejectForm", bestRenewalOffersKey, form, (data) =>
        callFormDispatcher(data, () => history.push(routes.batchListPage))
      );
    };
    const addUnitComment = function (
      bestRenewalOffersKey: string,
      unitKey: string,
      comment: string
    ): void {
      dispatcher({
        type: AtlasActionTypes.UpdateLoadingState,
        loadingState: { isLoading: true }
      });

      makeRemoteRequest(
        getApiEndpoint(identity, ReviewSummaryFormClient, "addUnitComment"),
        [bestRenewalOffersKey, unitKey, comment]
      )
        .then((response) => {
          const data = response.data;
          if (data) {
            dispatcher({
              type: AtlasActionTypes.ReviewSummaryPageAddUnitComment,
              unitKey: unitKey,
              comments: data
            });
          }

          dispatcher({
            type: AtlasActionTypes.UpdateLoadingState,
            loadingState: { isLoading: false }
          });
        })
        .catch(() => {
          dispatcher({
            type: AtlasActionTypes.ShowErrorState,
            messages: []
          });
        });
    };
    const deleteUnitComment = function (
      bestRenewalOffersKey: string,
      unitKey: string,
      commentId: number
    ): void {
      dispatcher({
        type: AtlasActionTypes.UpdateLoadingState,
        loadingState: { isLoading: true }
      });

      makeRemoteRequest(
        getApiEndpoint(identity, ReviewSummaryFormClient, "deleteUnitComment"),
        [bestRenewalOffersKey, unitKey, commentId]
      )
        .then((response) => {
          const data = response.data;
          if (data) {
            dispatcher({
              type: AtlasActionTypes.BestRenewalOffersPageDeleteUnitComment,
              unitKey: unitKey,
              comments: data
            });
          }
        })
        .catch(() => {
          dispatcher({
            type: AtlasActionTypes.ShowErrorState,
            messages: []
          });
        });
    };
    const addPropertyComment = function (
      bestRenewalOffersKey: string,
      propertyId: string,
      comment: string
    ) {
      dispatcher({
        type: AtlasActionTypes.UpdateLoadingState,
        loadingState: { isLoading: true }
      });

      makeRemoteRequest(
        getApiEndpoint(identity, ReviewSummaryFormClient, "addPropertyComment"),
        [bestRenewalOffersKey, propertyId, comment]
      )
        .then((response) => {
          const data = response.data;
          if (data) {
            dispatcher({
              type: AtlasActionTypes.ReviewSummaryPageAddPropertyComments,
              propertyKey: propertyId,
              comments: data
            });
          }

          dispatcher({
            type: AtlasActionTypes.UpdateLoadingState,
            loadingState: { isLoading: false }
          });
        })
        .catch(() => {
          dispatcher({
            type: AtlasActionTypes.ShowErrorState,
            messages: []
          });
        });
    };
    const deletePropertyComment = function (
      bestRenewalOffersKey: string,
      propertyKey: string,
      commentId: number
    ) {
      dispatcher({
        type: AtlasActionTypes.UpdateLoadingState,
        loadingState: { isLoading: true }
      });

      makeRemoteRequest(
        getApiEndpoint(
          identity,
          ReviewSummaryFormClient,
          "deletePropertyComment"
        ),
        [bestRenewalOffersKey, propertyKey, commentId]
      )
        .then((response) => {
          const data = response.data;
          if (data) {
            dispatcher({
              type: AtlasActionTypes.BestRenewalOffersPageDeletePropertyComment,
              propertyKey: propertyKey,
              comments: data
            });
          }
        })
        .catch(() => {
          dispatcher({
            type: AtlasActionTypes.ShowErrorState,
            messages: []
          });
        });
    };
    const updateActiveTab = function (activeTabView: CommonSummaryRollupKind) {
      dispatcher({
        type: AtlasActionTypes.ReviewSummaryPageUpdateActiveTab,
        activeTabView: activeTabView
      });
    };

    const updateRollupSelection = function (
      rollupKind: CommonSummaryRollupKind,
      selectedRollups: CommonSummaryFormRollup[]
    ) {
      dispatcher({
        type: AtlasActionTypes.ReviewSummaryPageUpdateRollupSelection,
        rollupKind: rollupKind,
        selectedRollups: selectedRollups
      });
    };

    const updateConfirmApprovalDialog = function (isVisible: boolean) {
      dispatcher({
        type: AtlasActionTypes.ReviewSummaryPageConfirmApprovalDialog,
        isVisible: isVisible
      });
    };

    const updateRejectionReason = function (rejectionReason: string) {
      dispatcher({
        type: AtlasActionTypes.ReviewSummaryPageUpdateRejectionReason,
        rejectionReason: rejectionReason
      });
    };

    const updateConfirmRejectionDialog = function (isVisible: boolean) {
      dispatcher({
        type: AtlasActionTypes.ReviewSummaryPageConfirmRejectionDialog,
        isVisible: isVisible
      });
    };

    return {
      getForm,
      updateFormState,
      updateFilters,
      applyFilters,
      approveForm,
      rejectForm,
      addUnitComment,
      addPropertyComment,
      deleteUnitComment,
      deletePropertyComment,
      updateActiveTab,
      updateRollupSelection,
      updateConfirmApprovalDialog,
      updateRejectionReason,
      updateConfirmRejectionDialog
    };
  }
};
