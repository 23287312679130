import React, { useEffect, useState } from "react";
import Label, { withLabel } from "../Label";
import { Calendar as PrimeReactCalendar } from "primereact/calendar";
import useStyle from "./useStyle";
import ValidationWrapper from "../ValidationWrapper";
import Spacer from "../Spacer";

export enum CalendarType {
  SingleSelection = 1,
  RangeSelection = 2
}

interface CalendarProps {
  type: CalendarType;
  label?: string | undefined;
  validationMessage?: string | undefined;
  appendTo?: any;
  onChange: (firstDate: Date | undefined, secondDate: Date | undefined) => void;
}

const Calendar: React.FC<CalendarProps> = (props) => {
  const classes = useStyle();

  const [dates, setDates] = useState<Date | Date[] | undefined>(undefined);

  useEffect(() => {
    setDates(undefined);
  }, [props.type]);

  function getDate(date: Date | Date[] | null | undefined, index: number) {
    if (!date) {
      return null;
    }

    if (Array.isArray(date)) {
      return date[index];
    }
    return date;
  }

  const label =
    props.label !== null
      ? props.label
      : props.type === CalendarType.RangeSelection
      ? "Date Range"
      : "Date";

  return (
    <span className="atlasPrimeOverride-input-with-validation">
      <Label label={props.label || label} />
      <Spacer orientation="h" />
      <ValidationWrapper showErrorState={props.validationMessage != null}>
        <PrimeReactCalendar
          appendTo={props.appendTo}
          className={classes.calendar}
          tooltip={props.validationMessage}
          dateFormat="mm/dd/yy"
          selectionMode={
            props.type === CalendarType.RangeSelection ? "range" : undefined
          }
          value={dates}
          readOnlyInput={true}
          onChange={(e: any) => {
            setDates(e.value);
            var dates: Array<Date> = e.value || [];
            props.onChange(
              getDate(dates, 0) || undefined,
              getDate(dates, 1) || undefined
            );
          }}
        />
      </ValidationWrapper>
    </span>
  );
};

export default withLabel(Calendar);
