import React from "react";
import Spacer from "components/controls/Spacer";
import {
  DefaultsAdminGtlAndLtlForm,
  DefaultsAdminGtlAndLtlFormDefaults,
  DefaultsAdminGtlAndLtlFormDivision,
  DefaultsPageConfiguration,
  BaselineDefaultsGtlAndLtlFormDropRateDefaults
} from "api";
import DefaultsGtlAndLtlFormTable from "components/forms/DefaultsGtlAndLtlFormTable";
import Dropdown from "components/controls/Dropdown";
import Label from "components/controls/Label";
import listUtils from "utils/listUtils";
import utils from "utils";
import PrimaryButton from "components/controls/PrimaryButton";
import Section from "components/controls/Section";
import Heading from "components/controls/Heading";
import ContentArea from "components/layouts/ContentArea";
import Column from "components/controls/Column";
import DefaultsGtlAndLtlFormDropRateRangesArea from "../DefaultsGtlAndLtlFormDropRateRangesArea";
import Input from "components/controls/Input";
import useStyle from "./useStyle";

interface PageState {
  form: DefaultsAdminGtlAndLtlForm | null | undefined;
}

export interface DefaultsGtlAndLtlFormProps {
  setForm: (form: DefaultsAdminGtlAndLtlForm) => any;
  form: DefaultsAdminGtlAndLtlForm;
  submit: () => void;
  isFetching: boolean;
  onAddRowAction?: (() => void) | undefined;
  hideDivisions?: boolean | undefined;
  hideSaveButton?: boolean | undefined;
  pageConfiguration: DefaultsPageConfiguration | undefined;
}

const DefaultsGtlAndLtlForm: React.FC<DefaultsGtlAndLtlFormProps> = function (
  props: DefaultsGtlAndLtlFormProps
) {
  const cloneForm = function (): DefaultsAdminGtlAndLtlForm {
    return utils.cloneObject(props.form);
  };

  const setForm = function (form: DefaultsAdminGtlAndLtlForm) {
    props.setForm(form);
  };

  const classes = useStyle();

  const onDivisionDefaultsChange = (
    defaults: DefaultsAdminGtlAndLtlFormDefaults[]
  ) => {
    onDivisionChanged({
      ...props.form.selectedDivision,
      defaults: (defaults && defaults.length > 0 && defaults[0]) || undefined,
      isChanged: true
    });
  };

  const onDivisionDropRateRangeChange = (
    defaults: BaselineDefaultsGtlAndLtlFormDropRateDefaults
  ) => {
    onDivisionChanged({
      ...props.form.selectedDivision,
      defaultsDropRateRangeDefaults: defaults,
      isChanged: true
    });
  };

  const onDivisionDropRateRangeIsEnabledChange = (isEnabled: boolean) => {
    onDivisionChanged({
      ...props.form.selectedDivision,
      defaultsDropRateRangeDefaults: {
        ...(props.form.selectedDivision &&
          props.form.selectedDivision.defaultsDropRateRangeDefaults),
        isEnabled: isEnabled
      },
      isChanged: true
    });
  };

  const onBedroomsDefaultsChange = (
    defaults: DefaultsAdminGtlAndLtlFormDefaults[]
  ) => {
    onDivisionChanged({
      ...props.form.selectedDivision,
      bedroomDefaults: defaults,
      isChanged: true
    });
  };

  const onBedroomsDropRateRangeChange = (
    defaults: BaselineDefaultsGtlAndLtlFormDropRateDefaults
  ) => {
    onDivisionChanged({
      ...props.form.selectedDivision,
      bedroomDropRateRangeDefaults: defaults,
      isChanged: true
    });
  };

  const onBedroomsDropRateRangeIsEnabledChange = (isEnabled: boolean) => {
    onDivisionChanged({
      ...props.form.selectedDivision,
      bedroomDropRateRangeDefaults: {
        ...(props.form.selectedDivision &&
          props.form.selectedDivision.bedroomDropRateRangeDefaults),
        isEnabled: isEnabled
      },
      isChanged: true
    });
  };

  const onPropertiesDefaultsChange = (
    defaults: DefaultsAdminGtlAndLtlFormDefaults[]
  ) => {
    onDivisionChanged({
      ...props.form.selectedDivision,
      propertyBedroomDefaults: defaults,
      isChanged: true
    });
  };

  const onPropertiesDropRateRangeChange = (
    defaults: BaselineDefaultsGtlAndLtlFormDropRateDefaults
  ) => {
    onDivisionChanged({
      ...props.form.selectedDivision,
      propertyBedroomDropRateRangeDefaults: defaults,
      isChanged: true
    });
  };

  const onPropertiesDropRateRangeIsEnabledChange = (isEnabled: boolean) => {
    onDivisionChanged({
      ...props.form.selectedDivision,
      propertyBedroomDropRateRangeDefaults: {
        ...(props.form.selectedDivision &&
          props.form.selectedDivision.propertyBedroomDropRateRangeDefaults),
        isEnabled: isEnabled
      },
      isChanged: true
    });
  };

  const onEssexMaxChange = (essexMax: string) => {
    onDivisionChanged({
      ...props.form.selectedDivision,
      defaults: {
        ...(props.form.selectedDivision &&
          props.form.selectedDivision.defaults),
        essexMaxPercentage: essexMax
      },
      isChanged: true
    });
  };

  const addBedroomDefaultsRow = function (): void {
    const selectedDivision = props.form && props.form.selectedDivision;
    const newRow = {
      ...(selectedDivision && selectedDivision.defaults),
      formValidationErrors: undefined,
      fieldValidationErrors: undefined,
      isValid: false,
      isChanged: true
    };
    const currentRows =
      (selectedDivision && selectedDivision.bedroomDefaults) || [];

    onBedroomsDefaultsChange(currentRows.concat(newRow));
  };

  const addPropertyDefaultsRow = function (): void {
    const selectedDivision = props.form && props.form.selectedDivision;
    const newRow = {
      ...(selectedDivision && selectedDivision.defaults),
      formValidationErrors: undefined,
      fieldValidationErrors: undefined,
      isValid: false,
      isChanged: true
    };
    const currentRows =
      (selectedDivision && selectedDivision.propertyBedroomDefaults) || [];

    onPropertiesDefaultsChange(currentRows.concat(newRow));
  };

  const onDivisionChanged = (
    division: DefaultsAdminGtlAndLtlFormDivision | undefined
  ) => {
    const divisions = (props.form && props.form.divisions) || undefined;
    let newDivisionsList = divisions;

    if (divisions && division) {
      newDivisionsList =
        listUtils.copyListWithNewKeyedItem(divisions, division) || undefined;
    }

    const form = cloneForm();

    form.selectedDivision = division;
    form.divisions = newDivisionsList;

    setForm(form);
  };

  let selectedDivision = undefined;
  let fieldValidationErrors:
    | {
        [key: string]: string;
      }
    | undefined = undefined;
  const form = props && props.form;
  if (form) {
    selectedDivision = form.selectedDivision;
    fieldValidationErrors = form.fieldValidationErrors;
  }

  const getDivisionDropdownOrLabel = function (): JSX.Element {
    if (props.hideDivisions) {
      return <div />;
    }

    const divisions = props.form.divisions;
    const selectedDivision = props.form.selectedDivision;
    if (divisions && divisions.length > 1) {
      return (
        <Dropdown
          onChange={onDivisionChanged}
          hideClear={true}
          options={divisions}
          selectedValue={selectedDivision}
          validationMessage={
            fieldValidationErrors && fieldValidationErrors["SelectedDivision"]
          }
        />
      );
    } else if (selectedDivision) {
      return <Label label={selectedDivision.displayValue} />;
    } else {
      return <div />;
    }
  };

  const getSaveButton = function (): JSX.Element {
    if (props.hideSaveButton) {
      return <div />;
    }

    return (
      <PrimaryButton
        disabled={props.isFetching}
        onClick={() => props.submit()}
        title="SAVE"
      />
    );
  };

  const divisionsArea = getDivisionDropdownOrLabel();
  const saveButton = getSaveButton();

  return (
    <div>
      <Section title="LTL/GTL" />

      <Column size="2">{divisionsArea}</Column>

      <Spacer size="s" orientation="v" />
      <Column size="2">
        <Label label={"Essex Max %"} className={classes.smLeftPad} />
        <Input
          value={
            selectedDivision &&
            selectedDivision.defaults &&
            selectedDivision.defaults.essexMaxPercentage
          }
          onChange={onEssexMaxChange}
          validationMessage={
            selectedDivision &&
            selectedDivision.defaults &&
            selectedDivision.defaults.fieldValidationErrors &&
            selectedDivision.defaults.fieldValidationErrors[
              "EssexMaxPercentage"
            ]
          }
        />
      </Column>
      <Spacer size="l" orientation="v" />

      <ContentArea columnSize={"12"}>
        <Heading level="3">Division Defaults</Heading>
        <Spacer size="s" orientation="v" />
        <DefaultsGtlAndLtlFormDropRateRangesArea
          defaults={
            selectedDivision && selectedDivision.defaultsDropRateRangeDefaults
          }
          onChange={onDivisionDropRateRangeChange}
          pageConfiguration={props.pageConfiguration}
          onIsEnabledChanged={onDivisionDropRateRangeIsEnabledChange}
        />
        <Spacer size="s" orientation="v" />
        <DefaultsGtlAndLtlFormTable
          defaults={
            (props &&
              props.form &&
              props.form.selectedDivision &&
              props.form.selectedDivision.defaults && [
                props.form.selectedDivision.defaults
              ]) || [{}]
          }
          onChange={onDivisionDefaultsChange}
          hidePropertyColumn={true}
          hideBedroomTypeColumn={true}
          hideRemoveRowColumn={
            !(
              props.form.pageConfiguration &&
              props.form.pageConfiguration.isEditEnabled
            )
          }
          paging={false}
          bedrooms={[]}
          properties={[]}
          calculationMethods={
            (selectedDivision && selectedDivision.calculationMethodTypes) || []
          }
          includeRemoveRowColumn="buffer"
          pageConfiguration={props.pageConfiguration}
          dropRateDefaults={
            selectedDivision && selectedDivision.defaultsDropRateRangeDefaults
          }
        />
      </ContentArea>

      <Spacer size="l" orientation="v" />
      <ContentArea columnSize={"12"}>
        <Heading level="3">By Bedroom</Heading>
        <Spacer size="s" orientation="v" />
        <DefaultsGtlAndLtlFormDropRateRangesArea
          defaults={
            selectedDivision && selectedDivision.bedroomDropRateRangeDefaults
          }
          onChange={onBedroomsDropRateRangeChange}
          pageConfiguration={props.pageConfiguration}
          onIsEnabledChanged={onBedroomsDropRateRangeIsEnabledChange}
        />
        <Spacer size="s" orientation="v" />
        <DefaultsGtlAndLtlFormTable
          defaults={
            (props &&
              props.form &&
              props.form.selectedDivision &&
              props.form.selectedDivision.bedroomDefaults) ||
            undefined
          }
          onChange={onBedroomsDefaultsChange}
          hidePropertyColumn={true}
          hideRemoveRowColumn={
            !(
              props.form.pageConfiguration &&
              props.form.pageConfiguration.isEditEnabled
            )
          }
          bedrooms={
            (selectedDivision &&
              selectedDivision.bedroomTypesForBedroomDefaults) ||
            []
          }
          properties={
            (selectedDivision && selectedDivision.propertyTypes) || []
          }
          calculationMethods={
            (selectedDivision && selectedDivision.calculationMethodTypes) || []
          }
          onAddRowAction={
            props.form.pageConfiguration &&
            props.form.pageConfiguration.isEditEnabled
              ? addBedroomDefaultsRow
              : props.onAddRowAction
          }
          includeRemoveRowColumn={
            props.form.pageConfiguration &&
            props.form.pageConfiguration.isEditEnabled
              ? "show"
              : undefined
          }
          pageConfiguration={props.pageConfiguration}
          dropRateDefaults={
            selectedDivision && selectedDivision.bedroomDropRateRangeDefaults
          }
        />
      </ContentArea>

      <Spacer size="l" orientation="v" />

      <ContentArea columnSize={"12"}>
        <Heading level="3">By Property</Heading>
        <Spacer size="s" orientation="v" />
        <DefaultsGtlAndLtlFormDropRateRangesArea
          defaults={
            selectedDivision &&
            selectedDivision.propertyBedroomDropRateRangeDefaults
          }
          onChange={onPropertiesDropRateRangeChange}
          pageConfiguration={props.pageConfiguration}
          onIsEnabledChanged={onPropertiesDropRateRangeIsEnabledChange}
        />
        <Spacer size="s" orientation="v" />
        <DefaultsGtlAndLtlFormTable
          defaults={
            (props &&
              props.form &&
              props.form.selectedDivision &&
              props.form.selectedDivision.propertyBedroomDefaults) ||
            undefined
          }
          onChange={onPropertiesDefaultsChange}
          bedrooms={
            (selectedDivision &&
              selectedDivision.bedroomTypesForPropertyBedroomDefaults) ||
            []
          }
          properties={
            (selectedDivision && selectedDivision.propertyTypes) || []
          }
          calculationMethods={
            (selectedDivision && selectedDivision.calculationMethodTypes) || []
          }
          onAddRowAction={
            props.form.pageConfiguration &&
            props.form.pageConfiguration.isEditEnabled
              ? addPropertyDefaultsRow
              : props.onAddRowAction
          }
          includeRemoveRowColumn={
            props.form.pageConfiguration &&
            props.form.pageConfiguration.isEditEnabled
              ? "show"
              : undefined
          }
          pageConfiguration={props.pageConfiguration}
          dropRateDefaults={
            selectedDivision &&
            selectedDivision.propertyBedroomDropRateRangeDefaults
          }
        />
      </ContentArea>

      <Spacer size="l" orientation="v" />

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {saveButton}
      </div>
    </div>
  );
};

export default DefaultsGtlAndLtlForm;
