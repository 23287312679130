import React from "react";
import useStyle from "./useStyle";
import cssUtils from "utils/cssUtils";

interface CommentsButtonProps {
  className?: string | undefined;
  alt?: string | undefined;
  label?: string | undefined;
  onClick: () => void;
  isHighlighted: boolean;
}

const CommentsButton: React.FC<CommentsButtonProps> = function(
  props: CommentsButtonProps
) {
  const classes = useStyle();
  const labelArea = props.label ? (
    <div className={classes.commentsButtonLabel}>{props.label}</div>
  ) : (
    <div />
  );
  return (
    <button
      className={cssUtils.join(classes.commentsButton, props.className)}
      onClick={props.onClick}
    >
      <div
        className={cssUtils.conditionClass(
          classes.commentsButtonIsHighlighted,
          props.isHighlighted
        )}
      >
        <div
          className={cssUtils.join(
            classes.commentsButtonImage,
            "pi pi-comments"
          )}
          title={props.alt}
        />
      </div>
      {labelArea}
    </button>
  );
};
export default CommentsButton;
