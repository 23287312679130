import React, { useState, useEffect } from "react";
import { Sidebar } from "primereact/sidebar";
import useStyle from "../useStyle";
import Spacer from "components/controls/Spacer";
import SecondaryButton from "components/controls/SecondaryButton";
import PrimaryButton from "components/controls/PrimaryButton";
import CommentList from "../CommentList";
import { InputTextarea } from "primereact/inputtextarea";
import {
  GenericFormPropertyWithComments,
  GenericFormComment
} from "../GenericFormCommentTypes";
import ScrollPanel from "components/layouts/ScrollPanel";
import Heading from "components/controls/Heading";

export interface PropertyCommentsSideBarProps {
  bestRenewalOffersKey: string;
  isVisible: boolean;
  property: GenericFormPropertyWithComments;
  onSave: (comment: GenericFormComment) => void;
  onCancel: () => void;
  deletePropertyComment: (
    bestRenewalOffersKey: string,
    propertyId: string,
    commentId: number
  ) => void;
}

interface SideBarState {
  text: string | undefined;
}

const PropertyNoteSideBar: React.FC<PropertyCommentsSideBarProps> = function (
  props
) {
  const classes = useStyle();
  const [state, setState] = useState<SideBarState>({
    text: ""
  });

  useEffect(() => {
    setState({ text: "" });
  }, [props.property]);

  return (
    <Sidebar
      visible={props.isVisible}
      position="right"
      className="ui-sidebar-sm"
      onHide={() => props.onCancel()}
      style={{ width: "50em" }}
    >
      <ScrollPanel>
        <Heading level="2">
          <span>{props.property.displayValue}</span>
        </Heading>
        <Spacer size="l" orientation="v" />
        <InputTextarea
          className={classes.commentsArea}
          rows={10}
          value={state.text}
          onChange={(e) => setState({ text: e.currentTarget.value })}
        ></InputTextarea>
        <Spacer orientation="v" size="l" />
        <div className={classes.buttonsArea}>
          <PrimaryButton
            title="Save"
            onClick={() => {
              try {
                props.onSave({ text: state.text });
                setState({ text: "" });
              } catch {}
            }}
          />
          <Spacer orientation="h" size="l" />
          <SecondaryButton
            className={classes.cancelButton}
            title="Cancel"
            type="notice"
            onClick={() => {
              props.onCancel();
            }}
          />
        </div>
        <CommentList
          bestRenewalOffersKey={props.bestRenewalOffersKey}
          list={props.property.comments || []}
          deleteAction={props.deletePropertyComment}
        />
      </ScrollPanel>
    </Sidebar>
  );
};

export default PropertyNoteSideBar;
