import React from "react";
import Spacer from "components/controls/Spacer";
import {
  BaselineDefaultsRentControlForm,
  BaselineDefaultsRentControlFormDefaults,
  BaselineDefaultsRentControlFormDivision,
  DefaultsPageConfiguration
} from "api";
import BaselineDefaultsRentControlFormTable from "components/forms/BaselineDefaultsRentControlFormTable";
import Dropdown from "components/controls/Dropdown";
import Label from "components/controls/Label";
import listUtils from "utils/listUtils";
import utils from "utils";
import PrimaryButton from "components/controls/PrimaryButton";
import Section from "components/controls/Section";
import Heading from "components/controls/Heading";
import ContentArea from "components/layouts/ContentArea";
import Column from "components/controls/Column";

interface PageState {
  form: BaselineDefaultsRentControlForm | null | undefined;
}

export interface DefaultsRentControlFormProps {
  setForm: (form: BaselineDefaultsRentControlForm) => any;
  form: BaselineDefaultsRentControlForm;
  submit: () => void;
  isFetching: boolean;
  onAddRowAction?: (() => void) | undefined;
  hideDivisions?: boolean | undefined;
  hideSaveButton?: boolean | undefined;
  pageConfiguration: DefaultsPageConfiguration | undefined;
}

const DefaultsRentControlForm: React.FC<DefaultsRentControlFormProps> = function(
  props: DefaultsRentControlFormProps
) {
  const cloneForm = function(): BaselineDefaultsRentControlForm {
    return utils.cloneObject(props.form);
  };

  const setForm = function(form: BaselineDefaultsRentControlForm) {
    props.setForm(form);
  };

  const onPropertiesDefaultsChange = (
    defaults: BaselineDefaultsRentControlFormDefaults[]
  ) => {
    onDivisionChanged({
      ...props.form.selectedDivision,
      propertyDefaults: defaults,
      isChanged: true
    });
  };

  const onUnitDefaultsChange = (
    defaults: BaselineDefaultsRentControlFormDefaults[]
  ) => {
    onDivisionChanged({
      ...props.form.selectedDivision,
      unitDefaults: defaults,
      isChanged: true
    });
  };

  const addPropertyDefaultsRow = function(): void {
    const selectedDivision = props.form && props.form.selectedDivision;
    const newRow = {};
    const currentRows =
      (selectedDivision && selectedDivision.propertyDefaults) || [];

    onPropertiesDefaultsChange(currentRows.concat(newRow));
  };

  const addUnitDefaultsRow = function(): void {
    const selectedDivision = props.form && props.form.selectedDivision;
    const newRow = {};
    const currentRows =
      (selectedDivision && selectedDivision.unitDefaults) || [];

    onUnitDefaultsChange(currentRows.concat(newRow));
  };

  const onDivisionChanged = (
    division: BaselineDefaultsRentControlFormDivision | undefined
  ) => {
    const divisions = (props.form && props.form.divisions) || undefined;
    let newDivisionsList = divisions;

    if (divisions && division) {
      newDivisionsList =
        listUtils.copyListWithNewKeyedItem(divisions, division) || undefined;
    }

    const form = cloneForm();

    form.selectedDivision = division;
    form.divisions = newDivisionsList;

    setForm(form);
  };

  let selectedDivision = undefined;
  let fieldValidationErrors:
    | {
        [key: string]: string;
      }
    | undefined = undefined;
  const form = props && props.form;
  if (form) {
    selectedDivision = form.selectedDivision;
    fieldValidationErrors = form.fieldValidationErrors;
  }

  const getDivisionDropdownOrLabel = function(): JSX.Element {
    if (props.hideDivisions) {
      return <div />;
    }

    const divisions = props.form.divisions;
    const selectedDivision = props.form.selectedDivision;
    if (divisions && divisions.length > 1) {
      return (
        <Dropdown
          onChange={onDivisionChanged}
          hideClear={true}
          options={divisions}
          selectedValue={selectedDivision}
          validationMessage={
            fieldValidationErrors && fieldValidationErrors["SelectedDivision"]
          }
        />
      );
    } else if (selectedDivision) {
      return <Label label={selectedDivision.displayValue} />;
    } else {
      return <div />;
    }
  };

  const getSaveButton = function(): JSX.Element {
    if (props.hideSaveButton) {
      return <div />;
    }

    return (
      <PrimaryButton
        disabled={props.isFetching}
        onClick={() => props.submit()}
        title="SAVE"
      />
    );
  };

  const divisionsArea = getDivisionDropdownOrLabel();
  const saveButton = getSaveButton();

  return (
    <div>
      <Section title="RENT CONTROL" />

      <Column size="2">{divisionsArea}</Column>

      <Spacer size="l" orientation="v" />

      <ContentArea columnSize={"12"}>
        <Heading level="3">By Property</Heading>
        <Spacer size="s" orientation="v" />
        <BaselineDefaultsRentControlFormTable
          defaults={
            (props &&
              props.form &&
              props.form.selectedDivision &&
              props.form.selectedDivision.propertyDefaults) ||
            undefined
          }
          onChange={onPropertiesDefaultsChange}
          hideRemoveRowColumn={
            !(
              props.form.pageConfiguration &&
              props.form.pageConfiguration.isEditEnabled
            )
          }
          properties={
            (selectedDivision && selectedDivision.propertyTypes) || []
          }
          onAddRowAction={
            props.form.pageConfiguration &&
            props.form.pageConfiguration.isEditEnabled
              ? addPropertyDefaultsRow
              : props.onAddRowAction
          }
          includeRemoveRowColumn={
            props.form.pageConfiguration &&
            props.form.pageConfiguration.isEditEnabled
              ? "show"
              : undefined
          }
          hideUnitNumberColumn={true}
          pageConfiguration={props.pageConfiguration}
        />
      </ContentArea>

      <Spacer size="l" orientation="v" />

      <ContentArea columnSize={"12"}>
        <Heading level="3">By Unit</Heading>
        <Spacer size="s" orientation="v" />
        <BaselineDefaultsRentControlFormTable
          defaults={
            (props &&
              props.form &&
              props.form.selectedDivision &&
              props.form.selectedDivision.unitDefaults) ||
            undefined
          }
          onChange={onUnitDefaultsChange}
          hideRemoveRowColumn={
            !(
              props.form.pageConfiguration &&
              props.form.pageConfiguration.isEditEnabled
            )
          }
          properties={
            (selectedDivision && selectedDivision.propertyTypes) || []
          }
          onAddRowAction={
            props.form.pageConfiguration &&
            props.form.pageConfiguration.isEditEnabled
              ? addUnitDefaultsRow
              : props.onAddRowAction
          }
          includeRemoveRowColumn={
            props.form.pageConfiguration &&
            props.form.pageConfiguration.isEditEnabled
              ? "show"
              : undefined
          }
          pageConfiguration={props.pageConfiguration}
        />
      </ContentArea>

      <Spacer size="l" orientation="v" />

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {saveButton}
      </div>
    </div>
  );
};

export default DefaultsRentControlForm;
