import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  outerContainer: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.pallette.white
  },
  container: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: theme.pallette.white,
    height: theme.navHeight,
    minHeight: theme.navHeight,
    maxHeight: theme.navHeight,
    maxWidth: theme.maxScreenWidth,
    width: "100%"
  },
  buttons: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    flexGrow: 1,
    marginLeft: theme.marginScale.xlarge
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: theme.pallette.primary,
    fontWeight: "bold",
    marginTop: theme.marginScale.medium,
    marginBottom: theme.marginScale.medium,
    marginLeft: theme.marginScale.medium,
    borderStyle: "solid",
    borderWidth: [[0, 0, 0, 1]],
    borderColor: theme.pallette.foregroundContrast,
    "& > *": {
      marginLeft: theme.marginScale.large + theme.marginScale.medium
    }
  },
  spacer: {
    width: theme.marginScale.xlarge
  },
  logoWrapper: {
    margin: theme.marginScale.large,
    marginLeft: theme.marginScale.none
  },
  logo: {
    // width: "100px",
    height: "27px",
    marginLeft: "-3px"
  },
  helpButton: {
    paddingTop: "24px",
    height: "100%"
  }
}));
