import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.marginScale.xlarge,
    fontFamily: theme.fontFamilyEmphasis
  }
}));
