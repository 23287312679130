import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  container: {
    maxWidth: "1440px",
    width: "100%",
    height: "100%",
    minHeight: "100%",
    margin: "0 auto",
    boxSizing: "unset"
  }
}));
