import React, { useState } from "react";
import {
  Option,
  BaselineDefaultsBmrFormPropertyType,
  BaselineDefaultsBmrFormBedroomType,
  BaselineDefaultsBmrFormDefaults,
  BaselineDefaultsBmrFormAffordabilityType,
  DefaultsPageConfiguration
} from "api";
import createDataTable, {
  IPagedTableState,
  ColumnDefinition,
  DefaultInputEditorData,
  DataTableProps
} from "components/controls/createTable";

import Dropdown from "components/controls/Dropdown";
import listUtils from "utils/listUtils";

export interface DefaultsBmrFormTableProps {
  defaults?: BaselineDefaultsBmrFormDefaults[] | undefined;
  bedrooms: BaselineDefaultsBmrFormBedroomType[];
  properties: BaselineDefaultsBmrFormPropertyType[];
  affordabilityType: BaselineDefaultsBmrFormAffordabilityType[];
  onChange: (defaults: BaselineDefaultsBmrFormDefaults[]) => void;
  hideRemoveRowColumn?: boolean | undefined;
  paging?: boolean | undefined;
  onAddRowAction?: (() => void) | undefined;
  includeRemoveRowColumn?: "show" | "buffer" | undefined;
  pageConfiguration: DefaultsPageConfiguration | undefined;
}

interface TableState extends IPagedTableState {
  divisionFilter?: Option;
  sortField?: any;
  sortOrder?: any;
  sortState?: "unsorted" | "asc" | "desc" | undefined;
}

const DefaultsBmrFormTable: React.FC<DefaultsBmrFormTableProps> = function(
  props: DefaultsBmrFormTableProps
) {
  const [table, setTable] = useState<TableState>({
    offset: 0,
    limit: 10,
    sortOrder: 1
  });

  const onRowChanged = (
    oldRow: BaselineDefaultsBmrFormDefaults,
    newRow: BaselineDefaultsBmrFormDefaults
  ) => {
    if (!props.defaults) {
      return;
    }

    newRow.isChanged = true;

    props.onChange(
      listUtils.copyListWithNewItem(props.defaults, oldRow, newRow)
    );
  };

  const onRemoveRow = (row: BaselineDefaultsBmrFormDefaults) => {
    if (!props.defaults) {
      return;
    }

    const newDefaults = listUtils.copyListWithItemRemoved(props.defaults, row);
    props.onChange(newDefaults);
  };

  const onBaselineDefaultsBmrFormPropertyTypeChange = (
    defaultsRow: BaselineDefaultsBmrFormDefaults
  ) => {
    return (
      selectedProperty: BaselineDefaultsBmrFormPropertyType | undefined
    ) => {
      const newDefaultsRow: BaselineDefaultsBmrFormDefaults = {
        ...defaultsRow,
        selectedPropertyType: selectedProperty
      };

      onRowChanged(defaultsRow, newDefaultsRow);
    };
  };

  const onBaselineDefaultsBmrFormBedroomTypeChange = (
    defaultsRow: BaselineDefaultsBmrFormDefaults
  ) => {
    return (
      selectedBedroom: BaselineDefaultsBmrFormBedroomType | undefined
    ) => {
      const newDefaultsRow: BaselineDefaultsBmrFormDefaults = {
        ...defaultsRow,
        selectedBedroomType: selectedBedroom
      };

      onRowChanged(defaultsRow, newDefaultsRow);
    };
  };

  const onBaselineDefaultsBmrFormAffordabilityTypeChange = (
    defaultsRow: BaselineDefaultsBmrFormDefaults
  ) => {
    return (
      selectedAffordabilityType:
        | BaselineDefaultsBmrFormAffordabilityType
        | undefined
    ) => {
      const newDefaultsRow: BaselineDefaultsBmrFormDefaults = {
        ...defaultsRow,
        selectedAffordabilityType: selectedAffordabilityType
      };

      onRowChanged(defaultsRow, newDefaultsRow);
    };
  };

  const getPropertyDefaultsBody = function(rowData: any, column: any) {
    const defaults = rowData as BaselineDefaultsBmrFormDefaults;

    let dropDown = (
      <Dropdown
        onChange={onBaselineDefaultsBmrFormPropertyTypeChange(defaults)}
        selectedValue={defaults.selectedPropertyType}
        options={props.properties}
        style={{ minWidth: "100%", maxWidth: "100%" }}
        validationMessage={
          defaults.fieldValidationErrors &&
          defaults.fieldValidationErrors["SelectedPropertyType"]
        }
      />
    );

    let label = (
      <div>
        {defaults.selectedPropertyType &&
          defaults.selectedPropertyType.displayValue}
      </div>
    );

    if (props.pageConfiguration && props.pageConfiguration.isEditEnabled) {
      return dropDown;
    } else {
      return label;
    }
  };

  const getBedroomDefaultsBody = function(rowData: any, column: any) {
    const defaults = rowData as BaselineDefaultsBmrFormDefaults;

    let dropDown = (
      <Dropdown
        onChange={onBaselineDefaultsBmrFormBedroomTypeChange(defaults)}
        selectedValue={defaults.selectedBedroomType}
        options={props.bedrooms}
        style={{ minWidth: "100%", maxWidth: "100%" }}
        validationMessage={
          defaults.fieldValidationErrors &&
          defaults.fieldValidationErrors["SelectedBedroomType"]
        }
      />
    );

    let label = (
      <div>
        {defaults.selectedBedroomType &&
          defaults.selectedBedroomType.displayValue}
      </div>
    );

    if (props.pageConfiguration && props.pageConfiguration.isEditEnabled) {
      return dropDown;
    } else {
      return label;
    }
  };

  const getAffordabilityTypeDefaultsBody = function(rowData: any, column: any) {
    const defaults = rowData as BaselineDefaultsBmrFormDefaults;

    let dropDown = (
      <Dropdown
        onChange={onBaselineDefaultsBmrFormAffordabilityTypeChange(defaults)}
        selectedValue={defaults.selectedAffordabilityType}
        options={props.affordabilityType}
        style={{ minWidth: "100%", maxWidth: "100%" }}
        validationMessage={
          defaults.fieldValidationErrors &&
          defaults.fieldValidationErrors["SelectedAffordabilityType"]
        }
      />
    );
    let label = (
      <div>
        {defaults.selectedAffordabilityType &&
          defaults.selectedAffordabilityType.displayValue}
      </div>
    );

    if (props.pageConfiguration && props.pageConfiguration.isEditEnabled) {
      return dropDown;
    } else {
      return label;
    }
  };

  const getDefaultInputEditorData = function(): DefaultInputEditorData<BaselineDefaultsBmrFormDefaults> {
    return {
      isEditDisabled: !(
        props.pageConfiguration && props.pageConfiguration.isEditEnabled
      ),
      onChange: (
        row: BaselineDefaultsBmrFormDefaults,
        field: keyof BaselineDefaultsBmrFormDefaults,
        value: any
      ) => {
        const newDefaultsRow = {
          ...row
        };

        newDefaultsRow[field] = value;
        onRowChanged(row, newDefaultsRow);
      }
    };
  };

  const getColumns = function(): any[] {
    const columns = [] as ColumnDefinition<
      BaselineDefaultsBmrFormDefaults,
      keyof BaselineDefaultsBmrFormDefaults
    >[];

    const propertyColumnStyle = { width: "20%" };
    const bedroomTypeColumnStyle = { width: "15%" };

    return columns.concat([
      {
        header: "Property",
        style: propertyColumnStyle,
        body: getPropertyDefaultsBody
      },
      {
        header: "Bedroom",
        style: bedroomTypeColumnStyle,
        body: getBedroomDefaultsBody
      },
      {
        header: "Aff. Type",
        style: bedroomTypeColumnStyle,
        body: getAffordabilityTypeDefaultsBody
      },
      {
        field: "minAllowableDollar",
        header: "Min allowable $",
        style: { width: "10%", textAlign: "center" },
        defaultInputEditorData: getDefaultInputEditorData()
      },
      {
        field: "maxAllowableDollar",
        header: "Max allowable $",
        style: { width: "10%", textAlign: "center" },
        defaultInputEditorData: getDefaultInputEditorData()
      },
      {
        field: "minAllowablePercentage",
        header: "Min allowable %",
        style: { width: "10%", textAlign: "center" },
        defaultInputEditorData: getDefaultInputEditorData()
      },
      {
        field: "maxAllowablePercentage",
        header: "Max allowable %",
        style: { width: "10%", textAlign: "center" },
        defaultInputEditorData: getDefaultInputEditorData()
      },
      {
        field: "lastUpdatedDate",
        header: "Last updated date",
        style: { width: "10%", textAlign: "center" }
      },
      {
        field: "lastUpdatedBy",
        header: "Last updated by",
        style: { width: "10%", textAlign: "center" }
      }
    ]);
  };

  const tableProps: DataTableProps<
    BaselineDefaultsBmrFormDefaults,
    TableState
  > = {
    value: props.defaults || undefined,
    editMode: "cell",
    state: table,
    updateState: setTable,
    paging: props.paging,
    // TODO: This functionality only works when added at this leve. I was unable to get it to work when I moved it up to the createDataTable function
    sortField: table.sortField,
    sortOrder: table.sortOrder,
    onSort: (e: any) => {
      //clear sort column
      if (
        table.sortOrder === -1 &&
        e.sortOrder === 1 &&
        table.sortField === e.sortField
      ) {
        setTable({
          ...table,
          sortField: undefined,
          sortOrder: undefined
        });
      } else {
        setTable({
          ...table,
          sortField: e.sortField,
          sortOrder: e.sortOrder
        });
      }
    },
    onAddRowAction: props.onAddRowAction,
    onRemoveRowAction:
      props.includeRemoveRowColumn === "show" ? onRemoveRow : undefined,
    hasRemoveRowBuffer: props.includeRemoveRowColumn === "buffer",
    emptyMessage: "No defaults have been added."
  };

  const dataTable = createDataTable<
    BaselineDefaultsBmrFormDefaults,
    TableState
  >(getColumns(), tableProps);

  return <div>{dataTable}</div>;
};

export default DefaultsBmrFormTable;
