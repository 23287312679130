import React, { useState, useContext } from "react";
import WizardMenu, { WizardMenuItemProps } from "components/forms/WizardMenu";
import routes, { BatchDefaultsEditMode } from "components/routes";
import Dialog from "components/controls/Dialog";
import PrimaryButton from "components/controls/PrimaryButton";
import SecondaryButton from "components/controls/SecondaryButton";
import Spacer from "components/controls/Spacer";
import permissionsUtils from "utils/permissionsUtils";
import { IdentityContext } from "components/IdentityProvider";
import { DefaultsPageConfiguration } from "api";
import utils from "utils";
import VisibilityToggle from "components/controls/VisibilityToggle";
import getBatchRegulationMenuItems from "./getBatchRegulationMenuItems";

interface MenuProps {
  title?: string | undefined;
  menuTitle?: string | undefined;
  onClick: () => Promise<any>;
  batchKey: string;
  hideFinishButton?: boolean | undefined;
  batchDefaultsEditMode: BatchDefaultsEditMode;
  hasUnsavedChanges: boolean;
  pageConfiguration: DefaultsPageConfiguration | undefined;
}

interface MenuItem {
  route: string;
  title: string;
}

interface BatchDefaultsMenuState {
  showDialog: boolean;
  onHidePromiseResolve?: () => any;
  onHidePromiseReject?: () => any;
}

const BatchDefaultsMenu = function (props: MenuProps) {
  const identity = useContext(IdentityContext);
  const isEditEnabled =
    (props.pageConfiguration && props.pageConfiguration.isEditEnabled) || false;
  const [state, setState] = useState<BatchDefaultsMenuState>({
    showDialog: false
  });

  const onClick = function (): Promise<any> {
    if (!isEditEnabled) {
      return utils.getEmptyPromise();
    }

    if (
      props.batchDefaultsEditMode === "editBatch" &&
      props.hasUnsavedChanges
    ) {
      return new Promise<any>(function (resolve, reject) {
        setState({
          showDialog: true,
          onHidePromiseResolve: resolve,
          onHidePromiseReject: reject
        });
      }).then(() => props.onClick());
    }

    return props.onClick();
  };

  const getMenuItems = function (): WizardMenuItemProps[] {
    const offersRoute = permissionsUtils.shouldUserNavigateToSummaryPageByDefault(
      identity
    )
      ? routes.reviewSummary(props.batchKey)
      : routes.bestRenewalOffers(props.batchKey);
    const offersMenuItem: MenuItem = {
      title: "Offers",
      route: offersRoute
    };

    return [offersMenuItem]
      .concat(
        getBatchRegulationMenuItems(props.batchKey, props.batchDefaultsEditMode)
      )
      .map((item) => {
        return { ...props, ...item, onClick: onClick };
      });
  };

  const onDialogHide = () => {
    state.onHidePromiseReject && state.onHidePromiseReject();
    setState({ showDialog: false });
  };

  const onDialogSave = () => {
    state.onHidePromiseResolve && state.onHidePromiseResolve();
    setState({ showDialog: false });
  };

  const footer = (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <PrimaryButton onClick={onDialogSave} title="Save" />
      <Spacer />
      <SecondaryButton type="notice" onClick={onDialogHide} title="Cancel" />
    </div>
  );

  let defaultMessage = "Navigate To...";
  if (props.hasUnsavedChanges && isEditEnabled) {
    defaultMessage = "Apply and Navigate To...";
  }

  const actionArea = (
    <VisibilityToggle isVisible={isEditEnabled && props.hasUnsavedChanges}>
      <SecondaryButton
        onClick={() => window.location.reload()}
        type="neutral"
        title="Reset"
      />
    </VisibilityToggle>
  );

  return (
    <>
      <WizardMenu
        title={`BATCH REGULATIONS - ${
          props.title && props.title.toUpperCase()
        }`}
        menuTitle={props.menuTitle || defaultMessage}
        items={getMenuItems()}
        isActionButtonCondensed={false}
        actionArea={actionArea}
      />
      <Dialog
        header="Warning"
        isVisible={state.showDialog}
        onHide={onDialogHide}
        footer={footer}
      >
        <span>
          Saving these defaults may change renewal offers in this batch.
          <br />
          Would you like to continue?
        </span>
      </Dialog>
    </>
  );
};

export default BatchDefaultsMenu;
