import React from "react";
import { TabPanel as PrimeTabPanel } from "primereact/tabview";

export interface TabPanelProps {
  header: string;
}

const TabPanel: React.FC<TabPanelProps> = function(props) {
  return <PrimeTabPanel header={props.header}>{props.children}</PrimeTabPanel>;
};

export default TabPanel;
