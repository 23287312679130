import { useContext } from "react";
import { IdentityContext } from "components/IdentityProvider";
import {
  BatchListFormClient,
  SiteClient,
  ResponsePayloadOfSite,
  UserPermissions
} from "./generated";
import { AtlasConfigContext } from "components/AtlasConfigProvider";

type ClientConstructor<T> = (
  baseUrl?: string,
  http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }
) => T;

const createUseClient = function<T>(clientFactory: ClientConstructor<T>) {
  return function() {
    const identity = useContext(IdentityContext);

    const config = useContext(AtlasConfigContext);
    const baseUrl = (config && config.apiUrl) || "";

    return clientFactory(baseUrl, {
      fetch: async function(url: RequestInfo, init?: RequestInit) {
        const accessToken = await identity.getAccessToken();

        if (accessToken != null) {
          init = init || {};
          init.headers = {
            ...init.headers,
            Authorization: `bearer ${accessToken}`
          };
        }
        return window.fetch(url, init);
      }
    });
  };
};

export interface Option {
  key?: string;
  displayValue?: string;
}

export type ResponsePayload = Required<
  Pick<ResponsePayloadOfSite, "traceId" | "permissions">
>;

export type ResponsePayloadOf<T> = {
  traceId: string;
  permissions: UserPermissions;
  data?: T;
};

const useBatchListFormClient = createUseClient(
  (url, http) => new BatchListFormClient(url, http)
);
export { useBatchListFormClient };

const useSiteClient = createUseClient((url, http) => new SiteClient(url, http));
export { useSiteClient };

export * from "./generated";
