import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  columns: {
    display: "flex",
    flexDirection: "row"
  },
  controlRow: {
    display: "flex",
    flexDirection: "row"
  },
  header: {
    fontFamily: theme.fontFamily,
    fontWeight: "bold",
    lineHeight: `${theme.typeScale.xxlarge}px`,
    minWidth: "50px",
    paddingRight: "40px"
  }
}));
