import React, { useState, useEffect } from "react";
import { Sidebar } from "primereact/sidebar";
import { BestRenewalOffersFormComment } from "api";
import useStyle from "../useStyle";
import Spacer from "components/controls/Spacer";
import SecondaryButton from "components/controls/SecondaryButton";
import PrimaryButton from "components/controls/PrimaryButton";
import CommentList from "../CommentList";
import { InputTextarea } from "primereact/inputtextarea";
import { GenericFormUnitWithComments } from "../GenericFormCommentTypes";
import ScrollPanel from "components/layouts/ScrollPanel";
import Heading from "components/controls/Heading";

export interface UnitCommentsSideBarProps {
  bestRenewalOffersKey: string;
  isVisible: boolean;
  unitWithComments: GenericFormUnitWithComments;
  onSave: (comment: BestRenewalOffersFormComment) => void;
  onCancel: () => void;
  deleteUnitComment: (
    bestRenewalOffersKey: string,
    unitKey: string,
    commentId: number
  ) => void;
}

interface SideBarState {
  text: string | undefined;
}

const UnitCommentsSideBar: React.FC<UnitCommentsSideBarProps> = function (
  props
) {
  const classes = useStyle();
  const [state, setState] = useState<SideBarState>({
    text: ""
  });

  useEffect(() => {
    setState({ text: "" });
  }, [props.unitWithComments]);

  return (
    <Sidebar
      visible={props.isVisible}
      position="left"
      className="ui-sidebar-sm"
      onHide={() => props.onCancel()}
      style={{ width: "30em" }}
    >
      <ScrollPanel>
        <Heading level="2">
          <span>{props.unitWithComments.property}</span>
        </Heading>
        <Heading level="3">
          <span>{`Unit #: ${props.unitWithComments.unit}`}</span>
        </Heading>
        <Spacer size="l" orientation="v" />
        <InputTextarea
          className={classes.commentsArea}
          rows={10}
          value={state.text}
          onChange={(e) => setState({ text: e.currentTarget.value })}
        ></InputTextarea>
        <Spacer orientation="v" size="l" />
        <div className={classes.buttonsArea}>
          <PrimaryButton
            title="Save"
            onClick={() => {
              props.onSave({ text: state.text });
              setState({ text: "" });
            }}
          />
          <Spacer orientation="h" size="l" />
          <SecondaryButton
            className={classes.cancelButton}
            title="Cancel"
            type="notice"
            onClick={() => {
              props.onCancel();
            }}
          />
        </div>
        <CommentList
          bestRenewalOffersKey={props.bestRenewalOffersKey}
          list={props.unitWithComments.comments || []}
          deleteAction={props.deleteUnitComment}
        />
      </ScrollPanel>
    </Sidebar>
  );
};

export default UnitCommentsSideBar;
