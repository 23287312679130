export interface IKeyable {
  key?: string | null | undefined;
}

const firstOrDefaultKeyedItem = function <T extends IKeyable>(
  list: Array<T>,
  item: T
): T | undefined {
  return list.find((listItem) => listItem.key === item.key);
};

const firstOrDefaultByKey = function <T extends IKeyable>(
  list: Array<T>,
  key: string
): T | undefined {
  return list.find((listItem) => listItem.key === key);
};

const firstOrDefaultCustomKeyedItem = function <T>(
  list: Array<T>,
  item: T,
  keyField: keyof T
): T | undefined {
  return list.find((listItem) => listItem[keyField] === item[keyField]);
};

const indexOfKeyedItem = function <T extends IKeyable>(
  list: Array<T>,
  item: T
): number {
  const itemInList = list.find((listItem) => listItem.key === item.key);
  if (!itemInList) {
    return -1;
  }

  return list.indexOf(itemInList);
};

const firstOrDefault = function <T>(
  list: Array<T>,
  findFunc?: (item: T) => boolean | undefined
): T | undefined {
  if (!findFunc) {
    return list[0];
  }

  return list.find(findFunc);
};

const any = function <T>(
  list: Array<T>,
  findFunc: (item: T) => boolean | undefined
): boolean {
  return firstOrDefault(list, findFunc) != null;
};

const anyKey = function <T extends IKeyable>(
  list: Array<T>,
  key: string | undefined | null
): boolean {
  if (!key) {
    return false;
  }

  return firstOrDefaultByKey(list, key) != null;
};

const anyKeyedItem = function <T extends IKeyable>(
  list: Array<T>,
  item: T
): boolean {
  return anyKey(list, item.key);
};

const flatten = function <T>(list: Array<Array<T>>): Array<T> {
  const reducerFunc = (
    accumulator: Array<T>,
    currentItems: Array<T>
  ): Array<T> => accumulator.concat(currentItems);
  return list.reduce(reducerFunc, []);
};

const deepCopyOneLevel = function <T>(list: Array<T>): Array<T> {
  const newList: Array<T> = [];
  list.forEach((item: T) => {
    newList.push({ ...item });
  });

  return newList;
};

const copyListWithNewItem = function <T>(
  list: Array<T>,
  oldItem: T,
  newItem: T
): Array<T> {
  const indexOfCurrentItem = list.indexOf(oldItem);
  if (indexOfCurrentItem >= 0) {
    // duplicate list so we do not modify the original
    const newList = list.slice();
    newList.splice(indexOfCurrentItem, 1, newItem);

    return newList;
  }

  return list;
};

const copyListWithNewCustomKeyedItem = function <T>(
  list: Array<T>,
  item: T,
  keyField: keyof T
): Array<T> {
  const oldItem = firstOrDefaultCustomKeyedItem(list, item, keyField);
  if (!oldItem) {
    return list.concat([item]);
  }

  return copyListWithNewItem(list, oldItem, item);
};

const copyListFilterNulls = function <T>(
  list: Array<T | undefined | null>
): Array<T> {
  return list.filter((i) => i != null) as Array<T>;
};

const copyListWithCustomKeyRemoved = function <T>(
  list: Array<T>,
  keys: Array<T[keyof T]>,
  keyField: keyof T
): Array<T> {
  let oldItemsWithPossibleNulls = keys.map((key) => {
    return list.find((listItem) => listItem[keyField] === key);
  });

  const oldItems = copyListFilterNulls(oldItemsWithPossibleNulls);

  if (!oldItems || oldItems.length <= 0) {
    return list.slice();
  }

  const reducerFunc = (accumulator: Array<T>, currentItem: T): Array<T> =>
    copyListWithItemRemoved(accumulator, currentItem);
  return oldItems.reduce(reducerFunc, list);
};

const copyListWithNewKeyedItem = function <T extends IKeyable>(
  list: Array<T>,
  item: T
): Array<T> {
  const oldItem = firstOrDefaultKeyedItem(list, item);
  if (!oldItem) {
    return list;
  }

  return copyListWithNewItem(list, oldItem, item);
};

const copyListWithItemRemoved = function <T>(
  list: Array<T>,
  item: T
): Array<T> {
  const newList = list.slice();
  const indexOfItemToRemove = newList.indexOf(item);
  if (indexOfItemToRemove < 0) {
    return newList;
  }

  newList.splice(indexOfItemToRemove, 1);
  return newList;
};

const getUniqueItems = function <T>(list: Array<T>): Array<T> {
  return list.filter(
    (item, currentIndex) => list.indexOf(item) === currentIndex
  );
};

export type SortDirection = "asc" | "desc";

const sortItemsBySingleKeyedItem = function <T>(
  list: Array<T>,
  field: keyof T,
  direction?: SortDirection | undefined
) {
  const newList = list.slice();

  const sortDirection: SortDirection = direction ? direction : "asc";

  if (sortDirection === "asc") {
    return newList.sort((a, b) => (a[field] > b[field] ? 1 : -1));
  }

  return newList.sort((a, b) => (a[field] > b[field] ? -1 : 1));
};

const filterNulls = function <T>(list: Array<T | undefined>): Array<T> {
  return list.filter((item) => item != null) as Array<T>;
};

const filterNullsAndFlatten = function <T>(
  list: Array<Array<T> | undefined>
): Array<T> {
  return flatten(filterNulls(list));
};

export default {
  copyListWithNewItem,
  copyListWithNewKeyedItem,
  copyListWithNewCustomKeyedItem,
  copyListWithCustomKeyRemoved,
  copyListWithItemRemoved,
  firstOrDefault,
  firstOrDefaultByKey,
  firstOrDefaultKeyedItem,
  any,
  anyKey,
  anyKeyedItem,
  flatten,
  deepCopyOneLevel,
  indexOfKeyedItem,
  getUniqueItems,
  sortItemsBySingleKeyedItem,
  filterNulls,
  filterNullsAndFlatten
};
