import React from "react";
import Page from "components/layouts/Page";
import RequirePermission from "../RequirePermission";
import useStyle from "./useStyle";
import Spacer from "components/controls/Spacer";
import ContentArea from "components/layouts/ContentArea";
import { RouteComponentProps } from "react-router";
import useFirstLoadEffect from "utils/useMountEffect";
import {
  CommonSummaryRollupKind,
  ReviewSummaryFormFilter,
  ReviewSummaryFormFilters
} from "api/generated";
import Spinner from "components/controls/Spinner";
import BatchDefaultsBatchInformationArea from "components/forms/BatchDefaultsBatchInformationArea";
import SideBars from "components/forms/BestRenewalOffersPage/Comments/SideBars";
import { MessageAreaBasic } from "components/controls/MessageArea";
import useAtlasStore from "components/store/useAtlasStore";
import CommonSummaryFormSummaryTable from "../CommonSummaryFormSummaryTable";
import TabView from "components/layouts/TabView";
import TabPanel from "components/layouts/TabView/TabPanel";
import listUtils from "utils/listUtils";
import Dropdown from "components/controls/Dropdown";
import PrimaryButton from "components/controls/PrimaryButton";
import VisibilityToggle from "components/controls/VisibilityToggle";
import NotesButton from "../BestRenewalOffersPage/Comments/CommentsButton";
import SecondaryButton from "components/controls/SecondaryButton";
import utils from "utils";
import Dialog from "components/controls/Dialog";
import BestRenewalOffersMenu from "../BestRenewalOffersMenu";
import SelectButton, {
  SelectButtonOption
} from "components/controls/SelectButton";
import ValidationWrapper from "components/controls/ValidationWrapper";
import Input from "components/controls/Input";
import { ExpandableTableState } from "components/store/AtlasStateTypes";
import Label from "components/controls/Label";

interface MatchParams {
  id: string;
}

interface PageProps extends RouteComponentProps<MatchParams> {}

enum ExceptionFilterEnum {
  All,
  ExceptionsOnly
}

export interface ExceptionFilterOption {
  name: string;
  type: ExceptionFilterEnum;
}

const ReviewSummaryPage: React.FC<PageProps> = function (props: PageProps) {
  const batchKey: string = props.match.params.id;
  const classes = useStyle();

  const [appState, appActions] = useAtlasStore();
  const pageState = {
    form: appState.reviewSummaryPageState.form,
    ...appState
  };
  const actions = {
    ...appActions.reviewSummaryPage,
    ...appActions.sideBar,
    ...appActions.messages,
    ...appActions
  };

  useFirstLoadEffect(() => {
    actions.getForm(batchKey);
  });

  const spinner = <Spinner isVisible={pageState.loadingState.isLoading} />;
  const messageAreas = pageState.messageState.messages.map((message) => (
    <MessageAreaBasic message={message} key={message.detail} />
  ));

  const form = pageState.form;

  //See T-4258 for refactoring improvements
  if (
    (form == null ||
      form.batchDetail == null ||
      form.countySummary == null ||
      form.propertySummary == null ||
      form.unitSummary == null) &&
    pageState.loadingState
  ) {
    return spinner;
  } else if (
    form == null ||
    form.batchDetail == null ||
    form.countySummary == null ||
    form.propertySummary == null ||
    form.unitSummary == null ||
    form.filters == null ||
    form.filters.countyFilters == null
  ) {
    return <div>{messageAreas}</div>;
  }

  const filters = form.filters;
  const isApprovalEnabled =
    form.pageConfiguration != null &&
    form.pageConfiguration.isApprovalEnabled === true;

  const getTabIndexFromCommonSummaryRollupKind = function (
    kind?: CommonSummaryRollupKind
  ): number {
    switch (kind) {
      case CommonSummaryRollupKind.County:
        return 0;
      case CommonSummaryRollupKind.Property:
        return 1;
      case CommonSummaryRollupKind.Unit:
        return 2;
    }

    return 0;
  };

  const getCommonSummaryRollupKindFromTabIndex = function (
    index: number
  ): number {
    switch (index) {
      case 0:
        return CommonSummaryRollupKind.County;
      case 1:
        return CommonSummaryRollupKind.Property;
      case 2:
        return CommonSummaryRollupKind.Unit;
    }

    return CommonSummaryRollupKind.County;
  };

  const getActiveTabIndex = function () {
    return getTabIndexFromCommonSummaryRollupKind(form.activeView);
  };

  const onTabIndexChange = function (newTabIndex: number) {
    actions.updateActiveTab(
      getCommonSummaryRollupKindFromTabIndex(newTabIndex)
    );
  };

  const openBatchNotes = function () {
    const batchComments =
      pageState.form &&
      pageState.form.batchDetail &&
      pageState.form.batchComments;

    if (batchComments) {
      actions.openBatchComments(batchComments);
    }
  };

  const getApproveButtonTitle = function () {
    return "APPROVE";
  };

  const getNewPropertyFilters = function (newValue: ReviewSummaryFormFilter) {
    const newFilters = {
      ...filters,
      selectedPropertyFilter: newValue
    };

    if (newValue && filters.propertyFilters) {
      newFilters.propertyFilters = listUtils.copyListWithNewKeyedItem(
        filters.propertyFilters,
        newValue
      );
    }

    return newFilters;
  };

  const updatePropertyFilter = function (
    newValue: ReviewSummaryFormFilter
  ): void {
    const newFilters = getNewPropertyFilters(newValue);
    actions.updateFilters(batchKey, newFilters);
  };

  const getNewCountyFilters = function (
    newValue: ReviewSummaryFormFilter
  ): ReviewSummaryFormFilters {
    const newFilters = {
      ...filters,
      selectedCountyFilter: newValue
    };

    if (newValue && filters.countyFilters) {
      newFilters.countyFilters = listUtils.copyListWithNewKeyedItem(
        filters.countyFilters,
        newValue
      );
    }

    return newFilters;
  };

  const updateCountyFilter = function (newValue: ReviewSummaryFormFilter) {
    const newFilters = getNewCountyFilters(newValue);

    actions.updateFilters(batchKey, newFilters);
  };

  const exceptionOptions: SelectButtonOption<ExceptionFilterEnum>[] = [
    {
      value: ExceptionFilterEnum.All,
      label: "All"
    } as SelectButtonOption<ExceptionFilterEnum>,
    {
      value: ExceptionFilterEnum.ExceptionsOnly,
      label: "Exceptions"
    } as SelectButtonOption<ExceptionFilterEnum>
  ];

  const getSelectedExceptionOption = function (): SelectButtonOption<ExceptionFilterEnum> {
    return (
      listUtils.firstOrDefault(
        exceptionOptions,
        (option) =>
          (filters.onlyHasExceptionsFilter === true &&
            option.value === ExceptionFilterEnum.ExceptionsOnly) ||
          (!filters.onlyHasExceptionsFilter &&
            option.value === ExceptionFilterEnum.All)
      ) || exceptionOptions[0]
    );
  };

  const getNewExceptionsFilters = function (
    newValue: boolean
  ): ReviewSummaryFormFilters {
    const newFilters: ReviewSummaryFormFilters = {
      ...filters,
      onlyHasExceptionsFilter: newValue
    };

    return newFilters;
  };

  const exceptionFilterOnChange = (
    option: SelectButtonOption<ExceptionFilterEnum> | undefined
  ) => {
    if (!option) {
      return;
    }

    const newFilters = getNewExceptionsFilters(
      option.value === ExceptionFilterEnum.ExceptionsOnly
    );

    actions.updateFilters(batchKey, newFilters);
  };

  const applyCountyNavigation = function (key: string) {
    const newFilter = listUtils.firstOrDefaultByKey(
      filters.countyFilters || [],
      key
    );

    if (!newFilter) {
      return;
    }

    const mutableForm = utils.cloneObject(form);
    mutableForm.activeView = CommonSummaryRollupKind.Property;
    mutableForm.filters = getNewCountyFilters(newFilter);
    actions.applyFilters(batchKey, mutableForm);
  };

  const applyPropertyNavigation = function (key: string) {
    const newFilter = listUtils.firstOrDefaultByKey(
      filters.propertyFilters || [],
      key
    );

    if (!newFilter) {
      return;
    }

    const mutableForm = utils.cloneObject(form);
    mutableForm.activeView = CommonSummaryRollupKind.Unit;
    mutableForm.filters = getNewPropertyFilters(newFilter);
    actions.applyFilters(batchKey, mutableForm);
  };

  const onConfirmApprovalDialogHide = function () {
    actions.updateConfirmApprovalDialog(false);
  };

  const confirmApprovalDialogFooter = (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <PrimaryButton
        onClick={() => actions.approveForm(batchKey, form)}
        title="Approve"
      />
      <Spacer />
      <SecondaryButton
        type="notice"
        onClick={onConfirmApprovalDialogHide}
        title="Cancel"
      />
    </div>
  );

  const getApprovalMessage = function (): JSX.Element {
    return (
      <div>
        <div>You are about to approve every property on this batch.</div>
        <Spacer orientation="v" />
        <Label label="Would you like to continue?" />
      </div>
    );
  };

  const onConfirmRejectionDialogHide = function () {
    actions.updateConfirmRejectionDialog(false);
  };

  const confirmRejectionDialogFooter = (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <PrimaryButton
        onClick={() => actions.rejectForm(batchKey, form)}
        title="Reject"
      />
      <Spacer />
      <SecondaryButton
        type="notice"
        onClick={onConfirmRejectionDialogHide}
        title="Cancel"
      />
    </div>
  );

  const defaultTableProps = {
    batchKey: batchKey,
    updatePagingState: actions.updateTablePagingState,
    tableState: {
      appliedFilters: [],
      expandedRows: [],
      pagingState: pageState.tableState.pagingState
    } as ExpandableTableState,
    updateTableState: (tableState: ExpandableTableState) =>
      actions.updateTablePagingState(tableState.pagingState),
    openUnitNotes: actions.openUnitNotes,
    openPropertyNotes: actions.openPropertyComments,
    updateRollupSelection: actions.updateRollupSelection,
    applyCountyNavigation: applyCountyNavigation,
    applyPropertyNavigation: applyPropertyNavigation
  };

  const getRejectionInput = () => {
    return (
      <div>
        <div>Rejecting a batch rejects all properties. </div>
        <Spacer orientation="v" />
        <Input
          value={form.rejectReason}
          onUnfocus={(rejectionReason) => {
            actions.updateRejectionReason(rejectionReason);
          }}
          label="Please provide a rejection reason to continue."
        />
      </div>
    );
  };

  const batchComments = form.batchDetail && form.batchComments;
  const approvalArea = (
    <VisibilityToggle isVisible={isApprovalEnabled}>
      <div className={classes.approvalArea}>
        <PrimaryButton
          className={classes.approvalButton}
          title={getApproveButtonTitle()}
          disabled={pageState.loadingState.isLoading}
          onClick={() => actions.updateConfirmApprovalDialog(true)}
        />
        <SecondaryButton
          className={classes.approvalButton}
          title="REJECT"
          type="important"
          disabled={pageState.loadingState.isLoading}
          onClick={() => actions.updateConfirmRejectionDialog(true)}
        />
      </div>
    </VisibilityToggle>
  );

  return (
    <Page
      menu={
        <BestRenewalOffersMenu
          bestRenewalOffersKey={batchKey}
          batchNavigationConfig={form.navigationConfiguration}
          actionArea={approvalArea}
        />
      }
    >
      <RequirePermission
        permissions={["canViewReviewSummaryForm"]}
        accessDenied="page"
      ></RequirePermission>
      {spinner}
      {messageAreas}
      <div className={classes.splitContent}>
        <div className={classes.headerMetaData}>
          <BatchDefaultsBatchInformationArea
            batchInformation={{ ...form.batchDetail }}
          />
        </div>
        <div>
          <NotesButton
            label={`Batch comments (${
              (batchComments && batchComments.totalCount) || 0
            })`}
            alt="View batch comments"
            className={classes.batchNotes}
            onClick={openBatchNotes}
            isHighlighted={
              batchComments != null &&
              batchComments.totalCount != null &&
              batchComments.totalCount > 0
            }
          />
        </div>
      </div>
      <Spacer orientation="v" size="xl" />
      {/* TODO: refactor this into a separate component */}
      <ContentArea columnSize="12">
        <div className={classes.filterDropdown}>
          <SelectButton
            label=""
            selectedValue={getSelectedExceptionOption()}
            options={exceptionOptions}
            onChange={exceptionFilterOnChange}
          ></SelectButton>
        </div>
        <div className={classes.filterDropdownArea}>
          <div className={classes.filterDropdown}>
            <Dropdown
              label="County"
              selectedValue={filters.selectedCountyFilter}
              options={filters.countyFilters}
              onChange={(o) => updateCountyFilter(o as ReviewSummaryFormFilter)}
            />
          </div>
          <div className={classes.filterDropdown}>
            <Dropdown
              label="Properties"
              selectedValue={filters.selectedPropertyFilter}
              options={filters.propertyFilters}
              onChange={(o) =>
                updatePropertyFilter(o as ReviewSummaryFormFilter)
              }
            />
          </div>
          <div className={classes.filterDropdown}>
            <Dropdown
              label="Bedroom"
              selectedValue={filters.selectedBedroomFilter}
              options={filters.bedroomFilters}
              onChange={(o) => {
                const newValue = o as ReviewSummaryFormFilter;

                const newFilters = {
                  ...filters,
                  selectedBedroomFilter: newValue
                };

                if (newValue && filters.bedroomFilters) {
                  newFilters.bedroomFilters = listUtils.copyListWithNewKeyedItem(
                    filters.bedroomFilters,
                    newValue
                  );
                }

                actions.updateFilters(batchKey, newFilters);
              }}
            />
          </div>
          <div className={classes.filterDropdown}>
            <Dropdown
              label="RPM"
              selectedValue={filters.selectedRpmFilter}
              options={filters.rpmFilters}
              onChange={(o) => {
                const newValue = o as ReviewSummaryFormFilter;

                const newFilters = {
                  ...filters,
                  selectedRpmFilter: newValue
                };

                if (newValue && filters.rpmFilters) {
                  newFilters.rpmFilters = listUtils.copyListWithNewKeyedItem(
                    filters.rpmFilters,
                    newValue
                  );
                }

                actions.updateFilters(batchKey, newFilters);
              }}
            />
          </div>
          <div className={classes.filterDropdown}>
            <Dropdown
              label="Show Excluded?"
              selectedValue={filters.selectedIsExcludedFilter}
              options={filters.isExcludedFilters}
              onChange={(o) => {
                const newValue = o as ReviewSummaryFormFilter;

                const newFilters = {
                  ...filters,
                  selectedIsExcludedFilter: newValue
                };

                if (newValue && filters.isExcludedFilters) {
                  newFilters.isExcludedFilters = listUtils.copyListWithNewKeyedItem(
                    filters.isExcludedFilters,
                    newValue
                  );
                }

                actions.updateFilters(batchKey, newFilters);
              }}
            />
          </div>

          <div className={classes.applyButtonWrapper}>
            <ValidationWrapper
              showErrorState={
                pageState.reviewSummaryPageState &&
                pageState.reviewSummaryPageState.hasUnappliedFilters
              }
            >
              <div className={classes.applyButton}>
                <PrimaryButton
                  title="Apply"
                  onClick={() => actions.applyFilters(batchKey, form)}
                  disabled={false}
                />
              </div>
            </ValidationWrapper>
          </div>
        </div>
      </ContentArea>
      <Spacer orientation="v" size="xl" />
      <TabView
        activeTabIndex={getActiveTabIndex()}
        onTabIndexChange={onTabIndexChange}
      >
        <TabPanel header="Counties">
          <CommonSummaryFormSummaryTable
            {...defaultTableProps}
            summary={form.countySummary || []}
            mode={CommonSummaryRollupKind.County}
            areCommentsDisabled={true}
            isApprovalEnabled={isApprovalEnabled}
            summaryRowTitle="Summary"
          />
        </TabPanel>
        <TabPanel header="Properties">
          <CommonSummaryFormSummaryTable
            {...defaultTableProps}
            summary={form.propertySummary || []}
            mode={CommonSummaryRollupKind.Property}
            isApprovalEnabled={isApprovalEnabled}
            summaryRowTitle="Summary"
          />
        </TabPanel>
        <TabPanel header="Units">
          <CommonSummaryFormSummaryTable
            {...defaultTableProps}
            summary={form.unitSummary || []}
            mode={CommonSummaryRollupKind.Unit}
            isApprovalEnabled={isApprovalEnabled}
            summaryRowTitle="Summary"
          />
        </TabPanel>
      </TabView>
      <Spacer orientation="v" size="xl" />
      <SideBars
        bestRenewalOffersKey={batchKey}
        sideBarState={pageState.sideBarState}
        closeSideBars={actions.closeSideBars}
        addPropertyComment={actions.addPropertyComment}
        addUnitComment={actions.addUnitComment}
        deletePropertyComment={actions.deletePropertyComment}
        deleteUnitComment={actions.deleteUnitComment}
      />
      <Dialog
        header="Alert"
        isVisible={
          pageState.reviewSummaryPageState.confirmApprovalDialogIsVisible
        }
        onHide={() => onConfirmApprovalDialogHide()}
        footer={confirmApprovalDialogFooter}
      >
        {getApprovalMessage()}
      </Dialog>
      <Dialog
        header="Alert"
        isVisible={
          pageState.reviewSummaryPageState.confirmRejectionDialogIsVisible
        }
        onHide={() => onConfirmRejectionDialogHide()}
        footer={confirmRejectionDialogFooter}
      >
        {getRejectionInput()}
      </Dialog>
    </Page>
  );
};
export default ReviewSummaryPage;
