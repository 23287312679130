import React from "react";
import { Route } from "react-router-dom";
import routes from "components/routes";
import DefaultAdminGrowthRatesPage from "components/forms/DefaultsAdminGrowthRatesPage";
import DefaultsAdminGtlAndLtlPage from "components/forms/DefaultsAdminGtlAndLtlPage";
import BaselineDefaultsScalingPage from "components/forms/BaselineDefaultsScalingPage";
import BaselineDefaultsIncentivesPage from "components/forms/BaselineDefaultsIncentivesPages";
import BaselineDefaultsBmrPage from "components/forms/BaselineDefaultsBmrPage";
import BaselineDefaultsExceptionsFormPage from "components/forms/BaselineDefaultsExceptionsFormPage";
import BaselineDefaultsRentControlFormPage from "components/forms/BaselineDefaultsRentControlFormPage";
import BaselineDefaultsExportTermsPage from "components/forms/BaselineDefaultsExportTermsPage";
import BaselineDefaultsRenewalNoticePeriodFormPage from "components/forms/BaselineDefaultsRenewalNoticePeriodFormPage";
import BaselineDefaultsRegulationsFormPage from "components/forms/BaselineDefaultsRegulationsFormPage";

export default () => (
  <>
    <Route
      exact
      path={routes.baselinedefaults.growthRates}
      component={DefaultAdminGrowthRatesPage}
    />
    <Route
      exact
      path={routes.baselinedefaults.gtlAndLtl}
      component={DefaultsAdminGtlAndLtlPage}
    />
    <Route
      exact
      path={routes.regulations.bmr}
      component={BaselineDefaultsBmrPage}
    />
    <Route
      exact
      path={routes.baselinedefaults.scaling}
      component={BaselineDefaultsScalingPage}
    />
    <Route
      exact
      path={routes.baselinedefaults.incentives}
      component={BaselineDefaultsIncentivesPage}
    />
    <Route
      exact
      path={routes.regulations.rentControl}
      component={BaselineDefaultsRentControlFormPage}
    />
    <Route
      exact
      path={routes.baselinedefaults.exceptions}
      component={BaselineDefaultsExceptionsFormPage}
    />
    <Route
      exact
      path={routes.baselinedefaults.exportTerms}
      component={BaselineDefaultsExportTermsPage}
    />
    <Route
      exact
      path={routes.baselinedefaults.renewalNotice}
      component={BaselineDefaultsRenewalNoticePeriodFormPage}
    />
    <Route
      exact
      path={routes.regulations.ordinances}
      component={BaselineDefaultsRegulationsFormPage}
    />
  </>
);
