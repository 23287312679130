import React from "react";
import createDataTable, {
  IPagedTableState,
  DefaultInputEditorData,
  ColumnDefinition,
  DataTableProps
} from "components/controls/createTable";

import useTableStyle from "./useTableStyle";
import Checkbox from "components/controls/Checkbox";
import {
  BestRenewalOffersFormOffer,
  BestRenewalOfferColumnConfiguration,
  BestRenewalOfferColumnConfigurationFieldType
} from "api/generated";
import NotesButton from "./Comments/CommentsButton";
import FilterDropdown from "components/controls/FilterDropdown";
import listUtils from "utils/listUtils";
import { FilteredSelectionsItemProps } from "components/controls/FilteredSelections";
import {
  BestRenewalOffersPageAppliedFilterState,
  SidebarUnitNotesDisplayState
} from "components/store/AtlasStateTypes";
import { BestRenewalOffersPageMode } from "components/store/BestRenewalOffersPage/BestRenewalOffersPageActionMethods";
import cssUtils from "utils/cssUtils";
import utils from "utils";
import Input from "components/controls/Input";
import RequirePermission from "../RequirePermission";

export interface BestRenewalOfferColumnConfigurationOverride {
  field?: string;
}

interface TableProps {
  bestRenewalOffersKey: string;
  offers: BestRenewalOffersFormOffer[];
  filteredOffers: BestRenewalOffersFormOffer[];
  appliedFilters: BestRenewalOffersPageAppliedFilterState[];
  pagingState: IPagedTableState;
  columnConfigurations: BestRenewalOfferColumnConfiguration[];
  columnConfigurationOverrides?:
    | BestRenewalOfferColumnConfigurationOverride[]
    | undefined;
  unitNotesDisplayState: SidebarUnitNotesDisplayState;
  isEditEnabled: boolean;
  areCommentsEnabled: boolean;
  areFiltersEnabled: boolean;
  pageMode: BestRenewalOffersPageMode;
  updateOffer: (
    bestRenewalOffersKey: string,
    updatedOffer: BestRenewalOffersFormOffer,
    offers: BestRenewalOffersFormOffer[],
    mode: BestRenewalOffersPageMode
  ) => void;
  updateAllOffers: (
    bestRenewalOffersKey: string,
    updatedOffers: BestRenewalOffersFormOffer[],
    offers: BestRenewalOffersFormOffer[],
    mode: BestRenewalOffersPageMode
  ) => void;
  updateAppliedFilters: (
    appliedFilters: BestRenewalOffersPageAppliedFilterState[]
  ) => void;
  updatePagingState: (state: IPagedTableState) => void;
  openUnitNotes: (selectedUnit: BestRenewalOffersFormOffer) => void;
  pagingOptions?: number[] | undefined;
  frozenColumns?: boolean | undefined;
}

interface BestRenewalOfferColumnDefinition
  extends ColumnDefinition<
    BestRenewalOffersFormOffer,
    keyof BestRenewalOffersFormOffer
  > {
  showCustomFilter?: boolean | undefined;
}

const BestRenewalOffersTable = React.forwardRef(function (
  props: TableProps,
  ref: React.Ref<any>
) {
  const tableClasses = useTableStyle(props.unitNotesDisplayState);
  const [tableState, setTableState] = React.useState({
    globalFilter: "",
    selectedOffers: [] as BestRenewalOffersFormOffer[]
  });
  const [width, setWidth] = React.useState(
    document.getElementsByTagName("body")[0].offsetWidth
  );

  const updateWidth = () => {
    setWidth(document.getElementsByTagName("body")[0].offsetWidth);
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  const getValueOrBlank = (value: string | null | undefined): string => {
    if (!utils.stringIsNullOrEmpty(value)) {
      return value as string;
    }

    return "(blank)";
  };

  // TODO: replace this logic with a better strategy.
  // this fixes the camel/pascal case translation issue between the front end and the
  // backend code (e.g. we work with camel field names like comments instead of Comments).
  const getColumnFieldNames = function () {
    const offer = props.offers[0];
    const fieldNames = [];
    for (var prop in offer) {
      if (offer.hasOwnProperty(prop)) {
        fieldNames.push(prop);
      }
    }

    return fieldNames;
  };

  const getColumnConfigurationOverrides = function (): any[] {
    return (
      (props.columnConfigurationOverrides &&
        props.columnConfigurationOverrides
          .map((override) => {
            const columnConfigForOverride = listUtils.firstOrDefault(
              props.columnConfigurations,
              (config) =>
                override.field != null &&
                config.field != null &&
                override.field.toLowerCase() === config.field.toLowerCase()
            );

            if (columnConfigForOverride) {
              return {
                field: columnConfigForOverride.field,
                hidden: false,
                header: columnConfigForOverride.header,
                className:
                  columnConfigForOverride.fieldType ===
                  BestRenewalOfferColumnConfigurationFieldType.String
                    ? tableClasses.defaultColumn
                    : tableClasses.defaultNumberColumn
              };
            } else {
              return null;
            }
          })
          .filter((config) => config != null)) ||
      []
    );
  };

  const getColumnsFromColumnConfigurations = function (): any[] {
    return props.columnConfigurations.map((config) => {
      return {
        field: config.field,
        hidden: config.isHidden,
        header: config.header,
        className:
          config.fieldType ===
          BestRenewalOfferColumnConfigurationFieldType.String
            ? tableClasses.defaultColumn
            : tableClasses.defaultNumberColumn
      };
    });
  };

  const getColumnsByFieldName = function (columns: any[], field: string): any {
    return columns.filter(
      (col) =>
        col && col.field && col.field.toUpperCase() === field.toUpperCase()
    )[0];
  };

  const getCorrectColumnFieldName = function (
    fieldNames: string[],
    fieldNameToFind: string
  ): string {
    const correctFieldName =
      fieldNameToFind &&
      fieldNames.filter(
        (fieldName) => fieldName.toUpperCase() === fieldNameToFind.toUpperCase()
      )[0];

    return correctFieldName;
  };

  const getHeaderWithFilterArea = function (
    field: keyof BestRenewalOffersFormOffer,
    header: string
  ) {
    const valuesForField = listUtils.getUniqueItems(
      props.offers.flatMap((offer) => {
        switch (field) {
          case "rpm":
            return getValueOrBlank(offer[field] as string).split("|");
          case "parameter":
            const getVal = (val: string) => {
              return val.includes(" - ") ? val.split(" - ")[1] : val;
            };
            return getVal(getValueOrBlank(offer[field] as string));
          default:
            return getValueOrBlank(offer[field] as string);
        }
      })
    );

    const appliedFilter = props.appliedFilters.filter(
      (filter) => filter.field === field
    )[0];

    const valuesForFieldSelections: FilteredSelectionsItemProps[] =
      valuesForField.map((valueForField) => {
        const areAnyFiltersApplied =
          appliedFilter != null && appliedFilter.selectedValues.length > 0;

        const fieldIsSelected =
          appliedFilter != null &&
          ((appliedFilter.selectedValues as string[]) || []).includes(
            getValueOrBlank(valueForField)
          );

        return {
          value: valueForField,
          isSelected: !areAnyFiltersApplied || fieldIsSelected
        };
      });

    const areAllSelected = valuesForFieldSelections.every(
      (selection) => selection.isSelected
    );

    return (
      <div className={tableClasses.filterHeader}>
        <div className={tableClasses.filterHeaderText}>{header}</div>
        <div>
          <FilterDropdown
            isActive={
              (appliedFilter &&
                appliedFilter.selectedValues &&
                appliedFilter.selectedValues.length > 0 &&
                !areAllSelected) ||
              false
            }
            items={valuesForFieldSelections}
            onItemsChange={(items) => {
              let selectedValues = items
                .filter((item) => item.isSelected)
                .map((item) => item.value);

              const areAllUnSelected = items.every((item) => !item.isSelected);
              if (areAllUnSelected) {
                selectedValues = items.map((item) => item.value);
              }

              const appliedFilters = listUtils.copyListWithNewCustomKeyedItem(
                props.appliedFilters,
                { field: field, selectedValues: selectedValues },
                "field"
              );

              props.updateAppliedFilters(appliedFilters);
            }}
          />
        </div>
      </div>
    );
  };

  const generateColumnDefinitionUsingSpecs = function (
    newColumnFields: ColumnDefinition<
      BestRenewalOffersFormOffer,
      keyof BestRenewalOffersFormOffer
    >[]
  ): any {
    const fieldNames = getColumnFieldNames();
    const columnConfigurations = props.columnConfigurationOverrides
      ? getColumnConfigurationOverrides()
      : getColumnsFromColumnConfigurations();

    const configs = columnConfigurations.map((column) => {
      const newColumn = getColumnsByFieldName(newColumnFields, column.field);
      const correctField = getCorrectColumnFieldName(fieldNames, column.field);
      const columnSoFar = {
        ...column,
        ...newColumn,
        className: cssUtils.join(
          column && column.className,
          newColumn && newColumn.className
        ),
        field: correctField
      };

      const fieldAsKey = correctField as keyof BestRenewalOffersFormOffer;
      const header =
        fieldAsKey && columnSoFar.showCustomFilter && props.areFiltersEnabled
          ? getHeaderWithFilterArea(fieldAsKey, columnSoFar.header)
          : columnSoFar.header;

      return {
        ...columnSoFar,
        header: header,
        className: cssUtils.join(tableClasses.allColumns, columnSoFar.className)
      };
    });

    return configs;
  };

  // note: updates to this method should also be done in BestRenewalOffersBasicViewTable.
  const updateOfferBasedOnEdit = function (
    offer: BestRenewalOffersFormOffer,
    fieldName: keyof BestRenewalOffersFormOffer,
    value: any
  ) {
    const newOffer: BestRenewalOffersFormOffer = { ...offer };

    let isUnchanged = true;
    if (typeof value === "string") {
      const valueAsString = value as string;
      const areBothValuesNullOrEmpty =
        utils.stringIsNullOrEmpty(valueAsString) &&
        utils.stringIsNullOrEmpty(newOffer[fieldName] as string);

      isUnchanged = areBothValuesNullOrEmpty || newOffer[fieldName] === value;
    } else {
      isUnchanged = newOffer[fieldName] === value;
    }

    if (isUnchanged) {
      return;
    }

    newOffer[fieldName] = value;
    newOffer.isChanged = true;
    props.updateOffer(
      props.bestRenewalOffersKey,
      newOffer,
      props.offers,
      props.pageMode
    );
  };

  const getDefaultInputEditorData = function ():
    | DefaultInputEditorData<BestRenewalOffersFormOffer>
    | undefined {
    if (!props.isEditEnabled) {
      return undefined;
    }

    return {
      isEditDisabledForCell: (
        row: BestRenewalOffersFormOffer,
        field: keyof BestRenewalOffersFormOffer
      ) => {
        return ((field === "bestOfferOverridePercentage" &&
          row["bestOfferOverrideDollars"] != null) ||
          (field === "bestOfferOverrideDollars" &&
            row["bestOfferOverridePercentage"])) as boolean;
      },
      onChange: (
        row: BestRenewalOffersFormOffer,
        field: keyof BestRenewalOffersFormOffer,
        value: any
      ) => {
        updateOfferBasedOnEdit(row, field, value);
      }
    };
  };

  const getUnitCommentsColumnTemplate = function () {
    return (rowData: any) => {
      const offer = rowData as BestRenewalOffersFormOffer;
      return (
        <div className={tableClasses.unitNotesColumnBody}>
          <NotesButton
            alt="Unit comments"
            className={tableClasses.unitNotesImage}
            isHighlighted={offer.comments != null && offer.comments.length > 0}
            onClick={() => props.openUnitNotes(offer)}
          />
        </div>
      );
    };
  };

  const dateToStringTemplate = function (
    field: keyof BestRenewalOffersFormOffer
  ) {
    return (rowData: any) => {
      const offer = rowData as BestRenewalOffersFormOffer;
      let displayText = "";
      if (offer[field] != null) {
        const dateValue = new Date(offer[field] as string);
        displayText += dateValue.getUTCMonth() + 1;
        displayText += "/";
        displayText += dateValue.getUTCDate();
        displayText += "/";
        displayText += dateValue.getUTCFullYear();
      }
      return <>{displayText}</>;
    };
  };

  const getRpmColumnTemplate = function () {
    return (rowData: any) => {
      const offer = rowData as BestRenewalOffersFormOffer;

      return (
        <div>
          {offer.rpm &&
            offer.rpm.split("|").map((i, key) => {
              return (
                <div key={key} style={{ display: "block", padding: "1px" }}>
                  {i}
                </div>
              );
            })}
        </div>
      );
    };
  };

  const getExcludeHeader = function () {
    return (
      <div>
        {"Excl"}
        <Checkbox
          onChange={(checked) => {
            excludeAllOnCurrentPage();
          }}
          disabled={
            !props.isEditEnabled || tableState.selectedOffers.length < 2
          }
          checked={
            tableState.selectedOffers.length > 1 &&
            tableState.selectedOffers.every((o) => o.excluded)
          }
        />
      </div>
    );
  };

  const getCheckboxColumnTemplate = function (
    fieldName: keyof BestRenewalOffersFormOffer
  ) {
    return (offer: BestRenewalOffersFormOffer) => {
      return (
        <Checkbox
          checked={offer.excluded || false}
          disabled={!props.isEditEnabled}
          onChange={(checked) => {
            updateOfferBasedOnEdit(offer, fieldName, checked === true);
          }}
        />
      );
    };
  };

  const getTableColumns = function (): any[] {
    let columnDefinitionSpecs: BestRenewalOfferColumnDefinition[] = [
      {
        field: "comments",
        className: tableClasses.unitNotesColumn,
        body: getUnitCommentsColumnTemplate(),
        header: undefined,
        frozen: true,
        hidden: false
      },
      {
        field: "property",
        showCustomFilter: true,
        className: tableClasses.propertyColumn,
        frozen: true,
        hidden: false
      },
      {
        field: "rpm",
        showCustomFilter: true,
        body: getRpmColumnTemplate(),
        className: tableClasses.rpmColumn,
        frozen: true
      },
      {
        field: "unit",
        className: tableClasses.unitColumn,
        frozen: true,
        hidden: false
      },
      {
        field: "currentRent",
        className: tableClasses.adjustedRentColumn,
        frozen: true,
        hidden: false
      },
      {
        field: "currentNetEffectiveRent",
        className: tableClasses.netEffectiveRent,
        frozen: true,
        hidden: false
      },
      {
        field: "adjustedMarketRentDollars",
        className: tableClasses.adjustedRentColumn,
        frozen: true,
        hidden: false
      },
      {
        field: "unitType",
        className: tableClasses.unitColumn,
        showCustomFilter: true
      },
      {
        field: "affordabilityType",
        className: tableClasses.affordabilityTypeColumn,
        showCustomFilter: true
      },
      {
        field: "bedrooms",
        className: tableClasses.bedroomColumn,
        showCustomFilter: true
      },
      {
        field: "excluded",
        body: getCheckboxColumnTemplate("excluded"),
        header: getExcludeHeader(),
        className: tableClasses.excludedColumn
      },
      {
        field: "resident",
        className: tableClasses.residentDisplayColumn
      },
      {
        field: "excludedReason",
        showCustomFilter: true,
        className: tableClasses.excludedReasonColumn
      },
      {
        field: "effectiveDate"
      },
      {
        field: "effectiveDateOverride",
        defaultInputEditorData: getDefaultInputEditorData(),
        className: tableClasses.effectiveDateOverrideColumn
      },
      {
        field: "termCategory",
        showCustomFilter: true,
        className: tableClasses.termCategoryColumn
      },
      {
        field: "recurringConcession",
        className: tableClasses.recurringConcessionsColumn
      },
      {
        field: "upfrontConcession",
        className: tableClasses.upfrontConcessionsColumn
      },
      {
        field: "dropRate",
        style: { width: "75px", textAlign: "right", paddingRight: "25px" }
      },
      {
        field: "ltlOrGtlPercentage",
        className: tableClasses.ltlGtlColumn,
        sortable: true
      },
      {
        field: "ltlOrGtlDollars",
        className: tableClasses.ltlGtlColumn,
        sortable: true
      },
      {
        field: "adjustedLtlOrGtlDollars",
        className: tableClasses.adjLtlGtlColumn,
        sortable: true
      },
      {
        field: "adjustedLtlOrGtlPercentage",
        className: tableClasses.adjLtlGtlColumn,
        sortable: true
      },
      {
        field: "maxPercentage",
        style: { width: "85px", textAlign: "right", paddingRight: "15px" }
      },
      {
        field: "maxDollars",
        style: { width: "85px", textAlign: "right", paddingRight: "15px" }
      },
      {
        field: "minPercentage",
        style: { width: "85px", textAlign: "right", paddingRight: "15px" }
      },
      {
        field: "minDollars",
        style: { width: "85px", textAlign: "right", paddingRight: "15px" }
      },
      {
        field: "rentOneYearAgo",
        className: tableClasses.rentOneYearAgoColumn
      },
      {
        field: "marketRent",
        className: tableClasses.adjustedRentColumn
      },
      {
        field: "growthRatePercentage",
        className: tableClasses.growthRateColumnRightAlign,
        defaultInputEditorData: getDefaultInputEditorData()
      },
      {
        field: "parameter",
        className: tableClasses.parameterAppliedColumn,
        showCustomFilter: true
      },
      {
        field: "maxAllowableRentDollars"
      },
      {
        field: "bestOfferDollars",
        className: tableClasses.bestOfferColumn
      },
      {
        field: "newRecurringConcession",
        className: tableClasses.newConcessionsColumn,
        sortable: true
      },
      {
        field: "newRecurringConcessionOverride",
        className: tableClasses.concessionOverrideDollarColumnRightAlign,
        defaultInputEditorData: getDefaultInputEditorData()
      },
      {
        field: "bestOfferOverrideDollars",
        className: tableClasses.bestOfferOverrideDollarColumnRightAlign,
        defaultInputEditorData: getDefaultInputEditorData()
      },
      {
        field: "bestOfferOverridePercentage",
        className: tableClasses.bestOfferOverridePercentColumnRightAlign,
        defaultInputEditorData: getDefaultInputEditorData()
      },
      {
        field: "rentChangeDollars",
        className: tableClasses.rentChange
      },
      {
        field: "rentChangePercentage",
        className: tableClasses.rentChange
      },
      {
        field: "netEffectiveOffer",
        className: tableClasses.rentChange,
        sortable: true
      },
      {
        field: "netEffectiveIncreasePercent",
        className: tableClasses.rentChange,
        sortable: true
      },
      {
        field: "actualOfferDate"
      },
      {
        field: "actualOfferDollar"
      },
      {
        field: "leasingAgentName",
        defaultInputEditorData: getDefaultInputEditorData()
      },
      {
        field: "leaseStatus"
      },
      {
        field: "newTerm"
      },
      {
        field: "moveInDate"
      },
      {
        field: "leaseExpires",
        body: dateToStringTemplate("leaseExpires"),
        sortable: true
      },
      {
        field: "leaseDescription",
        showCustomFilter: true,
        className: tableClasses.leaseDescriptionColumn
      },
      {
        field: "corporateOrEmployee",
        showCustomFilter: true
      },
      {
        field: "lastRentIncreaseDate",
        showCustomFilter: true
      },
      {
        field: "oneMonth",
        className: tableClasses.scalingColumn
      },
      {
        field: "twoMonths",
        className: tableClasses.scalingColumn
      },
      {
        field: "threeMonths",
        className: tableClasses.scalingColumn
      },
      {
        field: "fourMonths",
        className: tableClasses.scalingColumn
      },
      {
        field: "fiveMonths",
        className: tableClasses.scalingColumn
      },
      {
        field: "sixMonths",
        className: tableClasses.scalingColumn
      },
      {
        field: "sevenMonths",
        className: tableClasses.scalingColumn
      },
      {
        field: "eightMonths",
        className: tableClasses.scalingColumn
      },
      {
        field: "nineMonths",
        className: tableClasses.scalingColumn
      },
      {
        field: "tenMonths",
        className: tableClasses.scalingColumn
      },
      {
        field: "elevenMonths",
        className: tableClasses.scalingColumn
      },
      {
        field: "twelveMonths",
        className: tableClasses.scalingColumn
      },
      {
        field: "thirteenMonths",
        className: tableClasses.scalingColumn
      },
      {
        field: "fourteenMonths",
        className: tableClasses.scalingColumn
      },
      {
        field: "fifteenMonths",
        className: tableClasses.scalingColumn
      },
      {
        field: "anchorRent",
        className: tableClasses.rentOneYearAgoColumn
      }
    ];

    if (!props.areCommentsEnabled) {
      columnDefinitionSpecs = listUtils.copyListWithCustomKeyRemoved(
        columnDefinitionSpecs,
        ["comments"],
        "field"
      );
    }

    return generateColumnDefinitionUsingSpecs(columnDefinitionSpecs);
  };

  const getRowClassName = function (rowData: any): object {
    const offer = rowData as BestRenewalOffersFormOffer;
    if (!offer) {
      return {};
    }

    return {
      highlightRowForUnitException: offer.isUnitException,
      highlightRowForSelection:
        tableState.selectedOffers.length > 1 &&
        tableState.selectedOffers.some((o) => o.key === offer.key)
    };
  };

  const excludeAllOnCurrentPage = function () {
    const newOffers = tableState.selectedOffers;
    if (!newOffers) {
      return;
    }
    let excludeAll = true;

    if (newOffers.every((o) => o.excluded)) {
      excludeAll = false;
    }

    for (const offer of newOffers) {
      offer.excluded = excludeAll;
      offer.isChanged = true;
    }

    props.updateAllOffers(
      props.bestRenewalOffersKey,
      newOffers,
      props.offers,
      props.pageMode
    );
  };

  let header = (
    <div style={{ width: "25%" }}>
      <Input
        value={tableState.globalFilter}
        onChange={(e) => setTableState({ ...tableState, globalFilter: e })}
      />
    </div>
  );

  const offers = props.areFiltersEnabled ? props.filteredOffers : props.offers;
  const tableProps: DataTableProps<BestRenewalOffersFormOffer, any> = {
    value: offers,
    globalFilter: tableState.globalFilter,
    state: props.pagingState,
    updateState: props.updatePagingState,
    onPage: (e) => {
      props.updatePagingState({
        ...props.pagingState,
        offset: e.first,
        limit: e.rows
      });
    },
    selection: tableState.selectedOffers,
    selectionMode: "multiple",
    onSelectionChange: (e) => {
      setTableState({ ...tableState, selectedOffers: e.value });
    },
    deps: [offers],
    editMode: "cell",
    emptyMessage: "No units to display.",
    rowClassName: getRowClassName,
    pagingOptions: props.pagingOptions,
    ref: ref,
    frozenWidth: props.frozenColumns === true ? "690px" : undefined,
    scrollable: props.frozenColumns === true ? true : undefined,
    style:
      props.frozenColumns === true ? { width: `${width - 155}px` } : undefined
  };

  const dataTable = createDataTable<BestRenewalOffersFormOffer, any>(
    getTableColumns(),
    tableProps
  );

  return (
    <div>
      <RequirePermission
        permissions={["canAddEditDeleteUser"]}
        accessDenied={null}
      >
        {header}
      </RequirePermission>
      {dataTable}
    </div>
  );
});

export default BestRenewalOffersTable;
