import React from "react";
import useStyle from "./useStyle";

export type HeadingLevel = "1" | "2" | "3";

export interface HeadingProps {
  level: HeadingLevel;
}

const Heading: React.FC<HeadingProps> = function(props) {
  const classes = useStyle();
  let level = <div />;

  switch (props.level) {
    case "1":
      level = <h1 className={classes.h1}>{props.children}</h1>;
      break;
    case "2":
      level = <h2 className={classes.h2}>{props.children}</h2>;
      break;
    case "3":
      level = <h3 className={classes.h3}>{props.children}</h3>;
      break;
  }

  return level;
};

export default Heading;
