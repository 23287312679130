import React from "react";
import {
  DataTable as PrimeDataTable,
  DataTableProps as PrimeDataTableProps
} from "primereact/datatable";
import { Column, ColumnProps } from "primereact/column";
import AddButton from "components/controls/AddButton";
import RemoveButton from "components/controls/RemoveButton";
import Input from "./Input";
import validationUtils from "utils/validationUtils";
import ValidationWrapper from "./ValidationWrapper";

export interface DefaultInputEditorData<TRecord> {
  onChange: (row: TRecord, field: keyof TRecord, value: string) => void;
  isEditDisabled?: boolean | undefined;
  isEditDisabledForCell?: (row: TRecord, field: keyof TRecord) => boolean;
}

export type ColumnDefinition<TRecord, K extends keyof TRecord> = ColumnProps & {
  field?: K;
  header?: string | undefined;
  className?: string | undefined;
  hidden?: boolean;
  body?: (rowData: any, column: any) => void;
  selectionMode?: "single" | "multiple";
  defaultInputEditorData?: DefaultInputEditorData<TRecord>;
};

export type DataTableProps<TRecord, TTableState> = PrimeDataTableProps & {
  value: TRecord[] | undefined;
  state: TTableState;
  updateState: (state: TTableState) => void;
  paging?: boolean;
  pagingOptions?: number[];
  deps?: readonly any[];
  onAddRowAction?: (() => void) | undefined;
  onRemoveRowAction?: ((rowToRemove: TRecord) => void) | undefined;
  hasRemoveRowBuffer?: boolean | undefined;
  ref?: React.Ref<any>;
};

export interface IPagedTableState {
  offset?: number;
  limit?: number;
  scrollable?: boolean;
  scrollClass?: string;
  className?: string;
  rows?: any[];
}

type FilterOptions<TTable, TRecord> = {
  [K in keyof TTable]: (value: TTable[K], record: TRecord) => boolean;
};

export default function createDataTable<
  TRecord,
  TTableState extends IPagedTableState
>(
  columns: ColumnDefinition<TRecord, keyof TRecord>[],
  tableProps: DataTableProps<TRecord, TTableState>,
  filters?: FilterOptions<TTableState, TRecord>
) {
  const getEditableInputHoc = function (
    editData: DefaultInputEditorData<TRecord>,
    field: (string & keyof TRecord) | undefined
  ) {
    if (!field) {
      return <div />;
    }

    return (props: any) => {
      const row = props;

      let validationMessage = null;
      if (row.fieldValidationErrors) {
        validationMessage = validationUtils.getErrorForField(
          row.fieldValidationErrors,
          field
        );
      }

      if (
        editData.isEditDisabledForCell &&
        editData.isEditDisabledForCell(row as TRecord, field)
      ) {
        return (
          <ValidationWrapper showErrorState={validationMessage != null}>
            <div style={{ padding: "5px" }}>{row[field]}</div>
          </ValidationWrapper>
        );
      }

      return (
        <Input
          value={row[field]}
          onUnfocus={(value: string) => {
            editData.onChange(row as TRecord, field, value);
          }}
          validationMessage={validationMessage}
        />
      );
    };
  };

  columns.forEach((col) => {
    if (col.defaultInputEditorData) {
      col.body = col.defaultInputEditorData.isEditDisabled
        ? undefined
        : getEditableInputHoc(col.defaultInputEditorData, col.field);
    }
  });

  const cols = columns
    .filter((c) => c.hidden !== true)
    .map((c, i) => <Column key={i} {...c} />);

  const removeRowColumnEditor = (
    onRemoveRowAction: (toRemove: TRecord) => void
  ) => {
    return (rowProps: any, column: any) => {
      const row = rowProps;
      if (!row) {
        return <div />;
      }

      return <RemoveButton onClick={() => onRemoveRowAction(row)} />;
    };
  };

  const removeRowActionStyle = { width: "50px" };

  if (tableProps.onRemoveRowAction) {
    const removeColumnProps = {
      header: "",
      sortable: false,
      style: removeRowActionStyle,
      body: removeRowColumnEditor(tableProps.onRemoveRowAction)
    };

    cols.push(<Column key={cols.length} {...removeColumnProps} />);
  } else if (tableProps.hasRemoveRowBuffer) {
    const emptyRemoveColumnProps = {
      header: "",
      sortable: false,
      style: removeRowActionStyle
    };

    cols.push(<Column key={cols.length} {...emptyRemoveColumnProps} />);
  }

  let value = tableProps.value;
  if (filters != null && tableProps.value != null) {
    value = tableProps.value.filter((r) => {
      for (const field in filters) {
        const include = filters[field](tableProps.state[field], r);
        if (!include) {
          return false;
        }
      }
      return true;
    });
  }

  let pagingOptions = undefined;
  if (tableProps.pagingOptions) {
    if (tableProps.pagingOptions.length > 0) {
      pagingOptions = tableProps.pagingOptions;
    }
  } else {
    pagingOptions = [5, 10, 20];
  }

  const pagingIsEnabled = tableProps.paging == null ? true : tableProps.paging;
  tableProps = {
    ...tableProps,
    ...{
      value: value,
      paginator: pagingIsEnabled,
      rowsPerPageOptions: pagingOptions,
      first: tableProps.state.offset,
      rows: tableProps.state.limit,
      className: tableProps.className
    }
  };

  if (tableProps.onAddRowAction) {
    const footer = (
      <div>
        <div style={{ display: "flex" }}>
          <AddButton title="" onClick={tableProps.onAddRowAction} />
        </div>
        {tableProps.footer}
      </div>
    );

    if (pagingIsEnabled) {
      tableProps = { ...tableProps, paginatorLeft: footer };
    } else {
      tableProps = { ...tableProps, footer: footer };
    }
  }

  return (
    <div>
      <PrimeDataTable
        {...tableProps}
        resizableColumns={true}
        columnResizeMode="expand"
        reorderableColumns={true}
      >
        {cols}
      </PrimeDataTable>
    </div>
  );
}
